import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Form, InputNumber, message, Modal, Col, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { createReceiveValueCurrencyCoverage, createSendValueCurrencyCoverage, listReceiveValuesCurrencyCoverages, listSendValuesCurrencyCoverages, selectcurrencyCoverages } from "../../slice"
import stateStatus from "../../../../utils/status"
import { SyncOutlined } from "@ant-design/icons"

const AddNewCurrencyModal = ({ isModalVisible, setIsModalVisible }) => {
    const dispatch = useDispatch()
    const currencyCovarages = useSelector(selectcurrencyCoverages)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    const [isSend, setIsSend] = useState(undefined)
    const [isReceive, setIsReceive] = useState(undefined)
    const [isSelectedFlow, setIsSelectedFlow] = useState(true)
    const [selectedReceiveValueCurrencyCovarage, setSelectedReceiveValueCurrencyCovarage] = useState([])
    const [receiveValuesCurrencys, setReceiveValuesCurrencys] = useState([])
    const [selectedSendValueCurrencyCovarage, setSelectedSendValueCurrencyCovarage] = useState([])
    const [sendValuesCurrencys, setSendValuesCurrencys] = useState([])
    const [bothDispatchesSuccess, setBothDispatchesSuccess] = useState(false)

    const currencys = [
        { value: 'USD', label: 'USD' },
        { value: 'EUR', label: 'EUR' },
        { value: 'GBP', label: 'GBP' },
        { value: 'AUD', label: 'AUD' },
        { value: 'CAD', label: 'CAD' },
        { value: 'JPY', label: 'JPY' },
        { value: 'CHF', label: 'CHF' },
    ]

    useEffect(() => {
        if (isModalVisible) {
            setBothDispatchesSuccess(false)

            Promise.all([
                dispatch(listReceiveValuesCurrencyCoverages({ page: 1 })),
                dispatch(listSendValuesCurrencyCoverages({ page: 1 })),
            ]).then(([respReceive, respSend]) => {
                if (respReceive.payload && respSend.payload) {
                    setSelectedReceiveValueCurrencyCovarage(respReceive.payload?.receive_values_currency_coverage.map((item) => item.currency))
                    setSelectedSendValueCurrencyCovarage(respSend.payload?.send_values_currency_coverage.map((item) => item.currency))
                    setBothDispatchesSuccess(true)
                }
            }).catch((error) => {
                message.error('Erro interno do servidor:', error.message)
            });
        }
    }, [isModalVisible]);


    useEffect(() => {
        setReceiveValuesCurrencys(currencys.filter(currency =>
            !selectedReceiveValueCurrencyCovarage.includes(currency.value)
        ));
    }, [selectedReceiveValueCurrencyCovarage])

    useEffect(() => {
        setSendValuesCurrencys(currencys.filter(currency =>
            !selectedSendValueCurrencyCovarage.includes(currency.value)
        ));
    }, [selectedSendValueCurrencyCovarage])

    useEffect(() => {
        const statusReceive = currencyCovarages.status.createReceiveValueCurrencyCoverage
        const statusSend = currencyCovarages.status.createSendValueCurrencyCoverage

        if (statusReceive === stateStatus.loading || statusSend === stateStatus.loading) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }, [currencyCovarages.status])

    const onFinish = (values) => {
        values.D0 = typeof values.D0 === "number" ? values.D0 : "N/A"
        values.D1 = typeof values.D1 === "number" ? values.D1 : "N/A"
        values.D2 = typeof values.D2 === "number" ? values.D2 : "N/A"

        if (values.type === "send_values") {
            dispatch(createSendValueCurrencyCoverage(values)).then((resp) => {
                if (resp.payload) {
                    form.resetFields()
                    setIsModalVisible(false)
                    message.success("Sucesso!")
                    dispatch(listSendValuesCurrencyCoverages({ page: 1 }))
                    dispatch(listReceiveValuesCurrencyCoverages({ page: 1 }))
                } else {
                    message.error(resp.error.message || "Algo deu errado!")
                }
            })
        } else {
            dispatch(createReceiveValueCurrencyCoverage(values)).then((resp) => {
                if (resp.payload) {
                    form.resetFields()
                    setIsModalVisible(false)
                    message.success("Sucesso!")
                    dispatch(listSendValuesCurrencyCoverages({ page: 1 }))
                    dispatch(listReceiveValuesCurrencyCoverages({ page: 1 }))
                } else {
                    message.error(resp.error.message || "Algo deu errado!")
                }
            })
        }
    };

    return (
        <Modal
            title="Adicionar nova moeda"
            open={isModalVisible}
            okText="Criar"
            onOk={() => form.submit()}
            onCancel={() => {
                setIsModalVisible(false)
                form.resetFields()
                setIsSelectedFlow(true)
            }}
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            cancelButtonProps={{ disabled: isLoading }}
            maskClosable={true}
        >
            <div
                style={{
                    fontSize: "14px",
                    lineHeight: "25px",
                    marginBottom: "20px",
                }}
            >
                <p>
                    Insira abaixo o <strong>fluxo</strong> desejado e as demais informações para adicionar uma nova moeda.
                </p>
            </div>
            {!bothDispatchesSuccess ? (
                <SyncOutlined spin style={{ fontSize: 24, marginBottom: 10, width: "100%" }} />
            ) : (
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name={"type"}
                        label="Selecione o fluxo:"
                        rules={[
                            {
                                required: true,
                                message: "Informe o fluxo",
                            },
                        ]}
                    >
                        <Select
                            onChange={(v) => {
                                form.resetFields(['D0', 'D1', 'D2', "currency"])
                                if (v === "send_values") {
                                    setIsSend(0)
                                    setIsReceive(undefined)
                                    setIsSelectedFlow(false)
                                    if (sendValuesCurrencys.length === 0) {
                                        message.info({ content: "Todas moedas de saída já cadastradas", duration: 3 })
                                    }
                                }
                                if (v === "receive_values") {
                                    setIsSelectedFlow(false)
                                    setIsReceive(0)
                                    setIsSend(undefined)
                                    if (receiveValuesCurrencys.length === 0) {
                                        message.info({ content: "Todas moedas de entrada já cadastradas", duration: 3 })
                                    }
                                }
                            }}
                            options={[
                                { value: 'send_values', label: 'Saída' },
                                { value: 'receive_values', label: 'Entrada' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"currency"}
                        label="Selecione a moeda:"
                        rules={[
                            {
                                required: true,
                                message: "Informe a moeda",
                            },
                        ]}
                    >
                        <Select
                            options={form.getFieldValue("type") === "receive_values" ? receiveValuesCurrencys : sendValuesCurrencys}
                            disabled={isSelectedFlow ||
                                (form.getFieldValue("type") === "receive_values" && receiveValuesCurrencys.length === 0) ||
                                (form.getFieldValue("type") === "send_values" && sendValuesCurrencys.length === 0)
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name={"D0"}
                        label="D0:"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    value = value ? String(value).split(".") : false
                                    if (value && value[0].length > 4) {
                                        return Promise.reject(
                                            new Error("Certifique-se de que não haja mais de 4 dígitos antes do ponto decimal.")
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <InputNumber
                            placeholder="0.000000"
                            disabled={isSelectedFlow}
                            step={0.000001}
                            precision={6}
                            maxLength={11}
                            min={isSend}
                            max={isReceive}
                            style={{ width: "50%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"D1"}
                        label="D1:"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    value = value ? String(value).split(".") : false
                                    if (value && value[0].length > 4) {
                                        return Promise.reject(
                                            new Error("Certifique-se de que não haja mais de 4 dígitos antes do ponto decimal.")
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <InputNumber
                            placeholder="0.000000"
                            disabled={isSelectedFlow}
                            step={0.000001}
                            precision={6}
                            maxLength={11}
                            min={isSend}
                            max={isReceive}
                            style={{ width: "50%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"D2"}
                        label="D2:"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    value = value ? String(value).split(".") : false
                                    if (value && value[0].length > 4) {
                                        return Promise.reject(
                                            new Error("Certifique-se de que não haja mais de 4 dígitos antes do ponto decimal.")
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <InputNumber
                            placeholder="0.000000"
                            disabled={isSelectedFlow}
                            step={0.000001}
                            precision={6}
                            maxLength={11}
                            min={isSend}
                            max={isReceive}
                            style={{ width: "50%" }}
                        />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    )
}

export default AddNewCurrencyModal;

AddNewCurrencyModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
};
