import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../../utils/status'
import {
	getDataRecept,
	boReview,
	contractUpload,
	downloadFile,
	downloadZip,
	finalizeRecept,
	currencyRates,
	putRecept,
	postCatchRecept,
	approveReceiveValue,
	linkToSignedDoc,
	postDataRecept,
	removeRecept
} from './service'

const initialState = {
	status: {
		getRecept: stateStatus.idle,
		getReceptBackground: stateStatus.idle,
		postRecept: stateStatus.idle,
		saveRecept: stateStatus.idle,
		boReview: stateStatus.idle,
		refuse: stateStatus.idle,
		finalize: stateStatus.idle,
		uploadContract: stateStatus.idle,
		getCurrencyRates: stateStatus.idle,
		catchRecept: stateStatus.idle,
		downloadContract: stateStatus.idle,
		fileDownload: {},
		approvedReceiveValue: stateStatus.idle,
	},
	currencyRate: null,
	recept: null,
	errorMsg: null
}

export const saveRecept = createAsyncThunk(
	'recept/saveRecept',
	async (data) => {
		const response = await putRecept(data)
		return response.data
	}
)

export const getRecept = createAsyncThunk(
	'recept/getRecept',
	async ({ id }) => {
		const response = await getDataRecept({
			receive_value_id: id,
		})
		return response.data
	}
)

export const getReceptBackground = createAsyncThunk(
	'recept/getReceptBackground',
	async ({ id }) => {
		const response = await getDataRecept({
			receive_value_id: id,
		})
		return response.data
	}
)

export const postRecept = createAsyncThunk(
	'recept/postRecept',
	async (data) => {
		const response = await postDataRecept(data)
		return response.data
	}
)

export const catchRecept = createAsyncThunk(
	'recept/catchRecept',
	async (data) => {
		const response = await postCatchRecept(data)
		return response.data
	}
)


export const approveReceiveValueThunk = createAsyncThunk(
	'recept/approveReceiveValue',
	async (receiveValueId) => {
		const response = await approveReceiveValue(receiveValueId)
		return response.data
	}
)


export const postBoReview = createAsyncThunk(
	'recept/boReview',
	async (data) => {
		const response = await boReview({
			receive_value_id: data.receive_value_id,
			action_type: "positive",
			text: "Operação aprovada.",
			nature_exchange: data.nature_exchange,
			spread: data.spread,
			taxes: data.taxes,
		})
		return response.data
	}
)

export const refuse = createAsyncThunk(
	'recept/refuse',
	async ({ id, need_changes }) => {
		const response = await boReview({
			receive_value_id: id,
			action_type: "negative",
			text: need_changes,
		})
		return response.data
	}
)

export const fileDownload = createAsyncThunk(
	'recept/fileDownload',
	async ({ file }) => {
		const response = await downloadFile(file.id)
		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', file.file_name); //any other extension
		document.body.appendChild(link);
		link.click();
		link.remove();
		return true
	}
)

function createBlob(data, contentType) {
	return new Blob([data], { type: contentType });
}

export const downloadZipFiles = createAsyncThunk(
	'payment/downloadZipFiles',
	async ({ receiveValueId }, { rejectWithValue }) => {
		try {
			const response = await downloadZip(receiveValueId)
			const zipBloob = createBlob(response.data, 'application/zip')
			const zipUrl = URL.createObjectURL(zipBloob)
			const link = document.createElement('a')
			link.href = zipUrl
			link.setAttribute('download', 'transaction_files.zip')
			document.body.appendChild(link)
			link.click()
			link.remove()

			return true
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)

export const uploadContract = createAsyncThunk(
	'recept/uploadContract',
	async ({ id, file }) => {
		const data = new FormData()
		data.append("file", file)
		data.append("receive_value_id", id)
		const response = await contractUpload(data)
		return response.data
	}
)

export const downloadContract = createAsyncThunk(
	'payment/downloadContract',
	async (fileId) => {
		const response = await linkToSignedDoc(fileId)
		window.open(response.data.signed_file_url || response.data.original_file_url, "_blank")
		return true
	}
)

export const finalize = createAsyncThunk(
	'recept/finalize',
	async ({ id, comments }) => {
		const response = await finalizeRecept({
			receive_value_id: id,
			comments,
		})
		return response.data
	}
)

export const deleteRecept = createAsyncThunk(
	'recept/deleteRecept',
	async ({ id }) => {
		const response = await removeRecept(id)
		return response.data
	}
)


export const getCurrencyRates = createAsyncThunk(
	'recept/currency_rates',
	async ({ id }) => {
		const response = await currencyRates({
			receive_value_id: id,
		})
		return response.data.rate
	}
)



export const recept = createSlice({
	name: 'recept',
	initialState,
	reducers: {
		resetStatus: (state) => {
			state.status.getRecept = stateStatus.idle
		},
		removeFile: (state, action) => {
			state.recept = {
				...state.recept,
				files: state.recept.files.filter(file => file.id !== action.payload),
			}
		},
	},
	extraReducers: {
		// Get recept by ID
		[getRecept.pending]: (state) => {
			state.status.getRecept = stateStatus.loading
		},
		[getRecept.fulfilled]: (state, action) => {
			state.status.getRecept = stateStatus.succeeded
			state.recept = action.payload
		},
		[getRecept.rejected]: (state, action) => {
			state.status.getRecept = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Get recept by ID
		[getReceptBackground.pending]: (state) => {
			state.status.getReceptBackground = stateStatus.loading
		},
		[getReceptBackground.fulfilled]: (state, action) => {
			state.status.getReceptBackground = stateStatus.succeeded
			state.recept = action.payload
		},
		[getReceptBackground.rejected]: (state, action) => {
			state.status.getReceptBackground = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Post data recept
		[postRecept.pending]: (state) => {
			state.status.postRecept = stateStatus.loading
		},
		[postRecept.fulfilled]: (state, action) => {
			state.status.postRecept = stateStatus.succeeded
			state.recept = action.payload
		},
		[postRecept.rejected]: (state, action) => {
			state.status.postRecept = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Save Recept
		[saveRecept.pending]: (state) => {
			state.status.saveRecept = stateStatus.loading
		},
		[saveRecept.fulfilled]: (state, action) => {
			state.status.saveRecept = stateStatus.succeeded
			state.recept = action.payload
		},
		[saveRecept.rejected]: (state, action) => {
			state.status.saveRecept = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Catch Recept
		[catchRecept.pending]: (state) => {
			state.status.catchRecept = stateStatus.loading
		},
		[catchRecept.fulfilled]: (state, action) => {
			state.status.catchRecept = stateStatus.succeeded
			state.recept = action.payload
		},
		[catchRecept.rejected]: (state, action) => {
			state.status.catchRecept = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Review Recept
		[postBoReview.pending]: (state) => {
			state.status.boReview = stateStatus.loading
		},
		[postBoReview.fulfilled]: (state, action) => {
			state.status.boReview = stateStatus.succeeded
			state.recept = action.payload
		},
		[postBoReview.rejected]: (state, action) => {
			state.status.boReview = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Upload Contract
		[uploadContract.pending]: (state) => {
			state.status.uploadContract = stateStatus.loading
		},
		[uploadContract.fulfilled]: (state, action) => {
			state.status.uploadContract = stateStatus.succeeded
			state.recept = action.payload
		},
		[uploadContract.rejected]: (state, action) => {
			state.status.uploadContract = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// refuse recept
		[refuse.pending]: (state) => {
			state.status.refuse = stateStatus.loading
		},
		[refuse.fulfilled]: (state, action) => {
			state.status.refuse = stateStatus.succeeded
			state.recept = action.payload
		},
		[refuse.rejected]: (state, action) => {
			state.status.refuse = stateStatus.failed
		},
		// Finalize Recept
		[finalize.pending]: (state) => {
			state.status.finalize = stateStatus.loading
		},
		[finalize.fulfilled]: (state, action) => {
			state.status.finalize = stateStatus.succeeded
			state.recept = action.payload
		},
		[finalize.rejected]: (state, action) => {
			state.status.finalize = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// download file
		[fileDownload.pending]: (state, action) => {
			const { file } = action.meta.arg
			state.status.fileDownload[file.id] = stateStatus.loading
		},
		[fileDownload.fulfilled]: (state, action) => {
			const { file } = action.meta.arg
			state.status.fileDownload[file.id] = stateStatus.succeeded
		},
		[fileDownload.rejected]: (state, action) => {
			const { file } = action.meta.arg
			state.status.fileDownload[file.id] = stateStatus.failed
			state.errorMsg = `Erro ao fazer o download do arquivo ${file.file_name}`
		},
		// download contract
		[downloadContract.pending]: (state) => {
			state.status.downloadContract = stateStatus.loading
		},
		[downloadContract.fulfilled]: (state, action) => {
			state.status.downloadContract = stateStatus.succeeded
		},
		[downloadContract.rejected]: (state, action) => {
			state.status.downloadContract = stateStatus.failed
			state.errorMsg = "Erro ao gerar link de download"
		},
		// currency rates
		[getCurrencyRates.pending]: (state) => {
			state.status.getCurrencyRates = stateStatus.loading
		},
		[getCurrencyRates.fulfilled]: (state, action) => {
			state.status.getCurrencyRates = stateStatus.succeeded
			state.currencyRate = action.payload
		},
		[getCurrencyRates.rejected]: (state, action) => {
			state.status.getCurrencyRates = stateStatus.failed
		},

		// approved send value
		[approveReceiveValueThunk.pending]: (state) => {
			state.approvedReceiveValue = stateStatus.loading
		},
		[approveReceiveValueThunk.fulfilled]: (state, action) => {
			state.approvedReceiveValue = stateStatus.succeeded
			state.recept = action.payload
		},
		[approveReceiveValueThunk.rejected]: (state, action) => {
			state.approvedReceiveValue = stateStatus.failed
			state.errorMsg = action.error.message
		}
	}
})

export const { resetStatus, removeFile } = recept.actions;

export const selectRecept = (state) => state.recept
