import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getSendValuesCurrencyCoveragesList,
    getReceiveValuesCurrencyCoveragesList,
    putSendValuesCurrencyCoverage,
    putReceiveValuesCurrencyCoverage,
    createSendValuesCurrencyCoverage,
    createReceiveValuesCurrencyCoverage,
    deleteSendValuesCurrencyCoverage,
    deleteReceiveValuesCurrencyCoverage,
} from "./service";
import stateStatus from "../../utils/status";

const initialState = {
    status: {
        listReceiveValuesCurrencyCoverages: stateStatus.idle,
        listSendValuesCurrencyCoverages: stateStatus.idle,
        createSendValueCurrencyCoverage: stateStatus.idle,
        createReceiveValueCurrencyCoverage: stateStatus.idle,
        deleteSendValueCurrencyCoverage: stateStatus.idle,
        deleteReceiveValueCurrencyCoverage: stateStatus.idle,
    },
    errorMsg: null,
    listReceiveValuesCurrencyCoverages: [],
    listSendValuesCurrencyCoverages: [],
    pagination: {
        count: 0,
        page: 1,
        totalPages: 1,
    },
};

export const listSendValuesCurrencyCoverages = createAsyncThunk(
    "sendValues/listSendValuesCurrencyCoverages ",
    async ({ page }) => {
        const response = await getSendValuesCurrencyCoveragesList({ page })
        return response.data;
    },
);

export const listReceiveValuesCurrencyCoverages = createAsyncThunk(
    "receiveValues/listReceiveValuesCurrencyCoverages ",
    async ({ page }) => {
        const response = await getReceiveValuesCurrencyCoveragesList({ page })
        return response.data;
    },
);

export const editSendValueCurrencyCoverage = createAsyncThunk(
    "sendValues/editSendValueCurrencyCoverage",
    async (data) => {
        const response = await putSendValuesCurrencyCoverage(data)
        return response.data
    }
)

export const editReceiveValueCurrencyCoverage = createAsyncThunk(
    "receiveValues/editReceiveValueCurrencyCoverage",
    async (data) => {
        const response = await putReceiveValuesCurrencyCoverage(data)
        return response.data
    }
)

export const createSendValueCurrencyCoverage = createAsyncThunk(
    "sendValues/createSendValueCurrencyCoverage",
    async (data) => {
        const response = await createSendValuesCurrencyCoverage(data)
        return response.data
    }
)

export const createReceiveValueCurrencyCoverage = createAsyncThunk(
    "receiveValues/createReceiveValueCurrencyCoverage",
    async (data) => {
        const response = await createReceiveValuesCurrencyCoverage(data)
        return response.data
    }
)

export const deleteSendValueCurrencyCoverage = createAsyncThunk(
    "sendValues/deleteSendValueCurrencyCoverage",
    async ({currency}) => {
        const response = await deleteSendValuesCurrencyCoverage({ currency })
        return response.data
    }
)

export const deleteReceiveValueCurrencyCoverage = createAsyncThunk(
    "receiveValues/deleteReceiveValueCurrencyCoverage",
    async ({currency}) => {
        const response = await deleteReceiveValuesCurrencyCoverage({ currency })
        return response.data
    }
)

export const currencyCoveragesSlice = createSlice({
    name: "currencyCoverages",
    initialState,
    reducers: {},
    extraReducers: {
        [listReceiveValuesCurrencyCoverages.pending]: (state) => {
            state.status.listReceiveValuesCurrencyCoverages = stateStatus.loading;
        },
        [listReceiveValuesCurrencyCoverages.fulfilled]: (state, action) => {
            state.status.listReceiveValuesCurrencyCoverages = stateStatus.succeeded;
            state.listReceiveValuesCurrencyCoverages = action.payload;
        },
        [listReceiveValuesCurrencyCoverages.rejected]: (state, action) => {
            state.status.listReceiveValuesCurrencyCoverages = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [listSendValuesCurrencyCoverages.pending]: (state) => {
            state.status.listSendValuesCurrencyCoverages = stateStatus.loading;
        },
        [listSendValuesCurrencyCoverages.fulfilled]: (state, action) => {
            state.status.listSendValuesCurrencyCoverages = stateStatus.succeeded;
            state.listSendValuesCurrencyCoverages = action.payload;
        },
        [listSendValuesCurrencyCoverages.rejected]: (state, action) => {
            state.status.listSendValuesCurrencyCoverages = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [createSendValueCurrencyCoverage.pending]: (state) => {
            state.status.createSendValueCurrencyCoverage = stateStatus.loading;
        },
        [createSendValueCurrencyCoverage.fulfilled]: (state, action) => {
            state.status.createSendValueCurrencyCoverage = stateStatus.succeeded;
            state.createSendValueCurrencyCoverage = action.payload;
        },
        [createSendValueCurrencyCoverage.rejected]: (state, action) => {
            state.status.createSendValueCurrencyCoverage = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [createReceiveValueCurrencyCoverage.pending]: (state) => {
            state.status.createReceiveValueCurrencyCoverage = stateStatus.loading;
        },
        [createReceiveValueCurrencyCoverage.fulfilled]: (state, action) => {
            state.status.createReceiveValueCurrencyCoverage = stateStatus.succeeded;
            state.createReceiveValueCurrencyCoverage = action.payload;
        },
        [createReceiveValueCurrencyCoverage.rejected]: (state, action) => {
            state.status.createReceiveValueCurrencyCoverage = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [deleteSendValueCurrencyCoverage.pending]: (state) => {
            state.status.deleteSendValueCurrencyCoverage = stateStatus.loading;
        },
        [deleteSendValueCurrencyCoverage.fulfilled]: (state, action) => {
            state.status.deleteSendValueCurrencyCoverage = stateStatus.succeeded;
            state.deleteSendValueCurrencyCoverage = action.payload;
        },
        [deleteSendValueCurrencyCoverage.rejected]: (state, action) => {
            state.status.deleteSendValueCurrencyCoverage = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [deleteReceiveValueCurrencyCoverage.pending]: (state) => {
            state.status.deleteReceiveValueCurrencyCoverage = stateStatus.loading;
        },
        [deleteReceiveValueCurrencyCoverage.fulfilled]: (state, action) => {
            state.status.deleteReceiveValueCurrencyCoverage = stateStatus.succeeded;
            state.deleteReceiveValueCurrencyCoverage = action.payload;
        },
        [deleteReceiveValueCurrencyCoverage.rejected]: (state, action) => {
            state.status.deleteReceiveValueCurrencyCoverage = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
    },
});

export const selectcurrencyCoverages = (state) => state.currencycoverages