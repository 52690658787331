import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
	Layout,
	Card,
	Form,
	Button,
	Col,
	Row,
	Typography,
	DatePicker,
	message
} from "antd";

import Base from "../../common/components/Base";
import TransactionsBread from "./components/TransactionsBread";
import { exportTransactions, selectTransactions } from './slice';
import stateStatus from '../../utils/status';

const { Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const Transactions = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const trans = useSelector(selectTransactions)
	const [exported, setExported] = useState(false)

	useEffect(() => {
		if (trans.status.exportTransactions === stateStatus.failed && exported) {
			message.error("Nenhuma operação foi encontrada para o período desejado.")
			setExported(false)
		}
	});

	const onFinish = (values) => {
		let starts_at = values.date[0].format('YYYY-MM-DDTHH:mm:ss')
		let ends_at = values.date[1].format('YYYY-MM-DDTHH:mm:ss')
		setExported(true)
		dispatch(exportTransactions({ starts_at, ends_at }))
	};

	function disabledDate(current) {
		// Can not select days before today and today
		return current && current < moment().endOf("day");
	}

	const range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	const disabledRangeTime = (_, type) => {
		if (type === "start") {
			return {
				// disabledHours: () => range(0, 60),
				disabledMinutes: () => range(0, 60),
				disabledSeconds: () => range(0, 60),
			};
		}
		return {
			// disabledHours: () => range(0, 60),
			disabledMinutes: () => range(0, 60),
			disabledSeconds: () => range(0, 60),
		};
	};

	return (
		<Base selectedKeys={["7"]}>
			<Content className="content-people">
				<TransactionsBread />
				<Card>
					<Title
						style={{ marginLeft: "9px", marginBottom: "10px" }}
						level={5}
					>
						Exportar Operações
					</Title>
					<Form layout="vertical" form={form} onFinish={onFinish}>
						<Row gutter={[8, 0]} align="bottom">
							<Col sm={12} md={8}>
								<Form.Item
									label="Início - Fim"
									name="date"
									rules={[
										{
											required: true,
											message: "Campo obrigatório",
										},
									]}
									style={{ margin: 0, marginRight: 8 }}
								>
									<RangePicker
										style={{ width: "100%" }}
										disabledTime={disabledRangeTime}
										showTime={{
											hideDisabledOptions: true,
											defaultValue: [
												moment(
													"00:00:00",
													"HH:mm:ss",
												),
												moment(
													"11:59:59",
													"HH:mm:ss",
												),
											],
										}}
										format="YYYY-MM-DD HH:mm:ss"
									/>
								</Form.Item>
							</Col>
							<Col sm={12} md={16}>
								<Button
									type="primary"
									htmlType="submit"
									loading={trans.status.exportTransactions == stateStatus.loading}
								>
									Exportar
								</Button>
							</Col>
						</Row>
					</Form>
				</Card>
			</Content>
		</Base>
	);
};

export default Transactions;
