import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Input, message } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { mask } from '../../../../utils/consts';
import { UserOutlined } from '@ant-design/icons';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { findUser, postAddPerson, } from '../../slice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from '../../../../routes/routes';
import { getPerson } from '../../Person/slice';

const AddNewPersonModal = ({ isModalVisible, setIsModalVisible, form }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = (values) => {
        setIsLoading(true)
        values.cpf = cpfValidator.strip(values.cpf);
        dispatch(findUser(values.email)).then((resp) => {
            if (!resp.payload) {
                setIsLoading(false)
                message.error(resp.error.message || "Erro ao cadastrar pessoa. Tente novamente.");
            } else if (resp.payload) {
                dispatch(postAddPerson(values)).then((resp) => {
                    setIsLoading(false)
                    if (!resp.payload) {
                        message.error(resp.error.message || "Erro ao cadastrar pessoa. Tente novamente.");
                    } else if (resp.payload) {
                        dispatch(getPerson({ id: resp.payload.id }))
                        history.push(`${routes.people}/${resp.payload.id}`)
                        setIsModalVisible(false);
                    }
                })
            }
        })
    };

    return (
        <Modal
            title="Adicionar nova pesssoa"
            open={isModalVisible}
            onOk={() => form.submit()}
            onCancel={() => setIsModalVisible(false)}
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            cancelButtonProps={{ disabled: isLoading }}
            maskClosable={true}
        >
            <div
                style={{
                    fontSize: "14px",
                    lineHeight: "25px",
                    marginBottom: "20px",
                }}
            >
                <p>
                    Insira abaixo o <strong>e-mail</strong> cadastrado pelo usuário, o <strong>CPF</strong> e o <strong>nome completo</strong> da pessoa que será cadastrada.
                </p>
            </div>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name={"email"}
                    rules={[
                        {
                            required: true,
                            message: "Informe o email",
                        },
                        {
                            type: "email",
                            message: "Informe um email válido.",
                        },
                    ]}
                >
                    <Input
                        placeholder="E-mail do usuário"
                    />
                </Form.Item>
                <Form.Item
                    name={"cpf"}
                    rules={[
                        {
                            required: true,
                            message: "Informe o CPF",
                        },
                        () => ({
                            validator(_, value) {
                                if (!value || cpfValidator.isValid(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("CPF inválido.")
                                );
                            },
                        }),
                    ]}
                >
                    <MaskedInput
                        placeholder="CPF"
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        mask={mask.cpf}
                    ></MaskedInput>
                </Form.Item>
                <Form.Item
                    name={"name"}
                    rules={[
                        {
                            required: true,
                            message: "Informe o nome completo",
                        },
                    ]}
                >
                    <Input placeholder="Nome completo" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddNewPersonModal;

AddNewPersonModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
};
