import axios from 'axios'
import api from '../../../../../service/api'
import apiRoutes from '../../../../../service/apiRoutes'

export const getListDocuments = (id) => api.get(`${apiRoutes.person}${id}/documents`)

export const getDownloadPersonDoc = (id) => api.get(`${apiRoutes.person}document/${id}`, { responseType: 'blob' })

export const getDownloadPersonZip = (id) => api.get(`${apiRoutes.person}${id}/zip`, { responseType: 'blob' })

export const deletePersonDoc = (id) => api.delete(`${apiRoutes.person}document/${id}`)

export const postUploadPersonDoc = (formData, config) => api.post(`${apiRoutes.person}document`, formData, config);

export const postDocument = (id, formData, config) => api.post(`${apiRoutes.person}document?id=${id}`, formData, config)