/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import { Col, Form, Modal, Row, Select, Table, Tooltip } from "antd";
import "./styles.scss";
import InputPorcent from "../../../../common/components/inputPorcent";
import { naturesExchangeList } from "../../../../utils/lists";

import EditableSelection from '../EditableSelection';
import CurrencyField from "../../../../common/components/currency_field/currency_field";

const OperationTaxesModal = ({
	data,
	isVisible,
	isLoading,
	onSubmit,
	onCancel,
	isSendValues = true,
}) => {
	const [form] = Form.useForm();

	const dataSource = [
		{
			name: "IOF",
			key: 0,
			body: (
				<EditableSelection
					listValues={[
						{
							label: "0.0%",
							value: "0",
							prefix: "iof",
						},
						{
							label: "0.38%",
							value: "38",
							prefix: "iof",
						},
						{
							label: "1.1%",
							value: "110",
							prefix: "iof",
						},
						{
							label: "6.36%",
							value: "636",
							prefix: "iof",
						},
					]}
				/>
			),
		},

		{
			name: "IR",
			key: 1,
			body: isSendValues ? (
				<Form.Item
					name={["tax", "ir"]}
					rules={[
						{
							required: true,
							message: "Campo obrigatório",
						},
					]}
				>
					<InputPorcent style={{ width: "100px" }} precision={6} />
				</Form.Item>
			) : null,
		},

		{
			name: "Tarifa",
			key: 1,
			body: (
				<Form.Item
					initialValue={0.00}
					name={["tax", "tarifa"]}
					rules={[
						{
							required: true,
							message: "Campo obrigatório",
						},
					]}
				>
					<CurrencyField
						style={{ width: "100px" }}
					/>
				</Form.Item>
			),
		},
	];
	const columns = [
		{
			title: "Impostos e Taxas",
			dataIndex: "name",
		},
		{
			title: "Value",
			dataIndex: "body",
		},
	];

	return (
		<Modal
			className="operation-taxes-modal"
			title="Taxas de câmbio"
			visible={isVisible}
			confirmLoading={isLoading}
			onOk={() => {
				form.submit();
			}}
			onCancel={() => {
				form.resetFields()
				onCancel();
			}}
		>
			<Form
				layout="vertical"
				form={form}
				onFinish={(values) => onSubmit(values)}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							label="Spread"
							name="spread"
							initialValue={data.spread}
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<InputPorcent />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Natureza do Câmbio"
							name="nature_exchange"
							initialValue={""}
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Select showSearch>
								{naturesExchangeList.map((item, index) => {
									return (
										<Select.Option
											key={item.key}
											value={`${item.key} - ${item.value}`}
											title={`${item.key} - ${item.value}`}
										>
											<Tooltip>
												{item.key} - {item.value}
											</Tooltip>
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col md={24}>
						<Table
							dataSource={dataSource}
							columns={columns}
							pagination={false}
						></Table>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};
export default OperationTaxesModal;
