import React, { useCallback } from "react"
import PropTypes from 'prop-types'
import { InputNumber, Space } from "antd"
import { formatPorcent } from "../../../utils/functions"

const InputPorcent = ({ precision=2, ...props }) => {

	return (
		<Space direction="vertical">
			<InputNumber
				formatter={(value) => formatPorcent(value, precision)}
				parser={(value) => value.replace(/\D/g, "")}
				placeholder="0,00 %"
				style={{ width: "100%" }}
				max={1000000000}
				{...props}
			/>
		</Space>
	)
}
InputPorcent.propTypes = {
	precision: PropTypes.number,
}
export default InputPorcent