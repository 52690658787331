import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import addQsa from "../../../../common/assets/add-qsa.svg"
import { Modal, Card, Row, Button, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchQsa,
    selectQsa,
    toggleSelectedQsa,
    addNewQsa,
    removeQsa,
    getResponsibles,
    addNewResposible,
    removeResponsible,
} from '../../SelectFinRespSlice';
import stateStatus from '../../../../utils/status';
import PartnerCard from '../../../../common/components/PartnerCard';
import AddNewQsa from '../AddNewQsa';
import "./styles.scss"
import { getCompanyId, selectCompanyId } from '../../Company/slice';

const AddFRModal = ({ isModalVisible, setIsModalVisible, companyId }) => {
    const financialResponsibles = useSelector(selectQsa)
    const company = useSelector(selectCompanyId)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [addQsaModal, updateAddQsaModal] = useState(false);

    useEffect(() => {
        dispatch(fetchQsa(companyId))
        dispatch(getResponsibles(companyId))
    }, []);

    useEffect(() => {
        if (financialResponsibles?.status.responsibles === stateStatus.idle) {
            dispatch(getResponsibles(companyId))
        }
        if (financialResponsibles?.status.list === stateStatus.idle && financialResponsibles?.status.responsibles === stateStatus.succeeded) {
            dispatch(fetchQsa(companyId));
        }
    });

    const onAddQsa = (values) => {
        values.cpf = values.cpf.replace(/\D/g, "");
        dispatch(addNewQsa(values));
        dispatch(toggleSelectedQsa(values));
        updateAddQsaModal(false);
    };

    const hangleChangeSelected = (value) => {
        if (!value.isChecked) {
            const isPersonSelected = financialResponsibles.data.responsibles.some(responsible => Number(responsible.id) === Number(value.id));
            isPersonSelected && dispatch(removeResponsible({ id: companyId, fin_id: value.id }))
        }
        dispatch(toggleSelectedQsa(value))
    }

    const onFinish = () => {
        setIsLoading(true)
        const newQsas = [];
        const Qsas = [];
        financialResponsibles.data.responsibles.forEach((it, index) => {
            const newQsa = {};
            newQsa.is_pep = false;

            if (it.qualification === "socio") {
                newQsa["id"] = `${it.id}`;
                newQsa["cpf"] = it.cpf;
                newQsa["qualification"] = it.qualification;
                newQsa["name"] = it.name;
                newQsa["is_qsa"] = true
                Qsas.push(newQsa);
            } else {
                newQsa["cpf"] = it.cpf;
                newQsa["qualification"] = it.qualification;
                newQsa["name"] = it.name;
                newQsas.push(newQsa);
            }
        });
        dispatch(addNewResposible({ qsa: Qsas, new_qsa: newQsas, id: companyId })).then((resp) => {
            setIsLoading(false)
            if (resp.payload) {
                setIsModalVisible(false)
                dispatch(getCompanyId({ id: companyId }))
            } else if (!resp.payload) {
                message.error(resp.error.message || "Erro ao adicionar Responsável. Tente novamente.");
            }
        });
    };

    return (
        <Modal
            title="Administrador(es) da empresa"
            open={isModalVisible}
            onOk={onFinish}
            onCancel={() => setIsModalVisible(false)}
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            cancelButtonProps={{ disabled: isLoading }}
            maskClosable={true}
        >
            <div
                style={{
                    fontSize: "14px",
                    lineHeight: "25px",
                    marginBottom: "20px",
                }}
            >
                <p>
                    Selecione até 5 (cinco) responsáveis financeiros.
                </p>
            </div>
            <Card>
                {financialResponsibles.status.list === stateStatus.loading ? (
                    <Row justify="center">
                        <SyncOutlined spin />
                    </Row>
                ) : (
                    <> {financialResponsibles.data.allQsa.length > 0 ?
                        financialResponsibles.data.allQsa.map((qsa, index) => {
                            const blockNew = financialResponsibles.data.responsibles.length >= 5;
                            const isChecked = financialResponsibles.data.responsibles.filter(
                                (item) => item.name === qsa.name
                            ).length > 0;

                            return (
                                <Row key={qsa.name}>
                                    <PartnerCard
                                        isOdd={index % 2 === 0}
                                        personData={qsa}
                                        checked={isChecked}
                                        removePerson={(value) => { dispatch(removeQsa(qsa)); dispatch(toggleSelectedQsa(value)) }}
                                        disabled={blockNew ? (isChecked ? false : true) : false}
                                        onChange={hangleChangeSelected}
                                    />
                                </Row>
                            );
                        }) : null}
                        {company.company.financial_responsibles.length > 0 ?
                            company.company.financial_responsibles.map((fin_res, index) => {
                                const blockNew = financialResponsibles.data.responsibles.length >= 5;
                                const isChecked = financialResponsibles.data.responsibles.filter(
                                    (item) => item.name === fin_res.name
                                ).length > 0;

                                if (fin_res.is_qsa === false) {
                                    return (
                                        <Row key={fin_res.name}>
                                            <PartnerCard
                                                isOdd={index % 2 === 0}
                                                personData={fin_res}
                                                checked={isChecked}
                                                removePerson={(value) => { dispatch(removeQsa(fin_res)); dispatch(toggleSelectedQsa(value)) }}
                                                disabled={blockNew ? (isChecked ? false : true) : false}
                                                onChange={hangleChangeSelected}
                                            />
                                        </Row>
                                    );
                                } else {
                                    return null;
                                }

                            }) : null}
                        {financialResponsibles.data.allQsa.length <= 0
                            && company.company.financial_responsibles.length <= 0
                            && <div className="blank-container">
                                <img src={addQsa} alt='imagem para representar a função: Adicione um responsável financeiro' />
                                <p>Adicione um responsável financeiro</p>
                            </div>
                        }
                        <br />
                        <Row justify="end">
                            <Button
                                style={{ width: "180px" }}
                                onClick={() => updateAddQsaModal(true)}
                                disabled={
                                    financialResponsibles.data.responsibles.length === 5
                                }
                            >
                                Adicionar responsável
                            </Button>
                        </Row>
                    </>
                )}
            </Card>
            <AddNewQsa
                visible={addQsaModal}
                onCancel={() => updateAddQsaModal(false)}
                onOk={onAddQsa.bind(this)}
            />
        </Modal>
    );
};

export default AddFRModal;

AddFRModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired
};
