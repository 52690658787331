import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Card, Typography, Row, Col, Select } from 'antd'
import MaskedInput from 'antd-mask-input'
import moment from 'moment'
import TooltipEfex from '../../../../../common/components/Tooltip'
import IdentedField from "../../../../../common/components/IdentedField"
import { beautifyCpfCnpj } from '../../../../../utils/beautify'
import { optionsNat, countriesList, mask } from "../../../../../utils/consts"
import { BankList, BrazilStates } from "../../../../../utils/lists";
import { cnpj as cnpjValidator, cpf as cpfValidator } from "cpf-cnpj-validator";
import { getAddressByCep } from '../../../../../service/viacep.service'

import './styles.scss'
import CurrencyField from '../../../../../common/components/currency_field/currency_field'
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import br from 'world_countries_lists/data/countries/en/world.json';
import _ from 'lodash'

const { Title, Text } = Typography
const { Option } = Select

const CadastraData = ({ form, person, valueCountryNumber, onChangePhoneInput, finished, onChangeCountry, onChangeCep, useCep, ...props }) => {
	const [state, setState] = useState({});

	const updateState = (key, value) => {
		state[key] = value;
		setState({ ...state });
	};

	return (<>
		<Row gutter={[8, 8]}>
			<Col sm={12}>
				<Card>
					<Title level={5}>
						{person.person !== null && person.person.name}
					</Title>
					<Text type="secondary">
						{beautifyCpfCnpj(
							person.person !== null && person.person.cpf,
						)}
					</Text>
					<br />
					<br />
					<p className="subtitle">Dados Cadastrais</p>
					<Form.Item hidden={true} name={"qualification"}>
						<Input type="text"></Input>
					</Form.Item>
					<Row justify="space-between">
						<Col>
							<Form.Item
								label="Número do documento"
								name={"doc_number"}
								rules={[
									{
										required: true,
										message: "Documento inválido.",
									},
								]}
							>
								<Input
									addonBefore={TypeSelector()}
									placeholder="Número"
									type="text"
								></Input>
							</Form.Item>
						</Col>
						<Col>
							<Form.Item
								label="UF Emissor"
								name="doc_uf"
								rules={[
									{
										required: true,
										message:
											"UF emissora obrigatório",
									}
								]}
							>
								<Select placeholde="UF">
									<Select.Option value="AC">AC</Select.Option>
									<Select.Option value="AL">AL</Select.Option>
									<Select.Option value="AP">AP</Select.Option>
									<Select.Option value="AM">AM</Select.Option>
									<Select.Option value="BA">BA</Select.Option>
									<Select.Option value="CE">CE</Select.Option>
									<Select.Option value="DF">DF</Select.Option>
									<Select.Option value="ES">ES</Select.Option>
									<Select.Option value="GO">GO</Select.Option>
									<Select.Option value="MA">MA</Select.Option>
									<Select.Option value="MS">MS</Select.Option>
									<Select.Option value="MT">MT</Select.Option>
									<Select.Option value="MG">MG</Select.Option>
									<Select.Option value="PA">PA</Select.Option>
									<Select.Option value="PB">PB</Select.Option>
									<Select.Option value="PR">PR</Select.Option>
									<Select.Option value="PE">PE</Select.Option>
									<Select.Option value="PI">PI</Select.Option>
									<Select.Option value="RJ">RJ</Select.Option>
									<Select.Option value="RN">RN</Select.Option>
									<Select.Option value="RS">RS</Select.Option>
									<Select.Option value="RO">RO</Select.Option>
									<Select.Option value="RR">RR</Select.Option>
									<Select.Option value="SC">SC</Select.Option>
									<Select.Option value="SP">SP</Select.Option>
									<Select.Option value="SE">SE</Select.Option>
									<Select.Option value="TO">TO</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col sm={7}>
							<Form.Item
								label="Orgão Emissor"
								name={"doc_dispatcher"}
								rules={[
									{
										required: true,
										message:
											"Orgão emissor obrigatório",
									},
									{
										pattern: new RegExp(/^\D{2,}$/),
										message: "Nome inválido",
									},
								]}
							>
								<Input
									placeholder="Nome do Orgão"
									type="text"
								></Input>
							</Form.Item>
						</Col>
					</Row>
					<Row justify="space-between">
						<Col>
							<Form.Item
								label="Data de Expedição"
								name={"doc_shipping_date"}
								rules={[
									{
										required: true,
										message: "Orgão emissor obrigatório",
									},
									() => ({
										validator(_, value) {
											const dateOnlyNumbers = value.replace(/\D/g, "");
											const date = moment(value, "DD/MM/YYYY");
											if (
												dateOnlyNumbers.length <
												8 ||
												!date.isValid()
											) {
												return Promise.reject(
													new Error(
														"Data inválida",
													),
												);
											} else {
												return Promise.resolve();
											}
										},
									}),
								]}
							>
								<MaskedInput mask={mask.date}></MaskedInput>
							</Form.Item>
						</Col>
						<Col>
							<Form.Item
								label="Data de Nascimento"
								name={"birthday"}
								rules={[
									{
										required: true,
										message: "Data obrigatória",
									},
									() => ({
										validator(_, value) {
											const dateOnlyNumbers =
												value.replace(
													/\D/g,
													"",
												);
											const date = moment(
												value,
												"DD/MM/YYYY",
											);
											const actualDate =
												new Date();
											const miniumAge = moment([
												actualDate.getFullYear() -
												18,
												actualDate.getMonth(),
												actualDate.getDate(),
											]);
											if (
												dateOnlyNumbers.length <
												8 ||
												!date.isValid()
											) {
												return Promise.reject(
													new Error(
														"Data inválida",
													),
												);
											} else if (
												date.isBefore(miniumAge)
											) {
												return Promise.resolve();
											} else {
												return Promise.reject(
													new Error(
														"Não pode ser menor de idade",
													),
												);
											}
										},
									}),
								]}
							>
								<MaskedInput mask={mask.date}></MaskedInput>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								label="Email"
								name={"email"}
								rules={[
									{
										required: true,
										message: "Informe o email.",
									},
									{
										type: "email",
										message:
											"Informe um email válido.",
									},
								]}
							>
								<Input
									placeholder="Email"
									type="email"
								></Input>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								label="Nome completo da Mãe"
								name={"mother_name"}
								rules={[
									{
										required: true,
										message: "Nome inválido.",
									},
								]}
							>
								<Input
									placeholder="Nome Completo"
									type="text"
								></Input>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								label="Nome completo do Pai"
								name={"father_name"}
							>
								<Input
									placeholder="Nome Completo"
									type="text"
								></Input>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								label="Profissão"
								name={"profession"}
								rules={[
									{
										required: true,
										message: "Profissão inválida.",
									},
								]}
							>
								<Input
									placeholder="Profissão"
									type="text"
								></Input>
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								label="Renda mensal aproximada"
								name={"monthly_income"}
								rules={[
									{
										required: true,
										message: "Renda mensal aproximada",
									},
								]}
							>
								<CurrencyField style={{ width: "100%" }} initialValue={0} />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="space-between">
						<Col>
							<Form.Item
								name={"gender"}
								label="Gênero"
								rules={[
									{
										required: true,
										message: "Seleção Obrigatória.",
									},
								]}
							>
								<Select placeholder="Escolher">
									<Option value="M">Masculino</Option>
									<Option value="F">Feminino</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col>
							<Form.Item
								label="Telefone"
								name={"cell_phone"}
								rules={[
									{
										required: true,
										message: "Telefone inválido.",
									},
								]}
							>
								<ConfigProvider locale={br}>
									<CountryPhoneInput className="register-country-phone" onChange={onChangePhoneInput} value={valueCountryNumber} />
								</ConfigProvider>
								{/* <MaskedInput mask="(11) 11111-1111"></MaskedInput> */}
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						label="Cidade Natal"
						name={"birthplace"}
						rules={[
							{
								required: true,
								message: "Nome inválid0.",
							},
						]}
					>
						<Input
							placeholder="Nome Completo"
							type="text"
						></Input>
					</Form.Item>
					<Row justify="space-between">
						<Col sm={10}>
							<Form.Item
								label="País que reside"
								name={"residential_country"}
								rules={[
									{
										required: true,
										message: "Nome inválido.",
									},
								]}
							>
								<Select
									onChange={(value) => {
										onChangeCountry(value)
										const resetAddressFields = {
											addr_public_place: undefined,
											addr_neighborhood: undefined,
											addr_city_name: undefined,
											addr_province: undefined,
											addr_number: undefined,
											addr_zipcode: undefined,
											addr_complement: undefined,
										};
										form.setFieldsValue(resetAddressFields);
									}}
									showSearch
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(
												input.toLowerCase(),
											) >= 0
									}
								>
									{countriesList.map((item) => {
										return (
											<Option
												key={item.pais}
												value={item.pais}
											>
												{item.pais}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col sm={10}>
							<Form.Item
								label="Nacionalidade"
								name={"nationality"}
								rules={[
									{
										required: true,
										message: "Nome inválido.",
									},
								]}
							>
								<Select options={optionsNat}> </Select>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						name={"marital_status"}
						label="Estado Civil"
						rules={[
							{
								required: true,
								message: "Seleção Obrigatória.",
							},
						]}
					>
						<Select
							onSelect={(value) =>
								updateState(`marital`, value)
							}
						>
							<Option value="C">Casado(a)</Option>
							<Option value="S">Solteiro(a)</Option>
							<Option value="D">Divorciado(a)</Option>
							<Option value="V">Viúvo(a)</Option>
							<Option value="U">União Estável</Option>
						</Select>
					</Form.Item>
					{state[`marital`] === "C" || state[`marital`] === "U" && (
						<IdentedField>
							<Form.Item
								label="Nome do Cônjuge"
								name={"spouse_name"}
								rules={[
									{
										required: true,
										message: "Nome inválido.",
									},
								]}
							>
								<Input
									placeholder="Nome completo"
									type="text"
								></Input>
							</Form.Item>
							<Form.Item
								label="CPF do Cônjuge"
								name={"spouse_cpf"}
								rules={[
									{
										required: true,
										message: "Informe o CPF",
									},
									() => ({
										validator(_, value) {
											if (
												!value ||
												cpfValidator.isValid(
													value,
												)
											) {
												return Promise.resolve();
											}

											return Promise.reject(
												new Error(
													"CPF inválido.",
												),
											);
										},
									}),
								]}
							>
								<MaskedInput mask={mask.cpf}></MaskedInput>
							</Form.Item>
						</IdentedField>
					)}
					<Form.Item
						label="CEP"
						name={"addr_zipcode"}
						rules={[
							{
								required: true,
								message: "CEP inválido.",
							},
						]}
					>{useCep && !finished
						? <MaskedInput
							autoComplete="false"
							mask={mask.cep}
							onChange={(e) => {
								let val = e.target.value;
								form.setFieldsValue({ 'addr_zipcode': e.target.value });
								const zipcode = val.replace(/\D/g, "");
								if (zipcode.length === 8) {
									getAddressByCep(zipcode).then((response) => {
										let fields = {};
										fields.addr_public_place = response.logradouro;
										fields.addr_neighborhood = response.bairro;
										fields.addr_city_name = response.localidade;
										fields.addr_province = response.uf;
										form.setFieldsValue(fields);
									});
								}
							}}></MaskedInput>
						: <Input placeholder="Código Postal" type="text" onChange={(v) => {
							onChangeCep(v.target.value)
						}}></Input>
						}
					</Form.Item>
					<Row justify="space-between">
						<Col sm={18}>
							<Form.Item
								label="Logradouro"
								name={"addr_public_place"}
								rules={[
									{
										required: true,
										message: "Endereço inválido.",
									},
								]}
							>
								<Input
									placeholder="Informar"
									type="text"
								></Input>
							</Form.Item>
						</Col>
						<Col sm={5}>
							<Form.Item
								label="Número"
								name={"addr_number"}
								rules={[
									{
										required: true,
										message: "Número inválido.",
									},
								]}
							>
								<Input type="text"></Input>
							</Form.Item>
						</Col>
					</Row>
					<Row justify="space-between">
						<Form.Item
							label="Bairro"
							name={"addr_neighborhood"}
							rules={[
								{
									required: true,
									message: "Bairro inválido.",
								},
							]}
						>
							<Input
								placeholder="Informar"
								type="text"
							></Input>
						</Form.Item>
						<Form.Item
							label="Complemento"
							name={"addr_complement"}
							rules={[
								{
									message: "Complemento inválido.",
								},
							]}
						>
							<Input
								placeholder="Informar"
								type="text"
							></Input>
						</Form.Item>
					</Row>
					<Row justify="space-between">
						<Col sm={15}>
							<Form.Item
								label="Cidade"
								name={"addr_city_name"}
								rules={[
									{
										required: true,
										message: "Cidade inválido.",
									},
								]}
							>
								<Input
									placeholder="Informar"
									type="text"
								></Input>
							</Form.Item>
						</Col>
						<Col sm={5}>
							<Form.Item
								label="Estado"
								name={"addr_province"}
								rules={[
									{
										required: true,
										message: "Estado inválido.",
									},
								]}
							>
								{form.getFieldValue("residential_country") === "Brasil" || form.getFieldValue("residential_country") === undefined ? (
									<Select
										placeholder="Informar"
										showSearch
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									>
										{BrazilStates.map((item) => (
											<Option key={item.key} value={item.key}>
												{item.value}
											</Option>
										))}
									</Select>
								) : (
									<Input
										placeholder="Informar"
										type="text" />
								)}
							</Form.Item>
						</Col>
					</Row>
					<p className="subtitle">Dados Bancários</p>
					<Form.Item
						label="Banco"
						name={"bank_name"}
						rules={[
							{
								required: true,
								message: "Nome do Banco inválido.",
							},
						]}
					>
						<Select
							showSearch
							onChange={(value) => {
								const bank = BankList.filter(item => item.value === value)[0].key.split(" | ")
								form.setFieldsValue({ bank_code: bank[0] })
							}}
							filterOption={(input, option) =>
								option.children
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
						>
							{BankList.map((item) => (
								<Option
									key={item.key}
									value={item.value}
								>
									{item.key}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item hidden={true} name="bank_code">
						<Input></Input>
					</Form.Item>
					<Row justify="space-between">
						<Form.Item
							label="Agência"
							name={"agency_number"}
							rules={[
								{
									required: true,
									message: "Cidade inválido.",
								},
							]}
						>
							<Input
								placeholder="Informar"
								type="text"
							></Input>
						</Form.Item>
						<Form.Item
							label="Número da conta com dígito"
							name={"bank_account_number"}
							rules={[
								{
									required: true,
									message: "Cidade inválido.",
								},
							]}
						>
							<Input
								placeholder="Informar"
								type="text"
							></Input>
						</Form.Item>
					</Row>
				</Card>
			</Col>
			<Col sm={12}>
				<Card>
					<p className="subtitle">Dados Autodeclarados</p>
					<Form.Item
						shouldUpdate={(prevValues, curValues) => {
							if (
								prevValues.is_public_agent !==
								curValues.is_public_agent
							) {
								updateState(
									`publicAgent`,
									curValues.is_public_agent,
								);
							}
						}}
						name={"is_public_agent"}
						label={<>É agente/funcionário(a) público?</>}
						rules={[
							{
								required: true,
								message: "Seleção Obrigatória.",
							},
						]}
					>
						<Select
							onSelect={(value) =>
								updateState(`publicAgent`, value)
							}
						>
							<Option value={true}>Sim</Option>
							<Option value={false}>Não</Option>
						</Select>
					</Form.Item>
					{state[`publicAgent`] ? (
						<IdentedField>
							<Form.Item
								label="CNPJ"
								name={"public_agent_cnpj"}
								rules={[
									{
										required: true,
										message: "Informe o CNPJ",
									},
									() => ({
										validator(_, value) {
											if (
												!value ||
												cnpjValidator.isValid(
													value,
												)
											) {
												return Promise.resolve();
											}

											return Promise.reject(
												new Error(
													"CNPJ inválido.",
												),
											);
										},
									}),
								]}
							>
								<MaskedInput mask={mask.cnpj}></MaskedInput>
							</Form.Item>
							<Form.Item
								label="Ocupação"
								name={"public_agent_occupation"}
								rules={[
									{
										required: true,
										message: "Valor inválido.",
									},
								]}
							>
								<Input
									placeholder="Informe"
									type="text"
								></Input>
							</Form.Item>
							<Row justify="space-between">
								<Form.Item
									label="Data de Início"
									name={"public_agent_start_date"}
									rules={[
										{
											required: true,
											message: "Data obrigatória",
										},
										() => ({
											validator(_, value) {
												const dateOnlyNumbers =
													value.replace(
														/\D/g,
														"",
													);
												const date = moment(
													value,
													"DD/MM/YYYY",
												);
												if (
													dateOnlyNumbers.length <
													8 ||
													!date.isValid()
												) {
													return Promise.reject(
														new Error(
															"Data inválida",
														),
													);
												} else {
													return Promise.resolve();
												}
											},
										}),
									]}
								>
									<MaskedInput mask={mask.date}></MaskedInput>
								</Form.Item>
								<Form.Item
									label="Data de Término"
									name={"public_agent_end_date"}
								>
									<MaskedInput mask={mask.date}></MaskedInput>
								</Form.Item>
							</Row>
						</IdentedField>
					) : null}
					<Form.Item
						shouldUpdate={(prevValues, curValues) => {
							if (
								prevValues.has_public_agent_link !==
								curValues.has_public_agent_link
							) {
								updateState(
									`publicLink`,
									curValues.has_public_agent_link,
								);
							}
						}}
						name={"has_public_agent_link"}
						label="Possui relação com Agente/Pessoa Público(a)?"
						rules={[
							{
								required: true,
								message: "Seleção Obrigatória.",
							},
						]}
					>
						<Select
							onSelect={(value) => {
								updateState(`publicLink`, value);
							}}
						>
							<Option value={true}>Sim</Option>
							<Option value={false}>Não</Option>
						</Select>
					</Form.Item>
					{state[`publicLink`] ? (
						<IdentedField>
							<Form.Item
								label="Nome completo do Agente/Pessoa"
								name={"public_agent_link_name"}
								rules={[
									{
										required: true,
										message: "Nome inválido.",
									},
								]}
							>
								<Input
									placeholder="Informar"
									type="text"
								></Input>
							</Form.Item>
							<Form.Item
								label="CPF do(a) Agente/Pessoa"
								name={"public_agent_link_cpf"}
								rules={[
									{
										required: true,
										message: "CPF inválido.",
									},
								]}
							>
								<MaskedInput mask={mask.cpf}></MaskedInput>
							</Form.Item>
							<Form.Item
								label="Ocupação do(a) Agente/Pessoa"
								name={"public_agent_link_occupation"}
								rules={[
									{
										required: true,
										message: "Ocupação inválida.",
									},
								]}
							>
								<Input
									placeholder="Informar"
									type="text"
								></Input>
							</Form.Item>
							<Form.Item
								label="Tipo do relacionamento com o(a) Agente/Pessoa"
								name={"public_agent_link_type"}
								rules={[
									{
										required: true,
										message:
											"Relacionamento inválido.",
									},
								]}
							>
								<Input
									placeholder="Informar"
									type="text"
								></Input>
							</Form.Item>
						</IdentedField>
					) : null}
					<Form.Item
						name={"is_us_person"}
						label={
							<>
								É US Person?{" "}
								<TooltipEfex title="Cidadania norte-americana, incluindo os detentores de dupla nacionalidade e passaporte norte-americano, ainda que residam fora dos Estados Unidos; Residência fiscal nos EUA, ou seja, que possuam Green Card e que possa se tornar um residente permanente legal; Presença física nos Estados Unidos para fins tributários por pelo menos 31 dias durante o ano corrente ou 183 dias nos últimos 3 anos." />
							</>
						}
						rules={[
							{
								required: true,
								message: "Seleção Obrigatória.",
							},
						]}
					>
						<Select>
							<Option value={true}>Sim</Option>
							<Option value={false}>Não</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name={"has_link_with_bs2"}
						label={
							<>
								Possui relação com a Conecta Corretora?{" "}
								<TooltipEfex title="É colaborador direto/indireto da Conecta Corretora" />
							</>
						}
						rules={[
							{
								required: true,
								message: "Seleção Obrigatória.",
							},
						]}
					>
						<Select>
							<Option value={true}>Sim</Option>
							<Option value={false}>Não</Option>
						</Select>
					</Form.Item>
				</Card>
			</Col>
		</Row>
	</>)
}

CadastraData.propTypes = {
	form: PropTypes.object.isRequired,
	person: PropTypes.object.isRequired,
	valueCountryNumber: PropTypes.object.isRequired,
	onChangePhoneInput: PropTypes.func.isRequired,
	onChangeCep: PropTypes.func.isRequired,
	useCep: PropTypes.bool.isRequired,
	finished: PropTypes.bool.isRequired,
	onChangeCountry: PropTypes.func.isRequired
}

export default CadastraData

const TypeSelector = () => (
	<Form.Item
		name={"doc_type"}
		rules={[
			{
				required: true,
				message: "Valor inválido.",
			},
		]}
		noStyle
	>
		<Select placeholder="Escolher">
			<Option value="CNH">CNH</Option>
			<Option value="RG">RG</Option>
			<Option value="RNE">RNE</Option>
		</Select>
	</Form.Item>
);
