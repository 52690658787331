import React, { useState } from 'react'
import { Breadcrumb, Button } from 'antd';
import { DollarOutlined } from '@ant-design/icons';
import AddNewCurrencyModal from '../Modal';
import PropTypes from "prop-types"

const CovaragesBread = ({ bothDispatchesSuccess }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return <>
        <Breadcrumb>
            <Breadcrumb.Item>
                <DollarOutlined />
                Controle de coberturas
            </Breadcrumb.Item>
            <Breadcrumb.Item className='new-company-btn'>
                <Button
                    disabled={!bothDispatchesSuccess}
                    onClick={() => setIsModalVisible(true)}
                >
                    {" "}
                    Cadastrar nova moeda{" "}
                </Button>
            </Breadcrumb.Item>
        </Breadcrumb>

        <AddNewCurrencyModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
        />
    </>
}

export default CovaragesBread

CovaragesBread.propTypes = {
    bothDispatchesSuccess: PropTypes.bool,
};