import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { listNewNotifications, patchAllNotifications, patchNotification } from './service'

export const newNotifications = createAsyncThunk(
    'notifications/newNotifications',
    async ({ page }) => {
        const response = await listNewNotifications(page);
        return response.data;
    }
);

export const readNotification = createAsyncThunk(
    'notification/readNotification',
    async ({ notification_id }) => {
        const response = await patchNotification(notification_id);
        return response.data;
    }
);

export const readAllNotifications = createAsyncThunk(
    'notification/readAllNotifications',
    async ({ read_all }) => {
        const response = await patchAllNotifications(read_all)
        return response.data
    }
)