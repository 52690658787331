import React from "react";
import { Breadcrumb } from "antd";
import { FileTextOutlined } from "@ant-design/icons";

const TransactionsBread = () => {
	return (
		<>
			<Breadcrumb>
				<Breadcrumb.Item>
					<FileTextOutlined />
					Relatórios
				</Breadcrumb.Item>
			</Breadcrumb>
		</>
	);
};

export default TransactionsBread;
