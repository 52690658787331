import React from 'react'
import PropTypes from 'prop-types'
import { InputNumber } from 'antd'
import { formatCurrency, formatCurrencyPatrimony } from "../../../utils/functions";

const CurrencyField = ({
    prefix,
	acceptNegative,
    ...props
}) => {
	return (acceptNegative ?
        <InputNumber 
            className="efex-currency-field"
            formatter={value => formatCurrencyPatrimony(value, prefix ?? "R$ ")}
            parser={money => money.replace(/(?!\-)\D/g, "")}
            {...props}
            placeholder={`${prefix} 0,00`}
        />
        :
        <InputNumber
            className="efex-currency-field"
            min={0}
            formatter={value => formatCurrency(value, prefix ?? "R$ ")}
            parser={money => money.replace(/\D/g, "")}
            {...props}
        /> 
	)
}
CurrencyField.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.number,
	defaultValue: PropTypes.number,
	prefix: PropTypes.string,
	acceptNegative: PropTypes.bool,
	onChange: PropTypes.func
}

export default CurrencyField
