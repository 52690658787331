import React from "react";
import { Card, Typography, Timeline } from "antd";
import PropTypes from 'prop-types'

const ProgressCard = ({ history }) => {
    return (
        <Card style={{ width: "100%", height: "100%" }}>
            <Typography.Title level={5}>Andamento</Typography.Title>
            <div style={{ height: "100%", overflow: "auto", }}>
                <br />
                <Timeline>
                    {history &&
                        history.map((item, index) => {
                            return (
                                <Timeline.Item
                                    key={index}
                                    color={
                                        item.action_type === "negative"
                                            ? "red"
                                            : item.action_type ===
                                                "neutral"
                                                ? "grey"
                                                : "green"
                                    }
                                >
                                    {item.text} - {item.created_at}
                                </Timeline.Item>
                            );
                        })}
                </Timeline>
            </div>
        </Card>
    )
}

ProgressCard.propTypes = {
    history: PropTypes.array
};

export default ProgressCard;