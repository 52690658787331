import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Form,
    Card,
    Row,
    Col,
    Tabs,
    Layout,
    Button,
    Divider,
    Tooltip,
    Typography,
    message,
    Spin
} from "antd";
import { SaveOutlined, LoadingOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Base from "../../../common/components/Base";
import stateStatus from "../../../utils/status";
import ErroMsg from "../../../common/components/ErrorMsg";
import PropTypes from "prop-types";

import "./styles.scss";
import { editUser, getUser, selectUsers } from "../slice";
import UsersBread from "../components/UserBread";
import CadastralData from "../components/CadastralData";
import InfiniteScrollList from "../components/TableOfCompanies";
import { getPeople } from "../../People/slice";
import { getCompanies } from "../../Companies/slice";
import { selectPerson } from "../../People/Person/slice";
import { selectCompanyId } from "../../Companies/Company/slice";
import ResetPasswordModal from "../components/ResetPasswordModal";
import TableOfCompanies from "../components/TableOfCompanies";
import TableOfPeople from "../components/TableOfPeople";

const { Content } = Layout;
const { TabPane } = Tabs;

const User = ({ match, ...props }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const [isResetPass, setIsResetPass] = useState(false);
    const person = useSelector(selectPerson);
    const company = useSelector(selectCompanyId);

    useEffect(() => {
        if (company.status.getCompany === stateStatus.loading) {
            message.loading("Carregando empresa");
        }
        if (person.status.getPerson === stateStatus.loading) {
            message.loading("Carregando pessoa");
        }
    }, [company.status.getCompany, person.status.getPerson]);

    useEffect(() => {
        dispatch(getUser({ id: match.params.id }));
    }, []);

    const onFinish = (values) => {
        dispatch(editUser({id: match.params.id, data: values})).then(resp => {
            if(resp.payload){
                message.success("Alterações salvas com sucesso!")
            }else{
                message.error("Algo deu errado.")
            }
        })
    };

    const onChangeForm = (values) => {};

    return (
        <Base>
            {users.status.getUser === stateStatus.loading ? (
                "Loading"
            ) : users.status.getUser === stateStatus.failed ? (
                "Erro: contate o suporte"
            ) : (
                <Content className="user">
                    {users.status.getUser === stateStatus.succeeded ? (
                        <>
                            <Form
                                style={{ width: "100%" }}
                                form={form}
                                layout="vertical"
                                requiredMark={false}
                                className="legal-user"
                                onFinish={onFinish}
                                onValuesChange={onChangeForm}
                                scrollToFirstError={true}
                            >
                                <UsersBread userName={users.user.first_name} />
                                <Col>
                                    <Divider />
                                    <Row>
                                        <Tooltip title={"Salvar"}>
                                            <Button
                                                className="update-register-btn"
                                                htmlType="submit"
                                                icon={
                                                    users.status.updateUser ===
                                                    stateStatus.loading ? (
                                                        <LoadingOutlined />
                                                    ) : (
                                                        <SaveOutlined />
                                                    )
                                                }
                                            >
                                                Salvar alterações
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={"Redefinir"}>
                                            <Button
                                                className="update-register-btn"
                                                htmlType="button"
                                                icon={
                                                    users.status.updateUser ===
                                                    stateStatus.loading ? (
                                                        <LoadingOutlined />
                                                    ) : (
                                                        <EyeInvisibleOutlined />
                                                    )
                                                }
                                                onClick={() => {
                                                    setIsResetPass(true)
                                                }}
                                            >
                                                Redefinir senha
                                            </Button>
                                        </Tooltip>
                                    </Row>
                                    <Divider />
                                    {users.status.updateUser ===
                                        stateStatus.failed ||
                                    users.status.getUser ===
                                        stateStatus.failed ? (
                                        <ErroMsg message={users.errorMsg} />
                                    ) : null}
                                </Col>
                                <Card>
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab="Cadastro" key="1">
                                            <Divider orientation="left">
                                                <Typography.Title level={5}>
                                                    Dados do Usuário
                                                </Typography.Title>
                                            </Divider>
                                            {users.status.editUser === stateStatus.loading ? (
                                                <Spin
                                                    indicator={
                                                        <LoadingOutlined
                                                            style={{
                                                                fontSize: 24,
                                                            }}
                                                            spin
                                                        />
                                                    }
                                                />
                                            ) : (
                                                <CadastralData
                                                    form={form}
                                                    user={users.user}
                                                    finished={() => "opa"}
                                                    {...props}
                                                />
                                            )}
                                            <Divider orientation="left"></Divider>
                                        </TabPane>
                                        <TabPane tab="Empresas" key="3">
                                            <TableOfCompanies userId={match.params.id}/>
                                        </TabPane>
                                        <TabPane tab="Pessoas">
                                            <TableOfPeople userId={match.params.id}/>
                                        </TabPane>
                                    </Tabs>
                                </Card>
                            </Form>
                        </>
                    ) : null}
                </Content>
            )}
            <ResetPasswordModal isResetPass={isResetPass} setIsResetPass={setIsResetPass} id={match.params.id}/>
        </Base>
    );
};

User.propTypes = {
    match: PropTypes.object,
};

export default User;
