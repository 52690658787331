import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../utils/status'
import { listPeople, getUser, addPerson } from './service'

const initialState = {
	status: {
		getPeople: stateStatus.idle,
		getPerson: stateStatus.idle
	},
	list: {
		count: null,
		page: 1,
		total_pages: null,
		people: []
	},
	person: null,
	errorMsg: null
}

export const getPeople = createAsyncThunk(
	'people/listPeople',
	async ({ page, values }) => {
		if (values.status === "") {
			delete values.status
		}
		const response = await listPeople(page, values)
		return response.data
	}
)

export const findUser = createAsyncThunk(
	"user/findUser",
	async (email) => {
		const response = await getUser(email)
		return response.data
	}
)

export const postAddPerson = createAsyncThunk(
	"company/addCompany",
	async (data) => {
		const response = await addPerson(data);
		return response.data;
	},
);

export const people = createSlice({
	name: 'people',
	initialState,
	extraReducers: {
		[getPeople.pending]: (state) => {
			state.status.getPeople = stateStatus.loading
		},
		[getPeople.fulfilled]: (state, action) => {
			state.status.getPeople = stateStatus.succeeded
			state.list.people = []
			state.list.people = action.payload.people
			state.list.count = action.payload.count
			state.list.page = action.payload.page
			state.list.total_pages = action.payload.total_pages
		},
		[getPeople.rejected]: (state, action) => {
			state.status.getPeople = stateStatus.failed
			state.errorMsg = action.error.message
		}
	}
})

export const selectPeople = (state) => state.people
