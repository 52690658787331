import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Checkbox, Col, Row, message } from 'antd';
import { useDispatch } from 'react-redux';
import { requestCompanyUpdate, getCompanyId } from '../../Company/slice';

const CompanyRegisterUpdate = ({ isModalVisible, setIsModalVisible, company }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [error, setError] = useState(null);
    const isSA = (company?.juridical_nature === 3999 ||
        company?.juridical_nature === 2046 ||
        company?.juridical_nature === 2054) ? true : false
    const options = [
        { label: 'Atualização completa', value: "update_all" },
        { label: 'Atualizar ficha de cadastro', value: "registration_form" },
        { label: 'Atualizar ficha de representante', value: "representative_form" },
        { label: 'Atualizar KYC', value: "kyc" },
        isSA && { label: 'Atualizar documento de constituição da empresa', value: "company_incorporation_document" },
        isSA && { label: 'Atualizar Ata de Eleição da Diretoria', value: "board_election_minutes" },
        { label: 'Atualizar comprovante de endereço do representante', value: "representative_proof_of_address" },
        { label: 'Atualizar documento de identidade do representante', value: "representative_identity_document" },
        !company?.is_movement_less_15 && { label: 'Atualizar documentação financeira', value: "financial_documentation" },
    ].filter(Boolean);

    const dispatch = useDispatch()

    const validateSelection = () => {
        if (selectedOptions.length === 0) {
            setError("Selecione pelo menos uma opção");
            return false;
        }
        setError(null);
        return true;
    };

    const onFinish = () => {
        if (!validateSelection()) return;

        const id = company.id;
        setIsLoading(true);
        const filteredOptions = selectedOptions.filter(option => option !== "update_all");
        dispatch(requestCompanyUpdate({ company_id: id, keys: filteredOptions })).then((resp) => {
            setIsLoading(false);
            if (resp.payload) {
                dispatch(getCompanyId({ id: id }))
                setSelectedOptions([]);
                setIsModalVisible(false);
            } else {
                message.error(resp.error.message || "Erro ao cadastrar empresa. Tente novamente.");
            }
        });
    };

    const handleCheckboxChange = (value) => {
        setSelectedOptions((prevSelectedOptions) => {
            if (value === "update_all") {
                if (prevSelectedOptions.includes(value)) {
                    return [];
                } else {
                    return options.map(option => option.value);
                }
            } else {
                if (prevSelectedOptions.includes(value)) {
                    const updatedOptions = prevSelectedOptions.filter((option) => option !== value);
                    if (updatedOptions.includes("update_all")) {
                        return updatedOptions.filter((option) => option !== "update_all");
                    }
                    return updatedOptions;
                } else {
                    const updatedOptions = [...prevSelectedOptions, value];
                    if (updatedOptions.length === options.length - 1) {
                        return [...updatedOptions, "update_all"];
                    }
                    return updatedOptions;
                }
            }
        });
    };


    return (
        <Modal
            title="Atualizar Cadastro"
            open={isModalVisible}
            onOk={onFinish}
            onCancel={() => setIsModalVisible(false)}
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            cancelButtonProps={{ disabled: isLoading }}
            maskClosable={true}
        >
            <div
                style={{
                    fontSize: "14px",
                    lineHeight: "25px",
                    marginBottom: "20px",
                }}
            >
                <p>
                    Selecione abaixo o que a empresa deverá atualizar em seu cadastro
                </p>
            </div>
            <Row>
                {options.map((option) => (
                    <Col span={24} key={option.value}>
                        <Checkbox
                            value={option.value}
                            onChange={() => handleCheckboxChange(option.value)}
                            checked={selectedOptions.includes(option.value) ||
                                ((!isSA && selectedOptions.length === 6) || (isSA && selectedOptions.length === 8))}
                        >
                            {option.label}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
            {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </Modal>
    );
};

export default CompanyRegisterUpdate;

CompanyRegisterUpdate.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
};
