import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Form, Input, Button, Layout, Card } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import stateStatus from '../../utils/status'

import './Login.scss'
import routes from '../../routes/routes'
import { loginThunk, selectLogin } from './LoginSlice'
import ErrorMsg from '../../common/components/ErrorMsg'

const { Content, Footer } = Layout

const Login = (props) => {
	const dispatch = useDispatch()
	const login = useSelector(selectLogin)

	useEffect(() => {
		const accessToken = localStorage.getItem('access_token')
		if (accessToken !== null && accessToken !== undefined) {
			props.history.push(routes.companies)
		}
	})

	useEffect(() => {
		if (login.status.login === stateStatus.succeeded) {
			props.history.push(routes.companies)
		}
	}, [login, props.history])

	const onFinish = (values) => {
		dispatch(loginThunk(values))
	}

	return (
		<Layout className="login layout">
			<Content className="content">
				{login.status.login === stateStatus.failed
					? (
						<ErrorMsg message={login.errorMsg}></ErrorMsg>
					)
					: null}
				<Card>
					<Form name="basic" onFinish={onFinish}>
						<Form.Item
							name="username"
							rules={[
								{
									required: true,
									message: 'Por favor, informe o usuário.'
								}
							]}
						>
							<Input
								prefix={<UserOutlined className="site-form-item-icon" />}
								placeholder="Usuário"
							/>
						</Form.Item>

						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: 'Por favor, informe a senha.'
								}
							]}
						>
							<Input.Password
								prefix={<LockOutlined className="site-form-item-icon" />}
								placeholder="Senha"
							/>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" loading={login.status.login === stateStatus.loading}>
								{login.status.login === stateStatus.loading ? 'Carregando' : 'Entrar'}
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Content>
			<Footer></Footer>
		</Layout>
	)
}

Login.propTypes = {
	history: PropTypes.any
}

export default Login
