import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../utils/status'
import { listPayments, postCreateFile } from './service'

const initialState = {
	status: {
		// getPaymentsCompanies: stateStatus.idle,
		// getPaymentsPeople: stateStatus.idle,
		getPayments: stateStatus.idle,
		createFile: stateStatus.idle
	},
	createFile: {
		fileId: null
	},
	/* companies: {
		count: null,
		page: 1,
		total_pages: null,
		payments: []
	},
	people: {
		count: null,
		page: 1,
		total_pages: null,
		payments: []
	}, */
	payments: {
		lst: [],
		pagination: {}
	},
	errorMsg: null
}

/* export const getPaymentsCompanies = createAsyncThunk(
	'payments/listPaymentsCompanies',
	async ({ page, data }) => {
		const response = await listPayments(page, data)
		return response.data
	}
)

export const getPaymentsPeople = createAsyncThunk(
	'payments/listPaymentsPeople',
	async ({ page, data }) => {
		data.type = "person"
		const response = await listPayments(page, data)
		return response.data
	}
) */

export const createFile = createAsyncThunk(
	'payments/createFile',
	async ({ formData, config, onSuccess, onError }) => {
		try {
			const response = await postCreateFile(formData, config)
			onSuccess(response)
			return response.data
		} catch (e) {
			onError(e)
			throw e
		}
	}
)

export const getPayments = createAsyncThunk(
	'payments/listPayments',
	async ({ page, data }) => {
		const response = await listPayments(page, data)
		return response.data
	}
)

export const payments = createSlice({
	name: 'payments',
	initialState,
	reducers: {
		resetCreateFile: (state) => {
			state.status.createFile = stateStatus.idle
			state.createFile.fileId = null
		}
	},
	extraReducers: {
		/* [getPaymentsCompanies.pending]: (state) => {
			state.status.getPaymentsCompanies = stateStatus.loading
		},
		[getPaymentsCompanies.fulfilled]: (state, action) => {
			state.status.getPaymentsCompanies = stateStatus.succeeded
			state.companies.payments = []
			state.companies.payments = action.payload.lst
			state.companies.count = action.payload.pagination.total
			state.companies.page = action.payload.pagination.current_page
			state.companies.total_pages = action.payload.pagination.total_pages
		},
		[getPaymentsCompanies.rejected]: (state, action) => {
			state.status.getPaymentsCompanies = stateStatus.failed
			state.errorMsg = action.error.message
		},
		[getPaymentsPeople.pending]: (state) => {
			state.status.getPaymentsPeople = stateStatus.loading
		},
		[getPaymentsPeople.fulfilled]: (state, action) => {
			state.status.getPaymentsPeople = stateStatus.succeeded
			state.people.payments = []
			state.people.payments = action.payload.lst
			state.people.count = action.payload.pagination.total
			state.people.page = action.payload.pagination.current_page
			state.people.total_pages = action.payload.pagination.total_pages
		},
		[getPaymentsPeople.rejected]: (state, action) => {
			state.status.getPaymentsPeople = stateStatus.failed
			state.errorMsg = action.error.message
		}, */
		[getPayments.pending]: (state) => {
			state.status.getPayments = stateStatus.loading
		},
		[getPayments.fulfilled]: (state, action) => {
			state.status.getPayments = stateStatus.succeeded
			state.payments.lst = []
			state.payments.lst = action.payload.lst
			state.payments.pagination = action.payload.pagination
		},
		[getPayments.rejected]: (state, action) => {
			state.status.getPayments = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// create file
		[createFile.pending]: (state) => {
			state.status.createFile = stateStatus.loading
		},
		[createFile.fulfilled]: (state, action) => {
			state.status.createFile = stateStatus.succeeded
			state.createFile.fileId = action.payload.file_id
		},
		[createFile.rejected]: (state) => {
			state.status.createFile = stateStatus.failed
		},
	}
})
export const { resetCreateFile } = payments.actions
export const selectPayments = (state) => state.payments
