import React from "react";
import { Card, Typography } from "antd";
import PropTypes from 'prop-types'

const UserDataCard = ({user}) => {
    return (
        <Card style={{ width: "100%", height: "100%" }}>
            <Typography.Title level={5}>Dados do Usuário</Typography.Title>
            <div className="label-value">
                <label>NOME:</label>
                {user.first_name}{" "}{user.last_name}
            </div>
            <div className="label-value">
                <label>EMAIL:</label>
                {user.email}
            </div>
        </Card>
    )
}

UserDataCard.propTypes = {
	user: PropTypes.object
};

export default UserDataCard;