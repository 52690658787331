import {
	deletePayment,
	downloadContract,
	exchangeRates,
	fileDownload,
	finalize,
	getPayment,
	getPaymentBackground,
	postBoReview,
	refuse,
	resetSendReceiptBOStatus,
	selectPayment,
	sendCloseOpEmail,
	sendReceiptBO,
	uploadContract
} from './slice'
import React, { useEffect, useState } from 'react'
import { ArrowUpOutlined, DownloadOutlined } from '@ant-design/icons'
import { Card, Col, Row, Typography, Form, Timeline, Upload, message, notification, Divider, Button } from 'antd'
import { formatCurrencyBrl, formatCurrencyStr, formatPorcent } from '../../../../utils/functions'
import Base from '../../../../common/components/Base'
import ExchangeBread from '../../_components/ExchangeBread'
import routes from '../../../../routes/routes'
import ContactSendCard from '../../_components/ContactSendCard'
import TaxTable from '../../_components/TaxTable'
import ExchangeActions from '../../_components/ExchangeActions'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types"
import stateStatus from '../../../../utils/status'
import ErrorMsg from '../../../../common/components/ErrorMsg'
import { history } from '../../../../helpers/history'
import OperationTaxesModal from '../../_components/OperationTaxesModal'
import { listRates, selectRates } from '../../ListTaxes/slice'
import Modal from 'antd/lib/modal/Modal'
import { InboxOutlined } from '@ant-design/icons';
import { createFile, resetCreateFile, selectPayments } from '../slice'
import { downloadZipFiles } from './slice'
import { beautifyCpfCnpj } from '../../../../utils/beautify'
import FinalizeOperationModal from '../../../../common/components/FinalizeOperationModal'
import CardFile from '../../_components/CardFile'
import './styles.scss'
import TransactionDataCard from '../../../../common/components/TransactionDataCard'
import ClientDataCard from '../../../../common/components/ClientDataCard'
import UserDataCard from '../../../../common/components/UserDataCard'
import ProgressCard from '../../../../common/components/ProgressCard'

const SinglePayment = ({ match, location }) => {
	const { id } = match.params
	const payment = useSelector(selectPayment);
	const payments = useSelector(selectPayments)
	const rates = useSelector(selectRates)
	const [form] = Form.useForm()
	const [proofs, setProofs] = useState([])
	const [receipts, setReceipts] = useState([])
	// TODO: verificar esses dois states {valueToSend e valueExchange}, talvez possamos remove-los pois, aparentemente nao estao sendo usados
	const [valueToSend, setValueToSend] = useState(0);
	const [valueExchange, setValueExchange] = useState(0);
	const [showReviewModal, setShowReviewModal] = useState(false)
	const [_, setTaxes] = useState([]);
	const dispatch = useDispatch();
	const [showReceiptModal, setShowReceiptModal] = useState(false)
	const [timeout, updateTimeOut] = useState(0);
	const [timer, updateTimer] = useState(5);
	const [showApprovedMsg, setShowApprovedMsg] = useState(false);
	const [showFinalizeModal, setShowFinalizeModal] = useState(false)
	const [isFinelizeModalLoadig, setIsFinelizeModalLoadig] = useState(false)
	const [showErrorMsg, setShowErrorMsg] = useState(false);

	useEffect(async () => {
		if (payment.status.getPayment === stateStatus.succeeded) {
			form.setFieldsValue({
				spread: payment.payment.spread,
			})
			if (payment.payment.is_currency_target) {
				setValueExchange(payment.payment.target_value)
			} else {
				setValueToSend(payment.payment.target_value)
			}

			setTaxes(payment.payment.taxes.map(item => {
				return {
					name: item.name,
					description: item.description,
					tax: item.tax,
					value: item.value,
				}
			}))

			setProofs(payment.payment.receipts
				.filter(x => x.origin === "client"))

			setReceipts(payment.payment.receipts
				.filter(x => x.origin === "backoffice"))

			if (payment.payment.status === "blocked") {
				history.replace(`${location.pathname}/bloqueado`)
			}
		}

	}, [payment.status.getPayment, payment.payment])

	useEffect(() => {
		setShowReceiptModal(false)
		dispatch(resetCreateFile())
		dispatch(resetSendReceiptBOStatus())
		dispatch(getPayment({ id }))
		dispatch(exchangeRates({ sendValueId: id }))
	}, [id]);

	useEffect(() => {
		if (payment.status.getPayment === stateStatus.succeeded) {
			dispatch(listRates(payment.payment.owner.cpf_cnpj))
		}
	}, [payment.status.getPayment])

	useEffect(() => {
		if (payment.status.getPayment == stateStatus.succeeded) {
			calcValueExchange()
		}
	}, [payment.currencyRate])

	useEffect(() => {
		if (payment.status.getPaymentBackground == stateStatus.succeeded) {

			if (payment.payment.status === "waiting_exchange" && !showApprovedMsg) {
				notification.success({
					key: 'cambio-fechado',
					message: `Câmbio fechado!`,
					description: "Clique nessa mensagem e confira a tela de ordens fechadas",
					placement: "topRight",
					style: { 'cursor': 'pointer' },
					onClick: () => {
						history.push(routes.exchangeOperations)
						notification.close('cambio-fechado')
					}
				});
				setShowApprovedMsg(true);
			}
		}
	}, [payment.status.getPaymentBackground])

	useEffect(() => {
		if (id) {
			if (timer > 0) {
				clearTimeout(timeout);
				updateTimeOut(
					setTimeout(() => {
						updateTimer(timer - 1);
					}, 1000),
				);
			} else {
				dispatch(getPaymentBackground({ id }));
				updateTimer(5)
			}
		}
	}, [timer]);

	const calcValueExchange = () => {
		const values = form.getFieldsValue()
		const currency = payment.currencyRate / 100

		let value = 0
		if (payment.payment.is_currency_target) {
			value = (valueExchange / 100) * currency
		} else {
			value = (valueToSend / 100) / currency
		}

		if (values.taxes) {
			values.taxes.forEach(item => {
				if (item.value) {
					if (item.name == 'IR') {
						value += item.value / 1000000
					} else {
						value += item.value / 100
					}

				}
			})
		}

		if (values.spread) {
			value += (values.spread / 10000 * currency) + currency
		}

		if (payment.payment.is_currency_target) {
			setValueToSend(parseInt(value * 100))
		} else {
			setValueExchange(parseInt(value * 100))
		}
	}

	const customRequestUpload = ({ file, data, onProgress, onSuccess, onError }) => {
		var formData = new FormData();
		formData.append("cpf_cnpj", payment.payment.owner.cpf_cnpj)
		formData.append("file", file);

		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				onProgress({ percent });
			},
		};
		dispatch(createFile({
			formData,
			config,
			onSuccess,
			onError
		}))
	};

	return <Base selectedKeys={['4']}>
		{payment.status.getPayment === stateStatus.failed && (
			<ErrorMsg message={payment.errorMsg} />
		)}
		{payment.status.getPayment == stateStatus.succeeded && (
			<>
				<Form form={form}
					className="single"
				/* onFinish={(values) => {
					dispatch(savePayment({
						send_value_id: id,
						files: payment.payment.files.map(item => item.id), // TODO: remover da API o files
						spread: values.spread,
						taxes: values.taxes,
					}))
				}} */
				>

					<ExchangeBread
						parentIcon={<ArrowUpOutlined rotate={45} />}
						hasParent={true}
						parentName='Pagamentos'
						parentRoute={routes.payments}
						currentName={payment.payment.owner.name}
					/>
					<ExchangeActions
						// onSave={() => form.submit()}
						cpf_cnpj={payment.payment.owner.cpf_cnpj}
						exchange={payment.payment}
						exchageId={Number(id)}
						onReview={() => setShowReviewModal(true)}
						onFeedback={(values) => dispatch(refuse({ id, need_changes: values.text }))}
						onSendContract={(file) => dispatch(uploadContract({ id, file })).then((resp) => {
							if (resp.payload) {
								setShowErrorMsg(false)
							}
						})}
						onFinalize={() => {
							if (payment.payment?.status === "completed") {
								dispatch(finalize({ id }))
							}
							if (payment.payment?.status === "waiting_contract") {
								setShowFinalizeModal(true)
							}
						}}
						onSendEmail={() => {
							dispatch(sendCloseOpEmail({
								exchange_id: Number(id),
								exchange_type: "send_value",
								cpf_cnpj: payment?.payment?.owner?.cpf_cnpj
							}))
						}}
						onDelete={() => dispatch(deletePayment({ id })).then((resp) => {
							if (resp.payload?.success) {
								resp.payload?.success && message.success("Operação excluída com sucesso!")
								history.push(routes.payments)
							} else {
								message.error(resp.error.message)
							}
						})}
						onSendReceipt={() => setShowReceiptModal(true)}
						receiptsAlreadySent={receipts.length > 0}
						status={payment.payment.status}
						type="payment"
						loading={
							payment.status.boReview === stateStatus.loading ? "review" :
								payment.status.refuse === stateStatus.loading ? "feedback" :
									payment.status.sendReceipt === stateStatus.loading ? "receipts" :
										payment.status.finalize === stateStatus.loading ? "finalize" :
											payment.status.uploadContract === stateStatus.loading ? "contract" : null
						}
					/>
					<FinalizeOperationModal
						isVisible={showFinalizeModal}
						onCancel={() => setShowFinalizeModal(false)}
						onSubmit={(values) => {
							setIsFinelizeModalLoadig(true)
							dispatch(finalize({ id, comments: values.comments })).then((resp) => {
								setIsFinelizeModalLoadig(false)
								if (resp.payload) {
									setShowFinalizeModal(false)
								}
								if (!resp.payload) {
									setShowErrorMsg(true)
									notification.error({
										message: 'Erro ao finalizar operação',
										description: resp.error.message,
										placement: 'topRight'
									})
								}
							})
						}}
						isLoading={isFinelizeModalLoadig}
					/>
					{[
						payment.status.boReview,
						payment.status.refuse,
						payment.status.finalize,
						payment.status.uploadContract,
						payment.status.saveRecept,
						payment.status.downloadContract,
						...Object.values(payment.status.fileDownload)
					].includes(stateStatus.failed)
						&& payment.errorMsg !== null && showErrorMsg &&
						< ErrorMsg message={payment.errorMsg} />
					}

					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<ClientDataCard
										client={payment.payment.owner}
										contacts={payment.payment.owner.contacts_client}
									/>
								</Col>
								<Col span={12}>
									<UserDataCard user={payment.payment.owner.user} />
								</Col>
								<Col span={24}>
									<TransactionDataCard operation={payment.payment} />
								</Col>
							</Row>
						</Col>
						<Col span={12}>
							<ProgressCard history={payment.payment.history} />
						</Col>
						<Col span={12}>
							<Card style={{ width: "100%", height: "100%" }} className='exchange-values-card'>
								<Typography.Title level={5}>
									Valores da Transação
								</Typography.Title>
								<br />
								<div className="label-value">
									<label>Spread:</label>
									{formatPorcent(
										payment.payment.spread === 0 ?
											(rates.rates ? rates.rates.spread : 0)
											: payment.payment.spread
									)}
								</div>
								<br />
								<div>
									<TaxTable
										form={null}
										canEdit={false}
										exchangeValue={payment.currencyRate}
										data={payment.payment.taxes.map(item => {
											return {
												rates: rates,
												totalTaxes: payment.payment.taxes.reduce((prev, next) => prev.tax + next.tax) / 10000,
												currencyPrice: payment.currencyPrice,
												name: item.name,
												description: item.description,
												tax: item.tax,
												value: item.value,
												target_value: payment.payment.target_value,
												currency: !payment.payment.is_currency_target ? "BRL" : payment.payment.recipient_account.currency
											}
										})} />
								</div>
								<br />
								<Row justify="end">
									<Col></Col>
									<Col span={8} style={{ textAlign: "end" }}>
										<Typography.Title level={4}>
											{formatCurrencyBrl(
												payment.payment.recipient_account.currency,
												payment.payment.is_currency_target ? payment.payment.target_value : payment.payment.value
											)}
										</Typography.Title>
									</Col>
								</Row>
								<Row justify="end">
									<Col>
										<Typography.Title level={5}>
											TOTAL
										</Typography.Title>
									</Col>
									<Col span={8} style={{ textAlign: "end" }}>
										<Typography.Title level={4}>
											{formatCurrencyBrl(
												"BRL",
												payment.payment.is_currency_target ? payment.payment.value : payment.payment.target_value
											)}
										</Typography.Title>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col span={12}>
							<ContactSendCard
								title="Beneficiário"
								contact={
									{
										id: payment.payment.recipient.id,
										type: payment.payment.recipient.type,
										name: payment.payment.recipient.name,
										tax_identity: payment.payment.recipient.tax_identity,
										is_governmental: payment.payment.recipient.is_governmental,
										addr: payment.payment.recipient.addr,
										addr_city_name: payment.payment.recipient.addr_city_name,
										addr_country: payment.payment.recipient.addr_country,
										account: payment.payment.recipient_account
									}}
								onClick={() => { }}
							/>
						</Col>
						<Col span={12} className="list-docs">
							<Row className="title">
								<Typography.Title level={5}>Anexos</Typography.Title>
							</Row>
							<Row gutter={[8, 8]}>
								{payment.payment.files.map((item, index) =>
									<CardFile
										key={index}
										filename={item.file_name}
										isLoading={payment.status.fileDownload[item.id] === stateStatus.loading}
										onDownload={() => {
											dispatch(fileDownload({ file: item }))
										}}
									/>
								)}
							</Row>
							<Divider></Divider>
							<Row className="text">
								<Typography.Text level={5}>Fazer download de todos os documentos</Typography.Text>
							</Row>
							<Row>
								<Button
									className='download-zip'
									icon={<DownloadOutlined />}
									// loading={payment.status.fileDownload[item.id] === stateStatus.loading}
									onClick={() => {
										dispatch(downloadZipFiles({
											sendValueId: id,
										}))
									}}
								>Download ZIP</Button>
							</Row>
						</Col>
						{payment.payment.contract_id !== null && (
							<Col span={12} className="list-docs">
								<Row className="title">
									<Typography.Title level={5}>Contrato de Câmbio</Typography.Title>
								</Row>
								<Row gutter={[8, 8]}>
									<CardFile
										filename={"Contrato"}
										isLoading={payment.status.downloadContract === stateStatus.loading}
										onDownload={() => {
											dispatch(downloadContract(payment.payment.contract_id))
										}}
									/>
								</Row>
							</Col>
						)}
						{proofs.length > 0 && (
							<Col span={12} className="list-docs">
								<Row className="title">
									<Typography.Title level={5}>Comprovantes de Depósito</Typography.Title>
								</Row>
								<Row gutter={[8, 8]}>
									{proofs.map((item, index) =>
										<CardFile
											key={index}
											filename={item.file_name}
											isLoading={payment.status.fileDownload[item.id] === stateStatus.loading}
											onDownload={() => {
												dispatch(fileDownload({ file: item }))
											}}
										/>
									)}
								</Row>
							</Col>
						)}
						{receipts.length > 0 && (
							<Col span={12} className="list-docs">
								<Row className="title">
									<Typography.Title level={5}>Recibos</Typography.Title>
								</Row>
								<Row gutter={[8, 8]}>
									{receipts.map((item, index) =>
										<CardFile
											key={index}
											filename={item.file_name}
											isLoading={payment.status.fileDownload[item.id] === stateStatus.loading}
											onDownload={() => {
												dispatch(fileDownload({ file: item }))
											}}
										/>
									)}
								</Row>
							</Col>
						)}
					</Row>
				</Form>

				<OperationTaxesModal
					isVisible={showReviewModal}
					isLoading={payment.status.boReview === stateStatus.loading}
					data={{
						currencyRate: 530,
						spread: payment.payment.spread === 0 ?
							(rates.rates ? rates.rates.spread : 0)
							: payment.payment.spread,
						taxes: payment.payment.taxes.map(item => {
							return {
								name: item.name,
								description: item.description,
								tax: item.tax,
								value: 0,
							}
						})
					}}
					onSubmit={(values) => {
						if (values.nature_exchange) {
							values.nature_exchange = values.nature_exchange.split(' - ')[0];
						}
						dispatch(postBoReview({
							send_value_id: id,
							nature_exchange: values.nature_exchange,
							spread: values.spread,
							taxes: [
								{
									name: "IOF",
									description: "Imposto",
									tax: values.tax.iof,
								},
								{
									name: "IR",
									description: "Imposto de Renda",
									tax: values.tax.ir,
								},
								{
									name: "Tarifa",
									description: "Tarifa",
									tax: 0,
									value: values.tax.tarifa,
								},
							]
						})).then(() => {
							setShowReviewModal(false)
						})
					}}
					onCancel={() => {
						setShowReviewModal(false)
					}}
				/>

				{/* Fazer upload dos Arquivos de recibo de */}
				{
					showReceiptModal ? <Modal
						visible={true}
						title="Enviar Recibo De Câmbio"
						confirmLoading={payment.status.sendReceipt === stateStatus.loading}
						onCancel={() => {
							setShowReceiptModal(false)
							dispatch(resetCreateFile())
							dispatch(resetSendReceiptBOStatus())
						}}
						onOk={() => {
							dispatch(sendReceiptBO({
								files: [payments.createFile.fileId],
								send_value_id: payment.payment.id
							})).then(({ payload }) => {
								setShowReceiptModal(false)
								dispatch(resetCreateFile())
								dispatch(resetSendReceiptBOStatus())
							})
						}}
						okButtonProps={{ disabled: payments.createFile.fileId === null }}
					>
						{
							payment.status.sendReceipt === stateStatus.failed ? <ErrorMsg message={payment.errorMsg} /> : null
						}
						<Upload.Dragger
							accept=".pdf, .png, .jpg, .jpeg"
							customRequest={customRequestUpload}
							// maxCount={1}
							multiple={true}
							defaultFileList={[]}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-hint">
								Clique ou arraste os arquivos
							</p>
						</Upload.Dragger>
					</Modal> : null
				}
			</>
		)}
	</Base >
}

SinglePayment.propTypes = {
	match: PropTypes.object,
	location: PropTypes.any,
};

export default SinglePayment
