import axios from 'axios'
import api from '../../../../../service/api'
import apiRoutes from '../../../../../service/apiRoutes'

export const listDocuments = (id) => api.get(`${apiRoutes.companyId}${id}/documents`)

export const downloadCompanyDocs = (id) => api.get(`${apiRoutes.companyId}document/${id}`, { responseType: 'blob' })

export const sendDocuments = (cnpj, formData, config) => api.post(`${apiRoutes.companyId}document/send_documents?cnpj=${cnpj}`, formData, config);

export const sendQsaDocuments = (cnpj, formData, config) => api.post(`${apiRoutes.companyId}document/send_qsa_documents?cnpj=${cnpj}`, formData, config);

export const deleteCompanyDoc = (id) => api.delete(`${apiRoutes.companyId}document/${id}`)

export const downloadDocsZip = (id) => api.get(`${apiRoutes.companyId}${id}/zip`, { responseType: 'blob' })

export const uploadCompanyDoc = (formData, config) => api.post(`${apiRoutes.companyId}document`, formData, config);

export const downloadFinDocs = (id) => api.get(`${apiRoutes.companyId}financial_responsible/document/${id}`, { responseType: 'blob' })

export const deleteFinDoc = (id) => api.delete(`${apiRoutes.companyId}financial_responsible/document/${id}`)

export const uploadFinDoc = (formData, config) => api.post(`${apiRoutes.companyId}financial_responsible/document`, formData, config);

export const sendDocsToSign = (cnpj) => api.get(`${apiRoutes.companyId}sign_docs/company?cnpj=${cnpj}`)

export const sendFinDocsToSign = (cnpj) => api.get(`${apiRoutes.companyId}sign_docs/financial_responsibles?cnpj=${cnpj}`)