import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { companyId } from '../features/Companies/Company/slice'
import { docs } from '../features/Companies/Company/components/ListDocuments/slice'

import { company } from '../features/Companies/slice'
import { loginSlice } from '../features/Login/LoginSlice'
import { person } from '../features/People/Person/slice'
import { people } from '../features/People/slice'
import { users } from '../features/Users/slice'
import { personDocs } from '../features/People/Person/components/ListDocuments/slice'
import { personSignedDocs } from '../features/People/Person/components/SingedDocs/slice'
import { recepts } from '../features/Exchange/Recepts/slice'
import { recept } from '../features/Exchange/Recepts/SingleRecept/slice'
import { payments } from '../features/Exchange/Payments/slice'
import { payment } from '../features/Exchange/Payments/SinglePayment/slice'
import { rates } from '../features/Exchange/ListTaxes/slice'
import { exchangeOperationsState } from '../features/ExchangeOperations/slice'
import { transactions } from '../features/Transactions/slice'
import { financialResponsibles } from '../features/Companies/SelectFinRespSlice'
import { currencyCoveragesSlice } from '../features/Coverages/slice'

const combinedReducer = combineReducers({
	login: loginSlice.reducer,
	company: company.reducer,
	companyId: companyId.reducer,
	docs: docs.reducer,
	person: person.reducer,
	people: people.reducer,
	users: users.reducer,
	personDocs: personDocs.reducer,
	personSignedDocs: personSignedDocs.reducer,
	recepts: recepts.reducer,
	recept: recept.reducer,
	payments: payments.reducer,
	payment: payment.reducer,
	rates: rates.reducer,
	exchangeOperations: exchangeOperationsState.reducer,
	transactions: transactions.reducer,
	financialResponsibles: financialResponsibles.reducer,
	currencycoverages: currencyCoveragesSlice.reducer,
})

const rootReducer = (state, action) => {
	if (action.type === 'loginSlice/logout') {
		state = undefined
	}
	return combinedReducer(state, action)
}

const store = configureStore({
	reducer: rootReducer
})

export default store
