// import axios from 'axios'
import api from '../../../service/api'
import apiRoutes from '../../../service/apiRoutes'

export const getPerson = (id) => api.get(`${apiRoutes.person}${id}`)

export const putPerson = (id, data) => api.put(`${apiRoutes.person}${id}`, data)

export const boReview = (id, data) => api.post(`${apiRoutes.person}${id}/backoffice_review`, data)

export const getSignDocs = (cpf) => api.get(`${apiRoutes.person}sign_docs?cpf=${cpf}`)

export const updatePerson = (data) => api.patch(`${apiRoutes.person}register_update`, data);