// import axios from 'axios'
import api from '../../../../service/api'
import apiRoutes from '../../../../service/apiRoutes'

export const getDataPayment = (data) => api.get(`${apiRoutes.payment}`, { params: data })

export const putPayment = (data) => api.put(apiRoutes.payment, data)

export const postCatchPayment = (data) => api.post(`${apiRoutes.payment}/catch`, data)

export const boReview = (data) => api.post(`${apiRoutes.payment}/review_operation`, data)

export const contractUpload = (data) => api.post(`${apiRoutes.payment}/send_contract`, data)

export const finalizePayment = (data) => api.post(`${apiRoutes.payment}/finalize`, data)

export const removePayment = (dataId) => api.delete(`${apiRoutes.payment}?send_value_id=${dataId}`)

export const downloadFile = (fileId) => api.get(`${apiRoutes.exchangeFiles}?file_id=${fileId}`, { responseType: 'blob' })

export const downloadZip = (sendValueId) => api.get(`${apiRoutes.zipedSendVauleFiles}?send_value_id=${sendValueId}`, { responseType: 'arraybuffer' })

export const currencyRates = (params) => api.get(`${apiRoutes.rates}`, { params })

export const sendReceipt = (data) => api.post(`${apiRoutes.sendPaymentReceipt}`, data)

export const linkToSignedDoc = (fileId) => api.get(`${apiRoutes.downloadClickSign}?file_id=${fileId}`)

export const getExchangeRates = (sendValueId = null, receiveValueId = null) => api.get(`${apiRoutes.exchangeRates}`, { params: { send_value_id: sendValueId, receive_value_id: receiveValueId } })

export const postSendCloseOpEmail = (data) => api.post(apiRoutes.exchangeEmail, data)