import React, { useState } from 'react'
import { Breadcrumb, Button, Form } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import AddNewCompanyModal from '../AddNewCompanyModal';

import './styles.scss'

const CompaniesBread = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm()

	return <>
		<Breadcrumb className='company-header'>
			<Breadcrumb.Item >
				<ShopOutlined />
				Empresas
			</Breadcrumb.Item>
			<Breadcrumb.Item className='new-company-btn'>
				<Button
					onClick={() => setIsModalVisible(true)}
				>
					{" "}
					Cadastrar nova empresa{" "}
				</Button>
			</Breadcrumb.Item>
		</Breadcrumb>

		<AddNewCompanyModal
			isModalVisible={isModalVisible}
			setIsModalVisible={setIsModalVisible}
			form={form}
		/>
	</>
}

export default CompaniesBread