import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
const IdentedField = ({ children }) => {
	return (
		<div className="idented-box">
			{children}
		</div>
	)
}

IdentedField.propTypes = {
	children: PropTypes.element
}

export default IdentedField
