import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Layout, Menu, Modal, Badge } from "antd";
import {
	ShopOutlined,
	UserOutlined,
	LogoutOutlined,
	ArrowUpOutlined,
	SyncOutlined,
	FileTextOutlined,
	UsergroupAddOutlined,
	BellOutlined,
	DollarOutlined
} from "@ant-design/icons";

import "./styles.scss";
import { history } from "../../../helpers/history";
import routes from "../../../routes/routes";
import { logout as lout } from "../../../features/Login/LoginSlice";
import beep from "../../audios/notification.mp3"

import NotificationModal from "../NotificationsModal";
import { newNotifications } from "./slice";

const { Header, Sider } = Layout;

const Base = ({ selectedKeys, children }) => {
	const [collapsed, setCollapsed] = useState(true);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const dispatch = useDispatch();
	const [notifications, setNotifications] = useState([])
	const [timeout, updateTimeOut] = useState(0);
	const [timer, updateTimer] = useState(1);
	const [isAllDeleted, setIsAllDeleted] = useState(false)
	const [newNotificationAudio] = useState(new Audio(beep))

	useEffect(() => {
		if (isAllDeleted) {
			updateTimer(1)
		}
	}, [isAllDeleted])

	useEffect(() => {
		if (timer > 0) {
			clearTimeout(timeout);
			updateTimeOut(
				setTimeout(() => {
					updateTimer(timer - 1);
				}, 1000),
			);
		} else {
			loadNotifications();
			updateTimer(10)
		}
	}, [timer]);

	const loadNotifications = () => {
		dispatch(newNotifications({ page: 1 })).then(resp => {
			if (resp.payload) {
				setNotifications(resp.payload);
				resp.payload.pagination.total > 0 && newNotificationAudio.play();
				if (isAllDeleted) {
					setIsAllDeleted(false)
				}
			}
		});
	};


	const toggle = () => {
		setCollapsed(!collapsed);
	};
	const logout = () => {
		localStorage.clear();
		dispatch(lout());
		history.push(routes.login);
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<Layout className="base-layout">
			<Sider trigger={null} collapsible collapsed={collapsed}>
				<div className="logo">{collapsed ? "BO" : "BO - EfexHub"}</div>
				<Menu
					mode="inline"
					style={{ height: "100%" }}
					selectedKeys={selectedKeys}
				>
					<Menu.Item
						key="1"
						onClick={() => history.push(routes.companies)}
						icon={<ShopOutlined />}
					>
						Empresas
					</Menu.Item>
					<Menu.Item
						key="2"
						onClick={() => history.push(routes.people)}
						icon={<UserOutlined />}
					>
						Pessoa Física
					</Menu.Item>
					<Menu.Item
						key="3"
						onClick={() => history.push(routes.users)}
						icon={<UsergroupAddOutlined	 />}
					>
						Usuários
					</Menu.Item>
					<Menu.Item 
						key="4"
						onClick={() => history.push(routes.payments)}
						icon={<ArrowUpOutlined rotate={45} />}>
						Pagamentos - Em processo
					</Menu.Item>
					<Menu.Item 
						key="5"
						onClick={() => history.push(routes.recepts)}
						icon={<ArrowUpOutlined rotate={225} />}>
						Recebimentos - Em processo
					</Menu.Item>
					<Menu.Item
						key="6"
						onClick={() => history.push(routes.exchangeOperations)}
						icon={<SyncOutlined />}

					>
						Fechamento de Câmbio
					</Menu.Item>
					<Menu.Item
						key="7"
						onClick={() => history.push(routes.transactions)}
						icon={<FileTextOutlined />}
					>
						Relatórios
					</Menu.Item>
					<Menu.Item
						key="8"
						onClick={() => history.push(routes.coverages)}
						icon={<DollarOutlined />}
					>
						Controle de coberturas
					</Menu.Item>
				</Menu>
			</Sider>
			<Layout className="">
				<Header style={{ padding: "0 15px" }} className="">
					<div>
						{React.createElement(LogoutOutlined, {
							className: "trigger",
							onClick: logout,
						})}
					</div>
					{notifications.success && notifications.pagination?.total > 0 ? (
						<div style={{ marginRight: "10px" }}>
							<Badge count={notifications.pagination?.total} style={{ backgroundColor: '#f5222d' }}>
								<BellOutlined
									style={{ fontSize: "25px", cursor: "pointer", color: "white" }}
									onClick={showModal}
								/>
							</Badge>
						</div>
					) : (
						<div>
							<BellOutlined
								style={{ fontSize: "25px", cursor: "pointer" }}
								onClick={showModal}
							/>
						</div>
					)}

				</Header>
				{children}
			</Layout>

			<Modal
				title="Notificações"
				visible={isModalVisible}
				onOk={null}
				onCancel={handleCancel}
				maskClosable={true}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				notifications={notifications}

				style={{ top: 65, right: 17, position: 'fixed' }}
			>
				<NotificationModal
					notifications={notifications}
					setNotifications={setNotifications}
					setIsModalVisible={setIsModalVisible}
					setIsAllDeleted={setIsAllDeleted}
				/>
			</Modal>
		</Layout>
	);
};
Base.propTypes = {
	children: PropTypes.any,
	selectedKeys: PropTypes.array,
};
export default Base;
