import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Card, Typography, message, Table, Divider, Row } from "antd";

import "./styles.scss";
import { ShopOutlined, UserOutlined } from "@ant-design/icons";
import { beautifyCpfCnpj } from "../../../../utils/beautify";


const { Title } = Typography;

const ContactReceiveCard = ({ contact, onClick, loading, ...props }) => {

	useEffect(() => {
		if (loading) {
			message.loading({
				key: "loading",
				content: "Carregando"
			});
		}
	});

	const columnsAccounts = [
		{ title: 'Banco', dataIndex: 'bank_name' },
		{ title: 'Agência', dataIndex: 'agency_number' },
		{ title: 'Número da Conta', dataIndex: 'account_number' }
	]

	return (
		<Card
			className={`contact-receive-card`}
			onClick={onClick}
		>
			<div className="card-header">
				<Row gutter={[16, 0]} align="middle">
					<Col>
						<div className="icon-type">
							{contact.type == "company" ? <ShopOutlined /> : <UserOutlined />}
						</div>
					</Col>
					<Col>
						<Title level={5}>{contact.name}</Title>
						<div>{beautifyCpfCnpj(contact.cpf_cnpj)}</div>
					</Col>
				</Row>
			</div>
			<div className="card-body">
				<Divider />
				<Row gutter={[8, 0]}>
					<Col sm={24}>
						<Row>
							<Col sm={24}>
								<Table
									pagination={false}
									dataSource={[contact.account]}
									columns={columnsAccounts}
									rowKey="account_number"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</Card >
	);
};

ContactReceiveCard.propTypes = {
	contact: PropTypes.object,
	onClick: PropTypes.func,
	loading: PropTypes.bool
}


export default ContactReceiveCard;
