import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import stateStatus from '../../utils/status';
import { getTrasactions } from './service';

const initialState = {
	status: {
		exportTransactions: stateStatus.idle,
	},
	errorMsg: null,
	listDocs: null
}

export const exportTransactions = createAsyncThunk(
	'docs/downloadCompanyDocs',
	async (params) => {
		const response = await getTrasactions(params)
		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', 'operacoes.xlsx');
		document.body.appendChild(link);
		link.click();
		setTimeout(() => link.remove(), 20000)

		return true;
	}
)

export const transactions = createSlice({
    name: 'transactions',
    initialState,
    extraReducers: {
        [exportTransactions.pending]: (state, action) => {
			state.status.exportTransactions = stateStatus.loading
		},
		[exportTransactions.fulfilled]: (state, action) => {
			state.status.exportTransactions = stateStatus.succeeded
		},
		[exportTransactions.rejected]: (state, action) => {
			state.status.exportTransactions = stateStatus.failed
			state.errorMsg = `Erro ao fazer download`
		},
    }
})

export const selectTransactions = (state) => state.transactions
