/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Input, Form, Typography, InputNumber, Modal, Button } from 'antd';
import './styles.scss'
import { formatCurrencyStr } from '../../../../utils/functions';
import { useSelector } from 'react-redux';
import { selectExchangeOperations } from '../../../ExchangeOperations/slice';

const { Text } = Typography;


const CatchOperationModal = ({ isVisible, isLoading, onSubmit, onCancel }) => {
	const [form] = Form.useForm();
	const exchangeOperations = useSelector(selectExchangeOperations)

	useEffect(() => {
		if (!isVisible) {
			form.resetFields()
		}
	}, [isVisible])

	return (
		<Modal
			className="catch-operation-modal"
			title="Operar câmbio"
			visible={isVisible}
			confirmLoading={isLoading}
			// onOk={() => form.submit()}
			onCancel={onCancel}
			closable={true}
			footer={[
				<Button
					key="submit"
					type="primary"
					loading={isLoading}
					onClick={() => form.submit()}>
					Salvar
				</Button>,
			]}
		>
			<Form
				layout="vertical"
				form={form}
				onFinish={(values) => onSubmit(values)}
			>
				<div style={{ marginBottom: "15px" }}>
					<Text>
						Preencha os valores abaixo para tornar-se operador do câmbio <br />
						de ID {exchangeOperations.operationType === "send" ? (
							`${exchangeOperations.sendValueId} (envio).`
						) : (
							`${exchangeOperations.receiveValueId} (recebimento).`
						)}
					</Text>
				</div>
				<Form.Item
					label="Id externo da operação"
					name="external_id"
					initialValue=""
					rules={[
						{
							max: 30,
							message: 'Máximo de 30 caracteres'
						},
						{
							required: true,
							message: 'Campo obrigatório'
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Cotação da tesouraria"
					name="treasury_rate"
					initialValue=""
					rules={[
						{
							required: true,
							message: 'Campo obrigatório'
						},
					]}
				>
					<InputNumber
						style={{ width: "100%" }}
						min={1}
						formatter={(value) => formatCurrencyStr(value, 4)}
						parser={money => money.replace(/\D/g, '')}
					></InputNumber>
				</Form.Item>
			</Form>
		</Modal>
	);
}
export default CatchOperationModal;