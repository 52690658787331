import React, { useState } from "react";
import PropTypes from 'prop-types';
import TableNotification from "../TableNotification";
import { newNotifications, readAllNotifications } from "../Base/slice";
import { useDispatch } from "react-redux";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from 'antd';

const NotificationModal = ({
    notifications,
    setNotifications,
    setIsModalVisible,
    setIsAllDeleted,
}) => {
    const [loading, setLoading] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const dispatch = useDispatch();

    const handlePaginationChange = async (pagination) => {
        setLoading(true);
        try {
            const response = await dispatch(newNotifications({
                page: pagination.current,
            }));
            if (response.payload) {
                setNotifications(response.payload);
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteAllNotifications = async () => {
        setConfirmLoading(true);
        try {
            const response = await dispatch(readAllNotifications({ read_all: true }));
            if (response.payload) {
                setIsModalVisible(false);
                setIsAllDeleted(true)
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setConfirmLoading(false);
            setConfirmModalVisible(false);
        }
    };

    return (
        <>
            {!notifications.lst ? (
                <div>Carregando...</div>
            ) : (
                <TableNotification
                    dataSource={notifications?.lst.map(
                        (item) => ({
                            id: item.id,
                            title: item.title,
                            status: item.status,
                            company: item.company,
                            person: item.person,
                            receive_value: item.receive_value,
                            send_value: item.send_value,
                            description: item.description
                        }),
                    )}
                    pagination={{
                        current: notifications?.pagination.current_page,
                        total: notifications?.pagination.total,
                        pageSize: 5
                    }}
                    onChange={handlePaginationChange}
                />
            )}
            {notifications.lst?.length > 0 && !loading ? (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <Button type="primary"
                        onClick={() => setConfirmModalVisible(true)}
                        style={{ color: 'white', border: 'none' }}
                    >
                        Apagar todas notificações
                    </Button>
                </div>
            ) : null}
            <Modal
                title="Confirmar Exclusão"
                visible={confirmModalVisible}
                onCancel={() => setConfirmModalVisible(false)}
                confirmLoading={confirmLoading}
                onOk={handleDeleteAllNotifications}
            >
                Tem certeza que deseja apagar todas as notificações?
                {confirmLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            </Modal>
        </>
    );
}

NotificationModal.propTypes = {
    notifications: PropTypes.object.isRequired,
    setNotifications: PropTypes.func.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
    setIsAllDeleted: PropTypes.func.isRequired,
};

export default NotificationModal;
