/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Base from "../../common/components/Base";
import {
	Layout,
	Card,
	Table,
	Button,
	Input,
	Select,
	Col,
	Row,
	Tooltip,
} from "antd";
import "./styles.scss";
import { getCompanies, selectCompany } from "./slice";
import stateStatus from "../../utils/status";
import { CopyOutlined, EditFilled, EyeOutlined } from "@ant-design/icons";
import routes from "../../routes/routes";
import CompaniesBread from "./companents/CompaniesBread";
import { getCompanyId } from "./Company/slice";
import { resetStatus } from "./Company/components/ListDocuments/slice";
import { beautifyCpfCnpj } from "../../utils/beautify";
import CopyToClipboard from "react-copy-to-clipboard";
import { companyRegisterStatus } from "../../utils/consts";
const { Content } = Layout;

const columnsCompanies = [
	{
		title: "Usuário",
		dataIndex: "client",
		fixed: "left",
		render: (client) => `${client.first_name} ${client.last_name}`,
	},
	{
		title: "E-mail",
		dataIndex: "client",
		render: (client) => `${client.email}`,
	},
	{
		title: "Razão Social",
		dataIndex: "company_name",
	},
	{
		title: "CNPJ",
		dataIndex: "cnpj",
		width: 180,
		render: (text) => (
			<Row>
				<CopyToClipboard
					text={text}
					className={"copy-to-clipboard"}
				>
					<Tooltip title="Copiar">
						{beautifyCpfCnpj(text)}
					</Tooltip>
				</CopyToClipboard>
			</Row>
		),
	},
	{
		title: "Data início",
		dataIndex: "started_at",
		key: "data-inicio",
		width: 160,
		render: (text) => `${text}`,
	},
	{
		title: "Etapa cadastro",
		dataIndex: "register_step",
		width: 200,
		render: (step) => `${step.label}`,
	},
	{
		title: "Ações",
		// dataIndex: 'id',
		fixed: "right",
		width: 100,
		render: (data) => {
			const dispatch = useDispatch();
			return (
				<>
					<Link
						to={`${routes.companies}/${data.id}`}
						onClick={() => {
							dispatch(getCompanyId({ id: data.id }));
							dispatch(resetStatus());
						}}
					>
						<EyeOutlined />
					</Link>
				</>
			);
		},
	},
];

const Companies = () => {
	const company = useSelector(selectCompany);
	const dispatch = useDispatch();
	const [searchData, updateSearchData] = useState({});
	const [selectedStatus, setSelectedStatus] = useState([]);

	useEffect(() => {
		dispatch(getCompanies({ page: 1, values: searchData }));
	}, []);

	const handleStatusChange = (values) => {
		setSelectedStatus(values);
	};

	return (
		<Base selectedKeys={["1"]}>
			<Content className="content-companies">
				<CompaniesBread />
				<Card>
					<Row justify="space-between">
						<Col md={12}>
							<Input
								onBlur={(event) => {
									updateSearchData({
										...searchData,
										filter: event.target.value,
									});
								}}
								onPressEnter={(event) => {
									dispatch(
										getCompanies({
											page: 1,
											values: {
												...searchData,
												filter: event.target.value,
											},
										}),
									)
								}}
								placeholder="Buscar Empresa"
							></Input>
						</Col>
						<Col md={7}>
							<Select
								mode="multiple"
								style={{ width: "100%" }}
								placeholder="Filtrar Status"
								onChange={handleStatusChange}
								value={selectedStatus}
							>
								{companyRegisterStatus.map((status) => (
									<Select.Option key={status.value} value={status.value}>
										{status.label}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col>
							<Button
								onClick={() =>
									dispatch(
										getCompanies({
											page: 1,
											values: { ...searchData, status: selectedStatus },
										}),
									)
								}
							>
								{" "}
								Filtrar{" "}
							</Button>
						</Col>
					</Row>
				</Card>
				<Card>
					<Table
						pagination={{
							defaultPageSize: 15,
							current: company.list.page,
							total: company.list.count,
							pageSizeOptions: [],
						}}
						onChange={(pagination) => {
							dispatch(
								getCompanies({
									page: pagination.current,
									values: { ...searchData, status: selectedStatus },
								}),
							);
						}}
						dataSource={company.list.companies}
						columns={columnsCompanies}
						loading={
							company.status.getCompanies === stateStatus.loading
						}
					/>
				</Card>
			</Content>
		</Base>
	);
};

export default Companies;
