import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../../../utils/status'
import { getSignedDocs } from './service'

const initialState = {
	status: {
		signedDocs: stateStatus.idle,
	},
	errorMsg: null,
	listDocs: null
}

export const signedDocs = createAsyncThunk(
	'personSignedDocs/signedDocs',
	async ({ cpf }) => {
		const response = await getSignedDocs(cpf)
		return response.data
	}
)


export const personSignedDocs = createSlice({
	name: 'personSignedDocs',
	initialState,
	reducers: {
		resetStatus: (state) => {
			state.status.signedDocs = stateStatus.idle
		}
	},
	extraReducers: {
		// List Docs
		[signedDocs.pending]: (state) => {
			state.status.signedDocs = stateStatus.loading
		},
		[signedDocs.fulfilled]: (state, action) => {
			state.status.signedDocs = stateStatus.succeeded
			state.listDocs = action.payload.docs_to_sign
		},
		[signedDocs.rejected]: (state, action) => {
			state.status.signedDocs = stateStatus.failed
			state.errorMsg = action.error.message
		},
	}
})

export const { resetStatus } = personSignedDocs.actions

export const selectPersonSignedDocs = (state) => state.personSignedDocs