/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { Divider, Form, Select } from "antd";
import InputPorcent from "../../../../common/components/inputPorcent";
import { formatPorcent } from "../../../../utils/functions"

import { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";

const EditableSelection = ({listValues}) => {
	const [itemIof, setItemIof] = useState({});
	const [iofValues, setIofValues] = useState(listValues);
	const [isaddingNew, setIsAddingNew] = useState(false);

	useEffect(() => {
		if (isaddingNew) {
			let aux = iofValues;
			aux.push(itemIof);
			setIofValues(aux);
			setIsAddingNew(false);
		}
	});
	return (
		<Form.Item
			name={["tax", "iof"]}
			rules={[
				{
					required: true,
					message: "Campo obrigatório",
				},
			]}
		>
			<Select
				style={{ width: "120px" }}
				dropdownRender={(menu) => (
					<div>
						{menu}
						<Divider style={{ margin: "4px 0" }} />
						<div
							style={{
								display: "flex",
								flexWrap: "nowrap",
								padding: 4,
							}}
						>
							<InputPorcent
								onChange={(value) => {
									setItemIof({
										label: formatPorcent(value),
										value: value,
										prefix: "iof",
									});
								}}
							/>
							<a
								style={{
									flex: "none",
									padding: "8px",
									display: "block",
									cursor: "pointer",
								}}
								onClick={() => setIsAddingNew(true)}
							>
								<PlusOutlined />
							</a>
						</div>
					</div>
				)}
			>
				{iofValues.map((item, index) => (
					<Select.Option key={index} value={item.value}>
						{item.label}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default EditableSelection;
