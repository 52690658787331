import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom'
import  routes from '../../../../../routes/routes'
import { ShopOutlined, ProfileOutlined } from '@ant-design/icons';

const PersonBread = ({ personName }) => {
	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to={routes.people}>
					<ShopOutlined />Pessoas</Link>
			</Breadcrumb.Item>
			<Breadcrumb.Item>
				<ProfileOutlined />
				{personName}
			</Breadcrumb.Item>
		</Breadcrumb>
	</>
}

PersonBread.propTypes = {
	personName: PropTypes.string.isRequired
}

export default PersonBread