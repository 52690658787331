/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { Input, Form, Modal, InputNumber } from 'antd';
import './styles.scss'
import InputPorcent from '../inputPorcent';
import { formatCurrency } from '../../../utils/functions';


const ReviewClientModal = ({ isVisible, isLoading, onSubmit, onCancel, isSimple, operationalLimit }) => {
	const [form] = Form.useForm();

	return (
		<Modal
			className="review-client-modal"
			title="Aprovar Cadastro"
			open={isVisible}
			confirmLoading={isLoading}
			onOk={() => {
				form.submit();
			}}
			onCancel={onCancel}>
			<Form
				layout="vertical"
				form={form}
				onFinish={(values) => onSubmit(values)}
			>
				<Form.Item
					label="Spread Base"
					name="spread"
					initialValue=""
					rules={[
						{
							required: true,
							message: 'Campo obrigatório'
						},
					]}
				>
					<InputPorcent />
				</Form.Item>
				<Form.Item
					label="Código IBAN"
					name="iban_code"
					initialValue=""
					rules={[
						{
							required: true,
							message: 'Campo obrigatório'
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Limite Operacional"
					name="operational_limit"
					initialValue={operationalLimit ? operationalLimit : isSimple ? "1500000" : "0"}
				>
					<InputNumber
						style={{
							width: "100%",
						}}
						min={0}
						formatter={(
							value
						) =>
							formatCurrency(
								value,
								null
							)
						}
						parser={(
							money
						) =>
							money.replace(
								/\D/g,
								""
							)
						}
					/>
				</Form.Item>
			</Form>
		</Modal >
	);
}
export default ReviewClientModal;