import React, { useEffect, useState } from "react"
import Base from "../../common/components/Base"
import "./styles.scss";
import { CheckOutlined, CloseOutlined, DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { Layout, Card, Tabs, Table, InputNumber, Typography, Row, Col, Button, message, Popconfirm } from "antd";
import CovaragesBread from "./components/CoveragesBread";
import { useDispatch, useSelector } from "react-redux";
import {
    listSendValuesCurrencyCoverages,
    listReceiveValuesCurrencyCoverages,
    editSendValueCurrencyCoverage,
    editReceiveValueCurrencyCoverage,
    selectcurrencyCoverages,
    deleteSendValueCurrencyCoverage,
    deleteReceiveValueCurrencyCoverage,
} from "./slice";
import stateStatus from "../../utils/status";
const { Content } = Layout;

const { TabPane } = Tabs
const { Text } = Typography

const Coverages = () => {
    const dispatch = useDispatch();
    const currencyCovarages = useSelector(selectcurrencyCoverages);
    const [sendValuesCoverage, setSendValuesCoverage] = useState({})
    const [receiveValuesCoverage, setReceiveValuesCoverage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [bothDispatchesSuccess, setBothDispatchesSuccess] = useState(false)

    useEffect(() => {
        setBothDispatchesSuccess(false)

        Promise.all([
            dispatch(listReceiveValuesCurrencyCoverages({ page: 1 })),
            dispatch(listSendValuesCurrencyCoverages({ page: 1 })),
        ]).then(([respReceive, respSend]) => {
            if (respReceive.payload && respSend.payload) {
                setBothDispatchesSuccess(true)
            }
        }).catch((error) => {
            message.error('Erro interno do servidor:', error.message)
        })
    }, [])


    useEffect(() => {
        const statusSend = currencyCovarages.status.deleteSendValueCurrencyCoverage
        const statusReceive = currencyCovarages.status.deleteReceiveValueCurrencyCoverage

        if (statusSend === stateStatus.loading || statusReceive === stateStatus.loading) {
            message.loading("Excluindo...")
        }
    }, [currencyCovarages.status])

    const columnsSend = [
        {
            title: "Moeda",
            dataIndex: "",
            render: (item) => {
                return (
                    <Row>
                        <Text>{item?.currency}</Text>
                    </Row>
                )
            },
        },
        {
            title: "D0",
            dataIndex: "",
            render: (item) => {
                return (
                    <Row>
                        <InputNumber
                            disabled={sendValuesCoverage.currency && item.currency !== sendValuesCoverage.currency}
                            value={
                                sendValuesCoverage.currency === item.currency
                                    ? sendValuesCoverage.D0 !== undefined
                                        ? sendValuesCoverage.D0
                                        : item.D0
                                    : item.D0
                            }
                            step={0.000001}
                            precision={6}
                            min={0}
                            style={{ width: "125px" }}
                            onChange={(value) => {
                                setSendValuesCoverage({
                                    ...sendValuesCoverage,
                                    currency: item.currency,
                                    D0: value !== undefined && value !== null ? value : "N/A"
                                });
                            }}
                        />
                    </Row>
                );
            },
        },
        {
            title: "D1",
            dataIndex: "",
            render: (item) => {
                return (
                    <Row>
                        <InputNumber
                            disabled={sendValuesCoverage.currency && item.currency !== sendValuesCoverage.currency}
                            value={
                                sendValuesCoverage.currency === item.currency
                                    ? sendValuesCoverage.D1 !== undefined
                                        ? sendValuesCoverage.D1
                                        : item.D1
                                    : item.D1
                            }
                            step={0.000001}
                            precision={6}
                            min={0}
                            style={{ width: "125px" }}
                            onChange={(value) => {
                                setSendValuesCoverage({
                                    ...sendValuesCoverage,
                                    currency: item.currency,
                                    D1: value !== undefined && value !== null ? value : "N/A"
                                });
                            }}
                        />
                    </Row>
                );
            },
        },
        {
            title: "D2",
            dataIndex: "",
            render: (item) => {
                return (
                    <Row>
                        <InputNumber
                            disabled={sendValuesCoverage.currency && item.currency !== sendValuesCoverage.currency}
                            value={
                                sendValuesCoverage.currency === item.currency
                                    ? sendValuesCoverage.D2 !== undefined
                                        ? sendValuesCoverage.D2
                                        : item.D2
                                    : item.D2
                            }
                            step={0.000001}
                            precision={6}
                            min={0}
                            style={{ width: "125px" }}
                            onChange={(value) => {
                                setSendValuesCoverage({
                                    ...sendValuesCoverage,
                                    currency: item.currency,
                                    D2: value !== undefined && value !== null ? value : "N/A"
                                });
                            }}
                        />
                    </Row>
                );
            },
        },
        {
            title: "Ações",
            render: (record) => {
                return (
                    <>
                        <Row justify={"space-evenly"}>
                            {isLoading && sendValuesCoverage.currency && sendValuesCoverage.currency === record.currency ? (
                                <SyncOutlined spin />
                            ) : (
                                <>
                                    <Col>
                                        <Button
                                            title="Salvar Alterações"
                                            disabled={sendValuesCoverage && record.currency !== sendValuesCoverage.currency || isLoading}
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                setIsLoading(true)
                                                dispatch(editSendValueCurrencyCoverage({
                                                    currency: sendValuesCoverage.currency,
                                                    D0: sendValuesCoverage.D0,
                                                    D1: sendValuesCoverage.D1,
                                                    D2: sendValuesCoverage.D2,
                                                })).then((resp) => {
                                                    setIsLoading(false)
                                                    if (resp.payload) {
                                                        setSendValuesCoverage({})
                                                        dispatch(listSendValuesCurrencyCoverages({ page: 1 }))
                                                    } else {
                                                        message.error(resp.error.message)
                                                    }
                                                })
                                            }}
                                        >
                                            <CheckOutlined />
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            title="Cancelar Alterações"
                                            disabled={sendValuesCoverage && record.currency !== sendValuesCoverage.currency || isLoading}
                                            type="danger"
                                            size="small"
                                            onClick={() => {
                                                setSendValuesCoverage({})
                                            }}
                                        >
                                            <CloseOutlined />
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Popconfirm
                                            title="Deseja mesmo deletar esta moeda?"
                                            onConfirm={() => {
                                                dispatch(deleteSendValueCurrencyCoverage({ currency: record.currency }))
                                                    .then((resp) => {
                                                        if (resp.payload) {
                                                            message.success("Excluída com sucesso!")
                                                            dispatch(listSendValuesCurrencyCoverages({ page: 1 }))
                                                            dispatch(listReceiveValuesCurrencyCoverages({ page: 1 }))
                                                        } else {
                                                            message.error(resp.error.message || "Erro ao excluir!")
                                                        }
                                                    })
                                            }}
                                            placement="topRight"
                                            okText="Sim"
                                            cancelText="Não"
                                        >
                                            <Button
                                                title="Apagar Moeda"
                                                disabled={isLoading}
                                                type="danger"
                                                size="small"
                                            >
                                                <DeleteOutlined />
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </>
                );
            },
        },
    ];


    const columnsReceive = [
        {
            title: "Moeda",
            dataIndex: "",
            render: (item) => {
                return (
                    <Row>
                        <Text>{item?.currency}</Text>
                    </Row>
                );
            },
        },
        {
            title: "D0",
            dataIndex: "",
            render: (item) => {
                return (
                    <Row>
                        <InputNumber
                            disabled={receiveValuesCoverage.currency && item.currency !== receiveValuesCoverage.currency}
                            value={
                                receiveValuesCoverage.currency === item.currency
                                    ? receiveValuesCoverage.D0 !== undefined
                                        ? receiveValuesCoverage.D0
                                        : item.D0
                                    : item.D0
                            }
                            step={0.000001}
                            precision={6}
                            style={{ width: "125px" }}
                            onChange={(value) => {
                                const negativeValue = value > 0 ? -value : value;
                                setReceiveValuesCoverage({
                                    ...receiveValuesCoverage,
                                    currency: item.currency,
                                    D0: value !== undefined && value !== null ? negativeValue : "N/A"
                                });
                            }}
                        />
                    </Row>
                );
            },
        },
        {
            title: "D1",
            dataIndex: "",
            render: (item) => {
                return (
                    <Row>
                        <InputNumber
                            disabled={receiveValuesCoverage.currency && item.currency !== receiveValuesCoverage.currency}
                            value={
                                receiveValuesCoverage.currency === item.currency
                                    ? receiveValuesCoverage.D1 !== undefined
                                        ? receiveValuesCoverage.D1
                                        : item.D1
                                    : item.D1
                            }
                            step={0.000001}
                            precision={6}
                            style={{ width: "125px" }}
                            onChange={(value) => {
                                const negativeValue = value > 0 ? -value : value;
                                setReceiveValuesCoverage({
                                    ...receiveValuesCoverage,
                                    currency: item.currency,
                                    D1: value !== undefined && value !== null ? negativeValue : "N/A"
                                });
                            }}
                        />
                    </Row>
                );
            },
        },
        {
            title: "D2",
            dataIndex: "",
            render: (item) => {
                return (
                    <Row>
                        <InputNumber
                            disabled={receiveValuesCoverage.currency && item.currency !== receiveValuesCoverage.currency}
                            value={
                                receiveValuesCoverage.currency === item.currency
                                    ? receiveValuesCoverage.D2 !== undefined
                                        ? receiveValuesCoverage.D2
                                        : item.D2
                                    : item.D2
                            }
                            step={0.000001}
                            precision={6}
                            style={{ width: "125px" }}
                            onChange={(value) => {
                                const negativeValue = value > 0 ? -value : value;
                                setReceiveValuesCoverage({
                                    ...receiveValuesCoverage,
                                    currency: item.currency,
                                    D2: value !== undefined && value !== null ? negativeValue : "N/A"
                                });
                            }}
                        />
                    </Row>
                );
            },
        },
        {
            title: "Ações",
            render: (record) => {
                return (
                    <Row justify={"space-evenly"}>
                        {isLoading && receiveValuesCoverage.currency && receiveValuesCoverage.currency === record.currency ? (
                            <SyncOutlined spin />
                        ) : (
                            <>
                                <Col>
                                    <Button
                                        title="Salvar Alterações"
                                        disabled={receiveValuesCoverage && record.currency !== receiveValuesCoverage.currency || isLoading}
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            setIsLoading(true);
                                            dispatch(editReceiveValueCurrencyCoverage({
                                                currency: receiveValuesCoverage.currency,
                                                D0: receiveValuesCoverage.D0,
                                                D1: receiveValuesCoverage.D1,
                                                D2: receiveValuesCoverage.D2,
                                            })).then((resp) => {
                                                setIsLoading(false);
                                                if (resp.payload) {
                                                    setReceiveValuesCoverage({});
                                                    dispatch(listReceiveValuesCurrencyCoverages({ page: 1 }));
                                                } else {
                                                    message.error(resp.error.message);
                                                }
                                            });
                                        }}
                                    >
                                        <CheckOutlined />
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        title="Cancelar Alterações"
                                        disabled={receiveValuesCoverage && record.currency !== receiveValuesCoverage.currency || isLoading}
                                        type="danger"
                                        size="small"
                                        onClick={() => {
                                            setReceiveValuesCoverage({});
                                        }}
                                    >
                                        <CloseOutlined />
                                    </Button>
                                </Col>
                                <Col>
                                    <Popconfirm
                                        title="Deseja mesmo deletar esta moeda?"
                                        onConfirm={() => {
                                            dispatch(deleteReceiveValueCurrencyCoverage({ currency: record.currency }))
                                                .then((resp) => {
                                                    if (resp.payload) {
                                                        message.success("Excluída com sucesso!");
                                                        dispatch(listSendValuesCurrencyCoverages({ page: 1 }));
                                                        dispatch(listReceiveValuesCurrencyCoverages({ page: 1 }));
                                                    } else {
                                                        message.error(resp.error.message || "Erro ao excluir!");
                                                    }
                                                });
                                        }}
                                        placement="topRight"
                                        okText="Sim"
                                        cancelText="Não"
                                    >
                                        <Button
                                            title="Apagar Moeda"
                                            disabled={isLoading}
                                            type="danger"
                                            size="small"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </>
                        )}
                    </Row>
                );
            },
        },
    ];



    return (
        <Base selectedKeys={["8"]}>
            <Content className="content-coverages">
                <CovaragesBread bothDispatchesSuccess={bothDispatchesSuccess} />
                <Card>
                    <Tabs
                        type="card"
                        defaultActiveKey="1"
                    >
                        <TabPane tab="Saídas" key="1">
                            <Table
                                pagination={false}
                                locale={{ emptyText: "Sem resultado" }}
                                dataSource={currencyCovarages.listSendValuesCurrencyCoverages.send_values_currency_coverage}
                                columns={columnsSend}
                                loading={
                                    currencyCovarages.status.listSendValuesCurrencyCoverages ===
                                    stateStatus.loading
                                }
                            />
                        </TabPane>
                        <TabPane tab="Entradas" key="2">
                            <Table
                                pagination={false}
                                locale={{ emptyText: "Sem resultado" }}
                                dataSource={currencyCovarages.listReceiveValuesCurrencyCoverages.receive_values_currency_coverage}
                                columns={columnsReceive}
                                loading={
                                    currencyCovarages.status.listReceiveValuesCurrencyCoverages ===
                                    stateStatus.loading
                                }
                            />
                        </TabPane>
                    </Tabs>
                </Card>
            </Content>
        </Base>
    )
}

export default Coverages