import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import "./styles.scss";

export const GreyButton = (props) => {
    return <button {...props} className="efex-grey-button" >{props.loading ? <LoadingOutlined /> : null}{props.children}</button>
}

GreyButton.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node,
}
