const apiRoutes = {
	refreshToken: "/auth/refesh/",
	login: "/backoffice/v1/login/",
	users: "/backoffice/v1/users/",
	user: "/backoffice/v1/user/",
	company: "/backoffice/v1/companies/",
	companyId: "/backoffice/v1/company/",
	client: "/backoffice/v1/client/",
	people: "/backoffice/v1/people/",
	person: "/backoffice/v1/person/",
	docsToSign: "/backoffice/v1/company/list_sign_docs?cnpj=",
	recepts: "/backoffice/v1/exchange/receive_values?page=",
	recept: "/backoffice/v1/exchange/receive_value",
	payments: "/backoffice/v1/exchange/send_values?page=",
	payment: "/backoffice/v1/exchange/send_value",
	exchangeDefaultRates: "/backoffice/v1/exchange/default_rates",
	exchangeRates: "/backoffice/v1/exchange/rates",
	exchangeFiles: "/backoffice/v1/exchange/files",
	exchangeEmail: "/backoffice/v1/exchange/send_email",
	zipedSendVauleFiles: "/backoffice/v1/exchange/files/zip/send_value",
	zipedReceiveVauleFiles: "/backoffice/v1/exchange/files/zip/receive_value",
	rates: "/backoffice/v1/exchange/rates",
	catchSendValue: "/backoffice/v1/exchange/send_value/catch",
	uncatchSendValue: "/backoffice/v1/exchange/send_value/uncatch",
	makeSendValue: "/backoffice/v1/exchange/send_value/make_exchange",
	catchReceiveValue: "/backoffice/v1/exchange/receive_value/catch",
	uncatchReceiveValue: "/backoffice/v1/exchange/receive_value/uncatch",
	makeReceiveValue: "/backoffice/v1/exchange/receive_value/make_exchange",
	sendPaymentReceipt: "/backoffice/v1/exchange/send_value/send_receipt",
	approveReceiveValue: "/backoffice/v1/exchange/receive_value/approve",
	downloadClickSign: '/client/v1/exchange/files_signed/download',
	transactions: '/backoffice/v1/reports/operations',
	notifications: '/backoffice/v1/notification'
};
export default apiRoutes;
