import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Col, Input, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import br from "world_countries_lists/data/countries/en/world.json";
import { registerUser, selectUsers } from "../../slice";
import { cnpj as cnpjValidator } from "cpf-cnpj-validator";
import stateStatus from "../../../../utils/status";

const CreateUserModal = ({ isOpenModal, setIsOpenModal }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector(selectUsers);

    useEffect(() => {
        if(user.status.registerUser === stateStatus.loading){
            message.loading("Carregando...")
        }
    }, [dispatch, user])

    return (
        <Modal
            title="Criar Usuário"
            visible={isOpenModal}
            onOk={() => form.submit()}
            onCancel={() => {
                form.resetFields();
                setIsOpenModal(false);
            }}
            okText="Criar"
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={(data) => {
                    data.phone = `${data.phone.code}-${cnpjValidator.strip(
                        data.phone.phone
                    )}`;
                    delete data.password_confirm;
                    dispatch(registerUser({ data })).then((resp) => {
                        if (resp.payload?.success) {
                            message.success("Usuário criado com sucesso!");
                            form.resetFields();
                            setIsOpenModal(false);
                        } else {
                            message.error(
                                resp?.error?.message ||
                                    "Ops, não foi possivel criar este usuário"
                            );
                        }
                    });
                }}
            >
                <Col>
                    <Form.Item
                        label="Nome"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Informe o nome",
                            },
                            {
                                min: 3,
                                message: "O Nome deve ter no mínimo 3 letras",
                            },
                        ]}
                    >
                        <Input placeholder="Nome" />
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Informe o email",
                            },
                            {
                                type: "email",
                                message: "Informe um email válido",
                            },
                        ]}
                    >
                        <Input type="email" placeholder="Nome" />
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item
                        label="Telefone"
                        name={"phone"}
                        rules={[
                            {
                                required: true,
                                message: "Estado inválido.",
                            },
                        ]}
                    >
                        <ConfigProvider locale={br}>
                            <CountryPhoneInput
                                className="register-country-phone"
                                onChange={(v) => {
                                    form.setFieldsValue({ phone: v });
                                }}
                                value={{ short: "BR" }}
                            />
                        </ConfigProvider>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item
                        label="Digite uma nova senha:"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Informe a senha",
                            },
                            () => ({
                                validator(_, value) {
                                    if (value != null && value.length >= 8) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("Mínimo 8 caracteres.")
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={
                                <LockOutlined className="site-form-item-icon" />
                            }
                            placeholder="Senha"
                        />
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item
                        label="Confirme sua senha:"
                        name="password_confirm"
                        rules={[
                            {
                                required: true,
                                message: "Confirme a senha",
                            },
                            () => ({
                                validator(_, value) {
                                    const password =
                                        form.getFieldValue("password");
                                    if (value === password) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("As senhas devem ser iguais.")
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={
                                <LockOutlined className="site-form-item-icon" />
                            }
                            placeholder="Confirme sua senha"
                        />
                    </Form.Item>
                </Col>
            </Form>
        </Modal>
    );
};

CreateUserModal.propTypes = {
    isOpenModal: PropTypes.bool.isRequired,
    setIsOpenModal: PropTypes.bool.isRequired,
};

export default CreateUserModal;
