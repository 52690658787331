import { Card, Row, Col, Typography, Table, Progress, message } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Base from "../../common/components/Base"
import { beautifyCpfCnpj } from "../../utils/beautify"
import { formatCurrencyBrl } from "../../utils/functions"
import stateStatus from "../../utils/status"
import { getPayments, selectPayments } from "../Exchange/Payments/slice"
import { getRecepts, selectRecepts } from "../Exchange/Recepts/slice"
import beep from "../../common/audios/new_opr.mp3"
import errorAudio from "../../common/audios/system_off.mp3"

import { CloseCircleFilled, CheckCircleFilled, DollarOutlined } from "@ant-design/icons"
import CatchOperationModal from "../Exchange/_components/CatchOperationModal"
import { selectExchangeOperations, catchSendValue, makeSendValue, resetCatchSendValueStatus, resetCatchReceiveValueStatus, catchReceiveValue, makeReceiveValue, uncatchSendValue, uncatchReceiveValue } from "./slice"

const { Text } = Typography

const TableName = (owner) => <Text style={{ width: "150px" }} ellipsis={true}>{owner.name}</Text>
const Actions = (onClick) => <><DollarOutlined onClick={onClick} style={{ cursor: "pointer" }} /></>


const ExchangeOperations = () => {
    const maxTime = 5
    const dispatch = useDispatch()
    const payments = useSelector(selectPayments)
    const receivements = useSelector(selectRecepts)
    const exchangeOperations = useSelector(selectExchangeOperations)
    const [newItemAudio] = useState(new Audio(beep))
    const [systemOffAudio] = useState(new Audio(errorAudio))
    const [timeoutId, setTimeoutId] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [timer, setTimer] = useState(maxTime)

    useEffect(async () => {
        const status_in = ["waiting_exchange", "making_exchange"]
        dispatch(getPayments({ page: 0, data: { status_in, restrict_by_user: true } }));
        dispatch(getRecepts({ page: 0, data: { status_in, restrict_by_user: true, } }))
        setShowModal(false)
    }, [])

    useEffect(async () => {
        let status
        if (exchangeOperations.operationType === "send") {
            status = exchangeOperations.status.makeSendValue
        } else {
            status = exchangeOperations.status.makeReceiveValue
        }

        if (status === stateStatus.succeeded) {
            setShowModal(false)
            setTimer(-1)
            message.success({
                key: "loading",
                content: "Você é operador do câmbio"
            });

        } else if (status === stateStatus.failed) {
            message.error(exchangeOperations.errorMsg, 5)
        }

    }, [exchangeOperations.status.makeSendValue, exchangeOperations.status.makeReceiveValue])


    useEffect(async () => {
        let status
        if (exchangeOperations.operationType === "send") {
            status = exchangeOperations.status.uncatchSendValue
        } else {
            status = exchangeOperations.status.uncatchReceiveValue
        }

        if (status === stateStatus.succeeded) {
            setShowModal(false)
            setTimer(-1)

        } else if (status === stateStatus.failed) {
            message.error(exchangeOperations.errorMsg, 5)
        }

    }, [
        exchangeOperations.status.uncatchSendValue,
        exchangeOperations.status.uncatchReceiveValue,
    ])

    useEffect(async () => {
        let status
        if (exchangeOperations.operationType === "send") {
            status = exchangeOperations.status.catchSendValue
        } else {
            status = exchangeOperations.status.catchReceiveValue
        }

        if (status === stateStatus.succeeded) {
            setShowModal(true)
            dispatch(resetCatchReceiveValueStatus())
            dispatch(resetCatchSendValueStatus())
        } else if (status === stateStatus.succeeded) {
            message.error(exchangeOperations.errorMsg, 5)
            setTimer(-1)
            dispatch(resetCatchReceiveValueStatus())
            dispatch(resetCatchSendValueStatus())
        } else if (status === stateStatus.loading) {
            message.loading({
                key: "loading",
                content: "Reservando câmbio para operador...",
                duration: 2,
            });
        }
    }, [exchangeOperations.status.catchSendValue, exchangeOperations.status.catchReceiveValue])

    const OperateExchange = (sendValueId, status) => {
        clearTimeout(timeoutId)
        dispatch(catchSendValue({ sendValueId, status }))
    }

    const OperateReceivement = (receivementId, status) => {
        clearTimeout(timeoutId)
        dispatch(catchReceiveValue({ receivementId, status }))
    }

    const disposeSendValue = (sendValueId) => {
        dispatch(uncatchSendValue({ sendValueId }))
    }

    const disposeReceivement = (receivementId) => {
        dispatch(uncatchReceiveValue({ receivementId }))
    }

    useEffect(async () => {
        clearTimeout(timeoutId)

        if (timer <= 0) {
            const status_in = ["waiting_exchange", "making_exchange"]
            dispatch(getPayments({ page: 0, data: { status_in, restrict_by_user: true } }))
            dispatch(getRecepts({ page: 0, data: { status_in, restrict_by_user: true } }))
            return
        }

        const id = setTimeout(() => {
            setTimer(timer - 1)

        }, 1000)

        setTimeoutId(id)

    }, [timer])

    useEffect(async () => {
        if (payments.status.getPayments === stateStatus.succeeded) {
            if (payments.payments.lst.length > 0) {
                newItemAudio.play();
            }
            setTimer(maxTime)
        } else if (payments.status.getPayments === stateStatus.failed) {
            systemOffAudio.play()
            setTimer(maxTime)
        }
    }, [payments.status.getPayments])


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            render: (_, record) => {
                if (record.is_currency_target) {
                    return formatCurrencyBrl(record.currency, record.target_value.toString())
                } {
                    return formatCurrencyBrl(record.currency, record.value.toString())
                }
            }

        },
        {
            title: 'CPF/CNPJ',
            dataIndex: 'owner',
            key: 'cpf_cnpj',
            render: owner => beautifyCpfCnpj(owner.cpf_cnpj)
        },
        {
            title: 'Nome',
            dataIndex: 'owner',
            render: owner => TableName(owner)
        },
        {
            title: 'Fluxo',
            dataIndex: 'transaction_day',
            key: 'transaction_day'
        },
        {
            title: 'Ação',
            render: (_, record) => Actions(() => OperateExchange(record.id, record.status))
        }
    ]

    const columnsSendValue = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            render: (_, record) => {
                if (record.is_currency_target) {
                    return formatCurrencyBrl(record.currency, record.target_value.toString())
                } {
                    return formatCurrencyBrl(record.currency, record.value.toString())
                }
            }

        },
        {
            title: 'CPF/CNPJ',
            dataIndex: 'owner',
            key: 'cpf_cnpj',
            render: owner => beautifyCpfCnpj(owner.cpf_cnpj)
        },
        {
            title: 'Nome',
            dataIndex: 'owner',
            render: owner => TableName(owner)
        },
        {
            title: 'Fluxo',
            dataIndex: 'transaction_day',
            key: 'transaction_day'
        },
        {
            title: 'Ação',
            render: (_, record) => Actions(() => OperateReceivement(record.id, record.status))
        }
    ]
    const systemStatus = () => {
        return (
            payments.status.getPayments === stateStatus.failed
                ? <><CloseCircleFilled style={{ color: "red" }} /> SISTEMA DESCONECTADO</>
                : <><CheckCircleFilled style={{ color: "green" }} /> SISTEMA OPERANTE</>
        )
    }
    return <Base selectedKeys={["6"]}>
        <CatchOperationModal

            isVisible={showModal}
            isLoading={exchangeOperations.status.makeReceiveValue === stateStatus.loading
                || exchangeOperations.status.makeSendValue === stateStatus.loading}
            onSubmit={(values) => {
                if (exchangeOperations.operationType === "send") {
                    values['send_value_id'] = exchangeOperations.sendValueId
                    dispatch(makeSendValue(values))
                } else {
                    values['receive_value_id'] = exchangeOperations.receiveValueId
                    dispatch(makeReceiveValue(values))
                }
            }}
            onCancel={() => {
                if (exchangeOperations.operationType === "send") {
                    disposeSendValue(exchangeOperations.sendValueId)
                } else {
                    disposeReceivement(exchangeOperations.receiveValueId)
                }
            }}
        />
        <Row gutter={[8, 8]} style={{ width: "100%" }}>
            <Col md={12}>
                <Card>
                    {systemStatus()}
                </Card>
            </Col>
            <Col md={12}>
                <Card>
                    <Progress status="success" percent={(100 * timer) / maxTime} showInfo={false} />
                </Card>
            </Col>
        </Row>
        <br />
        <Row gutter={[8, 8]} style={{ width: "100%" }}>
            <audio>
                <source src={beep} type="audio/mpeg" />
            </audio>
            <Col md={12}>
                <div>
                    <Card>
                        <Typography.Title level={5}>Envio</Typography.Title>
                        <Table pagination={false} loading={payments.status.getPayments === stateStatus.loading} columns={columns} dataSource={payments.payments.lst}></Table>
                    </Card>
                </div>
            </Col>
            <Col md={12}>
                <div>
                    <Card>
                        <Typography.Title level={5}>Recebimento</Typography.Title>
                        <Table pagination={false} loading={receivements.status.getRecepts === stateStatus.loading} columns={columnsSendValue} dataSource={receivements.recepts.lst}></Table>
                    </Card>
                </div>
            </Col>
        </Row>
    </Base>
}

export default ExchangeOperations