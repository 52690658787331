import React from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import './style.scss'

const TooltipEfex = (props) => {
	return (<Tooltip {...props}>
		<QuestionCircleOutlined className="icon-green" />
	</Tooltip>)
}

export default TooltipEfex
