import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import stateStatus from "../../../../utils/status";
import { postCreateFile, putReceivedValue, putSendValue } from "./service";

const initialState = {
    status: {
        createFile: stateStatus.idle,
        updateReceiveValue: stateStatus.idle,
    },
    errorMsg: null,
    fileInfo: {},
};

export const createFile = createAsyncThunk(
    "exchange/createFile",
    async ({ formData, config, cpf_cnpj, onSuccess, onError }) => {
        try {
            formData.append("cpf_cnpj", cpf_cnpj);
            const response = await postCreateFile(formData, config, cpf_cnpj);
            onSuccess(response);
            return response;
        } catch (err) {
            onError(err);
            throw err;
        }
    }
);

export const updateReceiveValue = createAsyncThunk(
    'recept/updateReceiveValue',
    async (data) => {
        const response = await putReceivedValue(data)
        return response.data
    }
);

export const updateSendValue = createAsyncThunk(
    'recept/updateSendValue',
    async (data) => {
        const response = await putSendValue(data)
        return response.data
    }
);


export const fileSlice = createSlice({
    name: "file",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createFile.pending, (state) => {
                state.status.createFile = stateStatus.loading;
            })
            .addCase(createFile.fulfilled, (state, action) => {
                state.status.createFile = stateStatus.succeeded;
                state.fileInfo = action.payload.data;
            })
            .addCase(createFile.rejected, (state, action) => {
                state.status.createFile = stateStatus.failed;
                state.errorMsg = action.error.message;
            })
            .addCase(deleteExgFile.pending, (state) => {
                state.status.deleteExgFile = stateStatus.loading;
            })
            .addCase(deleteExgFile.fulfilled, (state, action) => {
                state.status.deleteExgFile = stateStatus.succeeded;
                state.fileInfo = action.payload.data;
            })
            .addCase(deleteExgFile.rejected, (state, action) => {
                state.status.deleteExgFile = stateStatus.failed;
                state.errorMsg = action.error.message;
            });
    },
});

export const selectFile = (state) => state.file;

export default fileSlice.reducer;