import React from "react"
import { Form, Input, Col, Row, Button, Typography } from "antd"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import InputPorcent from "../../../common/components/inputPorcent"
import "./styles.scss"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { listRates, postRates, selectRates } from "./slice"
import stateStatus from "../../../utils/status"
import PropTypes from "prop-types"

const { Title, Text } = Typography


const ListTaxes = ({ cpfCnpj }) => {
	const [form] = Form.useForm()
	const rates = useSelector(selectRates)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(listRates(cpfCnpj)).then(({ payload }) => {
			if (payload) {
				form.setFieldsValue({
					spread: payload.spread,
					iban_code: payload.iban_code,
				})
			}
		})
	}, [cpfCnpj])

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={(values) => {
				values.cpf_cnpj = cpfCnpj
				/* values.taxes = values.taxes.map((item) => {
					return {
						name: item.name,
						description: item.description,
						tax: item.tax
					}
				}) */
				dispatch(postRates(values))
			}}
		>
			<Row>
				<Col sm={4}>
					<Form.Item
						label="Taxa de serviço (%)"
						name="spread"
						initialValue=""
						rules={[
							{
								required: true,
								message: 'Campo obrigatório.'
							}
						]}
					>
						<InputPorcent />
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<Form.Item
						label="Código IBAN"
						name="iban_code"
						initialValue=""
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			{/* <Form.List name="taxes" initialValue={[{}]}>
				{(fields, { add, remove }, { errors }) => (
					<div className="list-taxes">
						<Row gutter={[16, 0]} justify="start">
							<Col >
								<Title level={5} style={{ lineHeight: "30px" }}>
									Taxas e Impostos
								</Title>
							</Col>
							<Col>
								<Button
									shape="circle"
									icon={<PlusOutlined />}
									className="add-taxes-icon"
									onClick={add}
								/>
							</Col>
						</Row>
						{fields.map((field) => (
							<Row gutter={[8, 0]} key={field.key} align="middle">
								<Col sm={6}>
									<Form.Item
										label="Nome"
										name={[field.name, "name"]}
										initialValue=""
										rules={[
											{
												required: true,
												message: 'Campo obrigatório.'
											}
										]}
									>
										<Input placeholder="Nome do imposto" type="text"></Input>
									</Form.Item>
								</Col>
								<Col sm={10} md={13}>
									<Form.Item
										label="Descrição"
										name={[field.name, "description"]}
										initialValue=""
										rules={[
											{
												required: true,
												message: 'Campo obrigatório.'
											}
										]}
									>
										<Input placeholder="Descrição" type="text"></Input>
									</Form.Item>
								</Col>
								<Col sm={6} md={3}>
									<Form.Item
										label="Desconto (%)"
										name={[field.name, "tax"]}
										initialValue=""
										rules={[
											{
												required: true,
												message: 'Campo obrigatório.'
											}
										]}
									>
										<InputPorcent />
									</Form.Item>
								</Col>
								<Col sm={1} style={{ textAlign: "center" }}>
									<DeleteOutlined onClick={() => remove(field.name)} />
								</Col>
							</Row>
						))}
					</div>
				)}
			</Form.List> */}
			<Button
				onClick={form.submit}
				loading={rates.status.postRates == stateStatus.loading}>
				Salvar
			</Button>
		</Form >
	)
}


ListTaxes.propTypes = {
	cpfCnpj: PropTypes.string
}

export default ListTaxes