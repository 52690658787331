import React from "react"
import { Card, Typography } from "antd"
import TextArea from "antd/lib/input/TextArea"
import PropTypes from 'prop-types'
import { formatCurrencyStr } from '../../../utils/functions';

const TransactionDataCard = ({ operation }) => {
    return (
        <Card style={{ width: "100%", height: "100%" }}>
            <Typography.Title level={5}>Dados da Transação</Typography.Title>
            <br />
            <div className="label-value">
                <label>Natureza informada pelo usuário:</label>
                <div>
                    {operation.nature_display}
                </div>
                {operation.status === "deleted" &&
                    <>
                        <label style={{ marginTop: "15px" }}>Motivo da exclusão:</label>
                        <div className='box'>
                            <TextArea value={operation.reason} readOnly={true} />
                        </div>
                    </>
                }
                {operation.nature === "other" &&
                    <>
                        <label style={{ marginTop: "15px" }}>Descrição do motivo:</label>
                        <div className='box'>
                            <TextArea value={operation.other} readOnly={true} />
                        </div>
                    </>
                }
                {operation.observation &&
                    <>
                        <label style={{ marginTop: "15px" }}>Observações do cliente:</label>
                        <div className='box'>
                            <TextArea value={operation.observation} readOnly={true} />
                        </div>
                    </>
                }
            </div>
            <br />
            <ul>
                {(["services"].includes(operation.nature) 
                    && operation.has_payment_relationship_display)
                        && (
                            <li>{operation.has_payment_relationship_display}</li>
                        )}

                {(["finance", "services", "other"].includes(operation.nature) 
                    && operation.is_tax_by_recipient_display)
                        && (
                            <li>{operation.is_tax_by_recipient_display}</li>
                        )}

                {(["commercial"].includes(operation.nature) 
                    && operation.has_advance_payment_display)
                        && (
                            <li>{operation.has_advance_payment_display}</li>
                        )}

                {(["services"].includes(operation.nature) 
                    && operation.has_capital_gain_display)
                        && (
                            <li>{operation.has_capital_gain_display}</li>
                        )}
            </ul>
            <br />
            {operation.nature_exchange !== null && (
                <div className="label-value">
                    <label>Natureza informada pelo backoffice:</label>
                    <div>
                        {operation.nature_exchange} - {operation.nature_display}
                    </div>
                    <br />
                </div>
            )}
            {operation.external_id && (
                <>
                    <div className="label-value">
                        <label>ID Externo:</label>
                        {operation.external_id}
                    </div>
                    <div className="label-value">
                        <label>Cotação Tesouraria:</label>
                        {formatCurrencyStr(operation.treasury_rate, 4)}
                    </div>
                </>
            )}
            {operation.transaction_day && operation.transaction_day === "D0" && (
                <div className="label-value">
                    <label>Data escolhida pelo usuário para envio dos recursos:</label>
                    <div>
                        Enviar hoje (no dia do fechamento)
                    </div>
                    <br />
                </div>
            )}
            {operation.transaction_day && operation.transaction_day === "D1" && (
                <div className="label-value">
                    <label>Data escolhida pelo usuário para envio dos recursos:</label>
                    <div>
                        Enviar no próximo dia útil
                    </div>
                    <br />
                </div>
            )}
            {operation.transaction_day && operation.transaction_day === "D2" && (
                <div className="label-value">
                    <label>Data escolhida pelo usuário para envio dos recursos:</label>
                    <div>
                        Enviar em dois dias úteis
                    </div>
                    <br />
                </div>
            )}
            {![
                "draft",
                "waiting_approval",
                "need_changes",
                "approved",
            ].includes(operation.status) ? (
                <div className="label-value">
                    <label>Taxa do usuário:</label>
                    {"$ "}{formatCurrencyStr(operation.currency_rates, 4)}
                </div>
            ) : null}
        </Card>
    )
}

TransactionDataCard.propTypes = {
    operation: PropTypes.object,
};

export default TransactionDataCard;