import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Row, Space, Typography, notification } from "antd";
import { ArrowUpOutlined, CheckCircleOutlined, FileTextOutlined, LoadingOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";

import Base from "../../../common/components/Base";
import TableExchange from "../_components/TableExchange";
import ExchangeBread from "../_components/ExchangeBread";
import { useDispatch, useSelector } from "react-redux";
import { getRecepts, selectRecepts } from "./slice";
import { selectRecept, postRecept } from "./SingleRecept/slice";
import stateStatus from "../../../utils/status";
import "./styles.scss";
import CreateOperation from "../../../common/components/CreateOperation";
import routes from "../../../routes/routes";
import { history } from "../../../helpers/history";



const Recepts = () => {
	const recepts = useSelector(selectRecepts);
	const singleRecept = useSelector(selectRecept);
	const dispatch = useDispatch();
	const [searchData, setSearchData] = useState({});
	const [showCreateOpModal, setShowCreateOpModal] = useState(false)
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [page, setPage] = useState(1)
	const [timeout, updateTimeOut] = useState(0);
	const [timer, updateTimer] = useState(5);

	useEffect(() => {
		dispatch(getRecepts({ page: 1, data: searchData }));
	}, []);

	useEffect(() => {
		if (timer > 0) {
			clearTimeout(timeout);
			updateTimeOut(
				setTimeout(() => {
					updateTimer(timer - 1);
				}, 1000),
			);
		} else {
			dispatch(getRecepts({ page, data: searchData }));
			updateTimer(5)
		}
	}, [timer]);

	const status = [
		{ value: "", label: "Todos" },
		{ value: "draft", label: "Rascunho" },
		{ value: "waiting_approval", label: "Aguardando Aprovação" },
		{ value: "need_changes", label: "Aguardando Alterações" },
		{ value: "approved", label: "Aprovada" },
		{ value: "scheduled", label: "Agendado" },
		{ value: "waiting_contract", label: "Aguardando envio do contrato" },
		{ value: "waiting_sign_doc", label: "Aguardando Assinatura de Contrato" },
		{ value: "contract_signed", label: "Contrato assinado" },
		{ value: "completed", label: "Operação Completada" },
		{ value: "blocked", label: "Bloqueado" },
		{ value: "finished", label: "Finalizado" },
	];

	const onCreateOp = () => {

	}

	return (
		<Base selectedKeys={["5"]}>
			<Col className="exchange-payments">
				<ExchangeBread
					hasParent={false}
					currentIcon={<ArrowUpOutlined rotate={225} />}
					currentName="Recebimentos"
				/>
				<Col>
					<Divider />
					<Row>
						<Space>
							<Button
								icon={<PlusOutlined />}
								onClick={() => setShowCreateOpModal(true)}
							>
								Criar Operação
							</Button>
						</Space>
					</Row>
					<Divider />
				</Col>
				<Card>
					<div style={{ marginLeft: "20px", marginTop: "20px" }}>
						<Typography.Title style={{ marginLeft: "9px", marginBottom: "10px" }} level={5}>
							Recebimentos
						</Typography.Title>
						<TableExchange
							dataSource={recepts.recepts.lst.map(
								(item) => ({
									id: item.id,
									// user: item.owner.client.first_name,
									// email: item.owner.client.email,
									name: item.owner.name,
									route: "recebimentos",
									status: item.status,
									cpf_cnpj: item.owner.cpf_cnpj,
									status_display: item.status_display,
									operator: item.backoffice_user,
									currency: item.currency,
									is_currency_target: item.is_currency_target,
									target_value: item.target_value,
									blocked: item.blocked,
								}),
							)}
							pagination={{
								current: recepts.recepts.pagination.current_page,
								total: recepts.recepts.pagination.total,
								pageSize: 15
							}}
							onChange={(pagination) => {
								setPage(pagination.current)
								dispatch(
									getRecepts({
										page: pagination.current,
										data: searchData,
									}),
								);
							}}
							onFilter={(values) => {
								setSearchData(values);
								dispatch(
									getRecepts({
										page: 1,
										data: {
											status: values.status,
											filter: values.filter,
										},
									}),
								);
							}}
							loading={
								recepts.status.getRecepts ===
								stateStatus.loading
							}
							status={status}
							selectedStatus={selectedStatus}
							onStatusChange={setSelectedStatus}
						/>
					</div>
				</Card>
			</Col>
			<CreateOperation
				isVisible={showCreateOpModal}
				isLoading={singleRecept.status.postRecept === stateStatus.loading}
				onCancel={() => setShowCreateOpModal(false)}
				onSubmit={(values) => {
					dispatch(postRecept(values)).then((value) => {
						if (value.payload) {
							history.push(`${routes.recepts}/${value.payload.id}`);
						} else {
							notification.error({
								message: `Erro!`,
								description: value.error.message,
								placement: "topRight",
							})
						}
					}).catch((errors) => {
						console.log(errors.message)
					})
				}}
			/>
		</Base>
	);
};

export default Recepts;
