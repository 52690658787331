import axios from 'axios'
import jwt_decode from 'jwt-decode'
import apiRoutes from './apiRoutes'

const apiUrl = process.env.REACT_APP_BASEURL

const api = axios.create({
	baseURL: apiUrl,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
})

api.interceptors.request.use(
	async (config) => {
		const accessToken = localStorage.getItem('access_token')
		if (accessToken) {
			const tokenData = jwt_decode(accessToken)
			const currentDate = new Date()
			if (tokenData.exp * 1000 <= currentDate.getTime()) {
				const refreshToken = localStorage.getItem('refresh_token')
				if (refreshToken) {
					try {
						const response = await axios.post(`${apiUrl}${apiRoutes.refreshToken}`, { refresh: refreshToken })
						localStorage.setItem('access_token', response.data.access)
						config.headers.Authorization = `Bearer ${response.data.access}`
					} catch (err) {
						// TODO: mudar para pagina inicial
						localStorage.clear()
						window.location = '/'
					}
				} else {
					// TODO: mudar para pagina inicial
					localStorage.clear()
					window.location = '/'
				}
			} else {
				config.headers.Authorization = `Bearer ${accessToken}`
			}
		} else {
			// TODO: mudar para pagina inicial
			window.location = '/'
		}
		return config
	},
	(error) => {
		return Promise.error(error)
	}
)

api.interceptors.response.use(undefined, (err) => {
	const customError = {
		name: 'Api Error',
		message: err.response && err.response.status < 500 ? err.response.data.msg : 'Erro no sistema, tente mais tarde!',
		data: err.response ? err.response.data : null
	}
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
	return Promise.reject(customError)
})

export default api
