import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Input, message } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { mask } from '../../../../utils/consts';
import { ShopOutlined } from '@ant-design/icons';
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import { postAddCompany, findUser } from '../../slice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from '../../../../routes/routes';

const AddNewCompanyModal = ({ isModalVisible, setIsModalVisible, form }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = (values) => {
        setIsLoading(true)
        values.cnpj = cnpjValidator.strip(values.cnpj);
        dispatch(findUser(values.email)).then((resp) => {
            if (!resp.payload) {
                setIsLoading(false)
                message.error(resp.error.message || "Erro ao cadastrar empresa. Tente novamente.");
            } else if (resp.payload) {
                dispatch(postAddCompany(values)).then((resp) => {
                    setIsLoading(false)
                    if (!resp.payload) {
                        message.error(resp.error.message || "Erro ao cadastrar empresa. Tente novamente.");
                    } else if (resp.payload) {
                        history.push(`${routes.companies}/${resp.payload.company_id}`)
                        setIsModalVisible(false);
                    }
                })
            }
        })
    };

    return (
        <Modal
            title="Adicionar nova empresa"
            open={isModalVisible}
            onOk={() => form.submit()}
            onCancel={() => setIsModalVisible(false)}
            okButtonProps={{ loading: isLoading, disabled: isLoading }}
            cancelButtonProps={{ disabled: isLoading }}
            maskClosable={true}
        >
            <div
                style={{
                    fontSize: "14px",
                    lineHeight: "25px",
                    marginBottom: "20px",
                }}
            >
                <p>
                    Insira abaixo o <strong>e-mail</strong> cadastrado pelo usuário e <strong>CNPJ</strong> da empresa que será cadastrada.
                </p>
            </div>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name={"email"}
                    rules={[
                        {
                            required: true,
                            message: "Informe o email",
                        },
                        {
                            type: "email",
                            message: "Informe um email válido.",
                        },
                    ]}
                >
                    <Input
                        placeholder="E-mail do usuário"
                    />
                </Form.Item>
                <Form.Item
                    name={"cnpj"}
                    rules={[
                        {
                            required: true,
                            message: "Informe o CNPJ",
                        },
                        () => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    cnpjValidator.isValid(value)
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("CNPJ inválido.")
                                );
                            },
                        }),
                    ]}
                >
                    <MaskedInput
                        prefix={<ShopOutlined />}
                        placeholder="CNPJ"
                        mask={mask.cnpj}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddNewCompanyModal;

AddNewCompanyModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    setIsModalVisible: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
};
