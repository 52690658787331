import React, { useEffect } from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import routes from "../../../../routes/routes"
import { Button, Card, Col, Input, Row, Table, Tooltip} from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { EyeOutlined, ShopOutlined, SearchOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { beautifyCpfCnpj, ellipsis } from "../../../../utils/beautify";
import stateStatus from "../../../../utils/status";
import { getPeople, selectPeople } from "../../../People/slice";
import PropTypes from "prop-types";

const TableOfPeople = ({userId}) => {
    const people = useSelector(selectPeople);
    const [searchData, updateSearchData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getPeople({ page: 1, values: { onwer_id: userId } })
        )
    }, [])

    const columnsPeople = [
        {
            title: "ID",
            fixed: "left",
            render: (person) => person.id,
        },
        {
            title: "Nome",
            render: (person) => {
                return <p>
                        <ShopOutlined style={{marginRight: "5px"}}/>
                        {ellipsis(person.name)}
                </p>
            },
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            render: (cpf) => {
                return (
                    <Row>
                        <CopyToClipboard
                            text={cpf}
                            className={"copy-to-clipboard"}
                        >
                            <Tooltip title="Copiar">{beautifyCpfCnpj(cpf)}</Tooltip>
                        </CopyToClipboard>
                    </Row>
                );
            },
        },
        {
            title: "Criada em",
            render: (person) => {
                const data = person.started_at.split(" ")

                return data[0]
            },
        },
        {
            title: "Ações",
            dataIndex: "id",
            fixed: "right",
            width: 100,
            render: function edit(id) {
                return (
                    <>
                        <Link
                            to={`${routes.people}/${id}`}
                        >
                            <EyeOutlined />
                        </Link>
                    </>
                );
            },
        },
    ];

    return <>
            <Content className="content-people">
                <Card>
                    <Row style={{width: "40%"}}>
                        <Col md={12}>
                            <Input
                                onBlur={(event) => {
                                    updateSearchData({
                                        ...searchData,
                                        filter: event.target.value,
                                    });
                                }}
                                onPressEnter={(event) => {
                                    dispatch(
                                        getPeople({
                                            page: 1,
                                            values: {
                                                ...searchData,
                                                filter: event.target.value,
                                                onwer_id: userId
                                            },
                                        })
                                    );
                                }}
                                placeholder="Buscar empresa"
                            ></Input>
                        </Col>
                        <Col style={{marginLeft: "5px"}}>
                            <Button
                                onClick={() =>
                                    dispatch(
                                        getPeople({
                                            page: 1,
                                            values: { ...searchData, onwer_id: userId },
                                        })
                                    )
                                }
                            >
                                <SearchOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Table
                        rowKey={(record) => record.email}
                        pagination={{
                            defaultPageSize: 15,
                            current: people.list.page,
                            total: people.list.count,
                            pageSizeOptions: [],
                        }}
                        onChange={(pagination) => {
                            dispatch(
                                getPeople({
                                    page: pagination.current,
                                    values: {...searchData, onwer_id: userId},
                                })
                            );
                        }}
                        dataSource={people.list.people}
                        columns={columnsPeople}
                        loading={people.status.getPeople === stateStatus.loading}
                    />
                </Card>
            </Content>
        </>
};

TableOfPeople.propTypes = {
    userId: PropTypes.string,
};

export default TableOfPeople;
