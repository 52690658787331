import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../utils/status'
import { listRecepts, postCreateReceptOp } from './service'

const initialState = {
	status: {
		postCreateReceptOp: stateStatus.idle,
		getRecepts: stateStatus.idle,
	},
	recepts: {
		lst: [],
		pagination: {}
	},
	errorMsg: null
}

export const getRecepts = createAsyncThunk(
	'recepts/listRecepts',
	async ({ page, data }) => {
		const response = await listRecepts(page, data)
		return response.data
	}
)

export const fileDownload = createAsyncThunk(
	'recept/fileDownload',
	async ({ fileId }) => {
		const response = await downloadFile({
			receive_value_id: id,
			file_id: fileId
		})
		return response.data
	}
)

export const createReceptOp = createAsyncThunk(
	"recept/createReceptOp",
	async (data) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj;
		const response = await postCreateReceptOp(data);
		return response.data;

	},
);

export const recepts = createSlice({
	name: 'recepts',
	initialState,
	extraReducers: {
		/* 	[getReceptsCompanies.pending]: (state) => {
				state.status.getReceptsCompanies = stateStatus.loading
			},
			[getReceptsCompanies.fulfilled]: (state, action) => {
				state.status.getReceptsCompanies = stateStatus.succeeded
				state.companies.recepts = []
				state.companies.recepts = action.payload.received_values
				state.companies.count = action.payload.count
				state.companies.page = action.payload.page
				state.companies.total_pages = action.payload.total_pages
			},
			[getReceptsCompanies.rejected]: (state, action) => {
				state.status.getReceptsCompanies = stateStatus.failed
				state.errorMsg = action.error.message
			},
			[getReceptsPeople.pending]: (state) => {
				state.status.getReceptsPeople = stateStatus.loading
			},
			[getReceptsPeople.fulfilled]: (state, action) => {
				state.status.getReceptsPeople = stateStatus.succeeded
				state.people.recepts = []
				state.people.recepts = action.payload.received_values
				state.people.count = action.payload.count
				state.people.page = action.payload.page
				state.people.total_pages = action.payload.total_pages
			},
			[getReceptsPeople.rejected]: (state, action) => {
				state.status.getReceptsPeople = stateStatus.failed
				state.errorMsg = action.error.message
			}, */
		[getRecepts.pending]: (state) => {
			state.status.getRecepts = stateStatus.loading
		},
		[getRecepts.fulfilled]: (state, action) => {
			state.status.getRecepts = stateStatus.succeeded
			state.recepts.lst = action.payload.lst
			state.recepts.pagination.total = action.payload.pagination.total
			state.recepts.pagination.current_page = action.payload.pagination.current_page			
		},
		[getRecepts.rejected]: (state, action) => {
			state.status.getRecepts = stateStatus.failed
			state.errorMsg = action.error.message
		}
	}
})

export const selectRecepts = (state) => state.recepts
