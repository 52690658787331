import React, { useEffect } from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import routes from "../../../../routes/routes"
import { Button, Card, Col, Input, Row, Table, Tooltip} from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { EyeOutlined, ShopOutlined, SearchOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { beautifyCpfCnpj, ellipsis } from "../../../../utils/beautify";
import { getCompanies, selectCompany } from "../../../Companies/slice";
import stateStatus from "../../../../utils/status";
import PropTypes from "prop-types";

const TableOfCompanies = ({userId}) => {
    const company = useSelector(selectCompany);
    const [searchData, updateSearchData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getCompanies({ page: 1, values: { onwer_id: userId } })
        )
    }, [])

    const columnsCompany = [
        {
            title: "ID",
            fixed: "left",
            render: (company) => company.id,
        },
        {
            title: "Nome",
            render: (company) => {
                return <p>
                        <ShopOutlined style={{marginRight: "5px"}}/>
                        {ellipsis(company.company_name)}
                </p>
            },
        },
        {
            title: "CNPJ",
            dataIndex: "cnpj",
            render: (cnpj) => {
                return (
                    <Row>
                        <CopyToClipboard
                            text={cnpj}
                            className={"copy-to-clipboard"}
                        >
                            <Tooltip title="Copiar">{beautifyCpfCnpj(cnpj)}</Tooltip>
                        </CopyToClipboard>
                    </Row>
                );
            },
        },
        {
            title: "Criada em",
            render: (company) => {
                const data = company.started_at.split(" ")

                return data[0]
            },
        },
        {
            title: "Ações",
            dataIndex: "id",
            fixed: "right",
            width: 100,
            render: function edit(id) {
                return (
                    <>
                        <Link
                            to={`${routes.companies}/${id}`}
                        >
                            <EyeOutlined />
                        </Link>
                    </>
                );
            },
        },
    ];

    return <>
            <Content className="content-people">
                <Card>
                    <Row style={{width: "40%"}}>
                        <Col md={12}>
                            <Input
                                onBlur={(event) => {
                                    updateSearchData({
                                        ...searchData,
                                        filter: event.target.value,
                                    });
                                }}
                                // onPressEnter={(event) => {
                                //     dispatch(
                                //         getCompanies({
                                //             page: 1,
                                //             values: {
                                //                 ...searchData,
                                //                 filter: event.target.value,
                                //                 onwer_id: userId
                                //             },
                                //         })
                                //     );
                                // }}
                                placeholder="Buscar empresa"
                            ></Input>
                        </Col>
                        <Col style={{marginLeft: "5px"}}>
                            <Button
                                onClick={() =>
                                    dispatch(
                                        getCompanies({
                                            page: 1,
                                            values: { ...searchData, onwer_id: userId },
                                        })
                                    )
                                }
                            >
                                <SearchOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Table
                        rowKey={(record) => record.email}
                        pagination={{
                            defaultPageSize: 15,
                            current: company.list.page,
                            total: company.list.count,
                            pageSizeOptions: [],
                        }}
                        onChange={(pagination) => {
                            dispatch(
                                getCompanies({
                                    page: pagination.current,
                                    values: {...searchData, onwer_id: userId},
                                })
                            );
                        }}
                        dataSource={company.list.companies}
                        columns={columnsCompany}
                        loading={company.status.getCompanies === stateStatus.loading}
                    />
                </Card>
            </Content>
        </>
};

TableOfCompanies.propTypes = {
    userId: PropTypes.string,
};

export default TableOfCompanies;
