import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import { removeResponsible, removeQsa } from '../../SelectFinRespSlice';
import { getCompanyId } from '../../Company/slice';
import { getListDocs } from '../../Company/components/ListDocuments/slice';

const DelFRModal = ({ isDelModalVisible, setIsDelModalVisible, companyId, frId, responsible }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)


    const handleRemoval = () => {
        setIsLoading(true)
        dispatch(removeQsa(responsible))
        dispatch(removeResponsible({ id: companyId, fin_id: frId })).then((resp) => {
            setIsLoading(false)
            if (resp.payload) {
                setIsDelModalVisible(false)
                dispatch(getCompanyId({ id: companyId }))
                dispatch(getListDocs({ id: companyId }))
            } else if (!resp.payload) {
                message.error(resp.error.message || "Erro ao remover Responsável. Tente novamente.");
            }
        });
    };

    return (
        <Modal
            title="Remover Responsável financeiro?"
            open={isDelModalVisible}
            onCancel={() => setIsDelModalVisible(false)}

            width={320}
            footer={null}
        >
            <div style={{ display: 'flex', justifyContent: "space-evenly", alignItems: "center" }}>
                <Button loading={isLoading} disabled={isLoading} type="primary" onClick={handleRemoval}>Remover</Button>
                <Button disabled={isLoading} onClick={() => setIsDelModalVisible(false)}>Cancelar</Button>
            </div>
        </Modal>
    );
};

export default DelFRModal;

DelFRModal.propTypes = {
    isDelModalVisible: PropTypes.bool.isRequired,
    setIsDelModalVisible: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    frId: PropTypes.number.isRequired,
    responsible: PropTypes.object.isRequired
};
