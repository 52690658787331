import React from 'react'
import { Form, Input, Typography, Select, Modal } from 'antd'
import PropTypes from 'prop-types'
import MaskedInput from "antd-mask-input"
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import { mask, qualificationsOptions } from '../../../../utils/consts';
const { Title } = Typography

const AddNewQsa = ({ visible, onCancel, onOk }) => {
    const [form] = Form.useForm()

    return (
        <Modal
            open={visible}
            title={<Title level={5}>Adicionar Responsável Financeiro</Title>}
            onCancel={() => {
                form.resetFields()
                if (onCancel !== undefined) {
                    onCancel()
                }
            }}
            onOk={() => {
                form.submit()
            }}
            okText={"Adicionar"}
        >
            <Form form={form} onFinish={(values) => {
                values.qualificationCode = values.qualification
                onOk(values)
                form.resetFields()
            }} layout="vertical">
                <Form.Item
                    label="CPF"
                    name="cpf"
                    rules={[
                        {
                            required: true,
                            message: "Informe o CPF",
                        },
                        () => ({
                            validator(_, value) {
                                if (!value || cpfValidator.isValid(value)) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error("CPF inválido."));
                            },
                        }),
                    ]}
                >
                    <MaskedInput mask={mask.cpf}></MaskedInput>
                </Form.Item>
                <Form.Item
                    label="NOME COMPLETO"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Informe o nome completo",
                        },
                        () => ({
                            validator(_, value) {
                                if (value.split(' ').length === 1 || value.split(' ')[1] === "") {
                                    return Promise.reject(new Error("Insira nome e sobrenome"));
                                }

                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input placeholder="Nome completo" />
                </Form.Item>
                <Form.Item
                    label="QUALIFICAÇÃDO DA PESSOA RESPONSÁVEL"
                    name="qualification"
                    rules={[{
                        required: true,
                        message: "Informe a qualificação do responsável financeiro"
                    }]}
                >
                    <Select placeholder="Informe a qualificação" options={qualificationsOptions}>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

AddNewQsa.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func
}
export default AddNewQsa