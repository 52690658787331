import {
	approveReceiveValueThunk,
	downloadContract,
	fileDownload,
	finalize,
	getRecept,
	postBoReview,
	refuse,
	deleteRecept,
	selectRecept,
	uploadContract,
	getReceptBackground
} from './slice'
import React, { useEffect, useState } from 'react'
import { ArrowUpOutlined, DownloadOutlined } from '@ant-design/icons'
import { Card, Col, Row, Typography, Form, Timeline, message, notification, Divider, Button } from 'antd'
import { formatCurrencyBrl, formatCurrencyStr, formatPorcent } from '../../../../utils/functions'
import Base from '../../../../common/components/Base'
import ExchangeBread from '../../_components/ExchangeBread'
import routes from '../../../../routes/routes'
import ContactReceiveCard from '../../_components/ContactReceiveCard'
import TaxTable from '../../_components/TaxTable'
import ExchangeActions from '../../_components/ExchangeActions'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import stateStatus from '../../../../utils/status'
import ErrorMsg from '../../../../common/components/ErrorMsg'
import OperationTaxesModal from '../../_components/OperationTaxesModal'
import { listRates, selectRates } from '../../ListTaxes/slice'
import { downloadZipFiles } from './slice'
import { beautifyCpfCnpj } from '../../../../utils/beautify'
import FinalizeOperationModal from '../../../../common/components/FinalizeOperationModal'
import CardFile from '../../_components/CardFile'
import { history } from '../../../../helpers/history'
import './styles.scss'
import TransactionDataCard from '../../../../common/components/TransactionDataCard'
import ClientDataCard from '../../../../common/components/ClientDataCard'
import UserDataCard from '../../../../common/components/UserDataCard'
import ProgressCard from '../../../../common/components/ProgressCard'
import { sendCloseOpEmail } from '../../Payments/SinglePayment/slice'


const SingleRecept = ({ match, location, ...props }) => {
	const { id } = match.params
	const recept = useSelector(selectRecept);
	const rates = useSelector(selectRates)
	const [form] = Form.useForm()
	const [valueToRecept, setValueToRecept] = useState(0);
	const [valueExchange, setValueExchange] = useState(0);
	const [showReviewModal, setShowReviewModal] = useState(false);
	const [isBO, setIsBO] = useState(false);
	const [taxes, setTaxes] = useState([]);
	const dispatch = useDispatch();
	const [timeout, updateTimeOut] = useState(0);
	const [timer, updateTimer] = useState(5);
	const [showApprovedMsg, setShowApprovedMsg] = useState(false);
	const [showFinalizeModal, setShowFinalizeModal] = useState(false)
	const [isFinelizeModalLoadig, setIsFinelizeModalLoadig] = useState(false)

	useEffect(() => {
		dispatch(getRecept({ id }))
	}, []);

	useEffect(async () => {
		if (recept.status.boReview === stateStatus.succeeded) {
			dispatch(getRecept({ id }))
		}
	}, [recept.status.boReview, id])

	useEffect(() => {
		if (id) {
			if (timer > 0) {
				clearTimeout(timeout);
				updateTimeOut(
					setTimeout(() => {
						updateTimer(timer - 1);
					}, 1000),
				);
			} else {
				dispatch(getReceptBackground({ id }));
				updateTimer(5)
			}
		}
	}, [timer]);

	useEffect(() => {
		if (recept.status.getRecept == stateStatus.succeeded) {
			dispatch(listRates(recept.recept.owner.cpf_cnpj))

			form.setFieldsValue({
				spread: recept.recept.spread,
			})
			if (recept.recept.is_currency_target) {
				setValueExchange(recept.recept.target_value)
			} else {
				setValueToRecept(recept.recept.target_value)
			}

			setTaxes(recept.recept.taxes.map(item => {
				return {
					name: item.name,
					description: item.description,
					tax: item.tax,
					value: item.value,
					currency: !recept.recept.is_currency_target ? "BRL" : recept.recept.currency
				}
			}))
		}
	}, [recept.status.getRecept])

	useEffect(() => {
		if (recept.status.getReceptBackground == stateStatus.succeeded) {
			dispatch(listRates(recept.recept.owner.cpf_cnpj))

			form.setFieldsValue({
				spread: recept.recept.spread,
			})
			if (recept.recept.is_currency_target) {
				setValueExchange(recept.recept.target_value)
			} else {
				setValueToRecept(recept.recept.target_value)
			}

			setTaxes(recept.recept.taxes.map(item => {
				return {
					name: item.name,
					description: item.description,
					tax: item.tax,
					value: item.value,
					currency: !recept.recept.is_currency_target ? "BRL" : recept.recept.currency
				}
			}))

			if (recept.recept.status === "waiting_exchange" && !showApprovedMsg) {
				notification.success({
					key: 'cambio-fechado',
					message: `Câmbio fechado!`,
					description: "Clique nessa mensagem e confira a tela de ordens fechadas",
					placement: "topRight",
					style: { 'cursor': 'pointer' },
					onClick: () => {
						history.push(routes.exchangeOperations)
						notification.close('cambio-fechado')

					}
				});
				setShowApprovedMsg(true);
			}
		}
	}, [recept.status.getReceptBackground])

	return <Base selectedKeys={['5']}>
		{recept.status.getRecept === stateStatus.failed && (
			<ErrorMsg message={recept.errorMsg} />
		)}
		{recept.status.getRecept == stateStatus.succeeded && (
			<>
				<Form form={form} className="single"
				/*
					onFinish={(values) => {
						dispatch(saveRecept({
							receive_value_id: id,
							files: recept.recept.files.map(item => item.id), // TODO: remover da API o files
							spread: values.spread,
							taxes: values.taxes,
						}))
					}}
				 onValuesChange={(_, values) => {
					calcValueExchange()
				}} */
				>
					<Col>
						<ExchangeBread
							parentIcon={<ArrowUpOutlined rotate={225} />}
							hasParent={true}
							parentName='Recebimentos'
							parentRoute={routes.recepts}
							currentName={recept.recept.owner.name}
						/>
						<ExchangeActions
							// onSave={() => form.submit()}
							onReview={() => dispatch(approveReceiveValueThunk(id))}
							exchageId={Number(id)}
							exchange={recept.recept}
							onFeedback={(values) => dispatch(refuse({ id, need_changes: values.text }))}
							onSendContract={(file) => dispatch(uploadContract({ id, file }))}
							onFinalize={() => {
								if (recept.recept?.status === "completed") {
									dispatch(finalize({ id }))
								}
								if (recept.recept?.status === "waiting_contract") {
									setShowFinalizeModal(true)
								}
							}}
							onSendEmail={() => {
								dispatch(sendCloseOpEmail({
									exchange_id: Number(id),
									exchange_type: "receive_value",
									cpf_cnpj: recept?.recept?.owner?.cpf_cnpj
								}))
							}}
							onDelete={() => dispatch(deleteRecept({ id })).then((resp) => {
								if (resp.payload?.success) {
									resp.payload?.success && message.success("Operação excluída com sucesso!")
									history.push(routes.recepts)
								} else {
									message.error(resp.error.message)
								}
							})}
							onApprove={(isBO) => {
								setIsBO(isBO)
								setShowReviewModal(true)
							}}
							files={recept.recept.files}
							cpf_cnpj={recept.recept.owner.cpf_cnpj}
							status={recept.recept.status}
							action={
								recept.recept.status === "waiting_approval" ? "review" :
									recept.recept.status === "waiting_contract" ? "contract" :
										recept.recept.status === "waiting_review" ? "finalize" : null
							}
							loading={
								recept.status.boReview === stateStatus.loading ? "review" :
									recept.status.refuse === stateStatus.loading ? "feedback" :
										recept.status.finalize === stateStatus.loading ? "finalize" :
											recept.status.uploadContract === stateStatus.loading ? "contract" : null
							}
							isBO={recept.recept?.history[recept.recept.history.length - 1]?.text.includes("Ordem")}
						/>
						<FinalizeOperationModal
							isVisible={showFinalizeModal}
							onCancel={() => setShowFinalizeModal(false)}
							onSubmit={(values) => {
								setIsFinelizeModalLoadig(true)
								dispatch(finalize({ id, comments: values.comments })).then((resp) => {
									setIsFinelizeModalLoadig(false)
									if (resp.payload) {
										setShowFinalizeModal(false)
									}
									if (!resp.payload) {
										notification.error({
											message: 'Erro ao finalizar operação',
											description: resp.error.message,
											placement: 'topRight'
										})
									}
								})
							}}
							isLoading={isFinelizeModalLoadig}
						/>
						{[
							recept.status.boReview,
							recept.status.refuse,
							recept.status.finalize,
							recept.status.uploadContract,
							recept.status.saveRecept,
							recept.status.downloadContract,
							...Object.values(recept.status.fileDownload)
						].includes(stateStatus.failed)
							&& recept.errorMsg !== null &&
							<ErrorMsg message={recept.errorMsg} />
						}

						<Row gutter={[16, 16]}>
							<Col sm={24}>
								<Row gutter={[16, 16]}>
									<Col sm={12}>
										<Row gutter={[16, 16]}>
											<Col sm={12}>
												<ClientDataCard
													client={recept.recept.owner}
													contacts={recept.recept.owner.contacts_client}
												/>
											</Col>
											<Col sm={12}>
												<UserDataCard user={recept.recept.owner.user} />
											</Col>
										</Row>
										<Row gutter={[16, 16]}>
											<Col sm={24}>
												<TransactionDataCard operation={recept.recept} />
											</Col>
										</Row>
									</Col>
									<Col sm={12}>
										<ProgressCard history={recept.recept.history} />
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Card className='exchange-values-card'>
											<Typography.Title level={5}>
												Valores da Transação
											</Typography.Title>
											<br />
											<div className="label-value">
												<label>Spread:</label>
												{formatPorcent(
													recept.recept.spread === 0 ?
														(rates.rates ? rates.rates.spread : 0)
														: recept.recept.spread
												)}
											</div>
											<br />
											<div>
												<TaxTable
													form={null}
													canEdit={false}
													exchangeValue={recept.currencyRate}
													data={recept.recept.taxes.map(item => {
														return {
															rates: rates,
															totalTaxes: recept.recept.taxes.length > 1
																? recept.recept.taxes.reduce((prev, next) => prev.tax + next.tax) / 10000
																: item.tax / 10000,
															currencyPrice: recept.currencyPrice,
															name: item.name,
															description: item.description,
															tax: item.tax,
															value: item.value,
															target_value: recept.recept.target_value,
															currency: !recept.recept.is_currency_target ? "BRL" : recept.recept.currency
														}
													})} />
											</div>
											<br />
											<Row justify="end">
												<Col></Col>
												<Col span={8} style={{ textAlign: "end" }}>
													<Typography.Title level={4}>
														{formatCurrencyBrl(
															recept.recept.currency,
															recept.recept.is_currency_target ? recept.recept.target_value : recept.recept.value
														)}
													</Typography.Title>
												</Col>
											</Row>
											<Row justify="end">
												<Col>
													<Typography.Title level={5}>
														TOTAL
													</Typography.Title>
												</Col>
												<Col span={8} style={{ textAlign: "end" }}>
													<Typography.Title level={4}>
														{formatCurrencyBrl(
															"BRL",
															recept.recept.is_currency_target ? recept.recept.value : recept.recept.target_value
														)}
													</Typography.Title>
												</Col>
											</Row>
										</Card>
									</Col>
									<Col span={12}>
										{recept.recept.receipt_account && <ContactReceiveCard
											title="Conta Bancária"
											contact={
												{
													cpf_cnpj: recept.recept.owner.cpf_cnpj,
													name: recept.recept.owner.name,
													type: recept.recept.owner.cpf_cnpj.length === 14
														? "company" : "person",
													account: {
														bank_name: recept.recept.receipt_account.bank_name,
														agency_number: recept.recept.receipt_account.agency_number,
														account_number: recept.recept.receipt_account.account_number
													}
												}
											}
											onClick={() => { }}
										/>}
									</Col>
								</Row>
							</Col>
							<Col span={12} className="list-docs">
								<Row className="title">
									<Typography.Title level={5}>Anexos</Typography.Title>
								</Row>
								<Row gutter={[8, 8]}>
									{recept.recept.files.map((item, index) =>
										<CardFile
											key={index}
											filename={item.file_name}
											isLoading={recept.status.fileDownload[item.id] === stateStatus.loading}
											onDownload={() => {
												dispatch(fileDownload({ file: item }))
											}}
										/>
									)}
								</Row>
								<Divider></Divider>
								<Row className="text">
									<Typography.Text level={5}>Fazer download de todos os documentos</Typography.Text>
								</Row>
								<Row>
									<Button
										className='download-zip'
										icon={<DownloadOutlined />}
										onClick={() => {
											dispatch(downloadZipFiles({
												receiveValueId: id,
											}))
										}}
									>Download ZIP</Button>
								</Row>
							</Col>
							{recept.recept.contract_id && (
								<Col span={12} className="list-docs">
									<Row className="title">
										<Typography.Title level={5}>Contrato de câmbio</Typography.Title>
									</Row>
									<Row gutter={[8, 8]}>
										<CardFile
											filename={"Contrato"}
											isLoading={recept.status.downloadContract === stateStatus.loading}
											onDownload={() => {
												dispatch(downloadContract(recept.recept.contract_id))
											}}
										/>
									</Row>
								</Col>
							)}
						</Row>
					</Col>
				</Form>
				<OperationTaxesModal
					isSendValues={false}
					isVisible={showReviewModal}
					isLoading={recept.status.boReview === stateStatus.loading}
					data={{
						currencyRate: 530,
						spread: recept.recept.spread,
						taxes: recept.recept.taxes.map(item => {
							return {
								name: item.name,
								description: item.description,
								tax: item.tax,
								currency: !recept.recept.is_currency_target ? "BRL" : recept.recept.currency,
								target_value: recept.recept.target_value
							}
						})
					}}
					onSubmit={(values) => {
						if (values.nature_exchange) {
							values.nature_exchange = values.nature_exchange.split(' - ')[0];
						}
						dispatch(postBoReview({
							receive_value_id: id,
							nature_exchange: values.nature_exchange,
							spread: values.spread,
							taxes: [
								{
									name: "IOF",
									description: "Imposto",
									tax: values.tax.iof,
								},
								/*{
										name: "IR",
										description: "Imposto de Renda",
										tax: values.tax.ir,
								}, */
								{
									name: "Tarifa",
									description: "Tarifa",
									tax: 0,
									value: values.tax.tarifa,
								},
							],
						})).then(() => {
							setShowReviewModal(false)
							if (isBO) {
								dispatch(approveReceiveValueThunk(id))
							}
						})
					}}
					onCancel={() => {
						setShowReviewModal(false)
					}}
				/>
			</>
		)}
	</Base >
}

SingleRecept.propTypes = {
	match: PropTypes.object,
	location: PropTypes.any,
};

export default SingleRecept
