import React from "react"
import { Card, Typography } from "antd" 
import { beautifyCpfCnpj } from "../../../utils/beautify"
import PropTypes from 'prop-types'


const ClientDataCard = ({client, contacts}) => {
    return (
        <Card style={{ width: "100%", height: "100%" }}>
            <Typography.Title level={5}>Dados do Cliente</Typography.Title>
            <div className="label-value">
                <label>NOME:</label>
                {client.name}
            </div>
            <div className="label-value">
                <label>CPF/CNPJ:</label>
                {beautifyCpfCnpj(client.cpf_cnpj)}
            </div>
            <br />
            <Typography.Title level={5}>Contatos do Cliente</Typography.Title>
            {
                contacts.map(contact => {
                    return (
                        <div key={contact.email}>
                            <div className="label-value">
                                <label>NOME:</label>
                                {contact.name}
                            </div>
                            <div className="label-value">
                                <label>E-MAIL:</label>
                                {contact.email}
                            </div>
                        </div>
                    )
                })
            }
        </Card>
    )
}

ClientDataCard.propTypes = {
	client: PropTypes.object,
	contacts: PropTypes.array,
};

export default ClientDataCard;