import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../utils/status'
import { getRates, editRates } from './service'

const initialState = {
	status: {
		listRates: stateStatus.idle,
		postRates: stateStatus.idle,
	},
	rates: null,
	errorMsg: null
}

export const listRates = createAsyncThunk(
	'rates/listRates',
	async (cnpj) => {
		const response = await getRates(cnpj)
		return response.data
	}
)

export const postRates = createAsyncThunk(
	'rates/postRates',
	async (data) => {
		const response = await editRates(data)
		return response.data
	}
)

export const rates = createSlice({
	name: 'rates',
	initialState,
	reducers: {},
	extraReducers: {
		// Get Rates
		[listRates.pending]: (state) => {
			state.status.listRates = stateStatus.loading
		},
		[listRates.fulfilled]: (state, action) => {
			state.status.listRates = stateStatus.succeeded
			state.rates = action.payload
		},
		[listRates.rejected]: (state, action) => {
			state.status.listRates = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Post Rates
		[postRates.pending]: (state) => {
			state.status.postRates = stateStatus.loading
		},
		[postRates.fulfilled]: (state) => {
			state.status.postRates = stateStatus.succeeded
		},
		[postRates.rejected]: (state, action) => {
			state.status.postRates = stateStatus.failed
			state.errorMsg = action.error.message
		},
	}
})

export const selectRates = (state) => state.rates
