import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom'
import  routes from '../../../../../routes/routes'
import { ShopOutlined, ProfileOutlined } from '@ant-design/icons';

const CompanyBread = ({ companyName }) => {
	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to={routes.companies}>
					<ShopOutlined />Empresas</Link>
			</Breadcrumb.Item>
			<Breadcrumb.Item>
				<ProfileOutlined />
				{companyName}
			</Breadcrumb.Item>
		</Breadcrumb>
	</>
}

CompanyBread.propTypes = {
	companyName: PropTypes.string.isRequired
}

export default CompanyBread