import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../../../utils/status'
import {
	getListDocuments,
	getDownloadPersonDoc,
	getDownloadPersonZip,
	deletePersonDoc,
	postUploadPersonDoc,
	postDocument
} from './service'

const initialState = {
	status: {
		listDocuments: stateStatus.idle,
		downloadPersonDoc: {},
		downloadPersonZip: stateStatus.idle,
		delPersonDoc: stateStatus.idle,
		uploadPersonDoc: stateStatus.idle
	},
	errorMsg: null,
	listDocs: null
}

export const listDocuments = createAsyncThunk(
	'personDocs/listDocuments',
	async ({ id }) => {
		const response = await getListDocuments(id)
		return response.data
	}
)

export const downloadPersonDoc = createAsyncThunk(
	'personDocs/downloadPersonDoc',
	async ({ id, filename }) => {
		const response = await getDownloadPersonDoc(id)

		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.remove();

		return true;
	}
)

export const downloadPersonZip = createAsyncThunk(
	'personDocs/downloadPersonZip',
	async ({ id, filename }) => {
		const response = await getDownloadPersonZip(id)

		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.remove();

		return response.data
	}
)

export const delPersonDoc = createAsyncThunk(
	'personDocs/delPersonDoc',
	async ({ id }) => {
		const response = await deletePersonDoc(id)
		return response.data
	}
)

export const uploadPersonDoc = createAsyncThunk(
	'personDocs/uploadPersonDoc',
	async ({ formData, config, onSuccess, onError }) => {
		try {
			const response = await postUploadPersonDoc(formData, config)
			onSuccess()
			return response.data
		} catch (err) {
			onError(err)
			throw err
		}

	}
)

export const sendDocument = createAsyncThunk(
	"fourthStep/sendDocument",
	async ({ formData, config, id, onSuccess, onError }) => {
		try {
			const response = await postDocument(id, formData, config);
			onSuccess();
			return response.data;
		} catch (err) {
			onError(err);
			throw err;
		}
	},
);

export const personDocs = createSlice({
	name: 'personDocs',
	initialState,
	reducers: {
		resetStatus: (state) => {
			state.status.listDocuments = stateStatus.idle
		},
		resetDownloadStatus: (state) => {
			Object.keys(state.status.downloadPersonDoc).map(key => {
				if (state.status.downloadPersonDoc[key] === stateStatus.failed) {
					state.status.downloadPersonDoc[key] = stateStatus.idle
				}
			})

			state.status.downloadPersonZip = stateStatus.idle
		}
	},
	extraReducers: {
		// List Docs
		[listDocuments.pending]: (state) => {
			state.status.listDocuments = stateStatus.loading
		},
		[listDocuments.fulfilled]: (state, action) => {
			state.status.listDocuments = stateStatus.succeeded
			state.listDocs = action.payload.documents
		},
		[listDocuments.rejected]: (state, action) => {
			state.status.listDocuments = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Download Person Docs
		[downloadPersonDoc.pending]: (state, action) => {
			const { id } = action.meta.arg
			state.status.downloadPersonDoc[id] = stateStatus.loading
		},
		[downloadPersonDoc.fulfilled]: (state, action) => {
			const { id } = action.meta.arg
			state.status.downloadPersonDoc[id] = stateStatus.succeeded
		},
		[downloadPersonDoc.rejected]: (state, action) => {
			const { id, filename } = action.meta.arg
			state.status.downloadPersonDoc[id] = stateStatus.failed
			state.errorMsg = `Erro ao fazer download de ${filename}`
		},
		// Download ZIP
		[downloadPersonZip.pending]: (state) => {
			state.status.downloadPersonZip = stateStatus.loading
		},
		[downloadPersonZip.fulfilled]: (state, action) => {
			state.status.downloadPersonZip = stateStatus.succeeded
		},
		[downloadPersonZip.rejected]: (state, action) => {
			const { filename } = action.meta.arg
			state.status.downloadPersonZip = stateStatus.failed
			state.errorMsg = `Erro ao fazer download de ${filename}`
		},
		// Delete Person Doc
		[delPersonDoc.pending]: (state) => {
			state.status.delPersonDoc = stateStatus.loading
		},
		[delPersonDoc.fulfilled]: (state, action) => {
			state.status.delPersonDoc = stateStatus.succeeded
		},
		[delPersonDoc.rejected]: (state, action) => {
			state.status.delPersonDoc = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Upload Person Doc
		[uploadPersonDoc.pending]: (state) => {
			state.status.uploadPersonDoc = stateStatus.loading
		},
		[uploadPersonDoc.fulfilled]: (state, action) => {
			state.status.uploadPersonDoc = stateStatus.succeeded
		},
		[uploadPersonDoc.rejected]: (state, action) => {
			state.status.uploadPersonDoc = stateStatus.failed
			state.errorMsg = action.error.message
		},
	}
})

export const { resetStatus, resetDownloadStatus } = personDocs.actions

export const selectPersonDocs = (state) => state.personDocs
