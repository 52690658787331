import React from 'react'
import PropTypes from "prop-types"

import { Layout, Table, Tooltip, } from 'antd'
import { Link } from 'react-router-dom'
import { EyeOutlined, } from '@ant-design/icons'
import routes from '../../../routes/routes'
import { useDispatch } from 'react-redux'
import { getCompanyId } from '../../../features/Companies/Company/slice'
import { getPerson } from '../../../features/People/Person/slice'
import { readNotification } from '../Base/slice'

const { Content } = Layout

const TableNotification = ({ dataSource, pagination, ...props }) => {
    const dispatch = useDispatch();

    const columnsPeople = [
        {
            title: 'descrição',
            dataIndex: 'description'
        },
        {
            title: 'Ações',
            dataIndex: 'id',
            fixed: 'right',
            width: 100,
            render: function edit(_, record) {

                let linkTo = ""

                if (record.company) {
                    linkTo = `${routes.companies}/${record.company.id}`
                } else if (record.person) {
                    linkTo = `${routes.people}/${record.person.id}`
                } else if (record.receive_value) {
                    linkTo = `${routes.recepts}/${record.receive_value.id}`
                } else if (record.send_value) {
                    linkTo = `${routes.payments}/${record.send_value.id}`
                }
                return <>
                    <Link to={linkTo} onClick={() => {
                        dispatch(readNotification({ notification_id: record.id }))
                        record?.company && dispatch(getCompanyId({ id: record.company.id })) ||
                            record?.person && dispatch(getPerson({ id: record.person.id }))
                    }}><Tooltip title='Visualizar'><EyeOutlined /></Tooltip></Link>
                </>
            }
        },
    ]

    return <>
        <Content className="content-people">
            <Table
                className="efex-table"
                dataSource={dataSource}
                columns={columnsPeople}
                pagination={pagination}
                rowKey="id"
                {...props}
            />

        </Content>
    </>
}

TableNotification.propTypes = {
    dataSource: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
}

export default TableNotification