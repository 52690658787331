import React from 'react'
import { useState } from 'react';
import { Breadcrumb, Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import CreateUserModal from '../CreateUser';

const UsersBread = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<UserAddOutlined />
				Usuários
			</Breadcrumb.Item>
			<Breadcrumb.Item className='new-company-btn'>
				<Button
					onClick={() => setIsModalVisible(true)}
				>
					{" "}
					Cadastrar novo usuário{" "}
				</Button>
			</Breadcrumb.Item>
		</Breadcrumb>

		<CreateUserModal
			isOpenModal={isModalVisible} 
			setIsOpenModal={setIsModalVisible}
		/>
	</>
}

export default UsersBread;