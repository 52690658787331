import { Button, Card, Col, Input, Row, Table, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";

import { getUsers, selectUsers } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import UsersBread from "./components/UserBread";
import Base from "../../common/components/Base";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { EyeOutlined } from "@ant-design/icons";
import stateStatus from "../../utils/status";
import routes from "../../routes/routes";
import { strFormatTime } from "../../utils/functions";
import { getUser } from "./slice";
import CopyToClipboard from "react-copy-to-clipboard";

const Users = () => {
    const columnsUsers = [
        {
            title: "Nome",
            fixed: "left",
            render: (user) => `${user.first_name} ${user.last_name}`,
        },
        {
            title: "E-mail",
            dataIndex: "username",
            width: 160,
            render: (email) => {
                return (
                    <Row>
                        <CopyToClipboard
                            text={email}
                            className={"copy-to-clipboard"}
                        >
                            <Tooltip title="Copiar">{email}</Tooltip>
                        </CopyToClipboard>
                    </Row>
                );
            },
        },
        {
            title: "Último login",
            render: (user) => {
                if (user.last_login) {
                    const data = user.last_login.split(".")[0].split("T");

                    return `${strFormatTime(data[0])} ${data[1]
                        .split(":", 2)
                        .join(":")}`;
                } else {
                    return "Sem login";
                }
            },
        },
        {
            title: "Ações",
            dataIndex: "id",
            fixed: "right",
            width: 100,
            render: function edit(id) {
                const dispatch = useDispatch();
                return (
                    <>
                        <Link
                            to={`${routes.users}/${id}`}
                            onClick={() => {
                                dispatch(getUser({ id }));
                                // dispatch(resetStatus())
                            }}
                        >
                            <EyeOutlined />
                        </Link>
                    </>
                );
            },
        },
    ];

    const users = useSelector(selectUsers);
    const dispatch = useDispatch();
    const [searchData, updateSearchData] = useState({});

    useEffect(() => {
        dispatch(getUsers({ page: 1, values: searchData }));
    }, []);

    return (
        <Base selectedKeys={["3"]}>
            <Content className="content-people">
                <UsersBread />
                <Card>
                    <Row justify="space-between">
                        <Col md={12}>
                            <Input
                                onBlur={(event) => {
                                    updateSearchData({
                                        ...searchData,
                                        filter: event.target.value,
                                    });
                                }}
                                onPressEnter={(event) => {
                                    dispatch(
                                        getUsers({
                                            page: 1,
                                            values: {
                                                ...searchData,
                                                filter: event.target.value,
                                            },
                                        })
                                    );
                                }}
                                placeholder="Buscar pessoa"
                            ></Input>
                        </Col>
                        <Col>
                            <Button
                                onClick={() =>
                                    dispatch(
                                        getUsers({
                                            page: 1,
                                            values: { ...searchData },
                                        })
                                    )
                                }
                            >
                                {" "}
                                Filtrar{" "}
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Table
                        rowKey={(record) => record.email}
                        pagination={{
                            defaultPageSize: 15,
                            current: users.list.page,
                            total: users.list.count,
                            pageSizeOptions: [],
                        }}
                        onChange={(pagination) => {
                            dispatch(
                                getUsers({
                                    page: pagination.current,
                                    values: searchData,
                                })
                            );
                        }}
                        dataSource={users.list.users}
                        columns={columnsUsers}
                        loading={users.status.getUsers === stateStatus.loading}
                    />
                </Card>
            </Content>
        </Base>
    );
};

export default Users;
