import React from 'react'
import PropTypes from 'prop-types'
import { beautifyCpfCnpj } from '../../../utils/beautify'
import { Card, Typography, Row, Col, Checkbox } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import './style.css'

const { Title, Text } = Typography

const PartnerCard = ({ personData, onChange, removePerson, checked, disabled }) => {
	return (
		<Card className={`partner-card ${disabled ? 'partner-card-disabled' : ''}`} onClick={() => {
			onChange({ name: personData.name, cpf: personData.cpf, id: personData.id, qualification: personData.qualification })
		}} >
			<Row>
				<Col>
					<div className="box-check">
						<Checkbox disabled={disabled} checked={checked} />
					</div>
				</Col>
				<Col>
					<Title level={5}>{personData.name}</Title>
					<Text type="secondary"> {personData.qualification === 'resp-legal' ? 'Responsável Legal' : personData.qualification} - {beautifyCpfCnpj(personData.cpf)}</Text>
				</Col>
				{['resp-legal', 'procurador'].indexOf(personData.qualification) !== -1
					? <DeleteOutlined
						onClick={(evt) => {
							evt.stopPropagation()
							if (removePerson) removePerson(personData)
						}}
					/>
					: null}
			</Row>
		</Card>
	)
}

PartnerCard.propTypes = {
	personData: PropTypes.object,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	removePerson: PropTypes.func,
	onChange: PropTypes.func
}
export default PartnerCard
