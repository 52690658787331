import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Tabs, Col } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";

import Base from "../../../common/components/Base";
import TableExchange from "../_components/TableExchange";
import ExchangeBread from "../_components/ExchangeBread";
import { getPayments, selectPayments } from "../Payments/slice";
import stateStatus from "../../../utils/status";

import "./styles.scss";
import { getRecepts, selectRecepts } from '../Recepts/slice';

const { TabPane } = Tabs;

const Closed = ({ ...props }) => {
	const payments = useSelector(selectPayments);
	const dispatch = useDispatch();
	const [searchData, setSearchData] = useState({
		status: "waiting_contract",
	});

	const recepts = useSelector(selectRecepts);
	const [receptsSearchData, setReceptsSearchData] = useState({status: "waiting_sign_doc",});

	useEffect(() => {
		if (payments.status.getPayments === stateStatus.idle) {
			dispatch(getPayments({ page: 0, data: searchData }));
		}
		if (recepts.status.getRecepts === stateStatus.idle) {
			dispatch(getRecepts({ page: 0, data: receptsSearchData }));
		}
	}, []);

	const status = [
		["waiting_contract", "Aguardando envio do contrato"],
		[
			"waiting_deposit_and_signdoc",
			"Aguardando envio de comprovante de depósito e assinatura de contrato.",
		],
		["waiting_deposit_receipt", "Aguardando Confirmação de Depósito"],
		["waiting_sign_doc", "Aguardando Assinatura de Contrato"],
		["waiting_review", "Aguardando revisão"],
		["finished", "Finalizado"],
	];

	const receptsStatus = [
		["waiting_sign_doc", "Aguardando Assinatura de Contrato"],
		["waiting_review", "Aguardando revisão"],
		["finished", "Finalizado"],
	];


	return (
		<Base selectedKeys={["3"]}>
			<Col className="exchange-payments">
				<ExchangeBread
					hasParent={false}
					currentIcon={<FileDoneOutlined />}
					currentName="Operações Fechadas"
				/>
				<Card>
					<Tabs defaultActiveKey="1">
						<TabPane tab="Envio" key="1">
							<TableExchange
								defaultSearchData={searchData}
								defaultStatus={'waiting_contract'}
								dataSource={payments.payments.lst.map(
									(item) => ({
										id: item.id,
										user: item.owner.client.first_name,
										email: item.owner.client.email,
										name: item.owner.name,
										route: "pagamentos",
										status: item.status_display,
										value: item.value,
									}),
								)}
								pagination={{
									current: payments.payments.pagination.current_page + 1,
									total: payments.payments.pagination.total,
								}}
								onChange={(pagination) => {
									dispatch(
										getPayments({
											page: pagination.current - 1,
											data: searchData,
										}),
									);
								}}
								onFilter={(values) => {
									setSearchData(values);
									dispatch(
										getPayments({
											page: 0,
											data: {
												status: values.status,
												filter: values.filter,
											},
										}),
									);
								}}
								loading={
									payments.status.getPayments ===
									stateStatus.loading
								}
								status={status}
							/>
						</TabPane>
						<TabPane tab="Recebimento" key="2">
							<TableExchange
								defaultStatus={'waiting_sign_doc'}
								defaultSearchData={receptsSearchData}
								dataSource={recepts.recepts.lst.map(
									(item) => ({
										id: item.id,
										user: item.owner.client.first_name,
										email: item.owner.client.email,
										name: item.owner.name,
										route: "recebimentos",
										status: item.status_display,
										value: item.value,
									}),
								)}
								pagination={{
									current: recepts.recepts.pagination.current_page,
									total: recepts.recepts.pagination.total,
									pageSize: 15
								}}
								onChange={(pagination) => {
									dispatch(
										getRecepts({
											page: pagination.current,
											data: receptsSearchData,
										}),
									);
								}}
								onFilter={(values) => {
									setReceptsSearchData(values);
									dispatch(
										getRecepts({
											page: 0,
											data: {
												status: values.status,
												filter: values.filter,
											},
										}),
									);
								}}
								loading={
									recepts.status.getRecepts ===
									stateStatus.loading
								}
								status={receptsStatus}
							/>
						</TabPane>
					</Tabs>
				</Card>
			</Col>
		</Base>
	);
};

export default Closed;
