import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Card, Typography, message, Table, Divider, Row } from "antd";

import "./styles.scss";
import { ShopOutlined, UserOutlined } from "@ant-design/icons";


const { Title } = Typography;

const ContactSendCard = ({ title, contact, onClick, loading, ...props }) => {

	useEffect(() => {
		if (loading) {
			message.loading({
				key: "loading",
				content: "Carregando"
			});
		}
	});

	const columnsAccounts = [
		{
			title: 'Banco',
			// eslint-disable-next-line react/display-name
			render: (obj) => <>
				<Row>
					<Col sm={10}>
						{obj.bank_name}
					</Col>
					<Col sm={14}>
						<div><span className="feature">IBAN: </span>{obj.iban_code}</div>
						<div><span className="feature">SWIFT: </span>{obj.swift_code}</div>
						<div><span className="feature">ABA/BIC: </span>{obj.aba_bic_code}</div>
					</Col>
				</Row>
			</>
		},
		{ title: 'Moeda', dataIndex: 'currency' },
		{ title: 'Agência', dataIndex: 'agency_number' },
		{ title: 'Número da Conta', dataIndex: 'account_number' }
	]

	return (
		<Card
			className={`contact-receive-card`}
			onClick={onClick}
		>
			{title && (
				<>
					<Typography.Title level={5}>
						{title}
					</Typography.Title>
					<br />
				</>
			)}
			<div className="card-header">
				<Row gutter={[16, 0]} align="middle">
					<Col>
						<div className="icon-type">
							{contact.type == "company" ? <ShopOutlined /> : <UserOutlined />}
						</div>
					</Col>
					<Col>
						<Title level={5}>{contact.name}</Title>
						<div>Identidade Fiscal - {contact.tax_identity}</div>
					</Col>
				</Row>
			</div>
			<div className="card-body">
				<Divider />
				<Row gutter={[8, 0]}>
					<Col sm={24}>
						<Row gutter={[8, 8]} style={{ marginBottom: '8px' }}>
							<Col sm={12}>
								<div className="feature">Endereço:</div>
								<Row gutter={[8, 0]}>
									<Col>{contact.addr_city_name}</Col>
									<Col> - </Col>
									<Col>{contact.addr_country}</Col>
								</Row>
								<Row gutter={[8, 0]}>
									<Col>
									{contact.addr}
									</Col>
								</Row>
							</Col>
							<Col sm={12}>
								{typeof contact.is_governmental === "boolean" && (
									<Row>
										<span className="feature">Orgão Governamental:</span>
										<span>{contact.is_governmental ? "Sim" : "Não"}</span>
									</Row>
								)}
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Table
									pagination={false}
									dataSource={[contact.account]}
									columns={columnsAccounts}
									rowKey="id"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</Card >
	);
};

ContactSendCard.propTypes = {
	title: PropTypes.string,
	contact: PropTypes.object,
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	resume: PropTypes.string
}


export default ContactSendCard;
