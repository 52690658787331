import api from '../../service/api'
import apiRoutes from '../../service/apiRoutes'

export const getSendValuesCurrencyCoveragesList = async (params) => api.get(`${apiRoutes.payment}s/currency_coverages/${params.page}`,)

export const getReceiveValuesCurrencyCoveragesList = async (params) => api.get(`${apiRoutes.recept}/currency_coverages/${params.page}`,)

export const createSendValuesCurrencyCoverage = async (data) => api.post(`${apiRoutes.payment}s/currency_coverages`, data)

export const createReceiveValuesCurrencyCoverage = async (data) => api.post(`${apiRoutes.recept}/currency_coverages`, data)

export const putSendValuesCurrencyCoverage = async (data) => api.put(`${apiRoutes.payment}s/currency_coverages`, data)

export const putReceiveValuesCurrencyCoverage = async (data) => api.put(`${apiRoutes.recept}/currency_coverages`, data)

export const deleteSendValuesCurrencyCoverage = async (params) => api.delete(`${apiRoutes.payment}s/currency_coverages?currency=${params.currency}`)

export const deleteReceiveValuesCurrencyCoverage = async (params) => api.delete(`${apiRoutes.recept}/currency_coverages?currency=${params.currency}`)