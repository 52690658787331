import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../utils/status'
import { getCompany as getComp, boReview, updateCompanyRegister, docsToSign, updateCompany } from './service'

const initialState = {
	status: {
		getCompany: stateStatus.idle,
		boReview: stateStatus.idle,
		updateCompanyRegister: stateStatus.idle,
		listDocsToSign: stateStatus.idle
	},
	company: null,
	docsToSign: [],
	kycToSign: {},
	errorMsg: null
}

export const getCompanyId = createAsyncThunk(
	'companyId/getCompanyId',
	async ({ id }) => {
		const response = await getComp(id)
		return response.data
	}
)

export const postBoReview = createAsyncThunk(
	'companyId/boReview',
	async ({ id, data }) => {
		const response = await boReview(id, data)
		return response.data
	}
)

export const fetchDocsToSign = createAsyncThunk(
	'company/DocsToSign',
	async (cnpj) => {
		const response = await docsToSign(cnpj)
		return response.data
	}
)

export const putUpdateCompanyRegister = createAsyncThunk(
	'companyId/updateCompanyRegister',
	async ({ id, data }) => {
		const response = await updateCompanyRegister(id, data)
		return response.data
	}
)

export const requestCompanyUpdate = createAsyncThunk(
	'company/requestCompanyUpdate',
	async ({ company_id, keys }) => {
		const response = await updateCompany({ company_id, keys });
		return response.data;
	}
)

export const companyId = createSlice({
	name: 'companyId',
	initialState,
	reducers: {
		resetStatus: (state) => {
			state.status.getCompany = stateStatus.idle
		},
		resetStepsStatus: (state) => {
			state.status.boReview = stateStatus.idle
			state.status.updateCompanyRegister = stateStatus.idle
			state.status.sendDocsToSign = stateStatus.idle
		}
	},
	extraReducers: {
		// Get Company by ID
		[getCompanyId.pending]: (state) => {
			state.status.getCompany = stateStatus.loading
		},
		[getCompanyId.fulfilled]: (state, action) => {
			state.status.getCompany = stateStatus.succeeded
			state.company = action.payload
		},
		[getCompanyId.rejected]: (state, action) => {
			state.status.getCompany = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Post Review
		[postBoReview.pending]: (state) => {
			state.status.boReview = stateStatus.loading
		},
		[postBoReview.fulfilled]: (state) => {
			state.status.boReview = stateStatus.succeeded
		},
		[postBoReview.rejected]: (state, action) => {
			state.status.boReview = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Put Update Data
		[putUpdateCompanyRegister.pending]: (state) => {
			state.status.updateCompanyRegister = stateStatus.loading
		},
		[putUpdateCompanyRegister.fulfilled]: (state) => {
			state.status.updateCompanyRegister = stateStatus.succeeded
		},
		[putUpdateCompanyRegister.rejected]: (state, action) => {
			state.status.updateCompanyRegister = stateStatus.failed
			if (action.error.message === undefined) {
				state.errorMsg = 'Falha na alteração, verifique os campos do formulário'
			} else {
				state.errorMsg = action.error.message
			}
		},
		// List Docs to Sign
		[fetchDocsToSign.pending]: (state) => {
			state.status.listDocsToSign = stateStatus.loading
		},
		[fetchDocsToSign.fulfilled]: (state, action) => {
			state.status.listDocsToSign = stateStatus.succeeded
			state.docsToSign = action.payload.financial_responsibles
			state.kycToSign = action.payload.backoffice
		},
		[fetchDocsToSign.rejected]: (state, action) => {
			state.status.listDocsToSign = stateStatus.failed
			state.errorMsg = action.error.message
		}
	}
})

export const { resetStatus, resetStepsStatus } = companyId.actions

export const selectCompanyId = (state) => state.companyId
