import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../../../utils/status'
import {
	listDocuments,
	downloadCompanyDocs,
	downloadFinDocs,
	downloadDocsZip,
	deleteCompanyDoc,
	deleteFinDoc,
	uploadCompanyDoc,
	sendDocsToSign,
	sendFinDocsToSign,
	sendDocuments,
	sendQsaDocuments,
} from './service'

const initialState = {
	status: {
		listDocuments: stateStatus.idle,
		downloadCompanyDocs: {},
		downloadFinDocs: {},
		downloadDocsZip: stateStatus.idle,
		deleteCompanyDoc: stateStatus.idle,
		deleteFinDoc: stateStatus.idle,
		uploadCompanyDoc: stateStatus.idle,
		sendDocsToSign: stateStatus.idle,
		sendFinDocsToSign: stateStatus.idle
	},
	errorMsg: null,
	listDocs: null
}

export const getListDocs = createAsyncThunk(
	'docs/listDocuments',
	async ({ id }) => {
		const response = await listDocuments(id)
		return response.data
	}
)

export const getDownloadCompanyDocs = createAsyncThunk(
	'docs/downloadCompanyDocs',
	async ({ id, filename }) => {
		const response = await downloadCompanyDocs(id)

		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.remove();

		return true;
	}
)

export const getDownloadFinDocs = createAsyncThunk(
	'docs/downloadFinDocs',
	async ({ id, filename }) => {
		const response = await downloadFinDocs(id)

		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.remove();

		return true;
	}
)

export const getDownloadDocsZip = createAsyncThunk(
	'docs/downloadDocsZip',
	async ({ id, filename }) => {
		const response = await downloadDocsZip(id)

		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.remove();

		return true;
	}
)

export const deleteCompDoc = createAsyncThunk(
	'docs/deleteCompanyDoc',
	async ({ id }) => {
		const response = await deleteCompanyDoc(id)
		return response.data
	}
)

export const deleteFinRespDoc = createAsyncThunk(
	'docs/deleteFinDoc',
	async ({ id }) => {
		const response = await deleteFinDoc(id)
		return response.data
	}
)

export const postUploadCompanyDoc = createAsyncThunk(
	'docs/uploadCompanyDoc',
	async ({ formData, config, onSuccess, onError }) => {
		try {
			const response = await uploadCompanyDoc(formData, config)
			onSuccess()
			return response.data
		} catch (err) {
			onError(err)
			throw err
		}

	}
)

export const sendDocumentsSlice = createAsyncThunk(
	"documents/sendDocuments",
	async ({ formData, config, cnpj, onSuccess, onError }) => {
		try {
			const response = await sendDocuments(cnpj, formData, config);
			onSuccess()

			return response.data;
		} catch (err) {
			onError(err)
			throw err
		}
	}
);

export const sendQsaDocumentsThunk = createAsyncThunk(
	"documents/sendQsaDocuments",
	async ({ formData, config, cnpj, onSuccess, onError }) => {
		try {
			const response = await sendQsaDocuments(cnpj, formData, config);
			onSuccess()

			return response.data;
		} catch (err) {
			onError(err)
			throw err
		}
	}
);


export const sendDocsToSignThunk = createAsyncThunk(
	'docs/sendDocsToSign',
	async (cnpj) => {
		const result = sendDocsToSign(cnpj)
		await sendFinDocsToSign(cnpj)
		await result
	}
)
export const getSendFinDocsToSign = createAsyncThunk(
	'docs/sendFinDocsToSign',
	async ({ cnpj }) => {
		const response = await sendFinDocsToSign(cnpj)
		return response.data
	}
)

export const docs = createSlice({
	name: 'docs',
	initialState,
	reducers: {
		resetStatus: (state) => {
			state.status.listDocuments = stateStatus.idle
		},
		resetDownloadStatus: (state) => {
			Object.keys(state.status.downloadFinDocs).map(key => {
				if (state.status.downloadFinDocs[key] === stateStatus.failed) {
					state.status.downloadFinDocs[key] = stateStatus.idle
				}
			})

			Object.keys(state.status.downloadCompanyDocs).map(key => {
				if (state.status.downloadCompanyDocs[key] === stateStatus.failed) {
					state.status.downloadCompanyDocs[key] = stateStatus.idle
				}
			})

			state.status.downloadDocsZip = stateStatus.idle
		}
	},
	extraReducers: {
		// List Docs
		[getListDocs.pending]: (state) => {
			state.status.listDocuments = stateStatus.loading
		},
		[getListDocs.fulfilled]: (state, action) => {
			state.status.listDocuments = stateStatus.succeeded
			state.listDocs = action.payload
		},
		[getListDocs.rejected]: (state, action) => {
			state.status.listDocuments = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Download Company Docs
		[getDownloadCompanyDocs.pending]: (state, action) => {
			const { id } = action.meta.arg
			state.status.downloadCompanyDocs[id] = stateStatus.loading
		},
		[getDownloadCompanyDocs.fulfilled]: (state, action) => {
			const { id } = action.meta.arg
			state.status.downloadCompanyDocs[id] = stateStatus.succeeded
		},
		[getDownloadCompanyDocs.rejected]: (state, action) => {
			const { id, filename } = action.meta.arg
			state.status.downloadCompanyDocs[id] = stateStatus.failed
			state.errorMsg = `Erro ao fazer download de ${filename}`
		},
		// Download Financial Responsibles Docs
		[getDownloadFinDocs.pending]: (state, action) => {
			const { id } = action.meta.arg
			state.status.downloadFinDocs[id] = stateStatus.loading
		},
		[getDownloadFinDocs.fulfilled]: (state, action) => {
			const { id } = action.meta.arg
			state.status.downloadFinDocs[id] = stateStatus.succeeded
		},
		[getDownloadFinDocs.rejected]: (state, action) => {
			const { id, filename } = action.meta.arg
			state.status.downloadFinDocs[id] = stateStatus.failed
			state.errorMsg = `Erro ao fazer download de ${filename}`
		},
		// Download ZIP
		[getDownloadDocsZip.pending]: (state) => {
			state.status.downloadDocsZip = stateStatus.loading
		},
		[getDownloadDocsZip.fulfilled]: (state, action) => {
			state.status.downloadDocsZip = stateStatus.succeeded
		},
		[getDownloadDocsZip.rejected]: (state, action) => {
			state.status.downloadDocsZip = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Delete Company Doc
		[deleteCompDoc.pending]: (state) => {
			state.status.deleteCompanyDoc = stateStatus.loading
		},
		[deleteCompDoc.fulfilled]: (state, action) => {
			state.status.deleteCompanyDoc = stateStatus.succeeded
		},
		[deleteCompDoc.rejected]: (state, action) => {
			state.status.deleteCompanyDoc = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Delete Fin Resp Doc
		[deleteFinRespDoc.pending]: (state) => {
			state.status.deleteFinDoc = stateStatus.loading
		},
		[deleteFinRespDoc.fulfilled]: (state, action) => {
			state.status.deleteFinDoc = stateStatus.succeeded
		},
		[deleteFinRespDoc.rejected]: (state, action) => {
			state.status.deleteFinDoc = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Upload Company Doc
		[postUploadCompanyDoc.pending]: (state) => {
			state.status.uploadCompanyDoc = stateStatus.loading
		},
		[postUploadCompanyDoc.fulfilled]: (state, action) => {
			state.status.uploadCompanyDoc = stateStatus.succeeded
		},
		[postUploadCompanyDoc.rejected]: (state, action) => {
			state.status.uploadCompanyDoc = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Send docs to sign
		[sendDocsToSignThunk.pending]: (state) => {
			state.status.sendDocsToSign = stateStatus.loading
		},
		[sendDocsToSignThunk.fulfilled]: (state) => {
			state.status.sendDocsToSign = stateStatus.succeeded
		},
		[sendDocsToSignThunk.rejected]: (state, action) => {
			state.status.sendDocsToSign = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Send Financial Resp docs to sign
		[getSendFinDocsToSign.pending]: (state) => {
			state.status.sendFinDocsToSign = stateStatus.loading
		},
		[getSendFinDocsToSign.fulfilled]: (state) => {
			state.status.sendFinDocsToSign = stateStatus.succeeded
		},
		[getSendFinDocsToSign.rejected]: (state, action) => {
			state.status.sendFinDocsToSign = stateStatus.failed
			state.errorMsg = action.error.message
		},
	}
})

export const { resetStatus, resetDownloadStatus } = docs.actions

export const selectDocs = (state) => state.docs
