import React, { useState } from 'react'
import PropTypes from "prop-types"

import { DownloadOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Card, Col, InputNumber, Row, Select, Tooltip, Typography, Form, Space } from 'antd'
import { formatCurrencyBrl } from '../../../../utils/functions'
import Base from '../../../../common/components/Base'
import ExchangeBread from '../ExchangeBread'
import ContactReceiveCard from '../ContactSendCard'
import TaxTable from '../TaxTable'
import ExchangeActions from '../ExchangeActions'
import './styles.scss'

const { Option } = Select

const SingleTemplate = ({
	selectedKeys,
	parentName,
	parentRoute,
	parentIcon,
	currentName,
	canEdit,
	...props
}) => {
	const [form] = Form.useForm()
	const [valueToSend, updateValueToSend] = useState(0);
	
	const onValuesChange = (values) => {
	}

	return <Base selectedKeys={selectedKeys}>
		<Form form={form} layout="vertical" className="single" onValuesChange={onValuesChange}>
			<Col>
				<ExchangeBread
					parentIcon={parentIcon}
					hasParent={true}
					parentName={parentName}
					parentRoute={parentRoute}
					currentName={currentName}
				/>
				<ExchangeActions/>
				<Form layout="vertical" form={form}>
					<Row gutter={[16, 16]} wrap={false}>
						<Col span={12}>
							<Typography.Title level={5}>
										Valores da Transação
							</Typography.Title>
							<Card
								className='exchange-values-card'
							>
								<Col style={{padding:0}}>	
									<Form.Item label="Beneficiário Recebe">
										<InputNumber
											disabled={!canEdit}
											value={100000}
											onChange={(value) => updateValueToSend(value)}
											style={{ width: "100%" }}
											min={1}
											formatter={(value) => formatCurrencyBrl('USD', value)}
											parser={money => money.replace(/\D/g, '')}
										></InputNumber>
									</Form.Item>
									<Row justify="space-between">
										<Col span={24}>
											<TaxTable canEdit={canEdit}/>
										</Col>
										{/* <
									<Col sm={11}>
										Impostos e Taxas (10%){" "}
										<Tooltip title="Calculo de imposos" />
									</Col>
									<Col sm={5}>
									R$ 100,00
									</Col> */}
									</Row>
								</Col>
								<Row justify="space-between">
									<Col sm={11}>
										<Typography.Title level={5}>
										Total
										</Typography.Title>
									</Col>
									<Col>
										<Typography.Title level={4}>
											{formatCurrencyBrl(
												'USD',
												(valueToSend + ((valueToSend / 100) * 0.1).toFixed( 2, ) * 100 ).toString())}
										</Typography.Title>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col span={12}>
							<Typography.Title level={5}>
								Beneficiário
							</Typography.Title>
							<ContactReceiveCard
								contact={
									{
										id: 1,
										name: 'Equipments S/A',
										tax_identity: '8858 88588 58 558',
										type: 'company',
										is_governmental: false,
										addr_zipcode: '36205000',
										addr_public_place: 'Rua 123',
										addr_neighborhood: 'João Paulo',
										addr_country: 'Brasil',
										addr_city_name: 'São Paulo',
										addr_province: 'MG',
										addr_number: '201',
										addr_complement: '',
										accounts: [
										  {
												id: 2,
												bank_account_number: '1258477-8',
												bank_name: 'Bank of America',
												agency_number: '0001',
												iban_code: '22588',
												swift_code: 'ABCDGRG4H67',
												aba_bic_code: 'ABCDGRG4H67',
												currency: 'USD'
										  }
										]
									  }
								}
								onClick={() => {}}
							/>
						</Col>
					</Row>
					<Row className='doc-list'>
						<Card className='doc-item'>
							<Row>
								<p>Tipo do Doc - Nome do Documento</p>
								<Col className="btns-doc-actions">
									<Tooltip className='download-btn' placement="top" title={"Download"}>
										<DownloadOutlined
											onClick={() => {}}
										/>
									</Tooltip>
									<Tooltip placement="top" title={"Excluir"}>
										{React.createElement(CloseCircleOutlined, {
											className: 'delete-btn',
										})}
									</Tooltip>
								</Col>
							</Row>
						</Card>
						<Card className='item-doc'>
							<Row>
								<p>Tipo do Doc - Nome do Documento</p>
								<Col className="btns-doc-actions">
									<Tooltip className='download-btn' placement="top" title={"Download"}>
										<DownloadOutlined
											onClick={() => {}}
										/>
									</Tooltip>
									<Tooltip placement="top" title={"Excluir"}>
										{React.createElement(CloseCircleOutlined, {
											className: 'delete-btn',
										})}
									</Tooltip>
								</Col>
							</Row>
						</Card>
						<Card className='item-doc'>
							<Row>
								<p>Tipo do Doc - Nome do Documento</p>
								<Col className="btns-doc-actions">
									<Tooltip className='download-btn' placement="top" title={"Download"}>
										<DownloadOutlined
											onClick={() => {}}
										/>
									</Tooltip>
									<Tooltip placement="top" title={"Excluir"}>
										{React.createElement(CloseCircleOutlined, {
											className: 'delete-btn',
										})}
									</Tooltip>
								</Col>
							</Row>
						</Card>
					</Row>
				</Form>
			</Col>
		</Form>
	</Base>
}

SingleTemplate.propTypes = {
	selectedKeys: PropTypes.array,
	parentName: PropTypes.string,
	parentRoute: PropTypes.string,
	parentIcon: PropTypes.any,
	currentName: PropTypes.string,
	canEdit: PropTypes.bool
}

export default SingleTemplate
