import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Card, Typography, Row, Col, Select, InputNumber } from 'antd'
import {
	comexTypeOptions,
	comexOperationOptions,
	numberOfWorkers,
	countriesList
} from '../../../../../utils/consts'
import IdentedField from '../../../../../common/components/IdentedField'
import TooltipEfex from '../../../../../common/components/Tooltip'
import { formatCurrency } from '../../../../../utils/functions'

const { Title } = Typography

const { Option } = Select

const KYC = ({ isComex, isOther, selectProps, isMovLess, setIsMovLess, form, ...props }) => {
	return (<Row gutter={[16, 16]} className="row-empresa-endereco">
		<Col span={12}>
			<Card>
				<Title level={5}>Empresa</Title>
				<Form.Item
					name="company_describe"
					label="Conte-nos um pouco mais sobre seu negócio"
					rules={[
						{
							required: true,
							message: 'Campo obrigatório'
						}
					]}
				>
					<Input.TextArea placeholder="" />
				</Form.Item>

				<Form.Item
					name="website"
					label="Website"
					rules={[
						{
							required: true,
							message: 'Informe o Website'
						}
					]}
				>
					<Input placeholder="Website" />
				</Form.Item>

				<Form.Item
					name="number_of_workers"
					label="Número de funcionários"
					rules={[
						{
							required: true,
							message: 'Informe número de funcionários'
						}
					]}
				>
					<Select options={numberOfWorkers} />
				</Form.Item>

				<Form.Item
					name="reason_for_exchange"
					label="Finalidade das remessas"
					rules={[
						{
							required: true,
							message: 'Informe a finalidade'
						}
					]}
				>
					<Input placeholder="Finalidade" />
				</Form.Item>

				<Form.Item
					name="is_movement_less_15"
					label={
						<>
							Movimentação inferior a US$
							15.000,00 por ano (ou equivalente em
							outras moedas)?
							<TooltipEfex
								title="Ao marcar essa opção, 
							você não precisará nos encaminhar os documentos 
							financeiros da empresa e o cadastro prosseguirá 
							com limite transacional de até US$ 15.000,00 
							(quinze mil dólares) por ano."
							/>
						</>
					}
					rules={[
						{
							required: true,
							message: "Esse campo é obrigatório",
						},
					]}
				>
					<Select
						onChange={(v) => {
							setIsMovLess(v);
							form.setFieldValue(
								"estimated_values",
								1500000
							);
						}}
					>
						<Option value={true}>Sim</Option>
						<Option value={false}>Não</Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="estimated_values"
					label="Valor aproximado, em dólares, a ser enviado e recebido anualmente"
					rules={[
						{
							required: true,
							message: "Informe a estimativa",
						},
						() => ({
							validator(_, value) {
								if (
									value != null &&
									(!isMovLess ? value > 1500000 : value > 1499999)
								) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error(
										"Valor deve deve ser maior que 15 mil"
									)
								);
							},
						}),
					]}
				>
					<InputNumber
						className="input-value-exchange"
						min={1}
						disabled={isMovLess}
						formatter={(value) =>
							formatCurrency(value, "US$")
						}
						parser={(money) =>
							money.replace(/\D/g, "")
						}
					></InputNumber>
				</Form.Item>

				{/* <TypeSelectorInput
					label="Nível operacional pretendido (em USD)"
					rulesMessage="Informe a estimativa"
					inputName="estimated_values"
					inputType="text"
					placeholder="Estimativa de valores"
					estimate={estimate}
				/> */}
				<Form.Item
					name="payers_and_buyers_country"
					label="Países dos pagadores e recebedores"
					rules={[
						{
							required: true,
							message: "Informe o(s) país(es)",
						},
					]}
				>
					<Select
						mode="multiple"
						style={{ width: "100%" }}
						placeholder="Países dos pagadores e recebedores"
						optionLabelProp="label"
					>
						{countriesList.map((item) => {
							return (
								<Option
									key={item.pais}
									value={item.pais}
								>
									{item.pais}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item
					name="principal_products_services"
					label="Principais produtos e serviços"
					rules={[
						{
							required: true,
							message: 'Informe os principais produtos e serviços'
						}
					]}
				>
					<Input
						autoComplete="off"
						placeholder="Principais produtos e serviços"
					/>
				</Form.Item>
				<Form.Item
					name="principal_clients"
					label="Principais Clientes"
					rules={[
						{
							required: true,
							message: 'Informe o(s) Cliente(s)'
						}
					]}
				>
					<Input placeholder="Principais clientes" />
				</Form.Item>
				<Form.Item
					name="principal_suppliers"
					label="Principais fornecedores"
					rules={[
						{
							required: true,
							message: 'Informe o(s) fornecedor(es)'
						}
					]}
				>
					<Input placeholder="Principais fornecedores" />
				</Form.Item>
			</Card>
		</Col>
		<Col span={12} className="purpose-col">
			<Card>
				<Title level={5}>Propósito da relação de negócio</Title>
				<Form.Item
					name="purposes_exchange"
					style={{
						width: '100%'
					}}
					rules={[
						{
							required: true,
							message: 'Selecione o propósito'
						}
					]}
				>
					<Select {...selectProps} />
				</Form.Item>
				{isOther ? <p className="subtitle">Outros:</p> : null}
				<IdentedField>
					<Form.Item
						hidden={!isOther}
						name="other_field"
						label=""
						rules={[
							{
								required: isOther,
								message: 'Campo obrigatório'
							}
						]}
					>
						<Input.TextArea placeholder="" />
					</Form.Item>
				</IdentedField>
				{isComex
					? (
						<p className="subtitle">
							Comércio Exterior: recebimento de exportação e/ou pagamentos
							de importação
						</p>
					)
					: null}
				<IdentedField>
					<Form.Item
						name="payment_receivement_type"
						rules={[
							{
								required: isComex,
								message: 'Selecione a modalidade'
							}
						]}
						label={
							<>
								Modalidade de Recebimento/Pagamento
								<TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla iaculis non odio at dignissim. Pellentesque venenatis magna diam, vel consectetur odio facilisis ut." />
							</>
						}
						hidden={!isComex}
					>
						<Select mode="tags" options={comexTypeOptions} />
					</Form.Item>
					{/* <Form.Item
						name="radar_limit"
						label={
							<>
								Modalidade Radar
								<TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla iaculis non odio at dignissim. Pellentesque venenatis magna diam, vel consectetur odio facilisis ut." />
							</>
						}
						rules={[
							{
								required: isComex,
								message: 'Selecione a modalidade'
							}
						]}
						hidden={!isComex}
					>
						<Select options={comexRadarOptions} />
					</Form.Item> */}
					<Form.Item
						name="operation_mode"
						label={
							<>
								Modo Operante
								<TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla iaculis non odio at dignissim. Pellentesque venenatis magna diam, vel consectetur odio facilisis ut." />
							</>
						}
						className="operation_mode"
						hidden={!isComex}
						rules={[
							{
								required: isComex,
								message: 'Selecione o modo'
							}
						]}
					>
						<Select mode="tags" options={comexOperationOptions} />
					</Form.Item>
					<Form.Item
						hidden={!isComex}
						name="merchandise_description"
						label={
							<>
								Mercadorias objeto de exportação/importação:
								<TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla iaculis non odio at dignissim. Pellentesque venenatis magna diam, vel consectetur odio facilisis ut." />
							</>
						}
						rules={[
							{
								required: isComex,
								message: 'Informe as mercadorias'
							}
						]}
					>
						<Input.TextArea placeholder="" />
					</Form.Item>
					<Form.Item
						hidden={!isComex}
						name="storage_comments"
						label={
							<>
								Como é feito o armazenamento
								<TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla iaculis non odio at dignissim. Pellentesque venenatis magna diam, vel consectetur odio facilisis ut." />
							</>
						}
						rules={[
							{
								required: isComex,
								message: 'Informe como é feito o armazenamento'
							}
						]}
					>
						<Input.TextArea placeholder="" />
					</Form.Item>
					<Form.Item
						name="last_six_months_declarations"
						hidden={!isComex}
						label={
							<>
								Declarações de Exportação/Importação Registradas 6 meses
								<TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
							</>
						}
						rules={[
							{
								required: isComex,
								message: 'Seleção Obrigatória.'
							}
						]}
					>
						<Select>
							<Option value={true}>Sim</Option>
							<Option value={false}>Não</Option>
						</Select>
					</Form.Item>
				</IdentedField>
			</Card>
		</Col>
	</Row>)
}

KYC.propTypes = {
	isOther: PropTypes.bool.isRequired,
	isComex: PropTypes.bool.isRequired,
	isMovLess: PropTypes.bool.isRequired,
	setIsMovLess: PropTypes.any,
	form: PropTypes.any,
	selectProps: PropTypes.object.isRequired
}

export default KYC
