import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../utils/status'
import { listCompanies, addCompany, getUser } from './service'

const initialState = {
	status: {
		getCompanies: stateStatus.idle,
		getCompany: stateStatus.idle
	},
	list: {
		count: null,
		page: null,
		total_pages: null,
		companies: []
	},
	company: null,
	errorMsg: null
}

export const getCompanies = createAsyncThunk(
	'company/listCompanies',
	async ({ page, values }) => {
		if (values.status === "") {
			delete values.status
		}
		const response = await listCompanies(page, values)
		return response.data
	}
)

export const postAddCompany = createAsyncThunk(
	"company/addCompany",
	async (data) => {
		const response = await addCompany(data);
		return response.data;
	},
);

export const findUser = createAsyncThunk(
	"user/findUser",
	async (email) => {
		const response = await getUser(email)
		return response.data
	}
)

export const company = createSlice({
	name: 'company',
	initialState,
	extraReducers: {
		[getCompanies.pending]: (state) => {
			state.status.getCompanies = stateStatus.loading
		},
		[getCompanies.fulfilled]: (state, action) => {
			state.status.getCompanies = stateStatus.succeeded
			state.list.companies = []
			state.list.companies = action.payload.companies
			state.list.count = action.payload.count
			state.list.page = action.payload.page
			state.list.total_pages = action.payload.total_pages
		},
		[getCompanies.rejected]: (state, action) => {
			state.status.getCompanies = stateStatus.failed
			state.errorMsg = action.error.message
		}
	}
})

export const selectCompany = (state) => state.company
