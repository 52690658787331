import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../utils/status'
import { catchSendValueOperation, makeSendValueOperation, catchReceiveValueOperation, makeReceiveValueOperation, uncatchReceiveValueOperation, uncatchSendValueOperation } from './services'

const initialState = {
    sendValueId: null,
    receiveValueId: null,
    operationType: null,
    status: {
        catchSendValue: stateStatus.idle,
        catchReceiveValue: stateStatus.idle,
        uncatchSendValue: stateStatus.idle,
        uncatchReceiveValue: stateStatus.idle,
        makeSendValue: stateStatus.idle,
        makeReceiveValue: stateStatus.idle
    },
    errorMsg: null
}

export const catchSendValue = createAsyncThunk(
    'exchangeOpertaions/catchSendValue',
    async ({ sendValueId, status }) => {
        if (status === "waiting_exchange") {
            await catchSendValueOperation(sendValueId)
        }

        return sendValueId
    })

export const uncatchSendValue = createAsyncThunk(
    'exchangeOpertaions/uncatchSendValue',
    async ({ sendValueId }) => {
        await uncatchSendValueOperation(sendValueId)
        return sendValueId
    })

export const makeSendValue = createAsyncThunk(
    'exchangeOpertaions/makeSendvaue',
    async (payload) => {
        await makeSendValueOperation(payload)
    })

export const catchReceiveValue = createAsyncThunk(
    'exchangeOpertaions/catchReceiveValue',
    async ({ receivementId, status }) => {
        if (status === "waiting_exchange") {
            await catchReceiveValueOperation(receivementId)
        }

        return receivementId
    })

export const uncatchReceiveValue = createAsyncThunk(
    'exchangeOpertaions/uncatchReceiveValue',
    async ({ receivementId }) => {
        await uncatchReceiveValueOperation(receivementId)
        return receivementId
    })

export const makeReceiveValue = createAsyncThunk(
    'exchangeOpertaions/makeReceiveValue',
    async (payload) => {
        await makeReceiveValueOperation(payload)
    })

export const exchangeOperationsState = createSlice({
    name: 'exchangeOpertaionsState',
    initialState: initialState,
    reducers: {
        resetCatchSendValueStatus: (state) => {
            state.status.catchSendValue = stateStatus.idle
        },
        resetMakeSendValueStatus: (state) => {
            state.status.makeSendValue = stateStatus.idle
        },
        resetMakeReceiveValueStatus: (state) => {
            state.status.makeReceiveValue = stateStatus.idle
        },
        resetCatchReceiveValueStatus: (state) => {
            state.status.catchReceiveValue = stateStatus.idle
        }
    },
    extraReducers: {

        // catch operation send value
        [catchSendValue.pending]: (state) => {
            state.status.catchSendValue = stateStatus.loading
        },
        [catchSendValue.fulfilled]: (state, action) => {
            state.status.catchSendValue = stateStatus.succeeded
            state.operationType = "send"
            state.sendValueId = action.payload
        },
        [catchSendValue.rejected]: (state, action) => {
            state.status.catchSendValue = stateStatus.failed
            state.errorMsg = action.error.message
        },

        // uncatch operation send value
        [uncatchSendValue.pending]: (state) => {
            state.status.uncatchSendValue = stateStatus.loading
        },
        [uncatchSendValue.fulfilled]: (state, action) => {
            state.operationType = "send"
            state.sendValueId = action.payload
            state.status.uncatchSendValue = stateStatus.succeeded
        },
        [uncatchSendValue.rejected]: (state, action) => {
            state.status.uncatchSendValue = stateStatus.failed
            state.errorMsg = action.error.message
        },

        // make exchange
        [makeSendValue.pending]: (state) => {
            state.status.makeSendValue = stateStatus.loading
        },
        [makeSendValue.fulfilled]: (state) => {
            state.status.makeSendValue = stateStatus.succeeded
        },
        [makeSendValue.rejected]: (state, action) => {
            state.status.makeSendValue = stateStatus.failed
            state.errorMsg = action.error.message
        },

        // catch receive value
        [catchReceiveValue.pending]: (state) => {
            state.status.catchReceiveValue = stateStatus.loading
        },
        [catchReceiveValue.fulfilled]: (state, action) => {
            state.operationType = "receive"
            state.receiveValueId = action.payload
            state.status.catchReceiveValue = stateStatus.succeeded
        },
        [catchReceiveValue.rejected]: (state, action) => {
            state.status.catchReceiveValue = stateStatus.failed
            state.errorMsg = action.error.message
        },

        // uncatch receive value
        [uncatchReceiveValue.pending]: (state) => {
            state.status.uncatchReceiveValue = stateStatus.loading
        },
        [uncatchReceiveValue.fulfilled]: (state, action) => {
            state.operationType = "receive"
            state.receiveValueId = action.payload
            state.status.uncatchReceiveValue = stateStatus.succeeded
        },
        [uncatchReceiveValue.rejected]: (state, action) => {
            state.status.uncatchReceiveValue = stateStatus.failed
            state.errorMsg = action.error.message
        },

        // make receive value
        [makeReceiveValue.pending]: (state) => {
            state.status.makeReceiveValue = stateStatus.loading
        },
        [makeReceiveValue.fulfilled]: (state) => {
            state.status.makeReceiveValue = stateStatus.succeeded
        },
        [makeReceiveValue.rejected]: (state, action) => {
            state.status.makeReceiveValue = stateStatus.failed
            state.errorMsg = action.error.message
        }
    }
})


export const { resetCatchSendValueStatus, resetMakeSendValueStatus, resetCatchReceiveValueStatus, resetMakeReceiveValueStatus } = exchangeOperationsState.actions

export const selectExchangeOperations = (state) => state.exchangeOperations

