import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import stateStatus from '../../../utils/status'
import { getPerson as getData, putPerson, boReview, getSignDocs, updatePerson as patchUpdatePerson } from './service'

const initialState = {
	status: {
		getPerson: stateStatus.idle,
		boReview: stateStatus.idle,
		updatePerson: stateStatus.idle,
		sendToSignDocs: stateStatus.idle
	},
	person: null,
	errorMsg: null
}

export const getPerson = createAsyncThunk(
	'person/getPerson',
	async ({ id }) => {
		const response = await getData(id)
		return response.data
	}
)

export const postBoReview = createAsyncThunk(
	'person/boReview',
	async ({ id, data }) => {
		const response = await boReview(id, data)
		return response.data
	}
)

export const updatePerson = createAsyncThunk(
	'person/updatePerson',
	async ({ id, data }) => {
		const response = await putPerson(id, data)
		return response.data
	}
)

export const requestPersonUpdate = createAsyncThunk(
	'company/requestPersonUpdate',
	async ({ person_id, keys }) => {
		const response = await patchUpdatePerson({ person_id, keys });
		return response.data;
	}
)

export const sendToSignDocs = createAsyncThunk(
	'person/sendToSignDocs',
	async ({ cpf }) => {
		const response = await getSignDocs(cpf)
		return response.data
	}
)

export const person = createSlice({
	name: 'person',
	initialState,
	reducers: {
		resetStatus: (state) => {
			state.status.getPerson = stateStatus.idle
		},
		resetStepsStatus: (state) => {
			state.status.boReview = stateStatus.idle
			state.status.updatePerson = stateStatus.idle
			state.status.sendToSignDocs = stateStatus.idle
		},
	},
	extraReducers: {
		// Get person by ID
		[getPerson.pending]: (state) => {
			state.status.getPerson = stateStatus.loading
		},
		[getPerson.fulfilled]: (state, action) => {
			state.status.getPerson = stateStatus.succeeded
			state.person = action.payload
		},
		[getPerson.rejected]: (state, action) => {
			state.status.getPerson = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Post Review
		[postBoReview.pending]: (state) => {
			state.status.boReview = stateStatus.loading
		},
		[postBoReview.fulfilled]: (state) => {
			state.status.boReview = stateStatus.succeeded
		},
		[postBoReview.rejected]: (state, action) => {
			state.status.boReview = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Send to Sign Docs
		[sendToSignDocs.pending]: (state) => {
			state.status.sendToSignDocs = stateStatus.loading
		},
		[sendToSignDocs.fulfilled]: (state, action) => {
			state.status.sendToSignDocs = stateStatus.succeeded
		},
		[sendToSignDocs.rejected]: (state, action) => {
			state.status.sendToSignDocs = stateStatus.failed
			state.errorMsg = action.error.message
		},
		// Put Update Data
		[updatePerson.pending]: (state) => {
			state.status.updatePerson = stateStatus.loading
		},
		[updatePerson.fulfilled]: (state) => {
			state.status.updatePerson = stateStatus.succeeded
		},
		[updatePerson.rejected]: (state, action) => {
			state.status.updatePerson = stateStatus.failed
			if (action.error.message === undefined) {
				state.errorMsg = 'Falha na alteração, verifique os campos do formulário'
			} else {
				state.errorMsg = action.error.message
			}
		}
	}
})

export const { resetStatus, resetStepsStatus } = person.actions;

export const selectPerson = (state) => state.person
