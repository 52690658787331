import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import Companies from './features/Companies'
import Login from './features/Login/Login'
import './App.scss'

import routes from './routes/routes'
import People from './features/People'
import Company from './features/Companies/Company'
import Person from './features/People/Person'
import Closed from './features/Exchange/Closed'
import Recepts from './features/Exchange/Recepts'
import Payments from './features/Exchange/Payments'
import SinglePayment from './features/Exchange/Payments/SinglePayment'
import SingleRecept from './features/Exchange/Recepts/SingleRecept'
import SingleClosed from './features/Exchange/Closed/SingleClosed'
import ExchangeOperations from './features/ExchangeOperations'
import Transactions from './features/Transactions'
import Users from './features/Users'
import User from './features/Users/User'

import Coverages from './features/Coverages'

const { Content } = Layout

const App = () => {

	return (
		<>
			<Content>
				<Router basename="/" hashType="slash">
					<Switch>
						<Route exact path={['/', routes.login]} component={Login} />
						<Route exact path={routes.companies} component={Companies} />
						<Route exact path={routes.people} component={People} />
						<Route exact path={routes.users} component={Users} />
						<Route path={`${routes.companies}/:id`} component={Company} />
						<Route path={`${routes.people}/:id`} component={Person} />
						<Route path={`${routes.users}/:id`} component={User} />
						<Route path={`${routes.closedExchange}/:id`} component={SingleClosed} />
						<Route path={routes.closedExchange} component={Closed} />
						<Route path={`${routes.payments}/:id`} component={SinglePayment} />
						<Route path={routes.payments} component={Payments} />
						<Route path={`${routes.recepts}/:id`} component={SingleRecept} />
						<Route path={routes.recepts} component={Recepts} />
						<Route path={routes.exchangeOperations} component={ExchangeOperations} />
						<Route path={routes.transactions} component={Transactions} />
						<Route path={routes.coverages} component={Coverages} />
					</Switch>
				</Router>
			</Content>
		</>
	)
}

export default App
