import stateStatus from '../../utils/status'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { login } from './LoginService'

const initialState = {
	status: {
		login: stateStatus.idle
	},
	errorMsg: null
}

export const loginThunk = createAsyncThunk('login/loginThunk', async (data) => {
	try {
		const response = await login(data)
		return response.data
	} catch (err) {
		const customError = {
			name: 'Api Error',
			message:
        err.response && err.response.status < 500 ? err.response.data.msg : 'Erro no sistema, tente mais tarde!',
			data: err.response.data
		}
		throw customError
	}
})

export const loginSlice = createSlice({
	name: 'loginSlice',
	initialState: initialState,
	reducers: {
		logout: (state) => {
		}
	},
	extraReducers: {
		[loginThunk.pending]: (state) => {
			state.status.login = stateStatus.loading
		},
		[loginThunk.fulfilled]: (state, action) => {
			state.status.login = stateStatus.succeeded
			localStorage.setItem('access_token', action.payload.access_token)
			localStorage.setItem('refresh_token', action.payload.refresh_token)
		},
		[loginThunk.rejected]: (state, action) => {
			state.status.login = stateStatus.failed
			state.errorMsg = action.error.message
		}
	}
})

export const { logout } = loginSlice.actions

export const selectLogin = (state) => state.login
