import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Col, Input, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, selectUsers } from "../../slice";
import stateStatus from "../../../../utils/status";

const ResetPasswordModal = ({ isResetPass, setIsResetPass, id }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector(selectUsers);

    useEffect(() => {
        if (user.status.resetPassword === stateStatus.loading) {
            message.loading("Carregando...");
        }
    }, [dispatch, user]);

    return (
        <Modal
            title="Redefinir Senha"
            visible={isResetPass}
            onOk={() => form.submit()}
            onCancel={() => {
                form.resetFields();
                setIsResetPass(false);
            }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={(data) => {
                    delete data.password_confirm;
                    dispatch(resetPassword({ id, data })).then((resp) => {
                        if (resp.payload.success) {
                            message.success("Senha redefinida com sucesso!");
                            form.resetFields();
                            setIsResetPass(false);
                        } else {
                            message.error(
                                resp?.error?.message ||
                                    "Ops, não foi possivel criar este usuário"
                            );
                        }
                    });
                }}
            >
                <Col>
                    <Form.Item
                        label="Digite uma nova senha:"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Informe a senha",
                            },
                            () => ({
                                validator(_, value) {
                                    if (value != null && value.length >= 8) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("Mínimo 8 caracteres.")
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={
                                <LockOutlined className="site-form-item-icon" />
                            }
                            placeholder="Senha"
                        />
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item
                        label="Confirme sua senha:"
                        name="password_confirm"
                        rules={[
                            {
                                required: true,
                                message: "Confirme a senha",
                            },
                            () => ({
                                validator(_, value) {
                                    const password =
                                        form.getFieldValue("password");
                                    if (value === password) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("As senhas devem ser iguais.")
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={
                                <LockOutlined className="site-form-item-icon" />
                            }
                            placeholder="Confirme sua senha"
                        />
                    </Form.Item>
                </Col>
            </Form>
        </Modal>
    );
};

ResetPasswordModal.propTypes = {
    isResetPass: PropTypes.bool.isRequired,
    setIsResetPass: PropTypes.bool.isRequired,
    id: PropTypes.string,
};

export default ResetPasswordModal;
