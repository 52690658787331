// import axios from 'axios'
import api from '../../service/api'
import apiRoutes from '../../service/apiRoutes'

export const listCompanies = (page, data) => api.post(`${apiRoutes.company}${page}`, data)
export const linkToSignedDoc = async (fileId) => api.get(`${apiRoutes.downloadClickSign}?file_id=${fileId}`)
export const addCompany = (data) => api.post(apiRoutes.companyId, data)
export const getUser = (email) => api.get(`${apiRoutes.user}find_user?email=${email}`)


export const listQsa = (id) => api.get(`${apiRoutes.companyId}qsa?id=${id}`)
export const getResponsibles = (id) => api.get(`${apiRoutes.companyId}financial_responsibles?id=${id}`)
export const postResposible = (data) => api.post(`${apiRoutes.companyId}financial_responsibles?id=${data.id}`, data)
export const deleteResponsible = (id, fin_id) => api.delete(`${apiRoutes.companyId}financial_responsibles?id=${id}&fin_id=${fin_id}`)
