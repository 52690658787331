/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Table, Button, Col, Typography, Row, Tooltip } from "antd";
import {
	CloseCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	SaveOutlined,
} from "@ant-design/icons";
import "./styles.scss";
import { useEffect } from "react";
import {
	formatPorcent,
	parseCurrencyStrToFloat,
	formatCurrencyBrl,
} from "../../../../utils/functions";
import { rates } from "../../ListTaxes/slice";
const { Text } = Typography;

const TaxTable = ({ form, canEdit, data }) => {
	const [dataSource, setdataSource] = useState([]);
	const [editingKey, setEditingKey] = useState("");
	useEffect(() => {
		if (dataSource.length == 0) {
			const newData = data.map((item, index) => {
				return { ...item, key: index, validated: true };
			});

			if (newData.length == 0 && canEdit) {
				newData.push({
					key: 0,
					name: "IOF",
					description: "Imposto",
					tax: 0,
					value: 0,
					validated: false,
				});
				setEditingKey(0);
			}

			if (form) {
				form.setFieldsValue({ taxes: newData });
			}
			setdataSource([...newData]);
		}
	}, [data]);

	const isEditing = (record) => record.key === editingKey;

	const edit = (record) => {
		setEditingKey(record.key);
	};

	const cancel = () => {
		setdataSource([...dataSource.filter((item) => item.validated)]);
		setEditingKey("");
	};

	const addTax = () => {
		const newData = [
			...dataSource,
			{
				key: dataSource.length,
				name: "",
				description: "",
				tax: 0,
				value: null,
				validated: false,
			},
		];
		setdataSource(newData);
		setEditingKey(dataSource.length);
	};

	const save = async (key) => {
		try {
			await form.validateFields([
				["taxes", editingKey, "name"],
				["taxes", editingKey, "tax"],
				["taxes", editingKey, "description"],
			]);

			const { taxes } = form.getFieldsValue();

			const newData = dataSource.map((item, index) => {
				return {
					...item,
					...taxes[index],
					key: index,
					validated: true,
				};
			});
			setdataSource(newData);
			setEditingKey("");
			form.setFieldsValue({ taxes: newData });
		} catch (errInfo) {
			console.error("Validate Failed:", errInfo);
		}
	};

	const columns = [
		{
			title: "Taxa",
			dataIndex: "name",
			width: "20%",
			editable: true,
		},
		{
			title: "Descrição",
			dataIndex: "description",
			width: "30%",
			editable: true,
		},
		{
			title: "%",
			dataIndex: "tax",
			width: "20%",
			editable: true,
			render: (value, record) => {
				if (record.name == 'IR') {
					return formatPorcent(value, 6)
				} else {
					return formatPorcent(value)
				}
			},
		},
		{
			title: "$",
			dataIndex: "value",
			width: "30%",
			render: (_, record) => {
				if (record.value != undefined
					&& record.value != null
					&& record.value > 0) {
					// const currency = record.name.toLocaleLowerCase() === "tarifa" ? "BRL" : record.currency;
					if (record.name == 'IR') {
						return formatCurrencyBrl("BRL", record.value.toString(), 6);
					} else {
						return formatCurrencyBrl("BRL", record.value.toString(), 2);
					}
				}

				let value;
				let tax = (record.tax / 10000);
				if (record.name == 'IR') {
					tax = (record.tax / 100000000)
				}
				if (record.currency !== "BRL") {
					value = ((record.target_value / 100) * (record.currencyPrice)) * tax;
				} else {
					value =
						((record.target_value / 100 / (1 + record.totalTaxes)) *
							record.tax) /
						1000000;
				}
				value = parseCurrencyStrToFloat(value.toFixed(2));
				return formatCurrencyBrl("BRL", value.toString());
			},
		},
		{
			title: "",
			dataIndex: "action",
			width: "10%",
			render: (_, record) => {
				if (!canEdit) return <></>;
				const editable = isEditing(record);
				return editable ? (
					<>
						<Tooltip title="Salvar">
							<SaveOutlined
								onClick={() => save(record.key)}
								style={{
									marginRight: 8,
								}}
							/>
						</Tooltip>
						<Tooltip title="Cancelar">
							<CloseCircleOutlined onClick={cancel} />
						</Tooltip>
					</>
				) : (
					<>
						<Tooltip title="Editar">
							<EditOutlined
								disabled={true}
								onClick={() => edit(record)}
								style={{
									marginRight: 8,
								}}
							/>
						</Tooltip>
						<Tooltip title="Deletar">
							<DeleteOutlined
								onClick={() => onDelete(record.key)}
							/>
						</Tooltip>
					</>
				);
			},
		},
	];

	// const mergedColumns = columns.map((col) => {
	// 	if (!col.editable && col.dataIndex != "value") {
	// 		return col;
	// 	}

	// 	return {
	// 		...col,
	// 		render: (value, record) => {
	// 			if (isEditing(record) && col.dataIndex != "value") {
	// 				return <Form.Item
	// 					key={record.key}
	// 					style={{ padding: 0, margin: 0 }}
	// 					name={["taxes", record.key, col.dataIndex]}
	// 					rules={[
	// 						{
	// 							required: true,
	// 							message: 'Campo obrigatório'
	// 						}
	// 					]}
	// 				>
	// 					{col.dataIndex === 'tax' ? <InputPorcent /> : <Input />}
	// 				</Form.Item>
	// 			} else {
	// 				return <>
	// 					<Form.Item
	// 						noStyle
	// 						name={["taxes", record.key, col.dataIndex]}
	// 						key={record.key}>
	// 						<input type="hidden" value={value} />
	// 					</Form.Item>
	// 					{col.dataIndex === 'tax' ? formatPorcent(value)
	// 						: col.dataIndex === 'value' ? (value / 100).toFixed(2)
	// 							: value}
	// 				</>
	// 			}
	// 		}
	// 	};
	// });

	return (
		<Col className="tax-table">
			<Row gutter={[8, 0]}>
				<Col>
					<Text className="feature">Impostos e Taxas</Text>
				</Col>
				{canEdit && (
					<Col>
						<Button
							shape="circle"
							icon={<PlusOutlined />}
							onClick={() => {
								addTax();
							}}
							style={{ position: "relative", bottom: "5px" }}
							disabled={!canEdit || editingKey !== ""}
						/>
					</Col>
				)}
			</Row>
			<Table
				className="efex-table"
				dataSource={dataSource}
				columns={columns}
				rowClassName="editable-row"
				pagination={false}
			/>
		</Col>
	);
};
export default TaxTable;
