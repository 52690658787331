import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Button, Card, Col, Row, Typography, Tooltip, Divider } from 'antd'
import { DownloadOutlined, CloseCircleOutlined, LoadingOutlined, SendOutlined } from '@ant-design/icons'

import { selectPersonSignedDocs, signedDocs } from './slice'
import stateStatus from '../../../../../utils/status'
import './styles.scss'
import { linkToSignedDoc } from '../../../../Companies/service'

const { Title } = Typography

const SignedDocs = ({ cpf }) => {
	const personSignedDocs = useSelector(selectPersonSignedDocs)
	const dispatch = useDispatch()
	
	useEffect(() => {
		if (personSignedDocs.status.signedDocs === stateStatus.idle) {
			dispatch(signedDocs({cpf: cpf}))
		}
	})
	const downloadFile = (fileId) => {
		linkToSignedDoc(fileId).then((response) => {
			window.open(response.data.signed_file_url || response.data.original_file_url, "_blank")
		}).catch((e) => {
			message.error("Erro ao gerar link de download")
		})
	}

	return <Row gutter={[8, 8]}>
				
		{personSignedDocs.status.signedDocs === stateStatus.succeeded ? (
			<Col span={12} className="responsaveis">
				<Title level={4}>Documentos</Title>
				{personSignedDocs.listDocs.map((doc, key) => {
					return (
						<Card key={key} style={{height: '48px'}}>
							<Form.Item
								key={key}
							>
								<Col>
									<p><DownloadOutlined onClick={() => downloadFile(doc.click_sign_id)}/> <b>{doc.doc_type}:</b> {doc.status}</p>
								</Col>
							</Form.Item>
						</Card>
					);
				})}
			</Col>
		) : null}
	</Row>
}

SignedDocs.propTypes = {
	cpf: PropTypes.string
}

export default SignedDocs