import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import stateStatus from "../../utils/status";
import { listQsa, getResponsibles as finResponsibles, postResposible, deleteResponsible } from "./service";

export const fetchQsa = createAsyncThunk(
    "financialResponsibles/fetchQsa",
    async (id) => {
        const response = await listQsa(id);
        return response.data;
    }
);

export const getResponsibles = createAsyncThunk(
    "financialResponsibles/getResponsibles",
    async (id) => {
        const response = await finResponsibles(id);
        return response.data;
    }
);

export const addNewResposible = createAsyncThunk(
    "financialResponsibles/postResponsibles",
    async (data) => {
        const response = await postResposible(data)
        return response.data
    }
)

export const removeResponsible = createAsyncThunk(
    "financialResponsibles/deleteResponsibles",
    async ({ id, fin_id }) => {
        const response = await deleteResponsible(id, fin_id)
        return response.data
    }
)

const initialState = {
    status: {
        list: stateStatus.idle,
        responsibles: stateStatus.idle,
    },
    data: {
        allQsa: [],
        newQsa: [],
        responsibles: [],
        phoneList: []
    },
    errorMsg: null,
};

export const financialResponsibles = createSlice({
    name: "financialResponsibles",
    initialState,
    reducers: {
        setResponsibles: (state) => {
            state.status.responsibles = stateStatus.loading;
            state.data.responsibles = JSON.parse(
                localStorage.getItem("financial_responsibles")
            );
            if (
                state.data.responsibles === null ||
                state.data.responsibles === undefined
            ) {
                state.data.responsibles = [];
            }
            state.status.responsibles = stateStatus.succeeded;
        },
        resetResponsiblesStatus: (state) => {
            state.status.responsables = stateStatus.idle;
        },
        toggleSelectedQsa: (state, action) => {
            if (
                state.data.responsibles.filter(
                    (item) => item.name === action.payload.name
                ).length > 0
            ) {
                state.data.responsibles = state.data.responsibles.filter(
                    (item) => item.name !== action.payload.name
                );
            } else {
                if (state.data.responsibles.length === 5) {
                    return;
                }
                const quali = action.payload.qualification
                if (quali !== "procurador" && quali !== "resp-legal") {
                    action.payload.qualification = "socio"
                }
                state.data.responsibles = [...state.data.responsibles, action.payload]
            }
        },
        addNewQsa: (state, action) => {
            let exists = false;
            state.data.allQsa.forEach((qsa) => {
                if (qsa.cpf === action.payload.cpf) {
                    exists = true;
                }
            });
            if (!exists) {
                state.data.allQsa.push(action.payload);
            }
        },
        removeQsa: (state, action) => {
            state.data.allQsa = state.data.allQsa.filter(
                (qsa) => qsa.name !== action.payload.name
            );
        },
        setLstPhoneValue: (state, action) => {
            state.data.phoneList = action.payload
        }
    },
    extraReducers: {
        [fetchQsa.pending]: (state) => {
            state.status.list = stateStatus.loading;
        },
        [fetchQsa.fulfilled]: (state, action) => {
            state.status.list = stateStatus.succeeded;
            state.data.allQsa = action.payload.companies[0].qsa;
            const finResp = JSON.parse(
                localStorage.getItem("financial_responsibles")
            );
            if (finResp !== null) {
                const newFinResp = finResp.map((item) => {
                    return item;
                });
                state.data.responsibles = newFinResp;
                localStorage.setItem(
                    "financial_responsibles",
                    JSON.stringify(newFinResp)
                );
            }
        },
        [fetchQsa.rejected]: (state, action) => {
            state.status.list = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [getResponsibles.pending]: (state) => {
            state.status.responsibles = stateStatus.loading;
        },
        [getResponsibles.fulfilled]: (state, action) => {

            // phoneList
            // state.data.phoneList = [{ short: 'BR', code: 55, phone: null }];
            state.status.responsibles = stateStatus.succeeded;
            state.data.responsibles = action.payload.financial_responsibles;
            localStorage.setItem("financial_responsibles", JSON.stringify(action.payload.financial_responsibles));
        },
        [getResponsibles.rejected]: (state, action) => {
            state.status.responsibles = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
    },
});
export const { toggleSelectedQsa, addNewQsa, removeQsa, resetRespStatus, resetResponsiblesStatus, setLstPhoneValue } =
    financialResponsibles.actions;

export const selectQsa = (state) => state.financialResponsibles;
