import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

const ErrorMsg = ({ message }) => {
	return (
		<div className="msg-box">
			{message}
		</div>
	)
}

ErrorMsg.propTypes = {
	message: PropTypes.string
}

export default ErrorMsg
