// import axios from 'axios'
import api from "../../service/api";
import apiRoutes from "../../service/apiRoutes";

export const addUser = (data) => api.post(`/client/v1/register/`, data);

export const listUsers = (page, data) => api.post(`${apiRoutes.users}${page}`, data);

export const getUserById = (id) => api.get(`${apiRoutes.user}${id}`);
export const editUserById = (id, data) => api.put(`${apiRoutes.user}${id}`, data);
export const resetPasswordById = (id, data) =>
    api.post(`${apiRoutes.user}${id}`, data);
