import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom'
import { ShopOutlined, ProfileOutlined } from '@ant-design/icons';

const ExchangeBread = ({  hasParent, parentName, parentRoute, parentIcon, currentName, currentIcon, ...props }) => {
	return <>
		<Breadcrumb>
			{hasParent
				? <Breadcrumb.Item>
					<Link to={parentRoute}>
						{parentIcon}{parentName}</Link>
				</Breadcrumb.Item>
				: null}
			<Breadcrumb.Item>
				{!hasParent ? currentIcon : null}
				{currentName}
			</Breadcrumb.Item>
		</Breadcrumb>
	</>
}

ExchangeBread.propTypes = {
	currentName: PropTypes.string.isRequired,
	currentIcon: PropTypes.any, 
	hasParent: PropTypes.bool.isRequired,
	parentName: PropTypes.string,
	parentRoute: PropTypes.string,
	parentIcon: PropTypes.any
}

export default ExchangeBread