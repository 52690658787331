const routes = {
	login: '/login',
	companies: '/pessoa-juridica',
	people: '/pessoa-fisica',
	users: '/usuarios',
	payments: '/pagamentos',
	recepts: '/recebimentos',
	closedExchange: '/fechado',
	exchangeOperations: "/operacoes-cambio",
	transactions: '/relatorios',
	coverages: '/coberturas'
}

export default routes
