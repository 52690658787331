/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Base from '../../common/components/Base'
import { Layout, Card, Table, Button, Input, Select, Col, Row, Tooltip } from 'antd'
import './styles.scss'
import { getPeople, selectPeople } from './slice'
import { getPerson } from './Person/slice'
import routes from '../../routes/routes'
import stateStatus from '../../utils/status'
import { EyeOutlined } from '@ant-design/icons'
import PeopleBread from './components/PeopleBread'
import { resetStatus } from './Person/components/ListDocuments/slice'
import { beautifyCpfCnpj } from '../../utils/beautify'
import CopyToClipboard from "react-copy-to-clipboard";
import { personRegisteStatus } from "../../utils/consts";

const { Content } = Layout

const columnsPeople = [
	{
		title: 'Usuário',
		dataIndex: 'client',
		fixed: 'left',
		render: (client) => `${client.first_name} ${client.last_name}`
	},
	{
		title: 'E-mail',
		dataIndex: 'client',
		render: (client) => `${client.email}`
	},
	{
		title: 'Nome',
		dataIndex: 'name'
	},
	{
		title: 'CPF',
		dataIndex: 'cpf',
		width: 160,
		render: (text) => {
			return (
				<Row>
					<CopyToClipboard
						text={text}
						className={"copy-to-clipboard"}
					>
						<Tooltip title="Copiar">
							{beautifyCpfCnpj(text)}
						</Tooltip>
					</CopyToClipboard>
				</Row>
			)
		},
	},
	{
		title: 'Data início',
		dataIndex: 'started_at',
		key: 'data-inicio',
		width: 160,
		render: (text) => `${text}`
	},
	{
		title: 'Etapa cadastro',
		dataIndex: 'register_step',
		width: 200,
		render: (step) => `${step.value}`
	},
	{
		title: 'Ações',
		dataIndex: 'id',
		fixed: 'right',
		width: 100,
		render: function edit(id) {
			const dispatch = useDispatch()
			return <>
				<Link to={`${routes.people}/${id}`} onClick={() => {
					dispatch(getPerson({ id: id }))
					dispatch(resetStatus())
				}}><EyeOutlined /></Link>
			</>
		}
	}
]

const People = () => {
	const people = useSelector(selectPeople)
	const dispatch = useDispatch()
	const [searchData, updateSearchData] = useState({})
	const [selectedStatus, setSelectedStatus] = useState([]);

	useEffect(() => {
		dispatch(getPeople({ page: 1, values: searchData }))
	}, [])

	const handleStatusChange = (values) => {
		setSelectedStatus(values);
	};

	return (
		<Base selectedKeys={['2']}>
			<Content className="content-people">
				<PeopleBread />
				<Card>
					<Row justify="space-between">
						<Col md={12}>
							<Input
								onBlur={(event) => { updateSearchData({ ...searchData, filter: event.target.value }) }}
								onPressEnter={(event) => {
									dispatch(getPeople({ page: 1, values: { ...searchData, filter: event.target.value } }))
								}}
								placeholder="Buscar pessoa"></Input>
						</Col>
						<Col md={7}>
							<Select
								mode="multiple"
								style={{ width: "100%" }}
								placeholder="Filtrar Status"
								onChange={handleStatusChange}
								value={selectedStatus}
							>
								{personRegisteStatus.map((status) => (
									<Select.Option key={status.value} value={status.value}>
										{status.label}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col>
							<Button
								onClick={() =>
									dispatch(
										getPeople({
											page: 1,
											values: { ...searchData, status: selectedStatus },
										}),
									)
								}
							>
								{" "}
								Filtrar{" "}
							</Button>
						</Col>
					</Row>
				</Card>
				<Card>
					<Table
						pagination={{ defaultPageSize: 15, current: people.list.page, total: people.list.count, pageSizeOptions: [] }}
						onChange={(pagination) => {
							dispatch(getPeople({ page: pagination.current, values: searchData }))
						}}
						dataSource={people.list.people}
						columns={columnsPeople}
						loading={people.status.getPeople === stateStatus.loading}
					/>
				</Card>
			</Content>
		</Base>
	)
}

export default People
