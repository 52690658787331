import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Card, Col, Row, Typography, Tooltip, Divider, Upload } from 'antd'

import { DownloadOutlined, CloseCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons'

import { useSelector, useDispatch } from 'react-redux'

import ErroMsg from '../../../../../common/components/ErrorMsg'
import stateStatus from '../../../../../utils/status'
import {
	getDownloadCompanyDocs,
	getDownloadDocsZip,
	getDownloadFinDocs,
	selectDocs,
	deleteCompDoc,
	deleteFinRespDoc,
	getListDocs,
	resetDownloadStatus,
	sendDocumentsSlice,
	sendQsaDocumentsThunk,
	// getSendDocsToSign,
	// getSendFinDocsToSign
} from './slice'

import './styles.scss'

const { Title } = Typography

const ListDocuments = ({ companyId, cnpj }) => {
	const documents = useSelector(selectDocs)
	const [isDeleting, setisDeleting] = useState([])
	const [isSendingCompDoc, setisSendingCompDoc] = useState(false)
	const [isSendingFinDoc, setisSendingFinDoc] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		if (documents.status.deleteCompanyDoc === stateStatus.succeeded && isDeleting.length > 0) {
			setisDeleting([])
			dispatch(getListDocs({ id: companyId }))
		}
	})

	useEffect(() => {
		if (documents.status.deleteFinDoc === stateStatus.succeeded && isDeleting.length > 0) {
			setisDeleting([])
			dispatch(getListDocs({ id: companyId }))
		}
	})

	useEffect(() => {
		if (documents.status.sendDocsToSign === stateStatus.succeeded && isSendingCompDoc) {
			setisSendingCompDoc(false)
		}
	})

	useEffect(() => {
		if (documents.status.sendFinDocsToSign === stateStatus.succeeded && isSendingFinDoc) {
			setisSendingFinDoc(false)
		}
	})

	const deleteDoc = (id) => {
		let loading = [];
		loading[id] = true;
		setisDeleting(loading)
		dispatch(deleteCompDoc({ id: id }))
	}

	const deleteFinDoc = (id) => {
		let loading = [];
		loading[id] = true;
		setisDeleting(loading)
		dispatch(deleteFinRespDoc({ id: id }))
	}

	const beforeUpload = (file) => {
		const isLt20M = file.size / 1024 / 1024 < 20;
		if (!isLt20M) {
			message.error("O Arquivo precisa ser menor que 20Mb!", 5)
			return false
		}
		return true
	}

	const customRequest = ({
		file,
		data,
		onProgress,
		onSuccess,
		onError
	}) => {
		var formData = new FormData();
		formData.append("file", file);
		formData.append("doc_type", data.doc_type);
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				onProgress({ percent });
			},
		};
		dispatch(
			sendDocumentsSlice({
				formData,
				config,
				cnpj,
				onSuccess: () => onSuccess("Ok"),
				onError: (err) => onError({ err }),
			})
		).then((resp) => resp && dispatch(getListDocs({ id: companyId })));
	};

	const customRequestQsa = ({ file, data, onProgress, onSuccess, onError }) => {
		var formData = new FormData();
		formData.append("file", file);
		formData.append("doc_type", data.doc_type);
		formData.append("qsa_id", data.qsa_id);
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				onProgress({ percent });
			},
		};
		dispatch(
			sendQsaDocumentsThunk({
				formData,
				config,
				cnpj,
				onSuccess: () => onSuccess("Ok"),
				onError: (err) => onError({ err }),
			})
		).then((resp) => resp && dispatch(getListDocs({ id: companyId })));
	};

	return (
		<Form className="content-upload">
			<Row>
				{[
					...Object.values(documents.status.downloadCompanyDocs),
					...Object.values(documents.status.downloadFinDocs),
					documents.status.downloadDocsZip,
					documents.status.listDocuments,
					documents.status.deleteCompanyDoc,
					documents.status.deleteFinDoc,
					documents.status.uploadCompanyDoc,
					documents.status.sendFinDocsToSign,
					documents.status.sendDocsToSign,
					documents.status.deleteCompanyDoc,
					documents.status.deleteFinDoc,
				].includes(stateStatus.failed)
					? (
						<ErroMsg message={documents.errorMsg} />
					) : null}
			</Row>
			<Row>
				<Button
					className='download-zip'
					icon={<DownloadOutlined />}
					loading={documents.status.downloadDocsZip === stateStatus.loading}
					onClick={() => {
						dispatch(resetDownloadStatus())
						dispatch(getDownloadDocsZip({
							id: companyId,
							filename: `efexhub-${cnpj}.zip`,
						}))
					}}
				>Download ZIP</Button>
				{/* <Button className='send-to-sign' 
					icon={documents.status.sendDocsToSign === stateStatus.loading
						? <LoadingOutlined />
						: < SendOutlined />}
					onClick={() => {
						dispatch(getSendDocsToSign({cnpj: cnpj}))
					}}
				>Empresa - Enviar para assinatura</Button>
				<Button className='send-to-sign' 
					icon={documents.status.sendFinDocsToSign === stateStatus.loading
						? <LoadingOutlined />
						: < SendOutlined />}
					onClick={() => {
						dispatch(getSendFinDocsToSign({cnpj: cnpj}))
					}}
				>Responsável(eis) - Enviar para assinatura</Button> */}
				<Divider></Divider>
			</Row>
			{documents.status.listDocuments === stateStatus.loading ? (
				<div className="loading-container">
					<LoadingOutlined style={{ fontSize: '50px' }} />
				</div>
			) : (
				<Row gutter={[8, 8]}>

					{documents.status.listDocuments === stateStatus.succeeded ? (
						<>
							{documents.listDocs.documents.length > 0 ? <Col span={12}>
								<Title level={4}>Empresa</Title>
								{documents.listDocs.documents.map((doc, key) => {
									return (
										<Card key={key} className='doc-company-card'>
											<Form.Item
												name={([key], doc.type)}
												rules={[
													{
														required: true,
														message: "Documento obrigatório",
													},
												]}
											>
												<Col>
													<p className='doc-required'>{doc.type.value}</p>
													{
														doc.status == 'pending'
															? <p className='doc-status'>Status: Pendente</p>
															: <p className='doc-filename'>Arquivo: {doc.filename}</p>
													}

												</Col>
												{doc.status == 'pending'
													? <Col className="btns-doc-actions">
														<Tooltip placement='top' title={"Upload"}>
															<Upload
																key={doc.type.key}
																beforeUpload={beforeUpload}
																data={{ doc_type: doc.type.key }}
																multiple={false}
																accept=".pdf, .png, .jpg, .jpeg"
																customRequest={customRequest}
															>
																<UploadOutlined
																/>
															</Upload>
														</Tooltip>
													</Col>
													: <Col className="btns-doc-actions">
														{documents.status.downloadCompanyDocs[doc.id] !== stateStatus.loading ? (
															<Tooltip className='download-btn' placement="top" title={"Download"}>
																<DownloadOutlined
																	onClick={() => {
																		dispatch(resetDownloadStatus())
																		dispatch(getDownloadCompanyDocs({
																			id: doc.id,
																			filename: `${doc.type.value} - ${doc.filename}`,
																		}))
																	}}
																/>
															</Tooltip>
														) : (
															<LoadingOutlined className='download-btn' style={{ marginRight: "8px" }} />
														)}
														<Tooltip placement="top" title={"Excluir"}>
															{React.createElement(isDeleting[doc.id] ? LoadingOutlined : CloseCircleOutlined, {
																className: 'delete-btn',
																onClick: isDeleting[doc.id] ? null : () => deleteDoc(doc.id)
															})}
														</Tooltip>
													</Col>}
											</Form.Item>
										</Card>
									);
								})}
							</Col>
								: <LoadingOutlined />}
						</>
					) : null}

					{documents.listDocs.financial_responsibles.length === 0 ? null : (
						<Col span={12} className="responsaveis">
							<Title level={4}>Responsáveis</Title>
							{documents.listDocs.financial_responsibles.map((doc, key) => {
								return (
									<Card key={key}>
										<Title level={5}>{doc.name}</Title>
										{doc.documents.map((val, key) => {
											return (
												<Form.Item
													key={key}
													rules={[
														{
															required: true,
															message: "Documento obrigatório",
														},
													]}
												>
													<Col>
														<p>{val.type.value} - {val.status == 'pending' ? 'Pendente' : val.filename}</p>
													</Col>
													{val.status == 'pending'
														? <Col className="btns-doc-actions">
															<Tooltip placement='top' title={"Upload"}>
																<Upload
																	key={val.type.key}
																	beforeUpload={beforeUpload}
																	data={{ doc_type: val.type.key, qsa_id: doc.id }}
																	multiple={false}
																	accept=".pdf, .png, .jpg, .jpeg"
																	customRequest={customRequestQsa}
																>
																	<UploadOutlined
																	/>
																</Upload>
															</Tooltip>
														</Col>
														: <Col className="btns-doc-actions">
															{documents.status.downloadFinDocs[val.id] !== stateStatus.loading ? (
																<Tooltip className='download-btn' placement="top" title={"Download"}>
																	<DownloadOutlined
																		onClick={() => {
																			dispatch(resetDownloadStatus())
																			dispatch(getDownloadFinDocs({
																				id: val.id,
																				filename: `${val.type.value} - ${val.filename}`,
																			}))
																		}}
																	/>
																</Tooltip>
															) : (
																<LoadingOutlined className='download-btn' style={{ marginRight: "8px" }} />
															)}
															<Tooltip placement="top" title={"Excluir"}>
																{React.createElement(isDeleting[val.id] ? LoadingOutlined : CloseCircleOutlined, {
																	className: 'delete-btn',
																	onClick: isDeleting[val.id] ? null : () => deleteFinDoc(val.id)
																})}
															</Tooltip>
														</Col>}
												</Form.Item>
											);
										})}
									</Card>
								);
							})}
						</Col>
					)}
				</Row>
			)}

		</Form>
	)
}

ListDocuments.propTypes = {
	companyId: PropTypes.any,
	cnpj: PropTypes.string
}

export default ListDocuments