/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { Input, Form, Modal, Col, Row } from 'antd';

import './styles.scss'

const FinalizeOperationModal = ({ isVisible, isLoading, onSubmit, onCancel }) => {
    const [form] = Form.useForm();

    return (
        <Modal
            className="finalize-op-modal"
            title="Atenção!"
            open={isVisible}
            confirmLoading={isLoading}
            onOk={() => {
                form.submit();
            }}
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
        >
            <Row style={{ gap: "10px" }}>
                <Col>
                    <p>Atenção, finalizando operação sem a assinatura do contrado</p>
                </Col>
                <Col span={24}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={(values) => {
                            onSubmit(values)
                        }}
                    >
                        <Form.Item
                            label="Observações:"
                            name="comments"
                            initialValue=""
                            style={{ paddingTop: '8px' }}
                        >
                            <Input.TextArea style={{ width: '100%' }} rows={4} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal >
    );
}
export default FinalizeOperationModal;