import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Card, Col, Row, Typography, Tooltip, Divider, Upload } from 'antd'
import { useParams } from 'react-router-dom/cjs/react-router-dom'

import { DownloadOutlined, CloseCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons'

import { useSelector, useDispatch } from 'react-redux'

import ErroMsg from '../../../../../common/components/ErrorMsg'
import stateStatus from '../../../../../utils/status'
import {
	selectPersonDocs,
	listDocuments,
	downloadPersonDoc,
	downloadPersonZip,
	delPersonDoc,
	resetDownloadStatus,
	sendDocument
} from './slice'

import './styles.scss'

const { Title } = Typography

const ListPersonDocuments = ({ personId, cpf }) => {
	const personDocs = useSelector(selectPersonDocs)
	const [isDeleting, setisDeleting] = useState([])
	const dispatch = useDispatch()
	const { id } = useParams()

	useEffect(() => {
		if (personDocs.status.delPersonDoc === stateStatus.succeeded && isDeleting.length > 0) {
			setisDeleting([])
			dispatch(listDocuments({ id: personId }))
		}
	})

	useEffect(() => {
		if (personDocs.status.listDocuments === stateStatus.idle) {
			dispatch(listDocuments({ id: personId }))
		}
	}, [])

	const deleteDoc = (id) => {
		let loading = [];
		loading[id] = true;
		setisDeleting(loading)
		dispatch(delPersonDoc({ id: id }))
	}

	const beforeUpload = (file) => {
		const isLt20M = file.size / 1024 / 1024 < 20;
		if (!isLt20M) {
			message.error("O Arquivo precisa ser menor que 20Mb!", 5)
			return false
		}
		return true
	}

	const customRequest = ({
		file,
		data,
		onProgress,
		onSuccess,
		onError,
	}) => {
		var formData = new FormData();
		formData.append("file", file);
		formData.append("doc_type", data.doc_type);
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				onProgress({ percent });
			},
		};
		dispatch(
			sendDocument({
				formData,
				config,
				id,
				onSuccess: () => onSuccess("Ok"),
				onError: (err) => onError({ err }),
			})
		).then(resp => resp && dispatch(listDocuments({ id: personId })));
	};

	return (
		<Form className="content-upload">
			<Row>
				{
					[
						...Object.values(personDocs.status.downloadPersonDoc),
						personDocs.status.downloadPersonZip,
						personDocs.status.listDocuments,
						personDocs.status.delPersonDoc,
					].includes(stateStatus.failed)
						? (
							<ErroMsg message={personDocs.errorMsg} />
						) : null}
			</Row>
			<Row>
				<Button
					className='download-zip'
					icon={<DownloadOutlined />}
					loading={personDocs.status.downloadPersonZip == stateStatus.loading}
					onClick={() => {
						dispatch(resetDownloadStatus())
						dispatch(downloadPersonZip({
							id: personId,
							filename: `efexhub-${cpf}.zip`,
						}))
					}}
				>Download ZIP</Button>
				{/* <Button className='send-to-sign' 
					icon={personDocs.status.sendFinDocsToSign === stateStatus.loading
						? <LoadingOutlined />
						: < SendOutlined />}
					onClick={() => {
						dispatch(getSendFinDocsToSign({cnpj: cnpj}))
					}}
				>Responsável(eis) - Enviar para assinatura</Button> */}
				<Divider></Divider>
			</Row>
			<Row gutter={[8, 8]}>

				{personDocs.status.listDocuments === stateStatus.loading ? (
					<div className='loading-container'>
						<LoadingOutlined style={{ fontSize: '50px' }} />
					</div>
				) : (
					<Row>
						{personDocs.status.listDocuments === stateStatus.succeeded ? (
							<Col span={12} className="responsaveis">
								<Title level={4}>Documentos</Title>
								{personDocs.listDocs.map((doc, key) => {
									return (
										<Card key={key}>
											<Form.Item
												key={key}
												rules={[
													{
														required: true,
														message: "Documento obrigatório",
													},
												]}
											>
												<Col>
													<p>{doc.type.value} - {doc.status == 'pending' ? 'Pendente' : doc.filename}</p>
												</Col>
												{doc.status == 'pending'
													? (
														<Col className='btns-doc-actions'>
															<Tooltip placement='top' title={"Upload"}>
																<Upload
																	key={doc.type.key}
																	beforeUpload={beforeUpload}
																	data={{
																		doc_type: doc.type.key
																	}}
																	multiple={false}
																	accept='.pdf, .png, jpg, .jpeg'
																	customRequest={customRequest}
																>
																	<UploadOutlined />
																</Upload>
															</Tooltip>
														</Col>
													)
													: (<Col className="btns-doc-actions">
														{personDocs.status.downloadPersonDoc[doc.id] !== stateStatus.loading ? (
															<Tooltip className='download-btn' placement="top" title={"Download"}>
																<DownloadOutlined
																	onClick={() => {
																		dispatch(resetDownloadStatus())
																		dispatch(downloadPersonDoc({
																			id: doc.id,
																			filename: `${doc.type.value} - ${doc.filename}`,
																		}))
																	}}
																/>
															</Tooltip>
														) : (
															<LoadingOutlined className='download-btn' style={{ marginRight: "8px" }} />
														)}

														<Tooltip placement="top" title={"Excluir"}>
															{React.createElement(isDeleting[doc.id] ? LoadingOutlined : CloseCircleOutlined, {
																className: 'delete-btn',
																onClick: isDeleting[doc.id] ? null : () => deleteDoc(doc.id)
															})}
														</Tooltip>
													</Col>)}
											</Form.Item>
										</Card>
									);
								})}
							</Col>
						) : null}
					</Row>
				)}
			</Row>
		</Form>
	)
}

ListPersonDocuments.propTypes = {
	personId: PropTypes.any,
	cpf: PropTypes.string
}

export default ListPersonDocuments