// import axios from 'axios'
import api from '../../../service/api'
import apiRoutes from '../../../service/apiRoutes'

export const getCompany = (id) => api.get(`${apiRoutes.companyId}${id}`)

export const boReview = (id, data) => api.post(`${apiRoutes.companyId}${id}/backoffice_review`, data)

export const updateCompanyRegister = (id, data) => api.put(`${apiRoutes.companyId}${id}`, data)

export const docsToSign = (cnpj) => api.get(`${apiRoutes.docsToSign}${cnpj}`)

export const updateCompany = (data) => api.patch(`${apiRoutes.companyId}register_update`, data);