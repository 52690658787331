import React, { useState } from 'react'
import { Breadcrumb, Button, Form } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AddNewPersonModal from '../AddNewPersonModal';

const PeopleBread = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm()

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<UserOutlined />
				Pessoas
			</Breadcrumb.Item>
			<Breadcrumb.Item className='new-company-btn'>
				<Button
					onClick={() => setIsModalVisible(true)}
				>
					{" "}
					Cadastrar nova pessoa{" "}
				</Button>
			</Breadcrumb.Item>
		</Breadcrumb>

		<AddNewPersonModal
			isModalVisible={isModalVisible}
			setIsModalVisible={setIsModalVisible}
			form={form}
		/>
	</>
}

export default PeopleBread