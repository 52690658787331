import React from 'react'
import './loading.css'

const LoadingIcon = () => {
	return (
		<div className="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}

export default LoadingIcon
