/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Input, Form, Modal, Col, InputNumber, Select, Row, Upload } from 'antd';
import './styles.scss'
import { formatCurrency } from '../../../utils/functions';
import { currency, mask } from "../../../utils/consts";
import { MaskedInput } from 'antd-mask-input';
import { CloseCircleOutlined, ShopOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { cnpj as cnpjValidator, cpf as cpfValidator } from "cpf-cnpj-validator";
import _ from 'lodash';
import { GreyButton } from '../Button';
import { createFile, deleteExgFile } from './slice';
import { useDispatch } from "react-redux";

const { Option } = Select;

const beforeUpload = (file) => {
	const isLt20M = file.size / 1024 / 1024 < 20;
	if (!isLt20M) {
		message.error("O Arquivo precisa ser menor que 20Mb!", 5);
		return false;
	}
	return true;
};

const TypeSelector = () => (
	<Form.Item
		name={"user_type"}
		rules={[
			{
				required: true,
				message: "Selecione um tipo de usuário.",
			},
		]}
		initialValue={'CPF'}
		noStyle
	>
		<Select placeholder="" className='select-user-type' >
			<Option value="CPF" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><UserOutlined style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} /></Option>
			<Option value="CNPJ" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ShopOutlined style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} /></Option>
		</Select>
	</Form.Item>
);

const CreateOperation = ({ isVisible, isLoading, onSubmit, onCancel }) => {
	const [form] = Form.useForm();
	const [files, setFiles] = useState([]);
	const [currencySelected, setCurrencySelected] = useState(null);
	const [showGreyButton, setShowGreyButton] = useState(false);
	const [userRules, setUserRules] = useState([
		{
			required: true,
			message: "Informe o CPF",
		},
		() => ({
			validator(_, value) {
				if (
					!value ||
					cpfValidator.isValid(value)
				) {
					return Promise.resolve();
				}
				return Promise.reject(
					new Error("CPF inválido.")
				);
			},
		}),
	]);
	const [userType, setUserType] = useState('CPF')
	const [useMask, setMask] = useState(mask.cpf)
	const [formFiles, setFormFiles] = useState([])

	const dispatch = useDispatch();

	const setMaskUserType = (cpf_cnpj) => {
		if (cpf_cnpj.charAt(9) == 0 && cpf_cnpj.charAt(10) == 0) {
			// cnpj
			setMask(mask.cnpj)
			setUserType('CNPJ')
			setUserRules([
				{
					required: true,
					message: "Informe o CNPJ",
				},
				() => ({
					validator(_, value) {
						if (
							!value ||
							cnpjValidator.isValid(value)
						) {
							return Promise.resolve();
						}
						return Promise.reject(
							new Error("CNPJ inválido.")
						);
					},
				}),
			])
		} else {
			// cpf
			setMask(mask.cpf)
			setUserType('CPF')
			setUserRules([
				{
					required: true,
					message: "Informe o CPF",
				},
				() => ({
					validator(_, value) {
						if (
							!value ||
							cpfValidator.isValid(value)
						) {
							return Promise.resolve();
						}
						return Promise.reject(
							new Error("CPF inválido.")
						);
					},
				}),
			])
		}
	}

	const customRequest = ({ file, onProgress, onSuccess, onError }) => {
		var formData = new FormData();
		formData.append("file", file);
		let files_ = files;

		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				onProgress({ percent });
			},
		};

		let { cpf_cnpj } = form.getFieldsValue(["cpf_cnpj"]);
		cpf_cnpj = cpf_cnpj.replace(/\D/g, '')

		dispatch(
			createFile({
				formData: formData,
				config: config,
				cpf_cnpj: cpf_cnpj,
				onSuccess: (response) => {
					files_.push({
						uid: response.data.file_id,
						local_uid: file.uid,
						name: file.name,
						url: "#",
						status: "done",
						response: null,
					});
					setFiles(files_);
					setFormFiles([...formFiles, { id: Number(response.data.file_id), name: file.name }]);
					onSuccess("Ok");
				},
				onError: (err) => onError({ err }),
			})
		);
	};

	return (
		<Modal
			className="create-op-modal"
			title="Criar Operação"
			visible={isVisible}
			confirmLoading={isLoading}
			onOk={() => {
				form.submit();
			}}
			onCancel={() => {
				form.resetFields();
				onCancel();
			}}
		>
			<Form
				layout="vertical"
				form={form}
				onFinish={(values) => {
					values.cpf_cnpj = cnpjValidator.strip(values.cpf_cnpj);
					values.files = files.map((item) => item.uid);
					onSubmit(values)
					// form.resetFields();
				}}
				onValuesChange={(values) => {
					if (!_.isUndefined(values.cpf_cnpj)) {
						let cpf_cnpj = cnpjValidator.strip(values.cpf_cnpj);
						setMaskUserType(cpf_cnpj)
						if (cpfValidator.isValid(cpf_cnpj) || cnpjValidator.isValid(cpf_cnpj)) {
							setShowGreyButton(true)
						} else {
							setShowGreyButton(false)
						}
					}
				}}
			>
				<Col xs={24} style={{ marginBottom: '8px' }}>
					<Form.Item
						label="Tipo e número do documento de identificação"
						name={"cpf_cnpj"}
						rules={userRules}
					>
						<MaskedInput
							prefix={userType == 'CPF' ? <UserOutlined /> : <ShopOutlined />}
							mask={useMask} />
					</Form.Item>
				</Col>
				<Row align="bottom" gutter={[8, 0]} justify="space-between">
					<Col xs={8} sm={8} md={12} lg={8}  >
						<Form.Item
							label="Moeda"
							className="select-currency"
							name="currency"
							rules={[
								{
									required: true,
									message: "Campo obrigatório.",
								},
							]}
						>
							<Select
								options={currency.map(x => ({
									label: x.value,
									value: x.value,
								}))}
								onChange={(value) => {
									setCurrencySelected(value);
									form.setFieldsValue({
										value: 0,
									});
								}}
								disabled={false}
							/>
						</Form.Item>
					</Col>
					<Col id="currency-value" xs={16} sm={16} md={12} lg={16} >
						<Form.Item
							label="Valor"
							name="target_value"
							initialValue="0"
							rules={[
								{
									required: true,
									message: "Campo obrigatório.",
								},
								() => ({
									validator(_, value) {
										if (value > 0) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error("Informe um valor maior que 0.")
										);
									},
								}),
							]}
						>
							<InputNumber
								disabled={false}
								style={{ width: "100%" }}
								min={1}
								formatter={(value) =>
									formatCurrency(value, currencySelected)
								}
								parser={(money) =>
									money.replace(/\D/g, "")
								}
							></InputNumber>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item
					label="Ordenante"
					name="payer"
					initialValue=""
					rules={[
						{
							required: true,
							message: 'Campo obrigatório'
						},
					]}
					style={{ paddingTop: '8px' }}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Observações"
					name="comments"
					initialValue=""
					style={{ paddingTop: '8px' }}
				>
					<Input />
				</Form.Item>
				<Form.Item>
					{showGreyButton && (
						<Row>
							<Upload
								className="efex-upload-file-button"
								accept=".pdf, .png, .jpg, .jpeg"
								multiple={true}
								beforeUpload={beforeUpload}
								customRequest={
									customRequest
								}
								onRemove={(remove) => {
									let file_id;
									setFiles(files.filter((item) => {
										if (item.local_uid === remove.uid) {
											file_id = item.uid
										}
										return item.local_uid !== remove.uid
									}));
									dispatch(deleteExgFile({ file_id }))
								}}
								showUploadList={{
									showDownloadIcon: false,
									showPreviewIcon: false,
									showRemoveIcon: true,
									removeIcon: (
										<>
											<span
												style={{
													width: "150px",
												}}
											>
												<CloseCircleOutlined
													style={{
														marginRight:
															"5px",
													}}
												/>
											</span>
										</>
									),
								}}
							>
								<Row className="ant-form-item-label">
									<label>
										Documentação
									</label>
								</Row>
								<Row
									gutter={[16, 0]}
									align="middle"
								>
									<Col>
										<GreyButton
											type="button"
											style={{
												fontSize:
													"10px",
												width: "140px",
											}}
										>
											Documento da remessa
											<UploadOutlined
												style={{
													position:
														"relative",
													left: "10px",
												}}
											/>
										</GreyButton>
									</Col>
								</Row>
							</Upload>
						</Row>
					)}
				</Form.Item>
			</Form>
		</Modal >
	);
}
export default CreateOperation;