import React from 'react'
import PropTypes from "prop-types"

import { Layout, Table, Select, Col, Row, Tooltip, Input, Button } from 'antd'
import { Link } from 'react-router-dom'
import { EyeOutlined, } from '@ant-design/icons'
import { useState } from 'react'
import { formatCurrencyBrl } from '../../../../utils/functions'
import { beautifyCpfCnpj } from '../../../../utils/beautify'

const { Content } = Layout
const columnsPeople = [
	{
		title: "ID",
		dataIndex: "id",
	},
	{
		title: 'Nome',
		dataIndex: 'name'
	},
	{
		title: 'CPF/CNPJ',
		dataIndex: 'cpf_cnpj',
		render: (cpfCnpj) => beautifyCpfCnpj(cpfCnpj)
	},
	// {
	// 	title: 'E-mail',
	// 	dataIndex: 'email',
	// 	// render: (client) => `${client.email}`
	// },
	{
		title: 'Status',
		dataIndex: 'status_display'
	},
	{
		title: 'Valor',
		// dataIndex: 'value',
		render: (obj) => {
			if (obj.is_currency_target) {
				return formatCurrencyBrl(obj.currency, obj.target_value)
			} else {
				return formatCurrencyBrl("BRL", obj.target_value)
			}
		}
	},
	{
		title: 'Ações',
		dataIndex: 'id',
		fixed: 'right',
		width: 100,
		render: function edit(_, render) {
			return <>
				<Link to={`${render.route}/${render.id}`} onClick={() => { }}><Tooltip title='Visualizar'><EyeOutlined /></Tooltip></Link>
			</>
		}
	},
]

// eslint-disable-next-line react/prop-types
const TableExchange = ({ dataSource, pagination, onFilter, status, defaultSearchData = {}, onStatusChange, selectedStatus, ...props }) => {
	const [searchData, setSearchData] = useState(defaultSearchData)
	return <>
		<Content className="content-people">
			<Row gutter={[8, 0]} justify="space-between" style={{ width: '100%' }}>
				<Col md={12}>
					<Input onBlur={(event) => { setSearchData({ ...searchData, filter: event.target.value }) }}
						placeholder="Buscar Operação"
						onPressEnter={(event) => {
							onFilter({ ...searchData, filter: event.target.value })
						}}
					/>
				</Col>
				<Col sm={8}>
					<Select
						mode="multiple"
						style={{ width: '100%' }}
						placeholder="Filtrar Status"
						value={selectedStatus}
						onChange={(values) => {
							setSearchData({ ...searchData, status: values });
							onStatusChange(values);
						}}

					>
						{status !== undefined && status.map((status) => (
							<Select.Option key={status.value} value={status.value}>
								{status.label}
							</Select.Option>
						))}
					</Select>
				</Col>
				<Col sm={4}>
					<Button onClick={() => onFilter(searchData)} > Filtrar / Atualizar </Button>
				</Col>
			</Row>
			<Table
				className="efex-table"
				dataSource={dataSource}
				columns={columnsPeople}
				pagination={pagination}
				rowKey="id"
				{...props}
			/>
		</Content>
	</>
}

TableExchange.propTypes = {
	dataSource: PropTypes.array.isRequired,
	pagination: PropTypes.object.isRequired,
	onFilter: PropTypes.func,
	status: PropTypes.array
}

export default TableExchange