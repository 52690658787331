export const mask = {
	cpf: "000.000.000-00",
	cnpj: "00.000.000/0000-00",
	phone: "(00) 0000-0000",
	cell: "(00) 00000-0000",
	cep: "00.000-000",
	date: "00/00/0000"
}
export const estimate = [
	{ label: "Até US$ 10.000", value: 0 },
	{ label: "De US$ 10.000 até US$ 20.000", value: 1 },
	{ label: "De US$ 20.000 até US$ 50.000", value: 2 },
	{ label: "De US$ 50.000 até US$ 100.000", value: 3 },
	{ label: "De US$ 100.000 até US$ 250.000", value: 4 },
	{ label: "De US$ 250.000 até US$ 1.500.000", value: 5 },
	{ label: "Acima de US$ 1.500.000", value: 6 },
]

export const currency = [
	{ label: 'USD', value: 'USD' },
	{ label: 'EUR', value: 'EUR' },
	{ label: 'AUD', value: 'AUD' },
	{ label: 'CAD', value: 'CAD' },
	{ label: 'GBP', value: 'GBP' },
	{ label: 'RMB', value: 'RMB' },
	{ label: 'JPY', value: 'JPY' },
	{ label: 'CHF', value: 'CHF' }
]

export const comexTypeOptions = [
	{ label: 'Antecipado/A vista (sem DUE/DUIMP)', value: 'antecipado' },
	{ label: 'Pronta (com DUE/DUIMP)', value: 'pronta' }
]

export const comexRadarOptions = [
	{ label: 'Limitado', value: true },
	{ label: 'Ilimitado', value: false }
]

export const comexOperationOptions = [
	{ label: 'Conta própria', value: 'CONTA PROPRIA' },
	{ label: 'Por conta e ordem de terceiros ', value: 'TERCEIROS' }
]

export const purposeOptionsCompany = [
	{ value: 0, label: "Comércio exterior (exportação e/ou importação de mercadorias)" },
	{ value: 1, label: "Transportes (fretes sobre exportação ou importação)" },
	{ value: 2, label: "Seguros (resseguros, prêmios, indenizações e sinistros)" },
	{ value: 3, label: "Capitais brasileiros no exterior (empréstimos e financiamentos, disponibilidades no exterior, compra e venda de imóveis no exterior, doações, investimentos, lucros e dividendos, etc)" },
	{ value: 4, label: "Capitais estrangeiros (empréstimos e financiamentos, disponibilidades no país, compra e venda de imóveis no país, doações, investimentos, lucros e dividendos, etc)" },
	{ value: 5, label: "Serviços diversos (serviços técnicos e profissionais, transações comerciais)" },
	{ value: 6, label: "Intermediação de pagamentos (prestadores de serviços e-FX, facilitadoras de pagamento internacional)" },
	{ value: 7, label: "Agências de viagens e turismo" },
]

export const purposeOptionsPerson = [
	{ value: 0, label: "Comércio exterior (exportação e/ou importação de mercadorias)" },
	{ value: 2, label: "Seguros (resseguros, prêmios, indenizações e sinistros)" },
	{ value: 3, label: "Capitais brasileiros (empréstimos e financiamentos, disponibilidades no exterior, compra e venda de imóveis no exterior, doações, investimentos, lucros e dividendos, outras transferências correntes)" },
	{ value: 4, label: "Capitais estrangeiros (empréstimos e financiamentos, disponibilidades no país, compra e venda de imóveis no país, doações, investimentos, lucros e dividendos, outras transferências correntes)" },
	{ value: 8, label: "Serviços diretos (serviços técnicos e profissionais, transações comerciais)" }
]

export const numberOfWorkers = [
	{ label: 'Até 9', value: '9' },
	{ label: 'De 9 a 20', value: '9-20' },
	{ label: 'De 20 a 50', value: '20-50' },
	{ label: 'De 50 a 100', value: '50-100' },
	{ label: 'De 100 a 500', value: '100-500' },
	{ label: 'Acima de 500', value: '500+' }
]

export const requiredItems = [
	'- dados cadastrais',
	'- informações da rotina operacional',
	'- documento de identidade e comprovante de endereço da pessoa que é responsável legal',
	'- documentos financeiros atualizados',
	'- última versão do documento registrado na junta comercial',
	'- indicação de todas as pessoas que são beneficiárias finais'
]

export const minimumWage = [
	{ label: 'Até 2 Salários Mínimos', value: '2S' },
	{ label: 'De 2 a 4 Salários Mínimos', value: '2S-4S' },
	{ label: 'De 4 a 10 Salários Mínimos', value: '4S-10S' },
	{ label: 'De 10 a 20 Salários Mínimos', value: '10S-20S' },
	{ label: 'Acima de 20 Salários Mínimos', value: '20S+' }
]

export const optionsNat = [
	{ label: 'Brasileiro', value: 'brasileiro' },
	{ label: 'Estrangeiro', value: 'estrangeiro' }
]

export const qualificationsOptions = [
	{ label: 'Responsável Legal', value: 'resp-legal' },
	{ label: 'Procurador', value: 'procurador' }
]

export const countriesList = [{ pais: 'Antígua e Barbuda', gentilico: 'Antiguano' },
{ pais: 'Argentina', gentilico: 'Argentino' },
{ pais: 'Bahamas', gentilico: 'Bahamense' },
{ pais: 'Barbados', gentilico: 'Barbadiano, barbadense' },
{ pais: 'Belize', gentilico: 'Belizenho' },
{ pais: 'Bolívia', gentilico: 'Boliviano' },
{ pais: 'Brasil', gentilico: 'Brasileiro' },
{ pais: 'Chile', gentilico: 'Chileno' },
{ pais: 'Colômbia', gentilico: 'Colombiano' },
{ pais: 'Costa Rica', gentilico: 'Costarriquenho' },
{ pais: 'Cuba', gentilico: 'Cubano' },
{ pais: 'Dominica', gentilico: 'Dominicano' },
{ pais: 'Equador', gentilico: 'Equatoriano' },
{ pais: 'El Salvador', gentilico: 'Salvadorenho' },
{ pais: 'Granada', gentilico: 'Granadino' },
{ pais: 'Guatemala', gentilico: 'Guatemalteco' },
{ pais: 'Guiana', gentilico: 'Guianês' },
{ pais: 'Guiana Francesa', gentilico: 'Guianense' },
{ pais: 'Haiti', gentilico: 'Haitiano' },
{ pais: 'Honduras', gentilico: 'Hondurenho' },
{ pais: 'Jamaica', gentilico: 'Jamaicano' },
{ pais: 'México', gentilico: 'Mexicano' },
{ pais: 'Nicarágua', gentilico: 'Nicaraguense' },
{ pais: 'Panamá', gentilico: 'Panamenho' },
{ pais: 'Paraguai', gentilico: 'Paraguaio' },
{ pais: 'Peru', gentilico: 'Peruano' },
{ pais: 'Porto Rico', gentilico: 'Portorriquenho' },
{ pais: 'República Dominicana', gentilico: 'Dominicana' },
{ pais: 'São Cristóvão e Nevis', gentilico: 'São-cristovense' },
{ pais: 'São Vicente e Granadinas', gentilico: 'São-vicentino' },
{ pais: 'Santa Lúcia', gentilico: 'Santa-lucense' },
{ pais: 'Suriname', gentilico: 'Surinamês' },
{ pais: 'Trinidad e Tobago', gentilico: 'Trindadense' },
{ pais: 'Uruguai', gentilico: 'Uruguaio' },
{ pais: 'Venezuela', gentilico: 'Venezuelano' },
{ pais: 'Alemanha', gentilico: 'Alemão' },
{ pais: 'Áustria', gentilico: 'Austríaco' },
{ pais: 'Bélgica', gentilico: 'Belga' },
{ pais: 'Croácia', gentilico: 'Croata' },
{ pais: 'Dinamarca', gentilico: 'Dinamarquês' },
{ pais: 'Eslováquia', gentilico: 'Eslovaco' },
{ pais: 'Eslovênia', gentilico: 'Esloveno' },
{ pais: 'Espanha', gentilico: 'Espanhol' },
{ pais: 'França', gentilico: 'Francês' },
{ pais: 'Grécia', gentilico: 'Grego' },
{ pais: 'Hungria', gentilico: 'Húngaro' },
{ pais: 'Irlanda', gentilico: 'Irlandês' },
{ pais: 'Itália', gentilico: 'Italiano' },
{ pais: 'Noruega', gentilico: 'Noruego' },
{ pais: 'Países Baixos', gentilico: 'Holandês' },
{ pais: 'Polônia', gentilico: 'Polonês' },
{ pais: 'Portugal', gentilico: 'Português' },
{ pais: 'Reino Unido', gentilico: 'Britânico' },
{ pais: 'Inglaterra', gentilico: 'Inglês' },
{ pais: 'País de Gales', gentilico: 'Galês' },
{ pais: 'Escócia', gentilico: 'Escocês' },
{ pais: 'Romênia', gentilico: 'Romeno' },
{ pais: 'Rússia', gentilico: 'Russo' },
{ pais: 'Sérvio', gentilico: 'Sérvio' },
{ pais: 'Suécia', gentilico: 'Sueco' },
{ pais: 'Suíça', gentilico: 'Suíço' },
{ pais: 'Turquia', gentilico: 'Turco' },
{ pais: 'Ucrânia', gentilico: 'Ucraniano' },
{ pais: 'Estados Unidos', gentilico: 'Americano' },
{ pais: 'Canadá', gentilico: 'Canadense' },
{ pais: 'Angola', gentilico: 'Angolano' },
{ pais: 'Moçambique', gentilico: 'Moçambicano' },
{ pais: 'África do Sul', gentilico: 'Sul-africano' },
{ pais: 'Zimbabue', gentilico: 'Zimbabuense' },
{ pais: 'Argélia', gentilico: 'Argélia' },
{ pais: 'Comores', gentilico: 'Comorense' },
{ pais: 'Egito', gentilico: 'Egípcio' },
{ pais: 'Líbia', gentilico: 'Líbio' },
{ pais: 'Marrocos', gentilico: 'Marroquino' },
{ pais: 'Gana', gentilico: 'Ganés' },
{ pais: 'Quênia', gentilico: 'Queniano' },
{ pais: 'Ruanda', gentilico: 'Ruandês' },
{ pais: 'Uganda', gentilico: 'Ugandense' },
{ pais: 'Botsuana', gentilico: 'Bechuano' },
{ pais: 'Costa do Marfim', gentilico: 'Marfinense' },
{ pais: 'Camarões', gentilico: 'Camaronense' },
{ pais: 'Nigéria', gentilico: 'Nigeriano' },
{ pais: 'Somália', gentilico: 'Somali' },
{ pais: 'Austrália', gentilico: 'Australiano' },
{ pais: 'Nova Zelândia', gentilico: 'Neozelandês' },
{ pais: 'Afeganistão', gentilico: 'Afegão' },
{ pais: 'Arábia Saudita', gentilico: 'Saudita' },
{ pais: 'Armênia', gentilico: 'Armeno' },
{ pais: 'Armeno', gentilico: 'Bangladesh' },
{ pais: 'China', gentilico: 'Chinês' },
{ pais: 'Coréia do Norte', gentilico: 'Norte-coreano, coreano' },
{ pais: 'Coréia do Sul', gentilico: 'Sul-coreano, coreano' },
{ pais: 'Índia', gentilico: 'Indiano' },
{ pais: 'Indonésia', gentilico: 'Indonésio' },
{ pais: 'Iraque', gentilico: 'Iraquiano' },
{ pais: 'Irã', gentilico: 'Iraniano' },
{ pais: 'Israel', gentilico: 'Israelita' },
{ pais: 'Japão', gentilico: 'Japonês' },
{ pais: 'Malásia', gentilico: 'Malaio' },
{ pais: 'Nepal', gentilico: 'Nepalês' },
{ pais: 'Omã', gentilico: 'Omanense' },
{ pais: 'Paquistão', gentilico: 'Paquistanês' },
{ pais: 'Palestina', gentilico: 'Palestino' },
{ pais: 'Qatar', gentilico: 'Qatarense' },
{ pais: 'Síria', gentilico: 'Sírio' },
{ pais: 'Sri Lanka', gentilico: 'Cingalês' },
{ pais: 'Tailândia', gentilico: 'Tailandês' },
{ pais: 'Timor-Leste', gentilico: 'Timorense, maubere' },
{ pais: 'Emirados Árabes Unidos', gentilico: 'Árabe, emiratense' },
{ pais: 'Vietnã', gentilico: 'Vietnamita' },
{ pais: 'Iêmen', gentilico: 'Iemenita' }]

export const companyRegisterStatus = [
	{ label: 'Todos', value: "" },
	{ label: 'Cadastrou o CNPJ', value: 'started' },
	{ label: 'Enviou KYC da Empresa', value: 'completed_company_data' },
	{ label: 'Cadastrou Responsáveis', value: 'completed_financial_responsible' },
	{ label: 'Revisou Cadastro', value: 'accepted_review' },
	{ label: 'Enviou Documentos da Empresa', value: 'sended_company_docs' },
	{ label: 'Enviou Documentos dos Responsáveis', value: 'sended_qsa_docs' },
	{ label: 'Todos os Documentos Enviados', value: 'sended_all_docs' },
	{ label: 'Aguardando Assinaturas', value: 'waiting_sign_documents' },
	{ label: 'Documentos Assinados', value: 'signed_docs' },
	{ label: 'Cadastro Completo', value: 'finalized' }
]

export const companyRegisterStatusCheckBoxOptions = [
	{ label: 'Cadastrou o CNPJ', value: 'started' },
	{ label: 'Enviou KYC da Empresa', value: 'completed_company_data' },
	{ label: 'Cadastrou Responsáveis', value: 'completed_financial_responsible' },
	{ label: 'Revisou Cadastro', value: 'accepted_review' },
	{ label: 'Enviou Documentos da Empresa', value: 'sended_company_docs' },
	{ label: 'Enviou Documentos dos Responsáveis', value: 'sended_qsa_docs' },
	{ label: 'Todos os Documentos Enviados', value: 'sended_all_docs' },
	{ label: 'Aguardando Assinaturas', value: 'waiting_sign_documents' },
	{ label: 'Documentos Assinados', value: 'signed_docs' },
	{ label: 'Cadastro Completo', value: 'finalized' }
]

export const personRegisteStatus = [
	{ label: 'Todos', value: "" },
	{ label: 'Cadastrou o CPF', value: 'started' },
	{ label: 'Enviou Dados Cadastrais da Pessoa', value: 'completed_cadastral_data' },
	{ label: 'Enviou KYC da Pessoal', value: 'completed_person_data' },
	{ label: 'Revisou Cadastro', value: 'accepted_review' },
	{ label: 'Enviou Documentos da Pessoa', value: 'sended_person_docs' },
	{ label: 'Aguardando Assinaturas', value: 'waiting_sign_documents' },
	{ label: 'Documentos Assinados', value: 'signed_docs' },
	{ label: 'Cadastro Completo', value: 'finalized' }
]

export const personRegisteStatusCheckBoxOptions = [
	{ label: 'Cadastrou o CPF', value: 'started' },
	{ label: 'Enviou Dados Cadastrais da Pessoa', value: 'completed_cadastral_data' },
	{ label: 'Enviou KYC da Pessoal', value: 'completed_person_data' },
	{ label: 'Revisou Cadastro', value: 'accepted_review' },
	{ label: 'Enviou Documentos da Pessoa', value: 'sended_person_docs' },
	{ label: 'Aguardando Assinaturas', value: 'waiting_sign_documents' },
	{ label: 'Documentos Assinados', value: 'signed_docs' },
	{ label: 'Cadastro Completo', value: 'finalized' }
]