import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Card, Typography, Row, Col } from "antd";
import MaskedInput from "antd-mask-input";
import { cpf as cpfValidator } from "cpf-cnpj-validator";

import "./styles.scss";
import _ from "lodash";
import { beautifyCpfCnpj } from "../../../../utils/beautify";
import { mask } from "../../../../utils/consts";

const { Title, Text } = Typography;

const CadastralData = ({ form, user, finished, ...props }) => {
    return (
        <>
            <Row>
                <Col style={{width: "100%"}}>
                    <Card style={{width: "40%"}}>
                        <Title level={5}>{user !== null && `${user.first_name} ${user.last_name}`}</Title>
                        <Text type="secondary">
                            {beautifyCpfCnpj(user !== null && user.username)}
                        </Text>
                        <br />
                        <br />
                        <p className="subtitle">Dados Cadastrais</p>
                        <Form.Item hidden={true} name={"qualification"}>
                            <Input type="text"></Input>
                        </Form.Item>
                        <Row justify={"space-between"}>
                            <Col>
                                <Form.Item
                                    label="Primeiro nome"
                                    name={"first_name"}
                                    initialValue={user.first_name}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Primeiro nome inválido.",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Nome"
                                        type="text"
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label="Sobrenome"
                                    name={"last_name"}
                                    initialValue={user.last_name}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Sobrenome inválido.",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Nome"
                                        type="text"
                                    ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item
                                    label="E-mail"
                                    name="email"
                                    initialValue={user.email}
                                    rules={[
                                        {
                                            required: true,
                                            message: "E-mail obrigatório",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="E-mail"
                                        type="text"
                                    ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item
                                    label="Telefone"
                                    name="phone"
                                    initialValue={user.phone}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Telefone obrigatório",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Telefone"
                                        type="text"
                                    ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

CadastralData.propTypes = {
    form: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    finished: PropTypes.bool.isRequired,
};

export default CadastralData;
