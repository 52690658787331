// import axios from 'axios'
import api from '../../../../service/api'
import apiRoutes from '../../../../service/apiRoutes'

export const getDataRecept = (data) => api.get(`${apiRoutes.recept}`, { params: data })

export const postDataRecept = (data) => api.post(`${apiRoutes.recept}`, data)

export const postCatchRecept = (data) => api.post(`${apiRoutes.recept}/catch`, data)

export const putRecept = (data) => api.put(apiRoutes.recept, data)

export const boReview = (data) => api.put(`${apiRoutes.recept}/review_operation`, data)

export const contractUpload = (data) => api.put(`${apiRoutes.recept}/send_contract`, data)

export const finalizeRecept = (data) => api.put(`${apiRoutes.recept}/finalize`, data)

export const removeRecept = (dataId) => api.delete(`${apiRoutes.recept}?receive_value_id=${dataId}`)

export const downloadFile = (fileId) => api.get(`${apiRoutes.exchangeFiles}?file_id=${fileId}`, { responseType: 'blob' })

export const downloadZip = (receiveValueId) => api.get(`${apiRoutes.zipedReceiveVauleFiles}?receive_value_id=${receiveValueId}`, { responseType: 'arraybuffer' })

export const currencyRates = (params) => api.get(`${apiRoutes.rates}`, { params })

export const approveReceiveValue = (receivementId) => api.post(`${apiRoutes.approveReceiveValue}`, { receive_value_id: receivementId })

export const linkToSignedDoc = (fileId) => api.get(`${apiRoutes.downloadClickSign}?file_id=${fileId}`)