import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import stateStatus from "../../../../../utils/status"
import { fetchDocsToSign, selectCompanyId } from "../../slice"
import PropTypes from 'prop-types'
import { message, Row, Typography } from 'antd'
import LoadingIcon from "../../../../../common/components/loading_icon/loading_icon"
import "./styles.scss"
import { linkToSignedDoc } from "../../../service"
import { DownloadOutlined } from "@ant-design/icons"

const { Title } = Typography

const ListSignatures = ({ cnpj }) => {
	const dispatch = useDispatch()
	const companyState = useSelector(selectCompanyId)
	const loadingSucceded = companyState.status.listDocsToSign === stateStatus.succeeded

	useEffect(() => {
		dispatch(fetchDocsToSign(cnpj))
	}, [])

	const downloadFile = (fileId) => {
		linkToSignedDoc(fileId).then((response) => {
			window.open(response.data.signed_file_url || response.data.original_file_url, "_blank")
		}).catch((e) => {
			message.error("Erro ao gerar link de download")
		})
	}

	return (
		<div className="docs-to-sign-container">
			{
				loadingSucceded && companyState.kycToSign.click_sign_id ? (
					<>
						<Title level={5}>KYC</Title>
						<div><DownloadOutlined onClick={() => {downloadFile(companyState.kycToSign.click_sign_id) }} /> {companyState.kycToSign.status} </div>
						<br />
					</>
				) : null

			}
			{
				companyState.status.listDocsToSign !== stateStatus.loading ? companyState.docsToSign.map((item, i) => {
					return (
						<div key={i}>
							<Title level={5}>{item.name}</Title>
							{
								item.docs_to_sign.map((docs, i) => {
									return <div key={i}> <DownloadOutlined onClick={() => {downloadFile(docs.click_sign_id) }} /> <b>{docs.doc_type}:</b> {docs.status}</div>
								})
							}
						</div>
					)
				})
					: <Row justify="center"><LoadingIcon /></Row>
			}
		</div>
	)
}

ListSignatures.propTypes = {
	cnpj: PropTypes.string
}


export default ListSignatures