import React from 'react'
import { DownloadOutlined, SyncOutlined } from '@ant-design/icons'
import { Card, Col, Row, Tooltip, } from 'antd'
import PropTypes from "prop-types"
import './styles.scss'

const CardFile = ({
  filename,
  onDownload,
  onDelete,
  isLoading,
}) => {
  return (
    <Col>
      <Card>
        <Row gutter={[8, 0]}>
          <Col><p>{filename}</p></Col>
          <Col className="btns-doc-actions">
            {!isLoading ? (
              <>
                <Tooltip className='download-btn' placement="top" title={"Download"}>
                  <DownloadOutlined onClick={onDownload} />
                </Tooltip>
              </>
            ) : (
              <SyncOutlined spin />
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  )
}

CardFile.propTypes = {
  filename: PropTypes.string,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CardFile