import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input, Card, Typography, Row, Col, Tabs, Select, Layout, Button, Divider, message, Space, Modal, Checkbox, Collapse, InputNumber } from 'antd'
import { CheckCircleOutlined, LoadingOutlined, SaveOutlined, FileTextOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import MaskedInput from 'antd-mask-input'
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator'
import moment from 'moment'

import Base from '../../../common/components/Base'
import TooltipEfex from '../../../common/components/Tooltip'
import IdentedField from '../../../common/components/IdentedField'

import {
	companyRegisterStatusCheckBoxOptions,
	countriesList,
	mask,
	optionsNat,
	purposeOptionsCompany
} from '../../../utils/consts'
import { toBrazilianDateString } from '../../../utils/date'
import { beautifyCpfCnpj } from '../../../utils/beautify'
import { BankList, BrazilStates } from '../../../utils/lists'
import stateStatus from '../../../utils/status'
import ErroMsg from '../../../common/components/ErrorMsg'
import { getAddressByCep } from '../../../service/viacep.service'

import { selectCompanyId, getCompanyId, postBoReview, putUpdateCompanyRegister, resetStepsStatus } from './slice'
import ListDocuments from './components/ListDocuments'
import { selectDocs, getListDocs, sendDocsToSignThunk } from './components/ListDocuments/slice'
import { formatCurrency } from '../../../utils/functions'
import './styles.scss'
import DataCompany from './components/DataCompany'
import KYC from './components/KYC'
import CompanyBread from './components/CompanyBread'
import ListSignatures from './components/ListSignatures/ListSignatures'
import ListTaxes from '../../Exchange/ListTaxes'
import ReviewClientModal from '../../../common/components/ReviewClientModal'
import CurrencyField from '../../../common/components/currency_field/currency_field'
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import br from 'world_countries_lists/data/countries/en/world.json';
import _ from 'lodash';
import AddFRModal from '../companents/AddFRModal'
import DelFRModal from '../companents/DelFRModal'
import CompanyRegisterUpdate from '../companents/CompanyRegisterUpdate'

const { Content } = Layout
const { Title, Text } = Typography
const { TabPane } = Tabs
const { Option } = Select
const { Panel } = Collapse

const Company = ({ match, ...props }) => {
	const [form] = Form.useForm()
	const [maskPhone, setmaskPhone] = useState('(00) 00000-0000')
	const [isComex, setIsComex] = useState(false)
	const [isOther, setIsOther] = useState(false)
	const [isUpdating, setisUpdating] = useState(false)
	const [showApproveModal, setShowApproveModal] = useState(false)
	const [valueSelect, setValueSelect] = React.useState([])
	const companyId = useSelector(selectCompanyId)
	const documents = useSelector(selectDocs)
	const [state, setState] = useState({})
	const [isLoading, setIsLoading] = useState(true);
	const [lstValueCountryNumber, setLstValueCountryNumber] = useState([{ short: 'BR', code: 55, phone: null }]);
	const [useCep, setUseCep] = useState([]);
	const [isMovLess, setIsMovLess] = useState(false);
	const [isFRModalVisible, setIsFRModalVisible] = useState(false)
	const [isDelModalVisible, setIsDelModalVisible] = useState(false)
	const [checkedList, setCheckedList] = useState([])
	const [showAttModal, setShowAttModal] = useState(false)
	const [operationalLimit, setOperationalLimit] = useState(null)
	const [isChangingOperationalLimit, setIsChangingOperationalLimit] = useState(false)

	const dispatch = useDispatch()
	const updateState = (key, value) => {
		state[key] = value
		setState({ ...state })
	}

	// useEffect(() => {
	// 	const phone = form.getFieldValue('phone_0')

	// 	if(phone){
	// 		if (phone.charAt(2) === '9') {
	// 			setmaskPhone('(00) 00000-0000')
	// 		} else {
	// 			setmaskPhone('(00) 0000-0000')
	// 		}
	// 	}
	// }, [form.getFieldValue('phone_0')])

	useEffect(() => {

		companyId?.company?.operational_limit > 0 && setOperationalLimit(companyId?.company?.operational_limit)
	}, [companyId.company])

	useEffect(() => {
		dispatch(getCompanyId({ id: match.params.id }))
	}, [])

	useEffect(() => {
		if (companyId.status.boReview === stateStatus.succeeded) {
			dispatch(resetStepsStatus())
			dispatch(getCompanyId({ id: match.params.id }))
		}
	}, [companyId.status.boReview])

	useEffect(() => {
		if (documents.status.sendDocsToSign == stateStatus.succeeded) {
			message.success({ content: "Solicitação enviada com sucesso!", duration: 5 })
			dispatch(resetStepsStatus())
			dispatch(getCompanyId({ id: match.params.id }))
		}
	}, [documents.status.sendDocsToSign])

	// abre sub menu caso seja um agente público
	useEffect(() => {
		if (companyId.status.getCompany === stateStatus.succeeded) {
			let aux = [];
			let auxPhone = lstValueCountryNumber;
			companyId.company.financial_responsibles.forEach((item, index) => {
				aux[index] = false;

				if (item.is_public_agent) {
					updateState(`${index}publicAgent`, true)
				}

				if (item.has_public_agent_link) {
					updateState(`${index}publicLink`, true)
				}

				if (item.cell_phone) {
					if (auxPhone[0].phone == null) {
						auxPhone = [];
					}
					if (item.cell_phone.includes('-')) {
						let phone = item.cell_phone.split('-');
						auxPhone.push({ code: parseInt(phone[0]), phone: phone[1] });
					} else {
						auxPhone.push({ short: 'BR', code: 55, phone: item.cell_phone });
					}
				} else {
					auxPhone.push({ short: 'BR', code: 55, phone: null });
				}

				if ((!_.isUndefined(item.residential_country) &&
					!_.isNull(item.residential_country) &&
					item.residential_country.toLowerCase() === "brasil") || _.isUndefined(item.residential_country)) {
					aux[index] = true;
				}
			})
			setLstValueCountryNumber(auxPhone);
			setUseCep(aux);
		}
	}, [companyId.status.getCompany])

	useEffect(() => {
		if (companyId.status.updateCompanyRegister === stateStatus.succeeded && isUpdating) {
			setisUpdating(false)
			dispatch(resetStepsStatus())
			dispatch(getCompanyId({ id: match.params.id }))
		}
	}, [companyId.status.updateCompanyRegister])

	useEffect(() => {
		if (companyId.status.getCompany === stateStatus.succeeded) {
			const compAux = {}
			if (
				Object.prototype.toString.call(companyId.company.comex) === '[object Object]'
			) {
				compAux.payment_receivement_type = companyId.company.comex.payment_receivement_type
				compAux.operation_mode = companyId.company.comex.operation_mode
				compAux.storage_comments = companyId.company.comex.storage_comments
				compAux.last_six_months_declarations = companyId.company.comex.last_six_months_declarations
				compAux.merchandise_description = companyId.company.comex.merchandise_description
				setIsComex(true)
			}
			let data = {
				...companyId.company,
				...compAux,
				...companyId.company.addresses[0],
				email: companyId.company.email,
				share_capital: companyId.company.share_capital !== null ? companyId.company.share_capital : null,
				patrimony: companyId.company.patrimony !== null ? companyId.company.patrimony : null,
				monthly_invoicing: companyId.company.monthly_invoicing !== null
					? companyId.company.monthly_invoicing
					: null,
				cnpj: cnpjValidator.format(companyId.company.cnpj),
				payers_and_buyers_country: companyId.company.payers_and_buyers_country === null
					? []
					: companyId.company.payers_and_buyers_country,
				purposes_exchange: companyId.company.purposes_exchange === null
					? []
					: companyId.company.purposes_exchange
			};
			form.setFieldsValue(data);
			setIsMovLess(data.is_movement_less_15)
			const phone = form.getFieldValue('phone_0')

			if (phone) {
				if (phone.charAt(2) === '9') {
					setmaskPhone('(00) 00000-0000')
				} else {
					setmaskPhone('(00) 0000-0000')
				}
			}
		}
		setTimeout(() => {
			setIsLoading(false)
		}, 3000)
	}, [companyId.status.getCompany, form])

	useEffect(() => {
		if (documents.status.listDocuments === stateStatus.idle) {
			dispatch(getListDocs({ id: match.params.id }))
		}
	})

	useEffect(() => {
		const disabledItems = companyRegisterStatusCheckBoxOptions
			.filter((option) => isOptionSelected(option.value))
			.map((option) => option.value);
		setCheckedList(disabledItems)
	}, [companyId.company?.register_steps])

	const onChangeCheckbox = (checkedValues) => {
		const disabledItems = companyRegisterStatusCheckBoxOptions
			.filter((option) => isOptionSelected(option.value))
			.map((option) => option.value);

		const allCheckedItems = new Set([...disabledItems, ...checkedValues]);
		setCheckedList(Array.from(allCheckedItems));
	};

	const isOptionSelected = (value) => {
		return companyId.company?.register_steps[value] === true;
	};

	const checkboxOptions = companyRegisterStatusCheckBoxOptions.map(option => ({
		...option,
		disabled: isOptionSelected(option.value)
	}));

	const onFinish = (values) => {
		setisUpdating(true)
		values.cnpj = cnpjValidator.strip(values.cnpj)
		values.zipcode = values.zipcode.replace(/\D/g, '')
		values.patrimony = parseInt(String(values.patrimony).replace(/\D/g, ''))
		values.phone_0 = cnpjValidator.strip(values.phone_0)
		if (typeof values.monthly_invoicing === 'string' || values.monthly_invoicing instanceof String) {
			values.monthly_invoicing = parseInt(String(values.monthly_invoicing).replace(/\D/g, ''))
		}
		if (values.init_date) {
			const splitedDate = values.init_date.split("/")
			values.init_date = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`
		}
		if (values.complement === "") delete values.complement
		if (values.comments === "" || values.comments === null || values.comments === undefined) delete values.comments
		if (isComex) {
			values.comex = {}
			values.comex.payment_receivement_type = values.payment_receivement_type
			values.comex.operation_mode = values.operation_mode
			values.comex.merchandise_description = values.merchandise_description
			values.comex.storage_comments = values.storage_comments
			values.comex.last_six_months_declarations =
				values.last_six_months_declarations
		} else {
			delete values.comex
		}

		const finResp = []
		companyId.company.financial_responsibles.forEach((it, index) => {
			const resp = {}
			Object.entries(values).forEach((item) => {
				let finalValue

				if (Array.isArray(item[1])
					&& item[0] !== 'payers_and_buyers_country'
					&& item[0] !== 'purposes_exchange'
					&& item[0] !== 'payment_receivement_type'
					&& item[0] !== 'operation_mode') {
					if (item[0].search("zipcode") !== -1) {
						finalValue = item[1][index].replace(/\D/g, "")
					} else if (item[0].search("cpf") !== -1) {
						finalValue = cpfValidator.strip(item[1][index])
					} else if (item[0].search("cell_phone") !== -1) {
						if (item[1][index].phone !== undefined) {
							finalValue = `${item[1][index].code}-${item[1][index].phone.replace(/\D/g, "")}`;
						} else {
							finalValue = item[1][index];
						}
					} else if (item[0].search("birthday") !== -1
						|| item[0].search("date") !== -1
					) {
						const splitedDate = item[1][index].split("/")
						finalValue = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`
					} else if (item[0].search("monthly_income") !== -1) {

					} else {
						finalValue = item[1][index]
					}
					resp[item[0]] = finalValue
				}
			})
			if (resp.is_public_agent) {
				resp["public_ocupation_details"] = {
					public_agent_occupation: resp.public_agent_occupation,
					public_agent_start_date: resp.public_agent_start_date,
					public_agent_end_date: resp.public_agent_end_date,
					public_agent_cnpj: cnpjValidator.strip(resp.public_agent_cnpj),
				}
				delete resp.public_agent_occupation
				delete resp.public_agent_start_date
				delete resp.public_agent_end_date
				delete resp.public_agent_cnpj
			}
			if (resp.has_public_agent_link) {
				resp["public_agent_link_details"] = {
					public_agent_link_name: resp.public_agent_link_name,
					public_agent_link_cpf: resp.public_agent_link_cpf,
					public_agent_link_occupation: resp.public_agent_link_occupation,
					public_agent_link_type: resp.public_agent_link_type,
				}

				delete resp.public_agent_link_name
				delete resp.public_agent_link_cpf
				delete resp.public_agent_link_occupation
				delete resp.public_agent_link_type
			}

			if (it.addr_complement === "") {
				delete resp.addr_complement
			}
			if (resp.father_name === "") {
				resp.father_name = null
			}
			resp["id"] = `${it.id}`
			resp["qualification"] = it.qualification
			resp["name"] = it.name
			resp["cpf"] = it.cpf
			resp["country_name"] = resp.residential_country
			resp["email"] = resp.email_fin
			resp["is_pep"] = resp.is_public_agent
			resp["participation"] = 1
			finResp.push(resp)
		})
		delete values.addr_city_name
		delete values.addr_complement
		delete values.addr_neighborhood
		delete values.addr_number
		delete values.addr_province
		delete values.addr_public_place
		delete values.addr_zipcode
		delete values.agency_number
		delete values.bank_account_number
		delete values.bank_name
		delete values.birthday
		delete values.birthplace
		delete values.cell_phone
		delete values.doc_dispatcher
		delete values.doc_number
		delete values.doc_shipping_date
		delete values.doc_type
		delete values.email_fin
		delete values.father_name
		delete values.gender
		delete values.has_link_with_bs2
		delete values.has_public_agent_link
		delete values.is_public_agent
		delete values.is_us_person
		delete values.marital_status
		delete values.mother_name
		delete values.nationality
		delete values.profession
		delete values.qualification
		delete values.residential_country
		dispatch(putUpdateCompanyRegister({
			id: match.params.id,
			data: { extra: values, financial_responsibles: finResp, register_steps: checkedList }
		})).then((resp) => {
			if (resp.payload) {
				setCheckedList([])
				dispatch(getListDocs({ id: match.params.id }))
			}
		})
	}
	const onFinishFailed = (values) => {
	}

	const onChangeForm = (values, fields) => {
		const phone = cnpjValidator.strip(values.phone_0)

		if (phone.charAt(2) === '9') {
			setmaskPhone('(00) 00000-0000')
		} else {
			setmaskPhone('(00) 0000-0000')
		}

		if (useCep[0]) {
			values.addr_zipcode?.forEach((el, index) => {
				const zipcode = el.replace(/\D/g, "");
				if (zipcode.length === 8) {
					getAddressByCep(zipcode).then((response) => {
						fields.addr_public_place[index] =
							response.logradouro;
						fields.addr_neighborhood[index] =
							response.bairro;
						fields.addr_city_name[index] =
							response.localidade;
						fields.addr_province[index] = response.uf;
						form.setFieldsValue(fields);
					});
				}
			});
		}

		if (values.residential_country) {
			let aux = [];
			values.residential_country.forEach((el, index) => {
				aux[index] = false;
				if (el.toLowerCase() === 'brasil') {
					aux[index] = true;
				}
			})
			setUseCep(aux)
		}
	}

	const selectProps = {
		mode: 'multiple',
		style: {
			width: '100%'
		},
		options: purposeOptionsCompany,
		valueSelect,
		onChange: (newValue) => {
			setValueSelect(newValue)
			if (newValue.includes(0)) {
				setIsComex(true)
			} else {
				setIsComex(false)
			}

			if (newValue.includes(13)) {
				setIsOther(true)
			} else {
				setIsOther(false)
			}
		},
		placeholder: 'Selecionar propósitos'
		// maxTagCount: "responsive",
	}
	return <Base>

		{
			isLoading
				? 'Loading'
				: companyId.status.getCompany === stateStatus.failed
					? 'Erro'
					: <Content className='company-id-content'>
						{companyId.status.getCompany === stateStatus.succeeded ? <>
							<Form
								form={form}
								layout="vertical"
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								onValuesChange={onChangeForm}
								requiredMark={false}
								className="legal-person"
								scrollToFirstError={true}
							>
								<CompanyBread companyName={companyId.company.company_name} />
								<Col>
									<Divider />
									<Row>
										<Space>
											<Button
												htmlType="submit"
												icon={companyId.status.updateCompanyRegister === stateStatus.loading
													? <LoadingOutlined />
													: <SaveOutlined />}
											>
												Salvar alterações
											</Button>
											<Button
												disabled={!["sended_all_docs", "waiting_sign_documents"].includes(companyId.company.register_step.id)}
												onClick={() => dispatch(sendDocsToSignThunk(companyId.company.cnpj))}
												icon={documents.status.sendDocsToSign === stateStatus.loading
													? <LoadingOutlined />
													: <FileTextOutlined />}
											>Solicitar Assinaturas</Button>
											<Button
												disabled={companyId.company.register_step.id !== "signed_docs"}
												className="review-btn"
												onClick={() => setShowApproveModal(true)}
												icon={companyId.status.boReview === stateStatus.loading
													? <LoadingOutlined />
													: <CheckCircleOutlined />}
											>Aprovar cadastro</Button>
											<Button
												disabled={companyId.company.register_step.id !== "finalized"}
												onClick={() => setShowAttModal(true)}
												icon={<EditOutlined />}
											>

												Solicitar atualização de cadastro
											</Button>
										</Space>
									</Row>
									<Divider />
									{companyId.status.updateCompanyRegister === stateStatus.failed
										|| companyId.status.getCompany === stateStatus.failed
										|| companyId.status.boReview === stateStatus.failed
										? <ErroMsg message={companyId.errorMsg} />
										: null}
								</Col>
								<Card>
									<Tabs defaultActiveKey="1" >
										<TabPane tab="Cadastro" key="1">

											<Row justify="space-between" >
												<Col>
													<Divider>
														<Typography.Title level={5}>
															Status do cadastro
														</Typography.Title>
													</Divider>
												</Col>
												<Col>
													<Divider>
														<Typography.Title level={5}>
															Limite Operacional
														</Typography.Title>
													</Divider>
												</Col>
											</Row>
											<Row justify="space-between" >
												<Col span={16}>
													<Collapse style={{ width: "50%" }}>
														<Panel header="Status do cadastro" key="1" style={{ width: "100%" }}>
															<div className="checkbox-group-vertical">
																<Checkbox.Group
																	options={checkboxOptions}
																	value={checkedList}
																	onChange={onChangeCheckbox}
																	checked={true}
																/>
															</div>
														</Panel>
													</Collapse>
												</Col>
												<Col style={{ textAlign: 'left', gap: "16px", display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
													<InputNumber
														onChange={(value) => setOperationalLimit(value)}
														onClick={(event) => {
															if (event.target.value === "não definido") {
																setOperationalLimit(0)
															}
														}}
														value={!companyId?.company?.operational_limit && operationalLimit === null ? "não definido" : operationalLimit}
														style={{
															width: '100%',
														}}
														min={0}
														formatter={value => formatCurrency(value, null)}
														parser={money => money.replace(/\D/g, '')}
													/>
													<Button
														style={{
															width: '100%',
														}}
														onClick={() => {
															setIsChangingOperationalLimit(true)
															dispatch(putUpdateCompanyRegister({
																id: match.params.id,
																data: { operational_limit: operationalLimit }
															})).then((resp) => {
																setIsChangingOperationalLimit(false)
																if (resp.payload) {
																	dispatch(getCompanyId({ id: match.params.id }))
																}
															})
														}}
														disabled={isChangingOperationalLimit ||
															operationalLimit === null ||
															operationalLimit === companyId?.company?.operational_limit}
														icon={
															isChangingOperationalLimit ? (
																<LoadingOutlined />
															) : (
																<SaveOutlined />
															)
														}
													>
														Alterar limite operacional
													</Button>
												</Col>
											</Row>


											<Divider orientation="left">
												<Typography.Title level={5}>
													Dados Cadastrais
												</Typography.Title>
											</Divider>
											<DataCompany maskPhone={maskPhone} {...props} />

											<Divider orientation="left">
												<Typography.Title level={5}>
													Know Your Costumer
												</Typography.Title>
											</Divider>
											<KYC
												isComex={isComex}
												isOther={isOther}
												selectProps={selectProps}
												isMovLess={isMovLess}
												setIsMovLess={setIsMovLess}
												form={form}
											/>
											<Divider orientation="left"></Divider>
											<Button onClick={() => setIsFRModalVisible(true)}>Adicionar Responsável Financeiro</Button>

											<Divider orientation="left">

												<Typography.Title level={5}>
													Responsável(eis) Financeiro
												</Typography.Title>

											</Divider>

											<AddFRModal
												companyId={match.params.id}
												isModalVisible={isFRModalVisible}
												setIsModalVisible={setIsFRModalVisible}
												form={form}
											/>
											{companyId.company !== null ? companyId.company.financial_responsibles.map((responsible, index) => {
												return (
													<div key={index}>
														<Row gutter={[8, 8]}>
															<Col sm={12}>
																<Card>
																	<Space style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
																		<div>
																			<Title level={5}>{responsible.name}</Title>
																			<Text type="secondary">
																				{beautifyCpfCnpj(responsible.cpf)}
																			</Text>
																		</div>
																		<DeleteOutlined onClick={() => setIsDelModalVisible(true)} />
																	</Space>
																	<br />
																	<br />
																	<p className="subtitle">Dados Cadastrais</p>
																	<Form.Item
																		hidden={true}
																		name={['qualification', index]}
																		initialValue={responsible.qualification}
																	>
																		<Input type="text"></Input>
																	</Form.Item>
																	<Row justify="space-between">
																		<Col>
																			<Form.Item
																				label="Número do documento"
																				name={['doc_number', index]}
																				initialValue={responsible.doc_number}
																				rules={[
																					{
																						required: true,
																						message: 'Documento inválido.'
																					}
																				]}
																			>
																				<Input
																					addonBefore={TypeSelector(
																						index,
																						responsible.doc_type
																					)}
																					placeholder="Número"
																					type="text"
																				></Input>
																			</Form.Item>
																		</Col>
																		<Col>
																			<Form.Item
																				label="UF Emissor"
																				name={[
																					"doc_uf",
																					index,
																				]}
																				initialValue={
																					responsible.doc_uf
																				}
																				rules={[
																					{
																						required: true,
																						message:
																							"UF emissora obrigatório",
																					}
																				]}
																			>
																				<Select placeholde="UF">
																					<Select.Option value="AC">AC</Select.Option>
																					<Select.Option value="AL">AL</Select.Option>
																					<Select.Option value="AP">AP</Select.Option>
																					<Select.Option value="AM">AM</Select.Option>
																					<Select.Option value="BA">BA</Select.Option>
																					<Select.Option value="CE">CE</Select.Option>
																					<Select.Option value="DF">DF</Select.Option>
																					<Select.Option value="ES">ES</Select.Option>
																					<Select.Option value="GO">GO</Select.Option>
																					<Select.Option value="MA">MA</Select.Option>
																					<Select.Option value="MS">MS</Select.Option>
																					<Select.Option value="MT">MT</Select.Option>
																					<Select.Option value="MG">MG</Select.Option>
																					<Select.Option value="PA">PA</Select.Option>
																					<Select.Option value="PB">PB</Select.Option>
																					<Select.Option value="PR">PR</Select.Option>
																					<Select.Option value="PE">PE</Select.Option>
																					<Select.Option value="PI">PI</Select.Option>
																					<Select.Option value="RJ">RJ</Select.Option>
																					<Select.Option value="RN">RN</Select.Option>
																					<Select.Option value="RS">RS</Select.Option>
																					<Select.Option value="RO">RO</Select.Option>
																					<Select.Option value="RR">RR</Select.Option>
																					<Select.Option value="SC">SC</Select.Option>
																					<Select.Option value="SP">SP</Select.Option>
																					<Select.Option value="SE">SE</Select.Option>
																					<Select.Option value="TO">TO</Select.Option>
																				</Select>
																			</Form.Item></Col>
																		<Col sm={7}>
																			<Form.Item
																				label="Orgão Emissor"
																				name={['doc_dispatcher', index]}
																				initialValue={responsible.doc_dispatcher}
																				rules={[
																					{
																						required: true,
																						message: 'Orgão emissor obrigatório'
																					},
																					{
																						pattern: /^\D{2,}$/,
																						message: 'Nome inválido'
																					}
																				]}
																			>
																				<Input
																					placeholder="Nome do Orgão"
																					type="text"
																				></Input>
																			</Form.Item>
																		</Col>
																	</Row>
																	<Row justify="space-between">
																		<Col>
																			<Form.Item
																				label="Data de Expedição"
																				name={['doc_shipping_date', index]}
																				initialValue={toBrazilianDateString(
																					responsible.doc_shipping_date
																				)}
																				rules={[
																					{
																						required: true,
																						message: 'Orgão emissor obrigatório'
																					},
																					() => ({
																						validator(_, value) {
																							const dateOnlyNumbers = value.replace(
																								/\D/g,
																								''
																							)
																							const date = moment(value, 'DD/MM/YYYY')
																							if (
																								dateOnlyNumbers.length < 8 || !date.isValid()
																							) {
																								return Promise.reject(
																									new Error('Data inválida')
																								)
																							} else {
																								return Promise.resolve()
																							}
																						}
																					})
																				]}
																			>
																				<MaskedInput mask={mask.date}></MaskedInput>
																			</Form.Item>
																		</Col>
																		<Col>
																			<Form.Item
																				label="Data de Nascimento"
																				name={['birthday', index]}
																				initialValue={toBrazilianDateString(
																					responsible.birthday
																				)}
																				rules={[
																					{
																						required: true,
																						message: 'Data obrigatória'
																					},
																					() => ({
																						validator(_, value) {
																							const dateOnlyNumbers = value.replace(
																								/\D/g,
																								''
																							)
																							const date = moment(value, 'DD/MM/YYYY')
																							const actualDate = new Date()
																							const miniumAge = moment([
																								actualDate.getFullYear() - 18,
																								actualDate.getMonth(),
																								actualDate.getDate()
																							])
																							if (
																								dateOnlyNumbers.length < 8 || !date.isValid()
																							) {
																								return Promise.reject(
																									new Error('Data inválida')
																								)
																							} else if (date.isBefore(miniumAge)) {
																								return Promise.resolve()
																							} else {
																								return Promise.reject(
																									new Error('Não pode ser menor de idade')
																								)
																							}
																						}
																					})
																				]}
																			>
																				<MaskedInput mask={mask.date}></MaskedInput>
																			</Form.Item>
																		</Col>
																		<Col sm={24}>
																			<Form.Item
																				label="Email"
																				name={['email_fin', index]}
																				initialValue={responsible.email}
																				rules={[
																					{
																						required: true,
																						message: 'Informe o email.'
																					},
																					{
																						type: 'email',
																						message: 'Informe um email válido.'
																					}
																				]}
																			>
																				<Input placeholder="Email" type="email"></Input>
																			</Form.Item>
																		</Col>
																		<Col sm={24}>
																			<Form.Item
																				label="Nome completo da Mãe"
																				name={['mother_name', index]}
																				initialValue={responsible.mother_name}
																				rules={[
																					{
																						required: true,
																						message: 'Nome inválido.'
																					}
																				]}
																			>
																				<Input
																					placeholder="Nome Completo"
																					type="text"
																				></Input>
																			</Form.Item>
																		</Col>
																		<Col sm={24}>
																			<Form.Item
																				label="Nome completo do Pai"
																				name={['father_name', index]}
																				initialValue={responsible.father_name}
																			>
																				<Input
																					placeholder="Nome Completo"
																					type="text"
																				></Input>
																			</Form.Item>
																		</Col>
																		<Col sm={24}>
																			<Form.Item
																				label="Profissão"
																				name={['profession', index]}
																				initialValue={responsible.profession}
																				rules={[
																					{
																						required: true,
																						message: 'Profissão inválida.'
																					}
																				]}
																			>
																				<Input placeholder="Profissão" type="text"></Input>
																			</Form.Item>
																		</Col>
																		<Col sm={24}>
																			<Form.Item
																				label="Renda mensal aproximada"
																				name={['monthly_income', index]}
																				initialValue={responsible.monthly_income}
																				rules={[
																					{
																						required: true,
																						message: 'Renda mensal aproximada'
																					}
																				]}
																			>
																				<CurrencyField style={{ width: "100%" }} />
																			</Form.Item>
																		</Col>
																	</Row>
																	<Row justify="space-between">
																		<Col>
																			<Form.Item
																				name={['gender', index]}
																				label="Gênero"
																				initialValue={responsible.gender}
																				rules={[
																					{
																						required: true,
																						message: 'Seleção Obrigatória.'
																					}
																				]}
																			>
																				<Select placeholder="Escolher">
																					<Option value="M">Masculino</Option>
																					<Option value="F">Feminino</Option>
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col>
																			<Form.Item
																				label="Telefone"
																				name={['cell_phone', index]}
																				initialValue={responsible.cell_phone}
																				rules={[
																					{
																						required: true,
																						message: 'Telefone inválido.'
																					}
																				]}
																			>
																				<ConfigProvider locale={br}>
																					<CountryPhoneInput className="register-country-phone" onChange={(v) => {
																						var aux = lstValueCountryNumber;
																						aux = _.cloneDeep(lstValueCountryNumber);
																						aux[index] = v;
																						setLstValueCountryNumber(aux);
																						form.setFieldsValue({ 'cell_phone': lstValueCountryNumber });
																					}} value={_.cloneDeep(lstValueCountryNumber[index])} />
																				</ConfigProvider>
																				{/* <MaskedInput mask="(00) 00000-0000"></MaskedInput> */}
																			</Form.Item>
																		</Col>
																	</Row>
																	<Form.Item
																		label="Cidade Natal"
																		name={['birthplace', index]}
																		initialValue={responsible.birthplace}
																		rules={[
																			{
																				required: true,
																				message: 'Nome inválid0.'
																			}
																		]}
																	>
																		<Input placeholder="Nome Completo" type="text"></Input>
																	</Form.Item>
																	<Row justify="space-between">
																		<Col sm={10}>
																			<Form.Item
																				label="País que reside"
																				name={['residential_country', index]}
																				initialValue={responsible.residential_country}
																				rules={[
																					{
																						required: true,
																						message: 'Nome inválido.'
																					}
																				]}
																			>
																				<Select
																					onChange={() => {
																						const resetAddressFields = {
																							addr_public_place: undefined,
																							addr_neighborhood: undefined,
																							addr_city_name: undefined,
																							addr_province: undefined,
																							addr_zipcode: undefined,
																							addr_number: undefined,
																							addr_complement: undefined,
																						};
																						form.setFieldsValue(resetAddressFields);
																					}}
																					showSearch
																					filterOption={(input, option) =>
																						option.children
																							.toLowerCase()
																							.indexOf(input.toLowerCase()) >= 0
																					}
																				>
																					{countriesList.map((item) => {
																						return (
																							<Option key={item.pais} value={item.pais}>
																								{item.pais}
																							</Option>
																						)
																					})}
																				</Select>
																			</Form.Item>
																		</Col>
																		<Col sm={10}>
																			<Form.Item
																				label="Nacionalidade"
																				name={['nationality', index]}
																				initialValue={responsible.nationality}
																				rules={[
																					{
																						required: true,
																						message: 'Nome inválido.'
																					}
																				]}
																			>
																				<Select options={optionsNat}> </Select>
																			</Form.Item>
																		</Col>
																	</Row>
																	<Form.Item
																		name={['marital_status', index]}
																		label="Estado Civil"
																		initialValue={responsible.marital_status}
																		rules={[
																			{
																				required: true,
																				message: 'Seleção Obrigatória.'
																			}
																		]}
																	>
																		<Select
																			onSelect={(value) =>
																				updateState(`${index}marital`, value)
																			}
																		>
																			<Option value="C">Casado(a)</Option>
																			<Option value="S">Solteiro(a)</Option>
																			<Option value="D">Divorciado(a)</Option>
																			<Option value="V">Viúvo(a)</Option>
																			<Option value="U">União Estável</Option>
																		</Select>
																	</Form.Item>
																	{state[`${index}marital`] === 'C' || state[`${index}marital`] === "U" ?
																		(
																			<IdentedField>
																				<Form.Item
																					label="Nome do Cônjuge"
																					name={['spouse_name', index]}
																					initialValue={responsible.spouse_name}
																					rules={[
																						{
																							required: true,
																							message: 'Nome inválido.'
																						}
																					]}
																				>
																					<Input
																						placeholder="Nome completo"
																						type="text"
																					></Input>
																				</Form.Item>
																				<Form.Item
																					label="CPF do Cônjuge"
																					name={['spouse_cpf', index]}
																					initialValue={responsible.spouse_cpf}
																					rules={[
																						{
																							required: true,
																							message: 'Informe o CPF'
																						},
																						() => ({
																							validator(_, value) {
																								if (!value || cpfValidator.isValid(value)) {
																									return Promise.resolve()
																								}

																								return Promise.reject(
																									new Error('CPF inválido.')
																								)
																							}
																						})
																					]}
																				>
																					<MaskedInput mask={mask.cpf}></MaskedInput>
																				</Form.Item>
																			</IdentedField>
																		)
																		: null}
																	<Form.Item
																		label="CEP"
																		name={['addr_zipcode', index]}
																		initialValue={responsible.addr_zipcode}
																		rules={[
																			{
																				required: true,
																				message: 'CEP inválido.'
																			}
																		]}
																	>
																		{useCep[index] ? <MaskedInput
																			autoComplete="false"
																			mask={mask.cep}
																		></MaskedInput> : <Input placeholder="Código Postal" type="text"></Input>}
																	</Form.Item>
																	<Row justify="space-between">
																		<Col sm={18}>
																			<Form.Item
																				label="Logradouro"
																				name={['addr_public_place', index]}
																				initialValue={responsible.addr_public_place}
																				rules={[
																					{
																						required: true,
																						message: 'Endereço inválido.'
																					}
																				]}
																			>
																				<Input placeholder="Informar" type="text"></Input>
																			</Form.Item>
																		</Col>
																		<Col sm={5}>
																			<Form.Item
																				label="Número"
																				name={['addr_number', index]}
																				initialValue={responsible.addr_number}
																				rules={[
																					{
																						required: true,
																						message: 'Número inválido.'
																					}
																				]}
																			>
																				<Input type="text"></Input>
																			</Form.Item>
																		</Col>
																	</Row>
																	<Row justify="space-between">
																		<Form.Item
																			label="Bairro"
																			name={['addr_neighborhood', index]}
																			initialValue={responsible.addr_neighborhood}
																			rules={[
																				{
																					required: true,
																					message: 'Bairro inválido.'
																				}
																			]}
																		>
																			<Input placeholder="Informar" type="text"></Input>
																		</Form.Item>
																		<Form.Item
																			label="Complemento"
																			name={['addr_complement', index]}
																			initialValue={responsible.addr_complement}
																			rules={[
																				{
																					message: 'Complemento inválido.'
																				}
																			]}
																		>
																			<Input placeholder="Informar" type="text"></Input>
																		</Form.Item>
																	</Row>
																	<Row justify="space-between">
																		<Col sm={15}>
																			<Form.Item
																				label="Cidade"
																				name={['addr_city_name', index]}
																				initialValue={responsible.addr_city_name}
																				rules={[
																					{
																						required: true,
																						message: 'Cidade inválido.'
																					}
																				]}
																			>
																				<Input
																					disabled={useCep[index]}
																					placeholder="Informar"
																					type="text"
																				></Input>
																			</Form.Item>
																		</Col>
																		<Col sm={5}>
																			<Form.Item
																				label="Estado"
																				name={["addr_province", index]}
																				initialValue={responsible.addr_province}
																				rules={[
																					{
																						required: true,
																						message: "Estado inválido.",
																					},
																				]}
																			>
																				{useCep[index] ? (
																					<Select
																						disabled={useCep[index]}
																						placeholder="Informar"
																						showSearch
																						filterOption={(input, option) =>
																							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																						}
																					>
																						{BrazilStates.map((item) => (
																							<Option key={item.key} value={item.key}>
																								{item.value}
																							</Option>
																						))}
																					</Select>
																				) : (
																					<Input
																						disabled={useCep[index]}
																						placeholder="Informar"
																						type="text" />
																				)}
																			</Form.Item>
																		</Col>
																	</Row>
																	<p className="subtitle">Dados Bancários</p>
																	<Form.Item
																		label="Banco"
																		name={['bank_name', index]}
																		initialValue={responsible.bank_name}
																		rules={[
																			{
																				required: true,
																				message: 'Nome do Banco inválido.'
																			}
																		]}
																	>
																		<Select
																			showSearch
																			onChange={(value) => {
																				const bank = BankList.filter(item => item.value === value)[0].key.split(" | ")
																				form.setFieldsValue({ bank_code: bank[0] })
																			}}
																			filterOption={(input, option) =>
																				option.children
																					.toLowerCase()
																					.indexOf(input.toLowerCase()) >= 0
																			}
																		>
																			{BankList.map((item) => (
																				<Option key={item.key} value={item.value}>
																					{item.key}
																				</Option>
																			))}
																		</Select>
																	</Form.Item>
																	<Form.Item hidden={true} initialValue={responsible.bank_code} name={["bank_code", index]}>
																		<Input></Input>
																	</Form.Item>
																	<Row justify="space-between">
																		<Form.Item
																			label="Agência"
																			name={['agency_number', index]}
																			initialValue={responsible.agency_number}
																			rules={[
																				{
																					required: true,
																					message: 'Cidade inválido.'
																				}
																			]}
																		>
																			<Input placeholder="Informar" type="text"></Input>
																		</Form.Item>
																		<Form.Item
																			label="Número da conta com dígito"
																			name={['bank_account_number', index]}
																			initialValue={responsible.bank_account_number}
																			rules={[
																				{
																					required: true,
																					message: 'Cidade inválido.'
																				}
																			]}
																		>
																			<Input placeholder="Informar" type="text"></Input>
																		</Form.Item>
																	</Row>
																</Card>
															</Col>
															<Col sm={12}>
																<Card>
																	<p className="subtitle">Dados Autodeclarados</p>
																	<Form.Item
																		name={['is_public_agent', index]}
																		label={<>É agente/funcionário(a) público?</>}
																		initialValue={responsible.is_public_agent}
																		rules={[
																			{
																				required: true,
																				message: 'Seleção Obrigatória.'
																			}
																		]}
																	>
																		<Select
																			onSelect={(value) =>
																				updateState(`${index}publicAgent`, value)
																			}
																		>
																			<Option value={true}>Sim</Option>
																			<Option value={false}>Não</Option>
																		</Select>
																	</Form.Item>
																	{state[`${index}publicAgent`]
																		? (
																			<IdentedField>
																				<Form.Item
																					label="CNPJ"
																					name={['public_agent_cnpj', index]}
																					initialValue={responsible.public_agent_cnpj}
																					rules={[
																						{
																							required: true,
																							message: 'Informe o CNPJ'
																						},
																						() => ({
																							validator(_, value) {
																								if (!value || cnpjValidator.isValid(value)) {
																									return Promise.resolve()
																								}

																								return Promise.reject(
																									new Error('CNPJ inválido.')
																								)
																							}
																						})
																					]}
																				>
																					<MaskedInput mask={mask.cnpj}></MaskedInput>
																				</Form.Item>
																				<Form.Item
																					label="Ocupação"
																					name={['public_agent_occupation', index]}
																					initialValue={responsible.public_agent_occupation}
																					rules={[
																						{
																							required: true,
																							message: 'Valor inválido.'
																						}
																					]}
																				>
																					<Input placeholder="Informe" type="text"></Input>
																				</Form.Item>
																				<Row justify="space-between">
																					<Form.Item
																						label="Data de Início"
																						name={['public_agent_start_date', index]}
																						initialValue={toBrazilianDateString(
																							responsible.public_agent_start_date
																						)}
																						rules={[
																							{
																								required: true,
																								message: 'Data obrigatória'
																							},
																							() => ({
																								validator(_, value) {
																									const dateOnlyNumbers = value.replace(
																										/\D/g,
																										''
																									)
																									const date = moment(value, 'DD/MM/YYYY')
																									if (
																										dateOnlyNumbers.length < 8 ||
																										!date.isValid()
																									) {
																										return Promise.reject(
																											new Error('Data inválida')
																										)
																									} else {
																										return Promise.resolve()
																									}
																								}
																							})
																						]}
																					>
																						<MaskedInput mask={mask.date}></MaskedInput>
																					</Form.Item>
																					<Form.Item
																						label="Data de Término"
																						initialValue={toBrazilianDateString(
																							responsible.public_agent_end_date
																						)}
																						name={['public_agent_end_date', index]}
																					>
																						<MaskedInput mask={mask.date}></MaskedInput>
																					</Form.Item>
																				</Row>
																			</IdentedField>
																		)
																		: null}
																	<Form.Item
																		name={['has_public_agent_link', index]}
																		initialValue={responsible.has_public_agent_link}
																		label="Possui relação com Agente/Pessoa Público(a)?"
																		rules={[
																			{
																				required: true,
																				message: 'Seleção Obrigatória.'
																			}
																		]}
																	>
																		<Select
																			onSelect={(value) => {
																				updateState(`${index}publicLink`, value)
																			}}
																		>
																			<Option value={true}>Sim</Option>
																			<Option value={false}>Não</Option>
																		</Select>
																	</Form.Item>
																	{state[`${index}publicLink`]
																		? (
																			<IdentedField>
																				<Form.Item
																					label="Nome completo do Agente/Pessoa"
																					name={['public_agent_link_name', index]}
																					initialValue={responsible.public_agent_link_name}
																					rules={[
																						{
																							required: true,
																							message: 'Nome inválido.'
																						}
																					]}
																				>
																					<Input placeholder="Informar" type="text"></Input>
																				</Form.Item>
																				<Form.Item
																					label="CPF do(a) Agente/Pessoa"
																					name={['public_agent_link_cpf', index]}
																					initialValue={responsible.public_agent_link_cpf}
																					rules={[
																						{
																							required: true,
																							message: 'CPF inválido.'
																						}
																					]}
																				>
																					<MaskedInput mask={mask.cpf}></MaskedInput>
																				</Form.Item>
																				<Form.Item
																					label="Ocupação do(a) Agente/Pessoa"
																					name={['public_agent_link_occupation', index]}
																					initialValue={
																						responsible.public_agent_link_occupation
																					}
																					rules={[
																						{
																							required: true,
																							message: 'Ocupação inválida.'
																						}
																					]}
																				>
																					<Input placeholder="Informar" type="text"></Input>
																				</Form.Item>
																				<Form.Item
																					label="Tipo do relacionamento com o(a) Agente/Pessoa"
																					name={['public_agent_link_type', index]}
																					initialValue={responsible.public_agent_link_type}
																					rules={[
																						{
																							required: true,
																							message: 'Relacionamento inválido.'
																						}
																					]}
																				>
																					<Input placeholder="Informar" type="text"></Input>
																				</Form.Item>
																			</IdentedField>
																		)
																		: null}
																	<Form.Item
																		name={['is_us_person', index]}
																		initialValue={responsible.is_us_person}
																		label={
																			<>
																				É US Person?{' '}
																				<TooltipEfex title="Cidadania norte-americana, incluindo os detentores de dupla nacionalidade e passaporte norte-americano, ainda que residam fora dos Estados Unidos Residência fiscal nos EUA, ou seja, que possuam Green Card e que possa se tornar um residente permanente legal Presença física nos Estados Unidos para fins tributários por pelo menos 31 dias durante o ano corrente ou 183 dias nos últimos 3 anos." />
																			</>
																		}
																		rules={[
																			{
																				required: true,
																				message: 'Seleção Obrigatória.'
																			}
																		]}
																	>
																		<Select>
																			<Option value={true}>Sim</Option>
																			<Option value={false}>Não</Option>
																		</Select>
																	</Form.Item>
																	<Form.Item
																		name={['has_link_with_bs2', index]}
																		initialValue={responsible.has_link_with_bs2}
																		label={
																			<>
																				Possui relação com a Conecta Corretora?{' '}
																				<TooltipEfex title="É colaborador direto/indireto da Conecta Corretora" />
																			</>
																		}
																		rules={[
																			{
																				required: true,
																				message: 'Seleção Obrigatória.'
																			}
																		]}
																	>
																		<Select>
																			<Option value={true}>Sim</Option>
																			<Option value={false}>Não</Option>
																		</Select>
																	</Form.Item>
																</Card>
															</Col>
														</Row>
														<br />
														<DelFRModal
															isDelModalVisible={isDelModalVisible}
															setIsDelModalVisible={setIsDelModalVisible}
															companyId={match.params.id}
															frId={responsible.id}
															responsible={responsible}
														/>
													</div>
												)
											}) : "Ainda não cadastrados"}
										</TabPane>

										<TabPane tab="Arquivos" key="2">
											<ListDocuments companyId={match.params.id} cnpj={companyId.company.cnpj} />
										</TabPane>
										<TabPane tab="Assinaturas" key="3">
											<ListSignatures cnpj={companyId.company.cnpj} />
										</TabPane>
										<TabPane tab="Dados de Câmbio" key="4">
											<ListTaxes cpfCnpj={companyId.company.cnpj} />
										</TabPane>
									</Tabs>
								</Card>
							</Form>
						</>
							: null}

					</Content>
		}
		<CompanyRegisterUpdate
			isModalVisible={showAttModal}
			setIsModalVisible={setShowAttModal}
			company={companyId.company}
		/>
		<ReviewClientModal
			isVisible={showApproveModal}
			isLoading={companyId.status.boReview === stateStatus.loading}
			onCancel={() => setShowApproveModal(false)}
			onSubmit={(values) => {
				dispatch(postBoReview({
					id: match.params.id,
					data: values,
				})).then(() => setShowApproveModal(false))
			}}
			isSimple={companyId?.company?.is_movement_less_15}
			operationalLimit={operationalLimit}
		/>
	</Base >
}

Company.propTypes = {
	match: PropTypes.object
}

export default Company

const TypeSelector = (index, initialValue) => (
	<Form.Item
		name={['doc_type', index]}
		initialValue={initialValue}
		rules={[
			{
				required: true,
				message: 'Valor inválido.'
			}
		]}
		noStyle
	>
		<Select placeholder="Escolher">
			<Option value="CNH">CNH</Option>
			<Option value="RG">RG</Option>
			<Option value="RNE">RNE</Option>
		</Select>
	</Form.Item>
)
