import React, { useState } from "react";
import PropTypes from 'prop-types';

import { Form, Input, Card, Typography, Row, Col, Select, InputNumber } from "antd";

import IdentedField from "../../../../../common/components/IdentedField";

import { purposeOptionsPerson, countriesList } from "../../../../../utils/consts";

import "./styles.scss";
import { formatCurrency } from "../../../../../utils/functions";
import TooltipEfex from "../../../../../common/components/Tooltip";

const { Title } = Typography;
const { Option } = Select;

const KycPF = ({isMovLess, setIsMovLess, form, ...props}) => {
	const [value, setValue] = useState([]);
	const [isOther, setIsOther] = useState(false);

	const selectProps = {
		mode: "multiple",
		style: {
			width: "100%",
		},
		options: purposeOptionsPerson,
		value,
		onChange: (newValue) => {
			setValue(newValue);
			if (newValue.includes(13)) {
				setIsOther(true);
			} else {
				setIsOther(false);
			}
		},
		placeholder: "Selecionar propósitos",
	};

	return (
		<>
			<Row gutter={[16, 16]} className="row-empresa-endereco">
				<Col span={12}>
					<Card>
						<Title level={5}>Dados Complementares</Title>
						<Form.Item
							name="economic_activity"
							label="Conte-nos um pouco mais sobre sua atividade econômica"
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input.TextArea placeholder="" />
						</Form.Item>

						<Form.Item
							name="reason_for_exchange"
							label="Finalidade das remessas"
							rules={[
								{
									required: true,
									message: "Informe a finalidade",
								},
							]}
						>
							<Input placeholder="Finalidade" />
						</Form.Item>

						<Form.Item
							name="is_movement_less_15"
							label={
								<>
									Movimentação inferior a US$
									15.000,00 por ano (ou equivalente em
									outras moedas)?
									<TooltipEfex
										title="Ao marcar essa opção, 
									você não precisará nos encaminhar os documentos 
									financeiros da empresa e o cadastro prosseguirá 
									com limite transacional de até US$ 15.000,00 
									(quinze mil dólares) por ano."
									/>
								</>
							}
							rules={[
								{
									required: true,
									message: "Esse campo é obrigatório",
								},
							]}
						>
							<Select
								onChange={(v) => {
									setIsMovLess(v);
									form.setFieldValue(
										"estimated_values",
										1500000
									);
								}}
							>
								<Option value={true}>Sim</Option>
								<Option value={false}>Não</Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="estimated_values"
							label="Valor aproximado, em dólares, a ser enviado e recebido anualmente"
							rules={[
								{
									required: true,
									message: "Informe a estimativa",
								},
								() => ({
									validator(_, value) {
										if (
											value != null &&
											(!isMovLess ? value > 1500000 : value > 1499999)
										) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"Valor deve deve ser maior que 15 mil"
											)
										);
									},
								}),
							]}
						>
							<InputNumber
								className="input-value-exchange"
								min={1}
								disabled={isMovLess}
								formatter={(value) =>
									formatCurrency(value, "US$")
								}
								parser={(money) =>
									money.replace(/\D/g, "")
								}
							></InputNumber>
						</Form.Item>

						<Form.Item
							name="payers_and_buyers_country"
							label="Países dos pagadores e recebedores"
							rules={[
								{
									required: true,
									message: "Informe o(s) país(es)",
								},
							]}
						>
							<Select
								mode="multiple"
								style={{ width: "100%" }}
								placeholder="Países dos pagadores e recebedores"
								optionLabelProp="label"
							>
								{countriesList.map((item) => {
									return (
										<Option
											key={item.pais}
											value={item.pais}
										>
											{item.pais}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Card>
				</Col>
				<Col span={12} className="purpose-col">
					<Card>
						<Title level={5}>
							Propósito da relação de negócio
						</Title>
						<Form.Item
							name="purposes_exchange"
							style={{
								width: "100%",
							}}
							rules={[
								{
									required: true,
									message: "Selecione o propósito",
								},
							]}
						>
							<Select {...selectProps} />
						</Form.Item>
						{isOther ? (
							<p className="subtitle">Outros:</p>
						) : null}
						<IdentedField>
							<Form.Item
								hidden={!isOther}
								name="other_field"
								label=""
								rules={[
									{
										required: isOther,
										message: "Campo obrigatório",
									},
								]}
							>
								<Input.TextArea placeholder="" />
							</Form.Item>
						</IdentedField>
					</Card>
				</Col>
			</Row>
		</>
	);
};

KycPF.propTypes = {
	isMovLess: PropTypes.bool,
	setIsMovLess: PropTypes.any,
	form: PropTypes.any
}

export default KycPF;
