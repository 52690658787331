import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
	Form,
	Card,
	Row,
	Col,
	Tabs,
	Layout,
	Button,
	Divider,
	Tooltip,
	Typography,
	Collapse,
	Checkbox,
	InputNumber,
} from "antd";
import {
	CheckCircleOutlined,
	SaveOutlined,
	LoadingOutlined,
	FileTextOutlined,
	EditOutlined
} from "@ant-design/icons";
import { cnpj as cnpjValidator, cpf as cpfValidator } from "cpf-cnpj-validator";

import Base from "../../../common/components/Base";
import { toBrazilianDateString } from "../../../utils/date";
import stateStatus from "../../../utils/status";
import ErroMsg from "../../../common/components/ErrorMsg";

import { selectPerson, getPerson, updatePerson, sendToSignDocs, postBoReview, resetStepsStatus } from "./slice";
import PersonBread from "./components/PersonBread";
import CadastraData from './components/CadastralData';
import KycPF from './components/KycPF';
import { formatCurrency, strFormatTimeApi } from '../../../utils/functions';
import ListPersonDocuments from './components/ListDocuments';
import "./styles.scss";
import SignedDocs from './components/SingedDocs';
import ListTaxes from "../../Exchange/ListTaxes";
import ReviewClientModal from "../../../common/components/ReviewClientModal";
import { listDocuments } from "./components/ListDocuments/slice";
import { personRegisteStatusCheckBoxOptions } from "../../../utils/consts";
import PersonRegisterUpdate from "../components/PersonRegisterUpdate";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse

const Person = ({ match, ...props }) => {
	const [form] = Form.useForm();
	const [maskPhone, setmaskPhone] = useState("(11) 11111-1111");
	const [showApproveModal, setShowApproveModal] = useState(false);
	const person = useSelector(selectPerson);
	const [valueCountryNumber, setValueCountryNumber] = useState({ short: 'BR', code: 55, phone: null });
	const [useCep, setUseCep] = useState(true);
	const [isMovLess, setIsMovLess] = useState(false);
	const [finished, setFinished] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [checkedList, setCheckedList] = useState([])
	const [showAttModal, setShowAttModal] = useState(false)
	const [operationalLimit, setOperationalLimit] = useState(null)
	const [isChangingOperationalLimit, setIsChangingOperationalLimit] = useState(false)

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPerson({ id: match.params.id }))
	}, []);

	useEffect(() => {
		person?.person?.operational_limit > 0 && setOperationalLimit(person?.person?.operational_limit)
	}, [person.person])

	useEffect(() => {
		if (person.status.boReview === stateStatus.succeeded) {
			dispatch(resetStepsStatus())
			dispatch(getPerson({ id: match.params.id }))
		}
	}, [person.status.boReview])

	useEffect(() => {
		if (person.status.sendToSignDocs === stateStatus.succeeded) {
			dispatch(resetStepsStatus())
			dispatch(getPerson({ id: match.params.id }))
		}
	}, [person.status.sendToSignDocs])

	useEffect(() => {
		if (person.status.getPerson === stateStatus.succeeded) {
			form.setFieldsValue({
				...person.person,
			});

			let data = {
				...person.person?.cadastral_data,
				birthday:
					person.person.cadastral_data?.birthday !== undefined
						? toBrazilianDateString(person.person.cadastral_data.birthday)
						: null,
				doc_shipping_date:
					person.person.cadastral_data?.doc_shipping_date !== undefined
						? toBrazilianDateString(person.person.cadastral_data.doc_shipping_date)
						: null,
				public_agent_end_date:
					person.person.cadastral_data?.public_agent_end_date !== undefined
						? toBrazilianDateString(person.person.cadastral_data.public_agent_end_date)
						: null,
				public_agent_start_date:
					person.person.cadastral_data?.public_agent_start_date !== undefined
						? toBrazilianDateString(person.person.cadastral_data.public_agent_start_date)
						: null,
				payers_and_buyers_country: person.person?.payers_and_buyers_country ?? [],
				purposes_exchange: person.person?.purposes_exchange ?? [],
			}
			if (!_.isUndefined(data['cell_phone']) && data.cell_phone?.includes('-')) {
				let phone = data.cell_phone.split('-');
				setValueCountryNumber({ code: parseInt(phone[0]), phone: phone[1] });
			} else {
				setValueCountryNumber({ short: 'BR', code: 55, phone: data.cell_phone });
			}

			if (!_.isUndefined(data['residential_country']) && data.residential_country.toLowerCase() === 'brasil') {
				setUseCep(true);
			} else {
				setUseCep(false);
			}

			if (!_.isUndefined(data['residential_country'])) {
				setFinished(true)
			} else if (_.isUndefined(data['residential_country'])) {
				setFinished(false)
			}

			if (!_.isUndefined(data['residential_country'])) {
				setFinished(true)
			} else if (_.isUndefined(data['residential_country'])) {
				setFinished(false)
			}

			setIsMovLess(person.person.is_movement_less_15)
			form.setFieldsValue(data)
		}
		setTimeout(() => {
			setIsLoading(false)
		}, 3000)
	}, [person.status.getPerson]);

	useEffect(() => {
		if (person.status.updatePerson === stateStatus.succeeded) {
			dispatch(resetStepsStatus())
			dispatch(getPerson({ id: match.params.id }));
		}
	});

	// useEffect(() => {
	// 	if (person.status.boReview === stateStatus.succeeded && isReviewing) {
	// 		setisReviewing(false);
	// 	}
	// });

	useEffect(() => {
		const disabledItems = personRegisteStatusCheckBoxOptions
			.filter((option) => isOptionSelected(option.value))
			.map((option) => option.value);
		setCheckedList(disabledItems)
	}, [person.person?.register_steps])

	const onChangeCheckbox = (checkedValues) => {
		const disabledItems = personRegisteStatusCheckBoxOptions
			.filter((option) => isOptionSelected(option.value))
			.map((option) => option.value);

		const allCheckedItems = new Set([...disabledItems, ...checkedValues]);
		setCheckedList(Array.from(allCheckedItems));
	};

	const isOptionSelected = (value) => {
		return person.person?.register_steps.some(step => step.key === value);
	};

	const checkboxOptions = personRegisteStatusCheckBoxOptions.map(option => ({
		...option,
		disabled: isOptionSelected(option.value)
	}));

	const onFinish = (values) => {
		let data = {}
		let cadastral_data = {}
		let kyc = {}
		// data = values;
		kyc = values
		cadastral_data = values
		cadastral_data.is_pep = values.is_public_agent;
		cadastral_data.birthday = strFormatTimeApi(values.birthday);
		cadastral_data.doc_shipping_date = strFormatTimeApi(values.doc_shipping_date);
		cadastral_data.addr_zipcode = values.addr_zipcode.replace(/[^a-zA-Z0-9]/g, '');
		cadastral_data.cell_phone = `${valueCountryNumber.code}-${valueCountryNumber.phone}`
		if (values.spouse_cpf !== undefined)
			cadastral_data.spouse_cpf = cpfValidator.strip(values.spouse_cpf);

		if (values.public_agent_cnpj !== undefined)
			cadastral_data.public_agent_cnpj = cnpjValidator.strip(
				values.public_agent_cnpj,
			);
		if (values.is_public_agent) {
			cadastral_data["public_ocupation_details"] = {
				public_agent_occupation: values.public_agent_occupation,
				public_agent_start_date: strFormatTimeApi(
					values.public_agent_start_date,
				),
				public_agent_end_date: values.public_agent_end_date ?
					strFormatTimeApi(values.public_agent_end_date)
					: null,
				public_agent_cnpj: cnpjValidator.strip(
					values.public_agent_cnpj,
				),
			};
			delete cadastral_data.public_agent_occupation;
			delete cadastral_data.public_agent_start_date;
			delete cadastral_data.public_agent_end_date;
			delete cadastral_data.public_agent_cnpj;
		}
		if (values.has_public_agent_link) {
			cadastral_data["public_agent_link_details"] = {
				public_agent_link_name: values.public_agent_link_name,
				public_agent_link_cpf: values.public_agent_link_cpf,
				public_agent_link_occupation:
					values.public_agent_link_occupation,
				public_agent_link_type: values.public_agent_link_type,
			};

			delete cadastral_data.public_agent_link_name;
			delete cadastral_data.public_agent_link_cpf;
			delete cadastral_data.public_agent_link_occupation;
			delete cadastral_data.public_agent_link_type;
		}

		if (cadastral_data.father_name === "") cadastral_data.father_name = null;
		if (cadastral_data.addr_complement === "") delete cadastral_data.addr_complement;
		data.cadastral_data = cadastral_data

		if (kyc.reason_for_exchange) {
			if (kyc.other_field === undefined) delete kyc.other_field;
			data.kyc = kyc
		}
		if (checkedList) {
			data.register_steps = checkedList
		}
		dispatch(updatePerson({ id: match.params.id, data: data })).then((resp) => {
			if (resp.payload) {
				dispatch(listDocuments({ id: match.params.id }))
			}
		})
	};

	const onChangeForm = (values) => {
		if (!_.isUndefined(values.residential_country)) {
			if (values.residential_country.toLowerCase() === 'brasil') {
				setUseCep(true)
			} else {
				setUseCep(false)
			}
		}
		const zipcode = values?.addr_zipcode
		setFinished(!zipcode ? false : true);
		// const phone = cnpjValidator.strip(values.phone);

		// if (phone.charAt(2) === "9") {
		// 	setmaskPhone("(11) 11111-1111");
		// } else {
		// 	setmaskPhone("(11) 1111-1111");
		// }
	};

	return (
		<Base>
			{isLoading ? (
				"Loading"
			) : person.status.getPerson === stateStatus.failed ? (
				"Erro: contate o suporte"
			) : (
				<Content className="person">
					{person.status.getPerson === stateStatus.succeeded ? (
						<>
							<Form
								form={form}
								layout="vertical"
								requiredMark={false}
								className="legal-person"
								onFinish={onFinish}
								// onFinishFailed={onFinishFailed}
								onValuesChange={onChangeForm}
								scrollToFirstError={true}
							>
								<PersonBread
									personName={person.person.name}
								/>
								<Col>
									<Divider />
									<Row>
										<Tooltip
											title={"Salvar"}
										>
											<Button
												className="update-register-btn"
												htmlType="submit"
												icon={
													person.status.updatePerson === stateStatus.loading
														? (
															<LoadingOutlined />
														) : (
															<SaveOutlined />
														)
												}
											>
												Salvar alterações
											</Button>
										</Tooltip>
										<Tooltip title={'Solicitar Assinaturas'}>
											<Button
												disabled={!["sended_person_docs", "waiting_sign_documents"].includes(person.person.register_step.key) || person.status.sendToSignDocs === stateStatus.loading}
												className="update-register-btn"
												onClick={() => dispatch(sendToSignDocs({ cpf: person.person.cpf }))}
												icon={person.status.sendToSignDocs === stateStatus.loading
													? <LoadingOutlined />
													: <FileTextOutlined />}
											>Solicitar Assinaturas</Button>
										</Tooltip>
										<Tooltip title={'Aprovar'}>
											<Button
												className="update-register-btn"
												disabled={person.person.register_step.key !== "signed_docs"}
												onClick={() => setShowApproveModal(true)}
												icon={person.status.boReview === stateStatus.loading
													? <LoadingOutlined />
													: <CheckCircleOutlined />}
											>Aprovar cadastro</Button>
										</Tooltip>
										<Tooltip title={"Atualizar"}>
											<Button
												className="update-register-btn"
												disabled={person.person.register_step.key !== "finalized"}
												onClick={() => setShowAttModal(true)}
												icon={<EditOutlined />}
											>

												Solicitar atualização de cadastro
											</Button>
										</Tooltip>
									</Row>
									<Divider />
									{person.status.updateCompanyRegister ===
										stateStatus.failed ||
										person.status.getPerson ===
										stateStatus.failed ||
										person.status.boReview ===
										stateStatus.failed
										? (<ErroMsg message={person.errorMsg} />) : null}
								</Col>
								<Card>

									<Tabs defaultActiveKey="1">
										<TabPane tab="Cadastro" key="1">
											<Row justify="space-between" >
												<Col>
													<Divider>
														<Typography.Title level={5}>
															Status do cadastro
														</Typography.Title>
													</Divider>
												</Col>
												<Col>
													<Divider>
														<Typography.Title level={5}>
															Limite Operacional
														</Typography.Title>
													</Divider>
												</Col>
											</Row>
											<Row justify="space-between">
												<Col span={16}>
													<Collapse style={{ width: "50%" }}>
														<Panel header="Status do cadastro" key="1" style={{ width: "100%" }}>
															<div className="checkbox-group-vertical">
																<Checkbox.Group
																	options={checkboxOptions}
																	value={checkedList}
																	onChange={onChangeCheckbox}
																	checked={true}
																/>
															</div>
														</Panel>
													</Collapse>
												</Col>
												<Col style={{ textAlign: 'left', gap: "16px", display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
													<InputNumber
														onChange={(value) => setOperationalLimit(value)}
														onClick={(event) => {
															if (event.target.value === "não definido") {
																setOperationalLimit(0)
															}
														}}
														value={!person?.person?.operational_limit && operationalLimit === null ? "não definido" : operationalLimit}
														style={{
															width: '100%',
														}}
														min={0}
														formatter={value => formatCurrency(value, null)}
														parser={money => money.replace(/\D/g, '')}
													/>
													<Button
														style={{
															width: '100%',
														}}
														onClick={() => {
															setIsChangingOperationalLimit(true)
															dispatch(updatePerson({
																id: match.params.id,
																data: { operational_limit: operationalLimit }
															})).then((resp) => {
																setIsChangingOperationalLimit(false)
																if (resp.payload) {
																	dispatch(getPerson({ id: match.params.id }))
																}
															})
														}}
														disabled={isChangingOperationalLimit ||
															operationalLimit === null ||
															operationalLimit === person?.person?.operational_limit}
														icon={
															isChangingOperationalLimit ? (
																<LoadingOutlined />
															) : (
																<SaveOutlined />
															)
														}
													>
														Alterar limite operacional
													</Button>
												</Col>
											</Row>

											<Divider orientation="left">
												<Typography.Title level={5}>
													Dados Cadastrais
												</Typography.Title>
											</Divider>
											<CadastraData
												form={form}
												person={person}
												valueCountryNumber={valueCountryNumber}
												onChangePhoneInput={(v) => {
													form.setFieldsValue({
														cell_phone: v,
													});
													setValueCountryNumber(v);
												}}
												onChangeCep={(value) => {
													if (value) {
														form.setFieldsValue({ 'addr_zipcode': value })
													}
												}}
												useCep={useCep}
												finished={finished}
												onChangeCountry={() => {
													setFinished(false)
												}}
												{...props} />
											<Divider orientation="left">
												<Typography.Title level={5}>
													Know Your Costumer
												</Typography.Title>
											</Divider>
											<KycPF isMovLess={isMovLess} setIsMovLess={setIsMovLess} form={form} />
										</TabPane>
										<TabPane tab="Arquivos" key="3">
											<ListPersonDocuments personId={person.person.id} cpf={person.person.cpf} />
										</TabPane>
										<TabPane tab="Assinaturas">
											<SignedDocs cpf={person.person.cpf} />
										</TabPane>
										<TabPane tab="Dados de Câmbio" key="6">
											<ListTaxes cpfCnpj={person.person.cpf} />
										</TabPane>
									</Tabs>
								</Card>
							</Form>
						</>
					) : null}
				</Content>
			)}
			<PersonRegisterUpdate
				isModalVisible={showAttModal}
				setIsModalVisible={setShowAttModal}
				person={person.person}
			/>
			<ReviewClientModal
				isVisible={showApproveModal}
				isLoading={person.status.boReview === stateStatus.loading}
				onCancel={() => setShowApproveModal(false)}
				onSubmit={(values) => {
					dispatch(postBoReview({
						id: match.params.id,
						data: values,
					})).then(() => setShowApproveModal(false))
				}}
				isSimple={person?.person?.is_movement_less_15}
				operationalLimit={operationalLimit}
			/>
		</Base>
	);
};

Person.propTypes = {
	match: PropTypes.object,
};

export default Person;