import Routes from '../routes/routes'
// import { useHistory } from 'react-router';
export const strFormatTime = (str) => {
	// str.replace('-', '/');
	const aux = str.split('-')

	return `${aux[2]}/${aux[1]}/${aux[0]}`
}

export const strFormatTimeApi = (str) => {
	// str.replace('-', '/');
	var aux = str.split("/");

	return `${aux[2]}-${aux[1]}-${aux[0]}`;
};


export const parseCurrencyStrToFloat = (str) => {
	let val = str
	if (typeof str === 'string') {
		val = str.replace(/[^0-9.-]+/g, '')
		if (val.includes('.')) {
			val = val.replace('.', '')
		}
		return Number(val)
	} else {
		return parseInt(val * 100)
	}
}

export const sizedNullList = (size) => {
	const resultList = []
	for (let i = 0; i <= size; i++) {
		resultList.push(null)
	}
	return resultList
}

export const CheckSteps = (history) => {
	// const history = useHistory()
	const steps = JSON.parse(localStorage.getItem('register_steps'))
	if (steps === null || localStorage.getItem('access_token') === undefined) {
		history.push(Routes.login)
	} else if (!steps.completed_company_data) {
		history.push(Routes.legalPerson)
	} else if (!steps.completed_financial_responsible) {
		history.push(Routes.selectPartners)
	} else if (!steps.accepted_review) {
		history.push(Routes.confirmRegister)
	} else if (!steps.sended_company_docs || !steps.sended_qsa_docs) {
		history.push(Routes.uploadFile)
	} else {
		history.push(Routes.dashboard)
	}
}

export const scrollUp = () => {
	window.scrollTo({ top: 0 })
}

export const hideDigits = (number) => {
	if (number.length === 14) {
		return `***.***.${number.substring(8, 11)}-**`
	} else {
		return `**.***.${number.substr(7, 10)}/****-**`
	}
}

export const maskPhone = (str) => {
	return `(${str.substring(0, 2)}) ${str.substring(2, 7)}-${str.substring(7)}`
}

const currencyPrefix = {
	BRL: "BRL",
	USD: "USD",
	CAD: "CAD",
	EUR: "EUR",
	GBP: "GBP",
	CHF: "CHF",
	JPY: "JPY",
};

export const formatCurrencyPatrimony = (val, prefix) => {
	if(val){
		if (val.length <= 2) {
			if(val.includes('-')){
				return `${prefix} -0,${val.padStart(2, 0).replace('-', '')}`;
			}else{
				return `${prefix} 0,${val.padStart(2, 0)}`;
			}
		}
		if (val.length <= 3 && val.includes('-')){
			return `${prefix} -0,${val.padStart(2, 0).replace('-', '')}`;
		}
		
		const prefixValue = val
		.slice(0, -2)
		.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	
		return `${prefix} ${prefixValue},${val.slice(-2).replace(/\.(?=\d{0,2}$)/g, ",")}`;
	}
}

export const formatCurrency = (initalValue, currency) => {
	if (typeof initalValue === "string") {
		initalValue = initalValue.replace(new RegExp(/[.,]/g), "");
	}

	if (
		initalValue === undefined ||
		initalValue === null ||
		initalValue === "" ||
		isNaN(initalValue)
	) {
		initalValue = 0;
	}

	const value = parseInt(initalValue).toString();
	const prefix = currencyPrefix[currency] ?? currency ?? "$";

	if (value.length === 0) return "";
	if (value.length <= 2) return `${prefix} 0,${value.padStart(2, 0)}`;

	const prefixValue = value
		.slice(0, -2)
		.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return `${prefix} ${prefixValue},${value.slice(-2)}`;
};

export const formatCurrencyStr = (value, numDigitsDecimal) => {
	if (!value) value = 0
	value = value.toString()
	if (value.length === 0) value = "0"
	if (value.length <= numDigitsDecimal) return `0,${value.padStart(numDigitsDecimal, 0)}`

	const prefixValue = value.slice(0, -numDigitsDecimal).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
	return `${prefixValue},${value.slice(-numDigitsDecimal)}`
}


export const formatCurrencyBrl = (currency, value, numDigitsDecimal = 2) => {
	const valueStr = formatCurrencyStr(value, numDigitsDecimal)

	if (currency) {
		return `${currency}$ ${valueStr}`
	} else {
		return valueStr
	}
}

export const formatPorcent = (value, precision = 2) => {
	value = value.toString()
	if (value.length === 0) return ""
	if (value.length <= precision) return `0,${value.padStart(precision, 0)} %`

	const prefixValue = value.slice(0, -precision).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
	return `${prefixValue},${value.slice(-precision)} %`
}