import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Card, Typography, Row, Col } from 'antd'
import MaskedInput from 'antd-mask-input'
import CurrencyField from '../../../../../common/components/currency_field/currency_field'
import TooltipEfex from '../../../../../common/components/Tooltip'
import './styles.scss'
import { mask } from '../../../../../utils/consts'
const { Title } = Typography

const DataCompany = ({ maskPhone, ...props }) => {
	return (<>
		<Row gutter={[16, 16]} className="row-empresa-endereco">
			<Col>
				<Card>
					<Title level={5}>Empresa</Title>
					<Form.Item name="company_name" label="Razão Social">
						<Input placeholder="Razão Social" />
					</Form.Item>
					<Form.Item name="cnpj" label="CNPJ">
						<Input disabled={true} />
					</Form.Item>
					<Form.Item
						name="economic_activity"
						label="Atividade"
						rules={[
							{
								required: true,
								message: 'Atividade obrigatória'
							},
						]}
					>
						<Input.TextArea type="text" placeholder="Atividade" />
					</Form.Item>
					<Form.Item
						name="juridical_nature_description"
						label="Natureza Jurídica"
					>
						<Input placeholder="Natureza Jurídica" />
					</Form.Item>

					<Form.Item
						name="init_date"
						label="Data de fundação"
						rules={[
							{
								required: true,
								message: 'Orgão emissor obrigatório'
							},
						]}
					>
						<MaskedInput mask={mask.date}></MaskedInput>
					</Form.Item>

					<Form.Item
						name="country_name"
						label="País"
					>
						<Input placeholder="País" />
					</Form.Item>

					<Form.Item
						name="share_capital"
						label="Capital Social"
					>
						<CurrencyField />
					</Form.Item>

					<Form.Item
						name="monthly_invoicing"
						label="Faturamento últimos 12 meses"
						initialValue={null}
						rules={[
							{
								required: true,
								message: 'Informe o faturamento'
							}]}
					>
						<CurrencyField />
					</Form.Item>
					<Form.Item
						name="patrimony"
						label="Patrimônio Líquido"
						initialValue={null}
						rules={[
							{
								required: true,
								message: 'Informe o patrimônio'
							}]}
					>
						<CurrencyField acceptNegative={true} prefix="R$" />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						rules={[
							{
								required: true,
								message: 'Informe o email'
							},
							{
								type: 'email',
								message: 'Informe um email válido.'
							}
						]}
					>
						<Input type="email" placeholder="Email" />
					</Form.Item>
					<Form.Item
						name="phone_0"
						label="Telefone"
					>
						<MaskedInput mask={maskPhone}></MaskedInput>
					</Form.Item>
				</Card>
			</Col>
			<Col>
				<Card>
					<Title level={5}>Endereço</Title>
					<Form.Item
						name="zipcode"
						label="CEP"
					>
						<MaskedInput mask={mask.cep}></MaskedInput>
					</Form.Item>
					<Form.Item
						name="public_place"
						label="Logradouro"
					>
						<Input placeholder="Logradouro" />
					</Form.Item>
					<Form.Item
						name="number"
						label="Número"
					>
						<Input placeholder="Número" />
					</Form.Item>
					<Form.Item
						name="neighborhood"
						label="Bairro"
					>
						<Input placeholder="Bairro" />
					</Form.Item>

					<Form.Item name="complement" label="Complemento" initialValue="">
						<Input placeholder="Complemento" />
					</Form.Item>

					<Form.Item
						name="city_name"
						label="Cidade"
					>
						<Input placeholder="Cidade" />
					</Form.Item>
					<Form.Item
						name="province"
						label="Estado"
					>
						<Input placeholder="Estado" />
					</Form.Item>
				</Card>
				<Card className="observacao">
					<Title level={5} className="obs">
						Observação
						<TooltipEfex title="Utilize esse campo para nos contar caso alguma informação não esteja certa." />
					</Title>
					<Form.Item name="comments" initialValue="">
						<Input.TextArea placeholder="Caso alguma informação esteja inconsistente, informe neste campo" />
					</Form.Item>
				</Card>
			</Col>
		</Row>
	</>)
}

DataCompany.propTypes = {
	maskPhone: PropTypes.string.isRequired
}

export default DataCompany
