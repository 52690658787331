import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import stateStatus from "../../utils/status";
import { addUser, getUserById, listUsers, resetPasswordById, editUserById } from "./service";

const initialState = {
    status: {
        getUsers: stateStatus.idle,
        getUser: stateStatus.idle,
        resetPassword: stateStatus.idle,
        editUser: stateStatus.idle,
        registerUser: stateStatus.idle
    },
    list: {
        count: null,
        page: 1,
        total_pages: null,
        users: [],
    },
    user: null,
    errorMsg: null,
};

export const getUsers = createAsyncThunk(
    "users/listUsers",
    async ({ page, values }) => {
        const response = await listUsers(page, values);
        return response.data;
    }
);

export const getUser = createAsyncThunk("user/getUser", async ({ id }) => {
    const response = await getUserById(id);
    return response.data;
});

export const resetPassword = createAsyncThunk(
    "user/resetPassword",
    async ({ id, data }) => {
        const response = await resetPasswordById(id, data);
        return response.data;
    }
);

export const editUser = createAsyncThunk(
    "user/editUser",
    async ({ id, data }) => {
        const response = await editUserById(id, data);
        return response.data;
    }
);

export const registerUser = createAsyncThunk(
    "user/registerUser",
    async ({ data }) => {
        const response = await addUser(data);
        return response.data;
    }
);

export const users = createSlice({
    name: "users",
    initialState,
    extraReducers: {
        [getUsers.pending]: (state) => {
            state.status.getUsers = stateStatus.loading;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.status.getUsers = stateStatus.succeeded;
            state.list.users = [];
            state.list.users = action.payload.users;
            state.list.count = action.payload.count;
            state.list.page = action.payload.page;
            state.list.total_pages = action.payload.total_pages;
        },
        [getUsers.rejected]: (state, action) => {
            state.status.getUsers = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [getUser.pending]: (state) => {
            state.status.getUser = stateStatus.loading;
        },
        [getUser.fulfilled]: (state, action) => {
            state.status.getUser = stateStatus.succeeded;
            state.user = {};
            state.user = action.payload;
        },
        [getUser.rejected]: (state, action) => {
            state.status.getUser = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [resetPassword.pending]: (state) => {
            state.status.resetPassword = stateStatus.loading;
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.status.resetPassword = stateStatus.succeeded;
        },
        [resetPassword.rejected]: (state, action) => {
            state.status.resetPassword = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [editUser.pending]: (state) => {
            state.status.editUser = stateStatus.loading;
        },
        [editUser.fulfilled]: (state, action) => {
            state.status.editUser = stateStatus.succeeded;
        },
        [editUser.rejected]: (state, action) => {
            state.status.editUser = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [registerUser.pending]: (state) => {
            state.status.registerUser = stateStatus.loading;
        },
        [registerUser.fulfilled]: (state, action) => {
            state.status.registerUser = stateStatus.succeeded;
        },
        [registerUser.rejected]: (state, action) => {
            state.status.registerUser = stateStatus.failed;
            state.errorMsg = action.error.message;
        },
    },
});

export const selectUsers = (state) => state.users;
