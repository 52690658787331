import React, { useState } from 'react'
import { ArrowUpOutlined, DownloadOutlined, InboxOutlined, PlusOutlined, CloseCircleOutlined, FileDoneOutlined } from '@ant-design/icons'
import { Button, Card, Col, InputNumber, Row, Select, Tooltip, Typography, Form, Space } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { formatCurrencyBrl } from '../../../../utils/functions'
import Base from '../../../../common/components/Base'
import ExchangeBread from '../../_components/ExchangeBread'
import routes from '../../../../routes/routes'
import './styles.scss'
import ContactReceiveCard from '../../_components/ContactSendCard'
import TaxTable from '../../_components/TaxTable'
import ExchangeActions from '../../_components/ExchangeActions'
import SingleTemplate from '../../_components/SingleTemplate'

const { Option } = Select

const SingleClosed = ({ ...props }) => {
	return <>
		<SingleTemplate
			selectedKeys={['3']}
			currentName='João Silva'
			parentName='Operações Fechadas'
			parentRoute={routes.closedExchange}
			parentIcon={<FileDoneOutlined />}
			canEdit={false}
		/>
	</>
}

export default SingleClosed
