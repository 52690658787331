export const rfQualification = [{ label: 'Procurador', value: 'procurador' }, { label: 'Responsável Legal', value: 'resp-legal' }, { label: 'Sócio', value: 'socio' }]

export const BankList = [
	{
		"key": "001 | BANCO DO BRASIL S.A.",
		"value": "BANCO DO BRASIL S.A."
	},
	{
		"key": "003 | BANCO DA AMAZONIA S.A.",
		"value": "BANCO DA AMAZONIA S.A."
	},
	{
		"key": "004 | BANCO DO NORDESTE DO BRASIL S.A.",
		"value": "BANCO DO NORDESTE DO BRASIL S.A."
	},
	{
		"key": "007 | BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
		"value": "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL"
	},
	{
		"key": "010 | CREDICOAMO CREDITO RURAL COOPERATIVA",
		"value": "CREDICOAMO CREDITO RURAL COOPERATIVA"
	},
	{
		"key": "011 | CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A",
		"value": "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A"
	},
	{
		"key": "012 | BANCO INBURSA S.A.",
		"value": "BANCO INBURSA S.A."
	},
	{
		"key": "014 | STATE STREET BRASIL S.A. - BANCO COMERCIAL",
		"value": "STATE STREET BRASIL S.A. - BANCO COMERCIAL"
	},
	{
		"key": "015 | UBS BRASIL CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "UBS BRASIL CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "016 | COOPERATIVA DE CR\u00c9DITO M\u00daTUO DOS DESPACHANTES DE TR\u00c2NSITO DE SANTA CATARINA E RI",
		"value": "COOPERATIVA DE CR\u00c9DITO M\u00daTUO DOS DESPACHANTES DE TR\u00c2NSITO DE SANTA CATARINA E RI"
	},
	{
		"key": "017 | BNY MELLON BANCO S.A.",
		"value": "BNY MELLON BANCO S.A."
	},
	{
		"key": "018 | BANCO TRICURY S.A.",
		"value": "BANCO TRICURY S.A."
	},
	{
		"key": "021 | BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
		"value": "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO"
	},
	{
		"key": "024 | BANCO BANDEPE S.A.",
		"value": "BANCO BANDEPE S.A."
	},
	{
		"key": "025 | BANCO ALFA S.A.",
		"value": "BANCO ALFA S.A."
	},
	{
		"key": "029 | BANCO ITA\u00da CONSIGNADO S.A.",
		"value": "BANCO ITA\u00da CONSIGNADO S.A."
	},
	{
		"key": "033 | BANCO SANTANDER (BRASIL) S.A.",
		"value": "BANCO SANTANDER (BRASIL) S.A."
	},
	{
		"key": "036 | BANCO BRADESCO BBI S.A.",
		"value": "BANCO BRADESCO BBI S.A."
	},
	{
		"key": "037 | BANCO DO ESTADO DO PAR\u00c1 S.A.",
		"value": "BANCO DO ESTADO DO PAR\u00c1 S.A."
	},
	{
		"key": "040 | BANCO CARGILL S.A.",
		"value": "BANCO CARGILL S.A."
	},
	{
		"key": "041 | BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.",
		"value": "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A."
	},
	{
		"key": "047 | BANCO DO ESTADO DE SERGIPE S.A.",
		"value": "BANCO DO ESTADO DE SERGIPE S.A."
	},
	{
		"key": "060 | CONFIDENCE CORRETORA DE C\u00c2MBIO S.A.",
		"value": "CONFIDENCE CORRETORA DE C\u00c2MBIO S.A."
	},
	{
		"key": "062 | HIPERCARD BANCO M\u00daLTIPLO S.A.",
		"value": "HIPERCARD BANCO M\u00daLTIPLO S.A."
	},
	{
		"key": "063 | BANCO BRADESCARD S.A.",
		"value": "BANCO BRADESCARD S.A."
	},
	{
		"key": "064 | GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.",
		"value": "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A."
	},
	{
		"key": "065 | BANCO ANDBANK (BRASIL) S.A.",
		"value": "BANCO ANDBANK (BRASIL) S.A."
	},
	{
		"key": "066 | BANCO MORGAN STANLEY S.A.",
		"value": "BANCO MORGAN STANLEY S.A."
	},
	{
		"key": "069 | BANCO CREFISA S.A.",
		"value": "BANCO CREFISA S.A."
	},
	{
		"key": "070 | BRB - BANCO DE BRASILIA S.A.",
		"value": "BRB - BANCO DE BRASILIA S.A."
	},
	{
		"key": "074 | BANCO J. SAFRA S.A.",
		"value": "BANCO J. SAFRA S.A."
	},
	{
		"key": "075 | BANCO ABN AMRO S.A.",
		"value": "BANCO ABN AMRO S.A."
	},
	{
		"key": "076 | BANCO KDB DO BRASIL S.A.",
		"value": "BANCO KDB DO BRASIL S.A."
	},
	{
		"key": "077 | BANCO INTER S.A.",
		"value": "BANCO INTER S.A."
	},
	{
		"key": "078 | HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A.",
		"value": "HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A."
	},
	{
		"key": "079 | PICPAY BANK - BANCO M\u00daLTIPLO S.A",
		"value": "PICPAY BANK - BANCO M\u00daLTIPLO S.A"
	},
	{
		"key": "080 | B&T CORRETORA DE CAMBIO LTDA.",
		"value": "B&T CORRETORA DE CAMBIO LTDA."
	},
	{
		"key": "081 | BANCOSEGURO S.A.",
		"value": "BANCOSEGURO S.A."
	},
	{
		"key": "082 | BANCO TOP\u00c1ZIO S.A.",
		"value": "BANCO TOP\u00c1ZIO S.A."
	},
	{
		"key": "083 | BANCO DA CHINA BRASIL S.A.",
		"value": "BANCO DA CHINA BRASIL S.A."
	},
	{
		"key": "084 | UNIPRIME DO BRASIL - COOPERATIVA DE CR\u00c9DITO",
		"value": "UNIPRIME DO BRASIL - COOPERATIVA DE CR\u00c9DITO"
	},
	{
		"key": "085 | COOPERATIVA CENTRAL DE CR\u00c9DITO - AILOS",
		"value": "COOPERATIVA CENTRAL DE CR\u00c9DITO - AILOS"
	},
	{
		"key": "088 | BANCO RANDON S.A.",
		"value": "BANCO RANDON S.A."
	},
	{
		"key": "089 | CREDISAN COOPERATIVA DE CR\u00c9DITO",
		"value": "CREDISAN COOPERATIVA DE CR\u00c9DITO"
	},
	{
		"key": "092 | BRK S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "BRK S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "093 | P\u00d3LOCRED SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORT",
		"value": "P\u00d3LOCRED SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORT"
	},
	{
		"key": "094 | BANCO FINAXIS S.A.",
		"value": "BANCO FINAXIS S.A."
	},
	{
		"key": "095 | TRAVELEX BANCO DE C\u00c2MBIO S.A.",
		"value": "TRAVELEX BANCO DE C\u00c2MBIO S.A."
	},
	{
		"key": "096 | BANCO B3 S.A.",
		"value": "BANCO B3 S.A."
	},
	{
		"key": "097 | CREDISIS - CENTRAL DE COOPERATIVAS DE CR\u00c9DITO LTDA.",
		"value": "CREDISIS - CENTRAL DE COOPERATIVAS DE CR\u00c9DITO LTDA."
	},
	{
		"key": "098 | CREDIALIAN\u00c7A COOPERATIVA DE CR\u00c9DITO RURAL",
		"value": "CREDIALIAN\u00c7A COOPERATIVA DE CR\u00c9DITO RURAL"
	},
	{
		"key": "099 | UNIPRIME CENTRAL - CENTRAL NACIONAL DE COOPERATIVA DE CR\u00c9DITO LTDA.",
		"value": "UNIPRIME CENTRAL - CENTRAL NACIONAL DE COOPERATIVA DE CR\u00c9DITO LTDA."
	},
	{
		"key": "100 | PLANNER CORRETORA DE VALORES S.A.",
		"value": "PLANNER CORRETORA DE VALORES S.A."
	},
	{
		"key": "101 | RENASCENCA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
		"value": "RENASCENCA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
	},
	{
		"key": "102 | XP INVESTIMENTOS CORRETORA DE C\u00c2MBIO,T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S/A",
		"value": "XP INVESTIMENTOS CORRETORA DE C\u00c2MBIO,T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S/A"
	},
	{
		"key": "104 | CAIXA ECONOMICA FEDERAL",
		"value": "CAIXA ECONOMICA FEDERAL"
	},
	{
		"key": "105 | LECCA CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S/A",
		"value": "LECCA CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S/A"
	},
	{
		"key": "107 | BANCO BOCOM BBM S.A.",
		"value": "BANCO BOCOM BBM S.A."
	},
	{
		"key": "108 | PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "111 | OLIVEIRA TRUST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILIARIOS S.A.",
		"value": "OLIVEIRA TRUST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILIARIOS S.A."
	},
	{
		"key": "113 | NEON CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "NEON CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "114 | CENTRAL COOPERATIVA DE CR\u00c9DITO NO ESTADO DO ESP\u00cdRITO SANTO - CECOOP",
		"value": "CENTRAL COOPERATIVA DE CR\u00c9DITO NO ESTADO DO ESP\u00cdRITO SANTO - CECOOP"
	},
	{
		"key": "117 | ADVANCED CORRETORA DE C\u00c2MBIO LTDA",
		"value": "ADVANCED CORRETORA DE C\u00c2MBIO LTDA"
	},
	{
		"key": "119 | BANCO WESTERN UNION DO BRASIL S.A.",
		"value": "BANCO WESTERN UNION DO BRASIL S.A."
	},
	{
		"key": "120 | BANCO RODOBENS S.A.",
		"value": "BANCO RODOBENS S.A."
	},
	{
		"key": "121 | BANCO AGIBANK S.A.",
		"value": "BANCO AGIBANK S.A."
	},
	{
		"key": "122 | BANCO BRADESCO BERJ S.A.",
		"value": "BANCO BRADESCO BERJ S.A."
	},
	{
		"key": "124 | BANCO WOORI BANK DO BRASIL S.A.",
		"value": "BANCO WOORI BANK DO BRASIL S.A."
	},
	{
		"key": "125 | BANCO GENIAL S.A.",
		"value": "BANCO GENIAL S.A."
	},
	{
		"key": "126 | BR PARTNERS BANCO DE INVESTIMENTO S.A.",
		"value": "BR PARTNERS BANCO DE INVESTIMENTO S.A."
	},
	{
		"key": "127 | CODEPE CORRETORA DE VALORES E C\u00c2MBIO S.A.",
		"value": "CODEPE CORRETORA DE VALORES E C\u00c2MBIO S.A."
	},
	{
		"key": "128 | MS BANK S.A. BANCO DE C\u00c2MBIO",
		"value": "MS BANK S.A. BANCO DE C\u00c2MBIO"
	},
	{
		"key": "129 | UBS BRASIL BANCO DE INVESTIMENTO S.A.",
		"value": "UBS BRASIL BANCO DE INVESTIMENTO S.A."
	},
	{
		"key": "130 | CARUANA S.A. - SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "CARUANA S.A. - SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "131 | TULLETT PREBON BRASIL CORRETORA DE VALORES E C\u00c2MBIO LTDA",
		"value": "TULLETT PREBON BRASIL CORRETORA DE VALORES E C\u00c2MBIO LTDA"
	},
	{
		"key": "132 | ICBC DO BRASIL BANCO M\u00daLTIPLO S.A.",
		"value": "ICBC DO BRASIL BANCO M\u00daLTIPLO S.A."
	},
	{
		"key": "133 | CONFEDERA\u00c7\u00c3O NACIONAL DAS COOPERATIVAS CENTRAIS DE CR\u00c9DITO E ECONOMIA FAMILIAR E",
		"value": "CONFEDERA\u00c7\u00c3O NACIONAL DAS COOPERATIVAS CENTRAIS DE CR\u00c9DITO E ECONOMIA FAMILIAR E"
	},
	{
		"key": "134 | BGC LIQUIDEZ DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
		"value": "BGC LIQUIDEZ DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
	},
	{
		"key": "136 | CONFEDERA\u00c7\u00c3O NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI",
		"value": "CONFEDERA\u00c7\u00c3O NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI"
	},
	{
		"key": "138 | GET MONEY CORRETORA DE C\u00c2MBIO S.A.",
		"value": "GET MONEY CORRETORA DE C\u00c2MBIO S.A."
	},
	{
		"key": "139 | INTESA SANPAOLO BRASIL S.A. - BANCO M\u00daLTIPLO",
		"value": "INTESA SANPAOLO BRASIL S.A. - BANCO M\u00daLTIPLO"
	},
	{
		"key": "140 | NU INVEST CORRETORA DE VALORES S.A.",
		"value": "NU INVEST CORRETORA DE VALORES S.A."
	},
	{
		"key": "142 | BROKER BRASIL CORRETORA DE C\u00c2MBIO LTDA.",
		"value": "BROKER BRASIL CORRETORA DE C\u00c2MBIO LTDA."
	},
	{
		"key": "143 | TREVISO CORRETORA DE C\u00c2MBIO S.A.",
		"value": "TREVISO CORRETORA DE C\u00c2MBIO S.A."
	},
	{
		"key": "144 | BEXS BANCO DE C\u00c2MBIO S/A",
		"value": "BEXS BANCO DE C\u00c2MBIO S/A"
	},
	{
		"key": "145 | LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.",
		"value": "LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA."
	},
	{
		"key": "146 | GUITTA CORRETORA DE CAMBIO LTDA.",
		"value": "GUITTA CORRETORA DE CAMBIO LTDA."
	},
	{
		"key": "149 | FACTA FINANCEIRA S.A. - CR\u00c9DITO FINANCIAMENTO E INVESTIMENTO",
		"value": "FACTA FINANCEIRA S.A. - CR\u00c9DITO FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "157 | ICAP DO BRASIL CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "ICAP DO BRASIL CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "159 | CASA DO CR\u00c9DITO S.A. SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR",
		"value": "CASA DO CR\u00c9DITO S.A. SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR"
	},
	{
		"key": "163 | COMMERZBANK BRASIL S.A. - BANCO M\u00daLTIPLO",
		"value": "COMMERZBANK BRASIL S.A. - BANCO M\u00daLTIPLO"
	},
	{
		"key": "173 | BRL TRUST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "BRL TRUST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "174 | PEFISA S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "PEFISA S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "177 | GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES",
		"value": "GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES"
	},
	{
		"key": "180 | CM CAPITAL MARKETS CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
		"value": "CM CAPITAL MARKETS CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
	},
	{
		"key": "183 | SOCRED S.A. - SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO P",
		"value": "SOCRED S.A. - SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO P"
	},
	{
		"key": "184 | BANCO ITA\u00da BBA S.A.",
		"value": "BANCO ITA\u00da BBA S.A."
	},
	{
		"key": "188 | ATIVA INVESTIMENTOS S.A. CORRETORA DE T\u00cdTULOS, C\u00c2MBIO E VALORES",
		"value": "ATIVA INVESTIMENTOS S.A. CORRETORA DE T\u00cdTULOS, C\u00c2MBIO E VALORES"
	},
	{
		"key": "189 | HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS",
		"value": "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS"
	},
	{
		"key": "190 | SERVICOOP - COOPERATIVA DE CR\u00c9DITO DOS SERVIDORES P\u00daBLICOS ESTADUAIS E MUNICIPAI",
		"value": "SERVICOOP - COOPERATIVA DE CR\u00c9DITO DOS SERVIDORES P\u00daBLICOS ESTADUAIS E MUNICIPAI"
	},
	{
		"key": "191 | NOVA FUTURA CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "NOVA FUTURA CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "194 | PARMETAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
		"value": "PARMETAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
	},
	{
		"key": "195 | VALOR SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "VALOR SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "196 | FAIR CORRETORA DE CAMBIO S.A.",
		"value": "FAIR CORRETORA DE CAMBIO S.A."
	},
	{
		"key": "197 | STONE INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
		"value": "STONE INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
	},
	{
		"key": "208 | BANCO BTG PACTUAL S.A.",
		"value": "BANCO BTG PACTUAL S.A."
	},
	{
		"key": "212 | BANCO ORIGINAL S.A.",
		"value": "BANCO ORIGINAL S.A."
	},
	{
		"key": "213 | BANCO ARBI S.A.",
		"value": "BANCO ARBI S.A."
	},
	{
		"key": "217 | BANCO JOHN DEERE S.A.",
		"value": "BANCO JOHN DEERE S.A."
	},
	{
		"key": "218 | BANCO BS2 S.A.",
		"value": "BANCO BS2 S.A."
	},
	{
		"key": "222 | BANCO CR\u00c9DIT AGRICOLE BRASIL S.A.",
		"value": "BANCO CR\u00c9DIT AGRICOLE BRASIL S.A."
	},
	{
		"key": "224 | BANCO FIBRA S.A.",
		"value": "BANCO FIBRA S.A."
	},
	{
		"key": "233 | BANCO CIFRA S.A.",
		"value": "BANCO CIFRA S.A."
	},
	{
		"key": "237 | BANCO BRADESCO S.A.",
		"value": "BANCO BRADESCO S.A."
	},
	{
		"key": "241 | BANCO CLASSICO S.A.",
		"value": "BANCO CLASSICO S.A."
	},
	{
		"key": "243 | BANCO MASTER S/A",
		"value": "BANCO MASTER S/A"
	},
	{
		"key": "246 | BANCO ABC BRASIL S.A.",
		"value": "BANCO ABC BRASIL S.A."
	},
	{
		"key": "249 | BANCO INVESTCRED UNIBANCO S.A.",
		"value": "BANCO INVESTCRED UNIBANCO S.A."
	},
	{
		"key": "250 | BCV - BANCO DE CR\u00c9DITO E VAREJO S.A.",
		"value": "BCV - BANCO DE CR\u00c9DITO E VAREJO S.A."
	},
	{
		"key": "253 | BEXS CORRETORA DE C\u00c2MBIO S/A",
		"value": "BEXS CORRETORA DE C\u00c2MBIO S/A"
	},
	{
		"key": "254 | PARAN\u00c1 BANCO S.A.",
		"value": "PARAN\u00c1 BANCO S.A."
	},
	{
		"key": "259 | MONEYCORP BANCO DE C\u00c2MBIO S.A.",
		"value": "MONEYCORP BANCO DE C\u00c2MBIO S.A."
	},
	{
		"key": "260 | NU PAGAMENTOS S.A. - INSTITUI\u00c7\u00c3O DE PAGAMENTO",
		"value": "NU PAGAMENTOS S.A. - INSTITUI\u00c7\u00c3O DE PAGAMENTO"
	},
	{
		"key": "265 | BANCO FATOR S.A.",
		"value": "BANCO FATOR S.A."
	},
	{
		"key": "266 | BANCO CEDULA S.A.",
		"value": "BANCO CEDULA S.A."
	},
	{
		"key": "268 | BARI COMPANHIA HIPOTEC\u00c1RIA",
		"value": "BARI COMPANHIA HIPOTEC\u00c1RIA"
	},
	{
		"key": "269 | BANCO HSBC S.A.",
		"value": "BANCO HSBC S.A."
	},
	{
		"key": "270 | SAGITUR CORRETORA DE C\u00c2MBIO S.A.",
		"value": "SAGITUR CORRETORA DE C\u00c2MBIO S.A."
	},
	{
		"key": "271 | IB CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "IB CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "272 | AGK CORRETORA DE CAMBIO S.A.",
		"value": "AGK CORRETORA DE CAMBIO S.A."
	},
	{
		"key": "273 | COOPERATIVA DE CR\u00c9DITO RURAL DE S\u00c3O MIGUEL DO OESTE - SULCREDI/S\u00c3O MIGUEL",
		"value": "COOPERATIVA DE CR\u00c9DITO RURAL DE S\u00c3O MIGUEL DO OESTE - SULCREDI/S\u00c3O MIGUEL"
	},
	{
		"key": "274 | MONEY PLUS SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
		"value": "MONEY PLUS SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT"
	},
	{
		"key": "276 | BANCO SENFF S.A.",
		"value": "BANCO SENFF S.A."
	},
	{
		"key": "278 | GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILI\u00c1RIOS S.A.",
		"value": "GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "279 | COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE",
		"value": "COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE"
	},
	{
		"key": "280 | WILL FINANCEIRA S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "WILL FINANCEIRA S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "281 | COOPERATIVA DE CR\u00c9DITO RURAL COOPAVEL",
		"value": "COOPERATIVA DE CR\u00c9DITO RURAL COOPAVEL"
	},
	{
		"key": "283 | RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA",
		"value": "RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA"
	},
	{
		"key": "285 | FRENTE CORRETORA DE C\u00c2MBIO LTDA.",
		"value": "FRENTE CORRETORA DE C\u00c2MBIO LTDA."
	},
	{
		"key": "286 | COOPERATIVA DE CR\u00c9DITO RURAL DE OURO SULCREDI/OURO",
		"value": "COOPERATIVA DE CR\u00c9DITO RURAL DE OURO SULCREDI/OURO"
	},
	{
		"key": "288 | CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
		"value": "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
	},
	{
		"key": "289 | EFX CORRETORA DE C\u00c2MBIO LTDA.",
		"value": "EFX CORRETORA DE C\u00c2MBIO LTDA."
	},
	{
		"key": "290 | PAGSEGURO INTERNET INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
		"value": "PAGSEGURO INTERNET INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
	},
	{
		"key": "292 | BS2 DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "BS2 DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "293 | LASTRO RDV DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "LASTRO RDV DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "296 | OZ CORRETORA DE C\u00c2MBIO S.A.",
		"value": "OZ CORRETORA DE C\u00c2MBIO S.A."
	},
	{
		"key": "298 | VIP'S CORRETORA DE C\u00c2MBIO LTDA.",
		"value": "VIP'S CORRETORA DE C\u00c2MBIO LTDA."
	},
	{
		"key": "299 | BANCO SOROCRED S.A. - BANCO M\u00daLTIPLO",
		"value": "BANCO SOROCRED S.A. - BANCO M\u00daLTIPLO"
	},
	{
		"key": "300 | BANCO DE LA NACION ARGENTINA",
		"value": "BANCO DE LA NACION ARGENTINA"
	},
	{
		"key": "301 | BPP INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
		"value": "BPP INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
	},
	{
		"key": "306 | PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
		"value": "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
	},
	{
		"key": "307 | TERRA INVESTIMENTOS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "TERRA INVESTIMENTOS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "309 | CAMBIONET CORRETORA DE C\u00c2MBIO LTDA.",
		"value": "CAMBIONET CORRETORA DE C\u00c2MBIO LTDA."
	},
	{
		"key": "310 | VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
		"value": "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
	},
	{
		"key": "311 | DOURADA CORRETORA DE C\u00c2MBIO LTDA.",
		"value": "DOURADA CORRETORA DE C\u00c2MBIO LTDA."
	},
	{
		"key": "312 | HSCM - SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE LT",
		"value": "HSCM - SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE LT"
	},
	{
		"key": "313 | AMAZ\u00d4NIA CORRETORA DE C\u00c2MBIO LTDA.",
		"value": "AMAZ\u00d4NIA CORRETORA DE C\u00c2MBIO LTDA."
	},
	{
		"key": "318 | BANCO BMG S.A.",
		"value": "BANCO BMG S.A."
	},
	{
		"key": "319 | OM DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
		"value": "OM DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
	},
	{
		"key": "320 | CHINA CONSTRUCTION BANK (BRASIL) BANCO M\u00daLTIPLO S/A",
		"value": "CHINA CONSTRUCTION BANK (BRASIL) BANCO M\u00daLTIPLO S/A"
	},
	{
		"key": "321 | CREFAZ SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
		"value": "CREFAZ SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT"
	},
	{
		"key": "322 | COOPERATIVA DE CR\u00c9DITO RURAL DE ABELARDO LUZ - SULCREDI/CREDILUZ",
		"value": "COOPERATIVA DE CR\u00c9DITO RURAL DE ABELARDO LUZ - SULCREDI/CREDILUZ"
	},
	{
		"key": "323 | MERCADO PAGO INSTITUI\u00c7\u00c3O DE PAGAMENTO LTDA.",
		"value": "MERCADO PAGO INSTITUI\u00c7\u00c3O DE PAGAMENTO LTDA."
	},
	{
		"key": "324 | CARTOS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "CARTOS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "325 | \u00d3RAMA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "\u00d3RAMA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "326 | PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
		"value": "PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A."
	},
	{
		"key": "328 | COOPERATIVA DE ECONOMIA E CR\u00c9DITO M\u00daTUO DOS FABRICANTES DE CAL\u00c7ADOS DE SAPIRANGA",
		"value": "COOPERATIVA DE ECONOMIA E CR\u00c9DITO M\u00daTUO DOS FABRICANTES DE CAL\u00c7ADOS DE SAPIRANGA"
	},
	{
		"key": "329 | QI SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "QI SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "330 | BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
		"value": "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A."
	},
	{
		"key": "331 | FRAM CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "FRAM CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "332 | ACESSO SOLU\u00c7\u00d5ES DE PAGAMENTO S.A.",
		"value": "ACESSO SOLU\u00c7\u00d5ES DE PAGAMENTO S.A."
	},
	{
		"key": "335 | BANCO DIGIO S.A.",
		"value": "BANCO DIGIO S.A."
	},
	{
		"key": "336 | BANCO C6 S.A.",
		"value": "BANCO C6 S.A."
	},
	{
		"key": "340 | SUPERDIGITAL INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
		"value": "SUPERDIGITAL INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
	},
	{
		"key": "341 | ITA\u00da UNIBANCO S.A.",
		"value": "ITA\u00da UNIBANCO S.A."
	},
	{
		"key": "342 | CREDITAS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "CREDITAS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "343 | FFA SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE LTDA.",
		"value": "FFA SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE LTDA."
	},
	{
		"key": "348 | BANCO XP S.A.",
		"value": "BANCO XP S.A."
	},
	{
		"key": "349 | AL5 S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "AL5 S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "350 | COOPERATIVA DE CR\u00c9DITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGR\u00c1RIA DO CE",
		"value": "COOPERATIVA DE CR\u00c9DITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGR\u00c1RIA DO CE"
	},
	{
		"key": "352 | TORO CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "TORO CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "354 | NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILI\u00c1RIOS E COMMODITIES",
		"value": "NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILI\u00c1RIOS E COMMODITIES"
	},
	{
		"key": "355 | \u00d3TIMO SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "\u00d3TIMO SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "358 | MIDWAY S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "MIDWAY S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "359 | ZEMA CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S/A",
		"value": "ZEMA CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S/A"
	},
	{
		"key": "360 | TRINUS CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "TRINUS CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "362 | CIELO S.A.",
		"value": "CIELO S.A."
	},
	{
		"key": "363 | SINGULARE CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "SINGULARE CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "364 | GERENCIANET S.A.",
		"value": "GERENCIANET S.A."
	},
	{
		"key": "365 | SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A.",
		"value": "SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A."
	},
	{
		"key": "366 | BANCO SOCIETE GENERALE BRASIL S.A.",
		"value": "BANCO SOCIETE GENERALE BRASIL S.A."
	},
	{
		"key": "367 | VITREO DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "VITREO DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "368 | BANCO CSF S.A.",
		"value": "BANCO CSF S.A."
	},
	{
		"key": "370 | BANCO MIZUHO DO BRASIL S.A.",
		"value": "BANCO MIZUHO DO BRASIL S.A."
	},
	{
		"key": "371 | WARREN CORRETORA DE VALORES MOBILI\u00c1RIOS E C\u00c2MBIO LTDA.",
		"value": "WARREN CORRETORA DE VALORES MOBILI\u00c1RIOS E C\u00c2MBIO LTDA."
	},
	{
		"key": "373 | UP.P SOCIEDADE DE EMPR\u00c9STIMO ENTRE PESSOAS S.A.",
		"value": "UP.P SOCIEDADE DE EMPR\u00c9STIMO ENTRE PESSOAS S.A."
	},
	{
		"key": "374 | REALIZE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S.A.",
		"value": "REALIZE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S.A."
	},
	{
		"key": "376 | BANCO J.P. MORGAN S.A.",
		"value": "BANCO J.P. MORGAN S.A."
	},
	{
		"key": "377 | BMS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "BMS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "378 | BANCO BRASILEIRO DE CR\u00c9DITO SOCIEDADE AN\u00d4NIMA",
		"value": "BANCO BRASILEIRO DE CR\u00c9DITO SOCIEDADE AN\u00d4NIMA"
	},
	{
		"key": "379 | COOPERFORTE - COOPERATIVA DE ECONOMIA E CR\u00c9DITO M\u00daTUO DE FUNCION\u00c1RIOS DE INSTITU",
		"value": "COOPERFORTE - COOPERATIVA DE ECONOMIA E CR\u00c9DITO M\u00daTUO DE FUNCION\u00c1RIOS DE INSTITU"
	},
	{
		"key": "380 | PICPAY INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
		"value": "PICPAY INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
	},
	{
		"key": "381 | BANCO MERCEDES-BENZ DO BRASIL S.A.",
		"value": "BANCO MERCEDES-BENZ DO BRASIL S.A."
	},
	{
		"key": "382 | FID\u00daCIA SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE L",
		"value": "FID\u00daCIA SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE L"
	},
	{
		"key": "383 | EBANX INSTITUICAO DE PAGAMENTOS LTDA.",
		"value": "EBANX INSTITUICAO DE PAGAMENTOS LTDA."
	},
	{
		"key": "384 | GLOBAL FINAN\u00c7AS SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO",
		"value": "GLOBAL FINAN\u00c7AS SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO"
	},
	{
		"key": "385 | COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V",
		"value": "COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V"
	},
	{
		"key": "386 | NU FINANCEIRA S.A. - SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "NU FINANCEIRA S.A. - SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "387 | BANCO TOYOTA DO BRASIL S.A.",
		"value": "BANCO TOYOTA DO BRASIL S.A."
	},
	{
		"key": "389 | BANCO MERCANTIL DO BRASIL S.A.",
		"value": "BANCO MERCANTIL DO BRASIL S.A."
	},
	{
		"key": "390 | BANCO GM S.A.",
		"value": "BANCO GM S.A."
	},
	{
		"key": "391 | COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM",
		"value": "COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM"
	},
	{
		"key": "393 | BANCO VOLKSWAGEN S.A.",
		"value": "BANCO VOLKSWAGEN S.A."
	},
	{
		"key": "394 | BANCO BRADESCO FINANCIAMENTOS S.A.",
		"value": "BANCO BRADESCO FINANCIAMENTOS S.A."
	},
	{
		"key": "395 | F.D'GOLD - DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "F.D'GOLD - DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "396 | HUB PAGAMENTOS S.A",
		"value": "HUB PAGAMENTOS S.A"
	},
	{
		"key": "397 | LISTO SOCIEDADE DE CREDITO DIRETO S.A.",
		"value": "LISTO SOCIEDADE DE CREDITO DIRETO S.A."
	},
	{
		"key": "398 | IDEAL CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "IDEAL CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "399 | KIRTON BANK S.A. - BANCO M\u00daLTIPLO",
		"value": "KIRTON BANK S.A. - BANCO M\u00daLTIPLO"
	},
	{
		"key": "400 | COOPERATIVA DE CR\u00c9DITO, POUPAN\u00c7A E SERVI\u00c7OS FINANCEIROS DO CENTRO OESTE - CREDIT",
		"value": "COOPERATIVA DE CR\u00c9DITO, POUPAN\u00c7A E SERVI\u00c7OS FINANCEIROS DO CENTRO OESTE - CREDIT"
	},
	{
		"key": "401 | IUGU INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
		"value": "IUGU INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
	},
	{
		"key": "402 | COBUCCIO SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "COBUCCIO SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "403 | CORA SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "CORA SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "404 | SUMUP SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "SUMUP SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "406 | ACCREDITO - SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "ACCREDITO - SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "407 | \u00cdNDIGO INVESTIMENTOS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "\u00cdNDIGO INVESTIMENTOS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "408 | BONUSPAGO SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "BONUSPAGO SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "410 | PLANNER SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR S.A.",
		"value": "PLANNER SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR S.A."
	},
	{
		"key": "411 | VIA CERTA FINANCIADORA S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTOS",
		"value": "VIA CERTA FINANCIADORA S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTOS"
	},
	{
		"key": "412 | SOCIAL BANK BANCO M\u00daLTIPLO S/A",
		"value": "SOCIAL BANK BANCO M\u00daLTIPLO S/A"
	},
	{
		"key": "413 | BANCO BV S.A.",
		"value": "BANCO BV S.A."
	},
	{
		"key": "414 | WORK SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "WORK SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "416 | LAMARA SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "LAMARA SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "418 | ZIPDIN SOLU\u00c7\u00d5ES DIGITAIS SOCIEDADE DE CR\u00c9DITO DIRETO S/A",
		"value": "ZIPDIN SOLU\u00c7\u00d5ES DIGITAIS SOCIEDADE DE CR\u00c9DITO DIRETO S/A"
	},
	{
		"key": "419 | NUMBRS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "NUMBRS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "421 | LAR COOPERATIVA DE CR\u00c9DITO - LAR CREDI",
		"value": "LAR COOPERATIVA DE CR\u00c9DITO - LAR CREDI"
	},
	{
		"key": "422 | BANCO SAFRA S.A.",
		"value": "BANCO SAFRA S.A."
	},
	{
		"key": "423 | COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILI\u00c1RIOS",
		"value": "COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILI\u00c1RIOS"
	},
	{
		"key": "425 | SOCINAL S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "SOCINAL S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "426 | BIORC FINANCEIRA - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S.A.",
		"value": "BIORC FINANCEIRA - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S.A."
	},
	{
		"key": "427 | COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO",
		"value": "COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO"
	},
	{
		"key": "428 | CREDSYSTEM SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "CREDSYSTEM SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "429 | CREDIARE S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "CREDIARE S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "430 | COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA",
		"value": "COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA"
	},
	{
		"key": "433 | BR-CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "BR-CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "435 | DELCRED SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "DELCRED SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "438 | PLANNER TRUSTEE DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "PLANNER TRUSTEE DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "439 | ID CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "ID CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "440 | CREDIBRF - COOPERATIVA DE CR\u00c9DITO",
		"value": "CREDIBRF - COOPERATIVA DE CR\u00c9DITO"
	},
	{
		"key": "442 | MAGNETIS - DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
		"value": "MAGNETIS - DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
	},
	{
		"key": "443 | CREDIHOME SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "CREDIHOME SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "444 | TRINUS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "TRINUS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "445 | PLANTAE S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "PLANTAE S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "447 | MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MO",
		"value": "MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MO"
	},
	{
		"key": "448 | HEMERA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "HEMERA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "449 | DMCARD SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "DMCARD SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "450 | FITBANK PAGAMENTOS ELETRONICOS S.A.",
		"value": "FITBANK PAGAMENTOS ELETRONICOS S.A."
	},
	{
		"key": "451 | J17 - SOCIEDADE DE CR\u00c9DITO DIRETO S/A",
		"value": "J17 - SOCIEDADE DE CR\u00c9DITO DIRETO S/A"
	},
	{
		"key": "452 | CREDIFIT SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "CREDIFIT SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "454 | M\u00c9RITO DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "M\u00c9RITO DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "456 | BANCO MUFG BRASIL S.A.",
		"value": "BANCO MUFG BRASIL S.A."
	},
	{
		"key": "457 | UY3 SOCIEDADE DE CR\u00c9DITO DIRETO S/A",
		"value": "UY3 SOCIEDADE DE CR\u00c9DITO DIRETO S/A"
	},
	{
		"key": "458 | HEDGE INVESTMENTS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "HEDGE INVESTMENTS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "459 | COOPERATIVA DE CR\u00c9DITO M\u00daTUO DE SERVIDORES P\u00daBLICOS DO ESTADO DE S\u00c3O PAULO - CRE",
		"value": "COOPERATIVA DE CR\u00c9DITO M\u00daTUO DE SERVIDORES P\u00daBLICOS DO ESTADO DE S\u00c3O PAULO - CRE"
	},
	{
		"key": "460 | UNAVANTI SOCIEDADE DE CR\u00c9DITO DIRETO S/A",
		"value": "UNAVANTI SOCIEDADE DE CR\u00c9DITO DIRETO S/A"
	},
	{
		"key": "461 | ASAAS GEST\u00c3O FINANCEIRA INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
		"value": "ASAAS GEST\u00c3O FINANCEIRA INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
	},
	{
		"key": "462 | STARK SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "STARK SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "463 | AZUMI DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "AZUMI DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "464 | BANCO SUMITOMO MITSUI BRASILEIRO S.A.",
		"value": "BANCO SUMITOMO MITSUI BRASILEIRO S.A."
	},
	{
		"key": "465 | CAPITAL CONSIG SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "CAPITAL CONSIG SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "467 | MASTER S/A CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS",
		"value": "MASTER S/A CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS"
	},
	{
		"key": "468 | PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "469 | LIGA INVEST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
		"value": "LIGA INVEST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
	},
	{
		"key": "470 | CDC SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESADE PEQUENO PORTE LTDA.",
		"value": "CDC SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESADE PEQUENO PORTE LTDA."
	},
	{
		"key": "471 | COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINH\u00c3O - CRES",
		"value": "COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINH\u00c3O - CRES"
	},
	{
		"key": "473 | BANCO CAIXA GERAL - BRASIL S.A.",
		"value": "BANCO CAIXA GERAL - BRASIL S.A."
	},
	{
		"key": "477 | CITIBANK N.A.",
		"value": "CITIBANK N.A."
	},
	{
		"key": "478 | GAZINCRED S.A. SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
		"value": "GAZINCRED S.A. SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
	},
	{
		"key": "479 | BANCO ITAUBANK S.A.",
		"value": "BANCO ITAUBANK S.A."
	},
	{
		"key": "484 | MAF DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
		"value": "MAF DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
	},
	{
		"key": "487 | DEUTSCHE BANK S.A. - BANCO ALEMAO",
		"value": "DEUTSCHE BANK S.A. - BANCO ALEMAO"
	},
	{
		"key": "488 | JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
		"value": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION"
	},
	{
		"key": "492 | ING BANK N.V.",
		"value": "ING BANK N.V."
	},
	{
		"key": "495 | BANCO DE LA PROVINCIA DE BUENOS AIRES",
		"value": "BANCO DE LA PROVINCIA DE BUENOS AIRES"
	},
	{
		"key": "505 | BANCO CREDIT SUISSE (BRASIL) S.A.",
		"value": "BANCO CREDIT SUISSE (BRASIL) S.A."
	},
	{
		"key": "506 | RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA",
		"value": "RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA"
	},
	{
		"key": "508 | AVENUE SECURITIES DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "AVENUE SECURITIES DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "511 | MAGNUM SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
		"value": "MAGNUM SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
	},
	{
		"key": "512 | CAPTALYS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
		"value": "CAPTALYS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
	},
	{
		"key": "545 | SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A",
		"value": "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A"
	},
	{
		"key": "600 | BANCO LUSO BRASILEIRO S.A.",
		"value": "BANCO LUSO BRASILEIRO S.A."
	},
	{
		"key": "604 | BANCO INDUSTRIAL DO BRASIL S.A.",
		"value": "BANCO INDUSTRIAL DO BRASIL S.A."
	},
	{
		"key": "610 | BANCO VR S.A.",
		"value": "BANCO VR S.A."
	},
	{
		"key": "611 | BANCO PAULISTA S.A.",
		"value": "BANCO PAULISTA S.A."
	},
	{
		"key": "612 | BANCO GUANABARA S.A.",
		"value": "BANCO GUANABARA S.A."
	},
	{
		"key": "613 | OMNI BANCO S.A.",
		"value": "OMNI BANCO S.A."
	},
	{
		"key": "623 | BANCO PAN S.A.",
		"value": "BANCO PAN S.A."
	},
	{
		"key": "626 | BANCO C6 CONSIGNADO S.A.",
		"value": "BANCO C6 CONSIGNADO S.A."
	},
	{
		"key": "630 | BANCO LETSBANK S.A.",
		"value": "BANCO LETSBANK S.A."
	},
	{
		"key": "633 | BANCO RENDIMENTO S.A.",
		"value": "BANCO RENDIMENTO S.A."
	},
	{
		"key": "634 | BANCO TRIANGULO S.A.",
		"value": "BANCO TRIANGULO S.A."
	},
	{
		"key": "637 | BANCO SOFISA S.A.",
		"value": "BANCO SOFISA S.A."
	},
	{
		"key": "643 | BANCO PINE S.A.",
		"value": "BANCO PINE S.A."
	},
	{
		"key": "652 | ITA\u00da UNIBANCO HOLDING S.A.",
		"value": "ITA\u00da UNIBANCO HOLDING S.A."
	},
	{
		"key": "653 | BANCO VOITER S.A.",
		"value": "BANCO VOITER S.A."
	},
	{
		"key": "654 | BANCO DIGIMAIS S.A.",
		"value": "BANCO DIGIMAIS S.A."
	},
	{
		"key": "655 | BANCO VOTORANTIM S.A.",
		"value": "BANCO VOTORANTIM S.A."
	},
	{
		"key": "707 | BANCO DAYCOVAL S.A.",
		"value": "BANCO DAYCOVAL S.A."
	},
	{
		"key": "712 | BANCO OURINVEST S.A.",
		"value": "BANCO OURINVEST S.A."
	},
	{
		"key": "720 | BANCO RNX S.A.",
		"value": "BANCO RNX S.A."
	},
	{
		"key": "739 | BANCO CETELEM S.A.",
		"value": "BANCO CETELEM S.A."
	},
	{
		"key": "741 | BANCO RIBEIRAO PRETO S.A.",
		"value": "BANCO RIBEIRAO PRETO S.A."
	},
	{
		"key": "743 | BANCO SEMEAR S.A.",
		"value": "BANCO SEMEAR S.A."
	},
	{
		"key": "745 | BANCO CITIBANK S.A.",
		"value": "BANCO CITIBANK S.A."
	},
	{
		"key": "746 | BANCO MODAL S.A.",
		"value": "BANCO MODAL S.A."
	},
	{
		"key": "747 | BANCO RABOBANK INTERNATIONAL BRASIL S.A.",
		"value": "BANCO RABOBANK INTERNATIONAL BRASIL S.A."
	},
	{
		"key": "748 | BANCO COOPERATIVO SICREDI S.A.",
		"value": "BANCO COOPERATIVO SICREDI S.A."
	},
	{
		"key": "751 | SCOTIABANK BRASIL S.A. BANCO M\u00daLTIPLO",
		"value": "SCOTIABANK BRASIL S.A. BANCO M\u00daLTIPLO"
	},
	{
		"key": "752 | BANCO BNP PARIBAS BRASIL S.A.",
		"value": "BANCO BNP PARIBAS BRASIL S.A."
	},
	{
		"key": "753 | NOVO BANCO CONTINENTAL S.A. - BANCO M\u00daLTIPLO",
		"value": "NOVO BANCO CONTINENTAL S.A. - BANCO M\u00daLTIPLO"
	},
	{
		"key": "754 | BANCO SISTEMA S.A.",
		"value": "BANCO SISTEMA S.A."
	},
	{
		"key": "755 | BANK OF AMERICA MERRILL LYNCH BANCO M\u00daLTIPLO S.A.",
		"value": "BANK OF AMERICA MERRILL LYNCH BANCO M\u00daLTIPLO S.A."
	},
	{
		"key": "756 | BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB",
		"value": "BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB"
	},
	{
		"key": "757 | BANCO KEB HANA DO BRASIL S.A.",
		"value": "BANCO KEB HANA DO BRASIL S.A."
	}
]

export const naturesExchangeList = [
	{
		"key": 12108,
		"value": "Exportação - Recebimento antecipado - Até 360 dias"
	},
	{
		"key": 12115,
		"value": "Exportação - Recebimento antecipado - mais de 360 dias"
	},
	{
		"key": 12122,
		"value": "Exportação - Recebimento posterior - Até 360 dias"
	},
	{
		"key": 12139,
		"value": "Exportação - Recebimento posterior - mais de 360 dias"
	},
	{
		"key": 12186,
		"value": "Ativos Virtuais"
	},
	{
		"key": 12407,
		"value": "Importação - Pagamento antecipado - Até 180 dias"
	},
	{
		"key": 12414,
		"value": "Importação - Pagamento antecipado - De 181 até 360 dias"
	},
	{
		"key": 12421,
		"value": "Importação - Pagamento antecipado - mais de 360 dias"
	},
	{
		"key": 12438,
		"value": "Importação - Pagamento posterior - Até 180 dias"
	},
	{
		"key": 12445,
		"value": "Importação - Pagamento posterior - De 181 até 360 dias"
	},
	{
		"key": 12452,
		"value": "Importação - Pagamento posterior - mais de 360 dias"
	},
	{
		"key": 12823,
		"value": "Comércio de mercadorias sem trânsito aduaneiro no Brasil (NOVA)"
	},
	{
		"key": 12995,
		"value": "Compra ou venda de mercadoria"
	},
	{
		"key": 22301,
		"value": "Fretes sobre exportação"
	},
	{
		"key": 22318,
		"value": "Fretes sobre importação"
	},
	{
		"key": 22325,
		"value": "Fretamento de meios de transporte com tripulação"
	},
	{
		"key": 22332,
		"value": "Passagens"
	},
	{
		"key": 22349,
		"value": "Outras receitas e despesas de transporte"
	},
	{
		"key": 27100,
		"value": "Seguro de frete/transporte de exportação e de importação"
	},
	{
		"key": 27117,
		"value": "Demais seguros e resseguros - Prêmio"
	},
	{
		"key": 27124,
		"value": "Demais seguros e resseguros - Indenização"
	},
	{
		"key": 32999,
		"value": "Viagem internacional"
	},
	{
		"key": 37028,
		"value": "Impostos"
	},
	{
		"key": 37217,
		"value": "Patrimônio"
	},
	{
		"key": 37303,
		"value": "Manutenção de residentes"
	},
	{
		"key": 37310,
		"value": "Contribuições e benefícios de seguridade social"
	},
	{
		"key": 37327,
		"value": "Contribuições e benefícios de fundos de pensão"
	},
	{
		"key": 37334,
		"value": "Doações e cooperação internacional"
	},
	{
		"key": 37358,
		"value": "Outras transferências unilaterais"
	},
	{
		"key": 37994,
		"value": "Doação ou outra transferência sem contrapartida"
	},
	{
		"key": 46002,
		"value": "Compra ou venda de serviço - Serviço de computação e de informação"
	},
	{
		"key": 46002,
		"value": "Serviços técnicos, profissionais e administrativos - Serviços de computação e de informação"
	},
	{
		"key": 46019,
		"value": "Serviços técnicos, profissionais e administrativos - Serviços financeiros"
	},
	{
		"key": 46026,
		"value": "Serviços técnicos e profissionais  e administrativos - Reparos, manutenção e assistência técnica"
	},
	{
		"key": 46033,
		"value": "Serviços técnicos, profissionais e administrativos - Agricultura, mineração, tratamento de resíduos e despoluição e serviços relacionados"
	},
	{
		"key": 46040,
		"value": "Serviços técnicos, profissionais e administrativos - Serviços relacionados a gestão e jurídicos"
	},
	{
		"key": 46057,
		"value": "Serviços técnicos, profissionais e administrativos - Serviços de engenharia/arquitetura e outros serviços técnicos, profissionais e administrativos"
	},
	{
		"key": 46105,
		"value": "Construção"
	},
	{
		"key": 46112,
		"value": "Marcas registradas e franquias - Cessão"
	},
	{
		"key": 46129,
		"value": "Patentes e tecnologia - Cessão"
	},
	{
		"key": 46136,
		"value": "Marcas, franquias, patentes e tecnologia – Direito de utilização"
	},
	{
		"key": 46150,
		"value": "Serviços pessoais, culturais, de saúde, de educação e de entretenimento - Jogos e apostas"
	},
	{
		"key": 46167,
		"value": "Serviços pessoais, culturais, de saúde, de educação e de entretenimento - Demais"
	},
	{
		"key": 46198,
		"value": "Receitas e despesas governamentais"
	},
	{
		"key": 46208,
		"value": "Outros - Aluguel de imóveis e de equipamentos"
	},
	{
		"key": 46215,
		"value": "Outros - Compra e venda de imóveis"
	},
	{
		"key": 46222,
		"value": "Outros - Reembolsos por serviços prestados ou recebidos – empresas de mesmo grupo econômico"
	},
	{
		"key": 46239,
		"value": "Outros - Cessão de créditos"
	},
	{
		"key": 46246,
		"value": "Outros - Indenizações não relacionadas a seguro"
	},
	{
		"key": 46978,
		"value": "Compra ou venda de serviço - Serviço de Negócio"
	},
	{
		"key": 46992,
		"value": "Compra ou venda de serviço - Outro serviço"
	},
	{
		"key": 46992,
		"value": "Outro serviço"
	},
	{
		"key": 47001,
		"value": "Serviços técnicos, profissionais e administrativos - Serviços postais e courier"
	},
	{
		"key": 47018,
		"value": "Serviços técnicos, profissionais e administrativos - Serviços de telecomunicações"
	},
	{
		"key": 47063,
		"value": "Serviços técnicos e profissionais  e administrativos - Pesquisa e desenvolvimento"
	},
	{
		"key": 47111,
		"value": "Serviços técnicos, profissionais e administrativos - Serviços de manufatura"
	},
	{
		"key": 47173,
		"value": "Serviços técnicos, profissionais e administrativos - Audiovisuais e serviços relacionados"
	},
	{
		"key": 47551,
		"value": "Direitos autorais - Licença para cópia e distribuição - programas de computador"
	},
	{
		"key": 47568,
		"value": "Direitos autorais - Licença para cópia e distribuição - outros"
	},
	{
		"key": 47575,
		"value": "Direitos autorais - Cessão e Uso - programas de computador"
	},
	{
		"key": 47582,
		"value": "Direitos autorais - Cessão e Uso - outros"
	},
	{
		"key": 47609,
		"value": "Comissões e outras despesas sobre transações comerciais"
	},
	{
		"key": 47908,
		"value": "Outros - Salários e outras compensações"
	},
	{
		"key": 47922,
		"value": "Outros - Direitos econômicos e federativos de atletas profissionais"
	},
	{
		"key": 47939,
		"value": "Outros - Créditos de carbono/direitos de emissão"
	},
	{
		"key": 52027,
		"value": "Mercado financeiro e de capitais - Ações e fundos de investimento - Dividendos/distribuição de lucros e Juros sobre capital próprio"
	},
	{
		"key": 52106,
		"value": "Mercado financeiro e de capitais - Títulos de dívida - juros de títulos no País"
	},
	{
		"key": 52113,
		"value": "Mercado financeiro e de capitais - Títulos de dívida - juros de títulos - mercado externo"
	},
	{
		"key": 52144,
		"value": "Mercado financeiro e de capitais - Títulos de dívida - ágios e deságios no lançamento ou na recompra de títulos brasileiros"
	},
	{
		"key": 52429,
		"value": "Empréstimos, financiamentos, antecipações, linhas de crédito e arrendamentos mercantis financeiros - Juros sobre operações relacionadas a comércio exterior"
	},
	{
		"key": 52436,
		"value": "Empréstimos, financiamentos, antecipações, linhas de crédito e arrendamentos mercantis financeiros - Juros sobre demais operações"
	},
	{
		"key": 52443,
		"value": "Investimento direto - Dividendos/distribuição de lucros e juros sobre capital próprio"
	},
	{
		"key": 52508,
		"value": "Depósitos - Juros sobre depósitos"
	},
	{
		"key": 67005,
		"value": "Mercado financeiro e de capitais - Ações"
	},
	{
		"key": 67043,
		"value": "Mercado financeiro e de capitais - Fundos de investimentos"
	},
	{
		"key": 67050,
		"value": "Mercado financeiro e de capitais - Brazilian Depositary Receipts (BDR)"
	},
	{
		"key": 67108,
		"value": "Mercado financeiro e de capitais - Títulos de Dívida - até 360 dias"
	},
	{
		"key": 67115,
		"value": "Mercado financeiro e de capitais - Títulos de Dívida - mais de 360 dias"
	},
	{
		"key": 67201,
		"value": "Mercado financeiro e de capitais - Derivativos - Prêmios de opções e ajustes periódicos"
	},
	{
		"key": 67218,
		"value": "Mercado financeiro e de capitais - Derivativos - Depósito e resgate de margens, garantias e colaterais"
	},
	{
		"key": 67438,
		"value": "Empréstimos, financiamentos, antecipações, linhas de crédito e arrendamentos mercantis financeiros  -  de gastos locais ou não relacionados a operações de comércio exterior - até 360 dias"
	},
	{
		"key": 67445,
		"value": "Empréstimos, financiamentos, antecipações, linhas de crédito e arrendamentos mercantis financeiros  -  de gastos locais ou não relacionados a operações de comércio exterior - mais de 360 dias"
	},
	{
		"key": 67476,
		"value": "Investimento direto - Relacionado a fusão ou aquisição"
	},
	{
		"key": 67483,
		"value": "Investimento direto - Demais"
	},
	{
		"key": 67531,
		"value": "Depósitos"
	},
	{
		"key": 67919,
		"value": "Outros - Participação do Brasil no capital de organismos internacionais"
	},
	{
		"key": 67995,
		"value": "Transferência entre contas da mesma pessoa natural ou jurídica"
	},
	{
		"key": 72007,
		"value": "Mercado financeiro e de capitais - Ações"
	},
	{
		"key": 72045,
		"value": "Mercado financeiro e de capitais - Fundos de investimentos"
	},
	{
		"key": 72090,
		"value": "Mercado financeiro e de capitais - Depositary Receipts (DR)"
	},
	{
		"key": 72117,
		"value": "Mercado financeiro e de capitais - Títulos no País"
	},
	{
		"key": 72124,
		"value": "Mercado financeiro e de capitais - Títulos no mercado externo - até 360 dias"
	},
	{
		"key": 72131,
		"value": "Mercado financeiro e de capitais - Títulos no mercado externo - mais de 360 dias"
	},
	{
		"key": 72210,
		"value": "Mercado financeiro e de capitais - Derivativos - depósito e resgate de margens, garantias e colaterais"
	},
	{
		"key": 72241,
		"value": "Mercado financeiro e de capitais - Derivativos - prêmios de opções e ajustes periódicos"
	},
	{
		"key": 72296,
		"value": "Mercado financeiro e de capitais - Outros"
	},
	{
		"key": 72423,
		"value": "Empréstimos, financiamentos, antecipações, linhas de crédito e arrendamentos mercantis financeiros - de gastos locais ou não relacionados a operações de comércio exterior - até 360 dias"
	},
	{
		"key": 72430,
		"value": "Empréstimos, financiamentos, antecipações, linhas de crédito e arrendamentos mercantis financeiros - de gastos locais ou não relacionados a operações de comércio exterior - mais de 360 dias"
	},
	{
		"key": 72447,
		"value": "Investimento direto - Relacionado a fusão ou aquisição"
	},
	{
		"key": 72454,
		"value": "Investimento direto - Demais"
	},
	{
		"key": 72533,
		"value": "Depósitos"
	},
	{
		"key": 72980,
		"value": "Credito externo - Principal"
	},
	{
		"key": 72997,
		"value": "Crédito Externo  - Juros"
	},
	{
		"key": 91992,
		"value": "Demais"
	}

]

export const oldnaturesExchangeList = [
	{
		key: "10007",
		value: "Exportação - Exportação de Mercadorias"
	},
	{
		key: "10124",
		value: "Exportação - Exportação em Consignação"
	},
	{
		key: "10306",
		value: "Exportação - Jóias, Gemas, Pedras Preciosas e Artefatos de Ouro e de Pedras Preciosas"
	},
	{
		key: "10409",
		value: "Exportação - Câmbio Simplificado"
	},
	{
		key: "10423",
		value: "Exportação - Fornecimento de combustíveis, lubrificantes e outros"
	},
	{
		key: "10447",
		value: "Exportação - Operações de back to back"
	},
	{
		key: "12005",
		value: "Comércio Exterior - Exportação de mercadorias"
	},
	{
		key: "12012",
		value: "Comércio Exterior - Importação de mercadorias"
	},
	{
		key: "12029",
		value: "Comércio Exterior - Operações de back to back"
	},
	{
		key: "12036",
		value: "Comércio Exterior - Encomendas internacionais"
	},
	{
		key: "12043",
		value: "Comércio Exterior - Ajustes em transações comerciais"
	},
	{
		key: "15002",
		value: "Importação - Importação Geral"
	},
	{
		key: "15442",
		value: "Importação - Operações de back to back"
	},
	{
		key: "15507",
		value: "Importação - Importação de Petróleo Bruto (NCM 2709.00.10)"
	},
	{
		key: "15538",
		value: "Importação - Importação de Petróleo Derivados (NCM - posições 2710 a 2713)"
	},
	{
		key: "15600",
		value: "Importação - Importação de Livros e Periódicos"
	},
	{
		key: "15806",
		value: "Importação - Câmbio Simplificado"
	},
	{
		key: "15900",
		value: "Importação - Loja Franca"
	},
	{
		key: "20107",
		value: "Transportes Aéreos - Resgate de Estadia (dispach money)"
	},
	{
		key: "20114",
		value: "Transportes Marítimos - Resgate de Estadia (dispach money)"
	},
	{
		key: "20121",
		value: "Transportes Terrestres - Resgate de Estadia (dispach money)"
	},
	{
		key: "20138",
		value: "Transportes Aéreos - Sobreestadias (demurrages)"
	},
	{
		key: "20145",
		value: "Transportes Marítimos - Sobreestadias (demurrages)"
	},
	{
		key: "20152",
		value: "Transportes Terrestres - Sobreestadias (demurrages)"
	},
	{
		key: "20169",
		value: "Transportes Aéreos - Supervisão de Pesagens"
	},
	{
		key: "20176",
		value: "Transportes Marítimos - Supervisão de Pesagens"
	},
	{
		key: "20183",
		value: "Transportes Terrestres - Supervisão de Pesagens"
	},
	{
		key: "20190",
		value: "Transporte - Outros - Aereo"
	},
	{
		key: "20200",
		value: "Transporte - Outros - Maritimo"
	},
	{
		key: "20217",
		value: "Transporte - Outros - Terrestre"
	},
	{
		key: "20310",
		value: "Transportes Aéreos - Aluguel de Contêineres ou Paletes"
	},
	{
		key: "20327",
		value: "Transportes Marítimos - Aluguel de Contêineres ou Paletes"
	},
	{
		key: "20334",
		value: "Transportes Terrestres - Aluguel de Contêineres ou Paletes"
	},
	{
		key: "20406",
		value: "Transportes Aéreos - Fretamento"
	},
	{
		key: "20413",
		value: "Transportes Marítimos - Fretamento"
	},
	{
		key: "20420",
		value: "Transportes Terrestres - Fretamento"
	},
	{
		key: "20468",
		value: "Transportes Aéreos - Reparos de Veículos"
	},
	{
		key: "20475",
		value: "Transportes Marítimos - Reparos de Veículos"
	},
	{
		key: "20482",
		value: "Transportes Terrestres - Reparos de Veículos"
	},
	{
		key: "20619",
		value: "Transporte - Frete - Aereo - Sobre Exportacoes"
	},
	{
		key: "20626",
		value: "Transporte - Frete - Maritimo - S/Exportacoes"
	},
	{
		key: "20633",
		value: "Transporte - Frete - Terrestre - S/Exportacoes"
	},
	{
		key: "20640",
		value: "Transporte - Frete - Aereo - Sobre Importacoes"
	},
	{
		key: "20657",
		value: "Transporte - Frete - Maritimo - Sobre Importacoes"
	},
	{
		key: "20664",
		value: "Transporte - Frete - Terrestre - Sobre Importacoes"
	},
	{
		key: "20671",
		value: "Transporte - Frete - Aereo - Outros"
	},
	{
		key: "20688",
		value: "Transporte - Frete - Maritimo - Outros"
	},
	{
		key: "20695",
		value: "Transporte - Frete - Terrestre - Outros"
	},
	{
		key: "20815",
		value: "Transportes Aéreos - Passagens de empresas de bandeira brasileira"
	},
	{
		key: "20822",
		value: "Transportes Marítimos - Passagens de empresas de bandeira brasileira"
	},
	{
		key: "20839",
		value: "Transportes Terrestres - Passagens de empresas de bandeira brasileira"
	},
	{
		key: "20846",
		value: "Transportes Aéreos - Passagens de empresas de bandeira estrangeira"
	},
	{
		key: "20853",
		value: "Transportes Marítimos - Passagens de empresas de bandeira estrangeira"
	},
	{
		key: "20860",
		value: "Transportes Terrestres - Passagens de empresas de bandeira estrangeira"
	},
	{
		key: "22002",
		value: "Transportes - Aéreo - Fretes - sobre exportação"
	},
	{
		key: "22019",
		value: "Transportes - Aéreo - Fretes - sobre importação"
	},
	{
		key: "22026",
		value: "Transportes - Aéreo - Fretes - outros fretes"
	},
	{
		key: "22033",
		value: "Transportes - Aéreo - Outras receitas/despesas de transporte"
	},
	{
		key: "22040",
		value: "Transportes - Aéreo - Fretamento"
	},
	{
		key: "22057",
		value: "Transportes - Aéreo - Passagens"
	},
	{
		key: "22105",
		value: "Transportes - Marítimo - Fretes - sobre exportação"
	},
	{
		key: "22112",
		value: "Transportes - Marítimo - Fretes - sobre importação"
	},
	{
		key: "22129",
		value: "Transportes - Marítimo - Fretes - outros fretes"
	},
	{
		key: "22136",
		value: "Transportes - Marítimo - Outras receitas/despesas de transporte"
	},
	{
		key: "22143",
		value: "Transportes - Marítimo - Fretamento"
	},
	{
		key: "22150",
		value: "Transportes - Marítimo - Passagens"
	},
	{
		key: "22208",
		value: "Transportes - Rodoviário - Fretes - sobre exportação"
	},
	{
		key: "22215",
		value: "Transportes - Rodoviário - Fretes - sobre importação"
	},
	{
		key: "22222",
		value: "Transportes - Rodoviário - Fretes - outros fretes"
	},
	{
		key: "22239",
		value: "Transportes - Rodoviário - Outras receitas/despesas de transporte"
	},
	{
		key: "22246",
		value: "Transportes - Rodoviário - Fretamento"
	},
	{
		key: "22253",
		value: "Transportes - Rodoviário - Passagens"
	},
	{
		key: "22909",
		value: "Transportes - Outros Modais - Hidroviário"
	},
	{
		key: "22916",
		value: "Transportes - Outros Modais - Transporte por dutos e transmissão de energia"
	},
	{
		key: "22923",
		value: "Transportes - Outros Modais - Ferroviário e aeroespacial"
	},
	{
		key: "25009",
		value: "Seguros-Transp.Int.Merc-Premios-S/Export."
	},
	{
		key: "25016",
		value: "Seguros-Transp.Int.Merc-Premios-S/Import."
	},
	{
		key: "25023",
		value: "Seguro de Transporte Internacional de Mercadorias (exclusive resseguros) - indenizações de sinistros sobre exportação"
	},
	{
		key: "25030",
		value: "Seguro de Transporte Internacional de Mercadorias (exclusive resseguros) - indenizações de sinistros sobre importação"
	},
	{
		key: "25102",
		value: "Seguros - demais seguros - prêmios"
	},
	{
		key: "25119",
		value: "Seguros - demais seguros - indenizações"
	},
	{
		key: "25205",
		value: "Resseguros Colocados no Exterior - prêmios"
	},
	{
		key: "25212",
		value: "Resseguros Colocados no Exterior - indenizações"
	},
	{
		key: "25346",
		value: "Resseguros Aceitos do Exterior - prêmios"
	},
	{
		key: "25353",
		value: "Resseguros Aceitos do Exterior - indenizações"
	},
	{
		key: "25425",
		value: "Seguros - Recuperação de Sinistros"
	},
	{
		key: "25937",
		value: "Seguros - Transferências - Outras"
	},
	{
		key: "27007",
		value: "Seguros - Seguro de frete/transporte de exportação - Prêmio"
	},
	{
		key: "27014",
		value: "Seguros - Seguro de frete/transporte de exportação - Indenização"
	},
	{
		key: "27021",
		value: "Seguros - Seguro de frete/transporte de importação - Prêmio"
	},
	{
		key: "27038",
		value: "Seguros - Seguro de frete/transporte de importação - Indenização"
	},
	{
		key: "27045",
		value: "Seguros - Resseguros - Prêmio"
	},
	{
		key: "27052",
		value: "Seguros - Resseguros - Indenização"
	},
	{
		key: "27069",
		value: "Seguros - Seguros de vida - Prêmio"
	},
	{
		key: "27076",
		value: "Seguros - Seguros de vida - Indenização"
	},
	{
		key: "27083",
		value: "Seguros - Outros seguros diretos - Prêmio"
	},
	{
		key: "27090",
		value: "Seguros - Outros seguros diretos - Indenização"
	},
	{
		key: "27904",
		value: "Seguros - Outros - Recuperação de sinistros"
	},
	{
		key: "27911",
		value: "Seguros - Outros - Outros serviços relacionados a seguros"
	},
	{
		key: "30128",
		value: "Viagens Internacionais - Missões Oficiais de Governos"
	},
	{
		key: "30166",
		value: "Viagens Internacionais - Tratamento de Saúde"
	},
	{
		key: "30403",
		value: "Viagens Internacionais - Turismo - no País"
	},
	{
		key: "32009",
		value: "Viagens Internacionais - Gastos em viagens internacionais - No país"
	},
	{
		key: "32016",
		value: "Viagens Internacionais - Gastos em viagens internacionais - No exterior - turismo"
	},
	{
		key: "32023",
		value: "Viagens Internacionais - Gastos em viagens internacionais - No exterior - outras finalidades"
	},
	{
		key: "32102",
		value: "Viagens Internacionais - Cartões de uso internacional - Aquisição de bens e serviços"
	},
	{
		key: "32119",
		value: "Viagens Internacionais - Cartões de uso internacional - Saques"
	},
	{
		key: "33101",
		value: "Viagens Internacionais - Fins Educacionais, Científicos e Culturais ou Eventos Esportivos"
	},
	{
		key: "33149",
		value: "Viagens Internacionais - Negócios, Serviço ou Treinamento"
	},
	{
		key: "33455",
		value: "Viagens Internacionais - Turismo - no exterior"
	},
	{
		key: "33462",
		value: "Viagens internacionais - Cartões - aquisição de bens e serviços"
	},
	{
		key: "33486",
		value: "Viagens internacionais - Cartões - saques"
	},
	{
		key: "33606",
		value: "Viagens Internacionais - Agências de Turismo e Meios de Hospedagem de Turismo - operações com bancos e outras instituições integrantes do SFN"
	},
	{
		key: "35013",
		value: "Rendas de Capitais - Lançamento, no Exterior, de Títulos Mobiliários Brasileiros - ágios"
	},
	{
		key: "35020",
		value: "Rendas de Capitais - Lançamento, no Exterior, de Títulos Mobiliários Brasileiros - deságios"
	},
	{
		key: "35075",
		value: "Rendas de Capitais - Juros de Financiamento à Importação - petróleo"
	},
	{
		key: "35082",
		value: "Rendas de Capitais - Juros de Financiamento à Importação - outros - curto prazo"
	},
	{
		key: "35099",
		value: "Rendas de Capitais - Juros de Financiamento à Importação - serviços"
	},
	{
		key: "35109",
		value: "Rendas de Capitais - Encargos Acessórios Incidentes sobre o Endividamento Externo - comissões sobre operações de empréstimos e financiamentos"
	},
	{
		key: "35123",
		value: "Rendas de Capitais - Encargos Acessórios Incidentes sobre o Endividamento Externo - outros"
	},
	{
		key: "35343",
		value: "Rendas de Capitais - Juros sobre Acordos do Plano Brasileiro de Financiamento - Projeto 1/A - New Money Facilities"
	},
	{
		key: "35350",
		value: "Rendas de Capitais - Juros sobre Acordos do Plano Brasileiro de Financiamento - MYDFA"
	},
	{
		key: "35367",
		value: "Rendas de Capitais - Juros sobre Depósitos no Banco Central do Brasil"
	},
	{
		key: "35398",
		value: "Rendas de Capitais - Juros sobre Acordos do Plano Brasileiro de Financiamento - Clube de Paris"
	},
	{
		key: "35422",
		value: "Rendas de Capitais - Juros de Empréstimos - empréstimos diretos"
	},
	{
		key: "35446",
		value: "Rendas de Capitais - Juros de Empréstimos - vinculados à exportação"
	},
	{
		key: "35453",
		value: "Rendas de Capitais - Juros de Empréstimos Administrados pelo Banco Central do Brasil"
	},
	{
		key: "35460",
		value: "Rendas de Capitais - Juros de Financiamento à Importação - vinculado à exportação"
	},
	{
		key: "35532",
		value: "Rendas de Capitais - Juros de Financiamento à Exportação de Bens e Serviços - outros - descontos de cambiais"
	},
	{
		key: "35549",
		value: "Rendas de Capitais - Juros de Financiamento à Exportação de Bens e Serviços - outros - créditos utilizados"
	},
	{
		key: "35556",
		value: "Rendas de Capitais - Juros de Pagamento Antecipado de Exportações"
	},
	{
		key: "35563",
		value: "Rendas de Capitais - Juros de Financiamento à Exportação de Bens e Serviços - FINEX - descontos de cambiais"
	},
	{
		key: "35570",
		value: "Rendas de Capitais - Juros de Financiamento à Exportação de Bens e Serviços - FINEX - créditos utilizados"
	},
	{
		key: "35587",
		value: "Rendas de Capitais - Juros de Financiamento à Exportação de Bens e Serviços - FINEX - equalização de taxas"
	},
	{
		key: "35635",
		value: "Rendas de Capitais - Juros de Financiamento à Importação - outros - longo prazo"
	},
	{
		key: "35666",
		value: "Rendas de Capitais - Juros de Mora"
	},
	{
		key: "35680",
		value: "Rendas de Capitais - Juros sobre Contas de Depósito"
	},
	{
		key: "35697",
		value: "Rendas de Capitais - Juros sobre Descobertos em Conta Corrente"
	},
	{
		key: "35707",
		value: "Rendas de Capitais - Juros de Títulos Mobiliários Brasileiros com prazo de aplicação superior a 360 dias - bônus"
	},
	{
		key: "35714",
		value: "Rendas de Capitais - Juros de Mora sobre Depósitos Res. 1.564"
	},
	{
		key: "35721",
		value: "Rendas de Capitais - Juros de Títulos Mobiliários Brasileiros com prazo de aplicação superior a 360 dias - notes"
	},
	{
		key: "35738",
		value: "Rendas de Capitais - Juros de Títulos Mobiliários Brasileiros com prazo de aplicação superior a 360 dias - outros"
	},
	{
		key: "35745",
		value: "Rendas de Capitais - Juros de Títulos Mobiliários Brasileiros com prazo de aplicação superior a 360 dias - commercial papers"
	},
	{
		key: "35769",
		value: "Rendas de Capitais - Juros de Títulos Mobiliários Estrangeiros com prazo de aplicação superior a 360 dias - bônus"
	},
	{
		key: "35776",
		value: "Rendas de Capitais - Juros de Títulos Mobiliários Estrangeiros com prazo de aplicação superior a 360 dias - debêntures"
	},
	{
		key: "35783",
		value: "Rendas de Capitais - Juros de Títulos Mobiliários Estrangeiros com prazo de aplicação superior a 360 dias - outros"
	},
	{
		key: "35790",
		value: "Rendas de Capitais - Juros de Títulos Mobiliários (Brasileiros e Estrangeiros) com prazo de aplicação inferior a 360 dias"
	},
	{
		key: "35800",
		value: "Rendas de Capitais - Juros de Transações Especiais - General Account"
	},
	{
		key: "35817",
		value: "Rendas de Capitais - Juros de Transações Especiais - Special Drawing Account"
	},
	{
		key: "35824",
		value: "Rendas de Capitais - Juros de Transações Especiais - outras"
	},
	{
		key: "35848",
		value: "Rendas Cap-Jrs Fin Exp Bens Serv-Proex-Cred Utiliz"
	},
	{
		key: "35855",
		value: "Rend Cap-Jrs Fin Exp Bens Serv-Proex-Desc Cambiais"
	},
	{
		key: "35862",
		value: "Rendas Cap-Jrs Fin Exp Bens Serv-Proex-Equaliz Txs"
	},
	{
		key: "35879",
		value: "Rendas Capitais-Jrs Fin Exp Bens Serv-Finamex"
	},
	{
		key: "35886",
		value: "Rendas de Capitais - Juros de Financiamento à Exportação de Bens e Serviços - recursos próprios"
	},
	{
		key: "35903",
		value: "Rendas de Capitais - Juros sobre Arrendamentos"
	},
	{
		key: "35965",
		value: "Rendas de Capitais - Juros sobre outros Créditos Utilizados"
	},
	{
		key: "36300",
		value: "Outras Rendas de Capitais - Ganhos ou perdas em aplicações financeiras no exterior"
	},
	{
		key: "36902",
		value: "Outras Rendas de Capitais - Investimento em portfolio (Res. 1.289 e 2.689) - dividendos"
	},
	{
		key: "36919",
		value: "Outras Rendas de Capitais - Investimento em portfolio (Res. 1.289 e 2.689) - bonificações em dinheiro"
	},
	{
		key: "36957",
		value: "Outras Rendas de Capitais - Investimento Direto - lucros, dividendos e bonificações em dinheiro"
	},
	{
		key: "36964",
		value: "Outras Rendas de Capitais - Investimento em portfolio (Res. 1.289 e 2.689) - juros sobre capital próprio (renda variável)"
	},
	{
		key: "36971",
		value: "Outras Rendas de Capitais - Investimento Direto - remuneração do capital próprio (juros)"
	},
	{
		key: "36988",
		value: "Outras Rendas de Capitais - Investimento em portfolio (Res. 1.289 e 2.689) - juros (renda fixa)"
	},
	{
		key: "37004",
		value: "Transferências Unilaterais - Transferências correntes - Manutenção de residentes"
	},
	{
		key: "37011",
		value: "Transferências Unilaterais - Transferências correntes - Manutenção de estudantes"
	},
	{
		key: "37028",
		value: "Transferências Unilaterais - Transferências correntes - Impostos"
	},
	{
		key: "37035",
		value: "Transferências Unilaterais - Transferências correntes - Contribuições à seguridade social"
	},
	{
		key: "37042",
		value: "Transferências Unilaterais - Transferências correntes - Contribuições a fundos de pensão"
	},
	{
		key: "37059",
		value: "Transferências Unilaterais - Transferências correntes - Recebimento de benefícios de seguridade social"
	},
	{
		key: "37066",
		value: "Transferências Unilaterais - Transferências correntes - Recebimento de benefícios de fundos de pensão"
	},
	{
		key: "37073",
		value: "Transferências Unilaterais - Transferências correntes - Cooperação internacional"
	},
	{
		key: "37080",
		value: "Transferências Unilaterais - Transferências correntes - Doações"
	},
	{
		key: "37097",
		value: "Transferências Unilaterais - Transferências correntes - Vales e reembolsos postais internacionais"
	},
	{
		key: "37107",
		value: "Transferências Unilaterais - Transferências correntes - Outras transferências correntes"
	},
	{
		key: "37200",
		value: "Transferências Unilaterais - Transferências de capital - Doações para obras de infraestrutura e aquisição de bens de capital"
	},
	{
		key: "37217",
		value: "Transferências Unilaterais - Transferências de capital - Patrimônio"
	},
	{
		key: "37224",
		value: "Transferências Unilaterais - Transferências de capital - Outras transferências de capital"
	},
	{
		key: "38405",
		value: "Outras Rendas de Capitais - Aplicações no mercado de capitais - MERCOSUL"
	},
	{
		key: "38508",
		value: "Rendas de Capitais - Outros Juros Contratuais"
	},
	{
		key: "38663",
		value: "Rendas de Capitais - Juros Bancários"
	},
	{
		key: "40008",
		value: "Serviços Governamentais - Rendas e Despesas do Governo brasileiro - militares"
	},
	{
		key: "40101",
		value: "Serviços Governamentais - Rendas e Despesas do Governo brasileiro - diplomáticas, consulares e semelhantes"
	},
	{
		key: "40259",
		value: "Serviços Governamentais - Rendas e Despesas do Governo brasileiro - outras"
	},
	{
		key: "40503",
		value: "Serviços Governamentais - Rendas e Despesas de governos estrangeiros - militares"
	},
	{
		key: "40558",
		value: "Serviços Governamentais - Rendas e Despesas de governos estrangeiros - diplomáticas, consulares e semelhantes"
	},
	{
		key: "40754",
		value: "Serviços Governamentais - Rendas e Despesas de governos estrangeiros - outras"
	},
	{
		key: "40905",
		value: "Serviços Governamentais - Rendas e Despesas de Entidades Internacionais"
	},
	{
		key: "45010",
		value: "Serviços Diversos - Outros - Aluguel de Equipamentos"
	},
	{
		key: "45034",
		value: "Serviços Diversos - Outros - Aluguel de Filmes Cinematográficos"
	},
	{
		key: "45058",
		value: "Serviços Diversos - Outros - Aluguel de Fitas e Discos Gravados"
	},
	{
		key: "45072",
		value: "Serviços Diversos - Outros - Aluguel de Imóveis"
	},
	{
		key: "45096",
		value: "Serviços Diversos - Outros - Assinatura de Jornais, Revistas, etc."
	},
	{
		key: "45110",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Serviços Técnicos Especializados - jurídicos, contábeis, assessoramentos e consultorias"
	},
	{
		key: "45120",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Serviços Técnicos Especializados - agrícolas, minerais e de transformação in loco"
	},
	{
		key: "45130",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Serviços Técnicos Especializados - pesquisa & desenvolvimento - P&D"
	},
	{
		key: "45182",
		value: "Serviços Diversos - Outros - Comunicações"
	},
	{
		key: "45209",
		value: "Serviços Diversos - Outros - Comissões Contratuais - comissões de agentes"
	},
	{
		key: "45223",
		value: "Serviços Diversos - Outros - Comissões Contratuais - outras"
	},
	{
		key: "45261",
		value: "Serviços Diversos - Outros - Corretagens"
	},
	{
		key: "45388",
		value: "Serviços Diversos - Outros - Administrativos - outros"
	},
	{
		key: "45405",
		value: "Serviços Diversos - Outros - Bancários"
	},
	{
		key: "45443",
		value: "Serviços Diversos - Outros - Direitos autorais"
	},
	{
		key: "45500",
		value: "Serviços Diversos - Outros - Créditos de carbono"
	},
	{
		key: "45515",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Patentes - cessão"
	},
	{
		key: "45522",
		value: "Serviços Diversos - Outros - Honorários - membros de conselhos consultivos e/ou administrativos"
	},
	{
		key: "45539",
		value: "Serviços Diversos - Outros - Honorários - remuneração por cursos, palestras e seminários"
	},
	{
		key: "45546",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Marcas - cessão"
	},
	{
		key: "45584",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Fornecimento de serviços e despesas complementares"
	},
	{
		key: "45591",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Franquias"
	},
	{
		key: "45601",
		value: "Serviços Diversos - Outros - Lucros e Perdas em Transações Mercantis com o Exterior"
	},
	{
		key: "45618",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Marcas - licença de uso"
	},
	{
		key: "45625",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Patentes - licença de exploração"
	},
	{
		key: "45632",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Fornecimento de tecnologia"
	},
	{
		key: "45649",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Fornecimento de serviços de assistência técnica"
	},
	{
		key: "45656",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Implantação ou Instalação de Projeto técnico-econômico"
	},
	{
		key: "45663",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Implantação ou Instalação de Projeto industrial"
	},
	{
		key: "45670",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Implantação ou Instalação de Projeto de engenharia"
	},
	{
		key: "45687",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Serviços Técnicos Especializados - projetos, desenhos e modelos industriais"
	},
	{
		key: "45694",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Serviços Técnicos Especializados - projetos, desenhos e modelos de engenharia/arquitetura"
	},
	{
		key: "45704",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Serviços Técnicos Especializados - montagem de equipamentos"
	},
	{
		key: "45711",
		value: "Serviços Diversos - Outros - Outros serviços técnicos - profissionais"
	},
	{
		key: "45728",
		value: "\"Serviços Diversos - Outros - Operações de \"\"Hedge\"\" - mediante opções - resultados\""
	},
	{
		key: "45742",
		value: "Serviços Diversos - Outros - Operações em Bolsas de Mercadorias no Exterior - margem de garantia"
	},
	{
		key: "45759",
		value: "Serviços Diversos - Outros - Operações em Bolsas de Mercadorias no Exterior - corretagens, comissões e despesas"
	},
	{
		key: "45766",
		value: "Serviços Diversos - Outros - Operações em Bolsas de Mercadorias no Exterior - lucros ou prejuízos realizados"
	},
	{
		key: "45780",
		value: "\"Serviços Diversos - Outros - Operações de \"\"Hedge\"\" - mediante \"\"swaps\"\" - resultados\""
	},
	{
		key: "45797",
		value: "Serviços Diversos - Outros - Outros Serviços Ligados às Transações Mercantis com o Exterior"
	},
	{
		key: "45807",
		value: "\"Serviços Diversos - Outros - Operações de \"\"Hedge\"\" - margem de garantia - comissões, prêmios e outras transferências correlatas do e para o exterior\""
	},
	{
		key: "45821",
		value: "Serviços Diversos - Outros - Marcas e Patentes - Registro - Depósito ou Manutenção"
	},
	{
		key: "45838",
		value: "Serviços Diversos - Outros - Operações em Bolsas de Mercadorias no País - margem de garantia"
	},
	{
		key: "45845",
		value: "Serviços Diversos - Outros - Operações em Bolsas de Mercadorias no País - corretagens, comissões e despesas"
	},
	{
		key: "45852",
		value: "Serviços Diversos - Outros - Operações em Bolsas de Mercadorias no País - lucros ou prejuízos realizados"
	},
	{
		key: "45876",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Serviços Técnicos Especializados - outras montagens sob encomenda"
	},
	{
		key: "45883",
		value: "Serviços Diversos - Outros - Publicidade, Propaganda, Marketing e Pesquisa de Opinião"
	},
	{
		key: "45890",
		value: "Serviços Diversos - Outros - Remunerações por Competições ou Exibições"
	},
	{
		key: "45900",
		value: "S.D.-Outros-Serv.Inform.Imprensa E Fin."
	},
	{
		key: "45955",
		value: "Serviços Diversos - Outros - Vencimentos e Ordenados Pessoais"
	},
	{
		key: "45979",
		value: "Serviços Diversos - Outros - Participações em Feiras e Exposições"
	},
	{
		key: "47001",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Serviços postais e courier"
	},
	{
		key: "47018",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Serviços de telecomunicações"
	},
	{
		key: "47025",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Serviços de computação"
	},
	{
		key: "47032",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Serviços financeiros"
	},
	{
		key: "47049",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Corretagens em bolsa de mercadorias ao amparo da Res. 2.687"
	},
	{
		key: "47056",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Aluguel de equipamentos"
	},
	{
		key: "47063",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Pesquisa e desenvolvimento"
	},
	{
		key: "47070",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Serviços de engenharia/arquitetura"
	},
	{
		key: "47087",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Reparos e manutenção em máquinas e veículos"
	},
	{
		key: "47094",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Tratamento de resíduos e despoluição"
	},
	{
		key: "47104",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Agricultura, mineração e serviços relacionados"
	},
	{
		key: "47111",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Serviços de manufatura"
	},
	{
		key: "47128",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Serviços jurídicos"
	},
	{
		key: "47135",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Auditoria, contabilidade e consultoria tributária"
	},
	{
		key: "47142",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Consultoria de negócios e relações públicas"
	},
	{
		key: "47159",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Publicidade, pesquisas de mercado e de opinião e participações em feiras e exposições"
	},
	{
		key: "47166",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Serviços de agências de notícias"
	},
	{
		key: "47173",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Audiovisuais e serviços relacionados"
	},
	{
		key: "47180",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Outros serviços de fornecimento de informação"
	},
	{
		key: "47197",
		value: "Serviços Diversos - Serviços técnicos e profissionais - Outros serviços técnicos, profissionais e administrativos"
	},
	{
		key: "47300",
		value: "Serviços Diversos - Construção - No país"
	},
	{
		key: "47317",
		value: "Serviços Diversos - Construção - No exterior"
	},
	{
		key: "47403",
		value: "Serviços Diversos - Franquias e marcas registradas - Cessão"
	},
	{
		key: "47410",
		value: "Serviços Diversos - Franquias e marcas registradas - Direitos de exploração/utilização"
	},
	{
		key: "47441",
		value: "Serviços Diversos - Patentes - Cessão"
	},
	{
		key: "47458",
		value: "Serviços Diversos - Patentes - Direitos de exploração/utilização"
	},
	{
		key: "47472",
		value: "Serviços Diversos - Patentes - Franquias"
	},
	{
		key: "47506",
		value: "Serviços Diversos - Fornecimento de - Tecnologia"
	},
	{
		key: "47513",
		value: "Serviços Diversos - Fornecimento de - Serviços de assistência técnica"
	},
	{
		key: "47520",
		value: "Serviços Diversos - Fornecimento de - Serviços e despesas complementares"
	},
	{
		key: "47551",
		value: "Serviços Diversos - Direitos autorais - Licença para cópia e distribuição de programas de computador"
	},
	{
		key: "47568",
		value: "Serviços Diversos - Direitos autorais - Licença para cópia e distribuição - outros"
	},
	{
		key: "47575",
		value: "Serviços Diversos - Direitos autorais - Cessão ou uso de programas de computador"
	},
	{
		key: "47582",
		value: "Serviços Diversos - Direitos autorais - Cessão ou uso - outros"
	},
	{
		key: "47609",
		value: "Serviços Diversos - Transações comerciais - Comissões e outras despesas sobre transações comerciais"
	},
	{
		key: "47702",
		value: "Serviços Diversos - Serviços pessoais, culturais e de entretenimento - Serviços de educação em viagem"
	},
	{
		key: "47719",
		value: "Serviços Diversos - Serviços pessoais, culturais e de entretenimento - Serviços de educação"
	},
	{
		key: "47726",
		value: "Serviços Diversos - Serviços pessoais, culturais e de entretenimento - Serviços de saúde em viagem"
	},
	{
		key: "47733",
		value: "Serviços Diversos - Serviços pessoais, culturais e de entretenimento - Serviços de saúde"
	},
	{
		key: "47740",
		value: "Serviços Diversos - Serviços pessoais, culturais e de entretenimento - Serviços turísticos"
	},
	{
		key: "47757",
		value: "Serviços Diversos - Serviços pessoais, culturais e de entretenimento - Outros serviços pessoais, culturais e de entretenimento"
	},
	{
		key: "47805",
		value: "Serviços Diversos - Receitas e despesas governamentais - Militares"
	},
	{
		key: "47812",
		value: "Serviços Diversos - Receitas e despesas governamentais - Corpos consulares e diplomáticos"
	},
	{
		key: "47829",
		value: "Serviços Diversos - Receitas e despesas governamentais - Outros"
	},
	{
		key: "47908",
		value: "Serviços Diversos - Outros - Salários e outras compensações"
	},
	{
		key: "47915",
		value: "Serviços Diversos - Outros - Aluguel de imóveis"
	},
	{
		key: "47922",
		value: "Serviços Diversos - Outros - Direitos econômicos e federativos de atletas profissionais"
	},
	{
		key: "47939",
		value: "Serviços Diversos - Outros - Créditos de carbono/direitos de emissão"
	},
	{
		key: "48000",
		value: "Serviços Diversos - Outros - Garantia - bancária"
	},
	{
		key: "48010",
		value: "Serviços Diversos - Outros - Garantia - outras"
	},
	{
		key: "48110",
		value: "Serviços Diversos - Exportação/Importação de Serviços - Direitos Autorais sobre programas de computador"
	},
	{
		key: "48158",
		value: "Serviços Diversos - Outros - Utilização de Banco de Dados Internacional"
	},
	{
		key: "48323",
		value: "Serviços Diversos - Outros - Cursos e Congressos"
	},
	{
		key: "48354",
		value: "Serviços Diversos - Outros - Administrativos - instalação ou manutenção de escritório"
	},
	{
		key: "48385",
		value: "Serviços Diversos - Outros - Pequenos Compromissos"
	},
	{
		key: "48419",
		value: "Serviços Diversos - Outros - Fiança de Crédito à Exportação"
	},
	{
		key: "48457",
		value: "Serviços Diversos - Outros - Passe de Atletas Profissionais"
	},
	{
		key: "48804",
		value: "Serviços Diversos - Outros - Encomendas Internacionais"
	},
	{
		key: "48914",
		value: "Serviços Diversos - Outros - Serviços Postais"
	},
	{
		key: "48938",
		value: "Serviços Diversos - Outros - Transmissão de Eventos"
	},
	{
		key: "48969",
		value: "Serviços Diversos - Outros - Cartões de Uso Internacional - outras receitas e despesas"
	},
	{
		key: "48990",
		value: "Serviços Diversos - Outros - Serviços Turísticos"
	},
	{
		key: "50005",
		value: "Transferências Unilaterais - Bilhetes e Prêmios de Loterias Oficiais"
	},
	{
		key: "50043",
		value: "Transferências Unilaterais - Contribuições para Organizações Internacionais - custeio"
	},
	{
		key: "50050",
		value: "Transferências Unilaterais - Contribuições para Organizações Internacionais - outros"
	},
	{
		key: "50108",
		value: "Transferências Unilaterais - Doações"
	},
	{
		key: "50153",
		value: "Transferências Unilaterais - Imposto de Renda"
	},
	{
		key: "50201",
		value: "Transferências Unilaterais - Indenizações e Multas"
	},
	{
		key: "50256",
		value: "Transferências Unilaterais - Outros Impostos e Taxas"
	},
	{
		key: "50304",
		value: "Transferências Unilaterais - Reparações de Guerra"
	},
	{
		key: "50600",
		value: "Transferências Unilaterais - Transferências de pequeno valor"
	},
	{
		key: "52003",
		value: "Rendas de Capitais - Mercado financeiro e de capitais - Ações e fundos de investimento - dividendos/distribuição de lucros"
	},
	{
		key: "52010",
		value: "Rendas de Capitais - Mercado financeiro e de capitais - Ações e fundos de investimento - juros sobre capital próprio"
	},
	{
		key: "52106",
		value: "Rendas de Capitais - Mercado financeiro e de capitais - Títulos de dívida - juros de títulos - no país"
	},
	{
		key: "52113",
		value: "Rendas de Capitais - Mercado financeiro e de capitais - Títulos de dívida - juros de títulos - mercado externo"
	},
	{
		key: "52120",
		value: "Rendas de Capitais - Mercado financeiro e de capitais - Títulos de dívida - ágios e deságios no lançamento de títulos brasileiros"
	},
	{
		key: "52137",
		value: "Rendas de Capitais - Mercado financeiro e de capitais - Títulos de dívida - ágios e deságios na recompra de títulos brasileiros"
	},
	{
		key: "52302",
		value: "Rendas de Capitais - Empréstimos e financiamentos - Juros de empréstimos"
	},
	{
		key: "52319",
		value: "Rendas de Capitais - Empréstimos e financiamentos - Juros sobre linhas de crédito"
	},
	{
		key: "52333",
		value: "Rendas de Capitais - Empréstimos e financiamentos - Juros sobre antecipações e financiamentos - exportação"
	},
	{
		key: "52357",
		value: "Rendas de Capitais - Empréstimos e financiamentos - Juros sobre antecipações e financiamentos - importação - curto prazo"
	},
	{
		key: "52364",
		value: "Rendas de Capitais - Empréstimos e financiamentos - Juros sobre antecipações e financiamentos - importação - longo prazo"
	},
	{
		key: "52371",
		value: "Rendas de Capitais - Empréstimos e financiamentos - Juros sobre antecipações e financiamentos - demais financiamentos"
	},
	{
		key: "52395",
		value: "Rendas de Capitais - Empréstimos e financiamentos - Juros de arrendamentos"
	},
	{
		key: "52405",
		value: "Rendas de Capitais - Investimento direto - Dividendos/distribuição de lucros"
	},
	{
		key: "52412",
		value: "Rendas de Capitais - Investimento direto - Juros sobre capital próprio"
	},
	{
		key: "52508",
		value: "Rendas de Capitais - Depósitos e disponibilidades - Juros sobre depósitos e disponibilidades"
	},
	{
		key: "52900",
		value: "Rendas de Capitais - Outros - Ganhos ou perdas em aplicações financeiras no exterior"
	},
	{
		key: "52917",
		value: "Rendas de Capitais - Outros - Juros de mora e multas por atraso de pagamento"
	},
	{
		key: "53435",
		value: "Transferências Unilaterais - Contribuições a Entidades de Classe e Associativas"
	},
	{
		key: "53552",
		value: "Transferências Unilaterais - Heranças e Legados"
	},
	{
		key: "53617",
		value: "Transferências Unilaterais - Aposentadorias e Pensões"
	},
	{
		key: "53631",
		value: "Transferências Unilaterais - Prêmios Auferidos em Eventos Culturais, Esportivos e Outros"
	},
	{
		key: "53741",
		value: "Transferências Unilaterais - Vales e Reembolsos Postais Internacionais"
	},
	{
		key: "53758",
		value: "Transferências Unilaterais - Manutenção de Residentes"
	},
	{
		key: "53909",
		value: "Transferências Unilaterais - Patrimônio"
	},
	{
		key: "55000",
		value: "Capitais Brasileiros a Curto Prazo - Disponibilidades no Exterior"
	},
	{
		key: "55048",
		value: "Capitais Brasileiros a Curto Prazo - Obrigações Vinculadas a Operações Interbancárias"
	},
	{
		key: "55093",
		value: "Capitais Brasileiros a Curto Prazo - Disponibilidades em Contas Especiais - Special Accounts"
	},
	{
		key: "55111",
		value: "Capitais Brasileiros a Curto Prazo - Aplicações no mercado financeiro"
	},
	{
		key: "55127",
		value: "Capitais Brasileiros a Curto Prazo - Cauções"
	},
	{
		key: "55251",
		value: "Capitais Brasileiros a Curto Prazo - Depósitos Judiciais"
	},
	{
		key: "55309",
		value: "Capitais Brasileiros a Curto Prazo - Exportação - vinculada a empréstimo"
	},
	{
		key: "55402",
		value: "Capitais Brasileiros a Curto Prazo - Financiamentos ao Exterior para Exportações Brasileiras de mercadorias - PROEX - parte não financiada"
	},
	{
		key: "55419",
		value: "Capitais Brasileiros a Curto Prazo - Financiamentos ao Exterior para Exportações Brasileiras de mercadorias - PROEX - amortização"
	},
	{
		key: "55426",
		value: "Capitais Brasileiros a Curto Prazo - Financiamentos ao Exterior para Exportações Brasileiras de serviços - PROEX - parte não financiada"
	},
	{
		key: "55428",
		value: "Capitais Brasileiros a Curto Prazo - Financiamentos ao Exterior para Exportações Brasileiras de mercadorias - Outros - parte não financiada"
	},
	{
		key: "55433",
		value: "Capitais Brasileiros a Curto Prazo - Financiamentos ao Exterior para Exportações Brasileiras de serviços - PROEX - amortização"
	},
	{
		key: "55440",
		value: "Capitais Brasileiros a Curto Prazo - Financiamentos ao Exterior para Exportações Brasileiras de serviços - Outros - parte não financiada"
	},
	{
		key: "55450",
		value: "Capitais Brasileiros a Curto Prazo - Financiamentos ao Exterior para Exportações Brasileiras de mercadorias - Outros - Amortização"
	},
	{
		key: "55470",
		value: "Capitais Brasileiros a Curto Prazo - Financiamentos ao Exterior para Exportações Brasileiras de serviços - Outros - Amortização"
	},
	{
		key: "55505",
		value: "Capitais Brasileiros a Curto Prazo - Empréstimos a Residentes no Exterior - empréstimos diretos"
	},
	{
		key: "55510",
		value: "Capitais Brasileiros a Curto Prazo - Empréstimos a Residentes no Exterior - notes"
	},
	{
		key: "55520",
		value: "Capitais Brasileiros a Curto Prazo - Empréstimos a Residentes no Exterior - commercial paper"
	},
	{
		key: "55530",
		value: "Capitais Brasileiros a Curto Prazo - Empréstimos a Residentes no Exterior - bônus"
	},
	{
		key: "55567",
		value: "Capitais Brasileiros a Curto Prazo - Depósitos em Contas no País em Moeda Estrangeira"
	},
	{
		key: "58100",
		value: "Capitais Brasileiros a Curto Prazo - Aplicações no mercado de capitais - MERCOSUL"
	},
	{
		key: "58203",
		value: "Capitais Brasileiros a Curto Prazo - Operações com Ouro"
	},
	{
		key: "60174",
		value: "Capitais Estrangeiros a Curto Prazo - Cauções"
	},
	{
		key: "60208",
		value: "Capitais Estrangeiros a Curto Prazo - Depósitos em Contas no País em Moeda Estrangeira de Ressegurador"
	},
	{
		key: "60325",
		value: "Capitais Estrangeiros a Curto Prazo - Depósitos Judiciais"
	},
	{
		key: "60507",
		value: "Capitais Estrangeiros a Curto Prazo - Empréstimos a Residentes no Brasil - empréstimos diretos"
	},
	{
		key: "60514",
		value: "Capitais Estrangeiros a Curto Prazo - Empréstimos a Residentes no Brasil - bridge loans"
	},
	{
		key: "60600",
		value: "Capitais Estrangeiros a Curto Prazo - Empréstimos a Residentes no Brasil - commercial papers"
	},
	{
		key: "60703",
		value: "Capitais Estrangeiros a Curto Prazo - Empréstimos a Residentes no Brasil - bônus"
	},
	{
		key: "60758",
		value: "Capitais Estrangeiros a Curto Prazo - Empréstimos a Residentes no Brasil - notes"
	},
	{
		key: "63009",
		value: "Capitais Estrangeiros a Curto Prazo - Disponibilidades no País"
	},
	{
		key: "63102",
		value: "Capitais Estrangeiros a Curto Prazo - Movimentações no País em Contas de Domiciliados no Exterior - aplic. financ. e resgates na própria instituição"
	},
	{
		key: "63150",
		value: "Capitais Estrangeiros a Curto Prazo - Movimentações no País em Contas de Domiciliados no Exterior - em contrapartida a operações de câmbio"
	},
	{
		key: "63205",
		value: "Capitais Estrangeiros a Curto Prazo - Aplicações no mercado de capitais - MERCOSUL"
	},
	{
		key: "65007",
		value: "Capitais Brasileiros a Longo Prazo - Empréstimos a Residentes no Exterior - empréstimos diretos"
	},
	{
		key: "65010",
		value: "Capitais Brasileiros a Longo Prazo - Empréstimos a Residentes no Exterior - notes"
	},
	{
		key: "65020",
		value: "Capitais Brasileiros a Longo Prazo - Empréstimos a Residentes no Exterior - commercial paper"
	},
	{
		key: "65030",
		value: "Capitais Brasileiros a Longo Prazo - Empréstimos a Residentes no Exterior - bônus"
	},
	{
		key: "65050",
		value: "Capitais Brasileiros a Longo Prazo - Arrendamento Mercantil Financeiro - Leasing"
	},
	{
		key: "65076",
		value: "Capitais Brasileiros a Longo Prazo - Cauções"
	},
	{
		key: "65100",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de mercadorias - PROEX - parte não financiada"
	},
	{
		key: "65117",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de serviços - PROEX - parte não financiada"
	},
	{
		key: "65131",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de mercadorias - outros - parte não financiada"
	},
	{
		key: "65148",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de mercadorias - BNDES-exim - parte não financiada"
	},
	{
		key: "65155",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de mercadorias - rec. próprios - parte não financiada"
	},
	{
		key: "65179",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de serviços - outros - parte não financiada"
	},
	{
		key: "65186",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de serviços - recursos próprios - parte não financiada"
	},
	{
		key: "65193",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de serviços - BNDES-exim - parte não financiada"
	},
	{
		key: "65210",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de mercadorias - outros - amortização"
	},
	{
		key: "65227",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de mercadorias - PROEX - amortização"
	},
	{
		key: "65234",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de serviços - BNDES-exim - amortização"
	},
	{
		key: "65258",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de serviços - outros - amortização"
	},
	{
		key: "65265",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de serviços - PROEX - amortização"
	},
	{
		key: "65272",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de mercadorias - BNDES-exim - amortização"
	},
	{
		key: "65289",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de mercadorias - recursos próprios - amortização"
	},
	{
		key: "65296",
		value: "Capitais Brasileiros a Longo Prazo - Financiamentos ao Exterior para Exportações Brasileiras - de serviços - recursos próprios - amortização"
	},
	{
		key: "65306",
		value: "Capitais Brasileiros a Longo Prazo - Exportação - Vinculada a Empréstimo"
	},
	{
		key: "65409",
		value: "Capitais Brasileiros a Longo Prazo - Investimentos em Portfolio no Exterior - fundos de investimento"
	},
	{
		key: "65454",
		value: "Capitais Brasileiros a Longo Prazo - Investimentos em Portfolio no Exterior - Brazilian Depositary Receipts"
	},
	{
		key: "65540",
		value: "Capitais Brasileiros a Longo Prazo - Investimentos em Portfolio no Exterior - Depositary Receipts"
	},
	{
		key: "65612",
		value: "Capitais Brasileiros a Longo Prazo - Participação do Brasil no Capital de Organismos Internacionais"
	},
	{
		key: "65825",
		value: "Capitais Brasileiros a Longo Prazo - Investimentos em Portfolio no Exterior - títulos mobiliários estrangeiros - ações"
	},
	{
		key: "65856",
		value: "Capitais Brasileiros a Longo Prazo - Investimentos em Portfolio no Exterior - títulos mobiliários estrangeiros - bônus"
	},
	{
		key: "65863",
		value: "Capitais Brasileiros a Longo Prazo - Investimentos em Portfolio no Exterior - títulos mobiliários estrangeiros - debêntures"
	},
	{
		key: "67005",
		value: "Capitais Brasileiros - Mercado financeiro e de capitais - Ações"
	},
	{
		key: "67043",
		value: "Capitais Brasileiros - Mercado financeiro e de capitais - Fundos de investimento"
	},
	{
		key: "67081",
		value: "Capitais Brasileiros - Mercado financeiro e de capitais - Brazilian Depositary Receipts (BDR) - ações"
	},
	{
		key: "67098",
		value: "Capitais Brasileiros - Mercado financeiro e de capitais - Brazilian Depositary Receipts (BDR) - outros valores mobiliários"
	},
	{
		key: "67108",
		value: "Capitais Brasileiros - Mercado financeiro e de capitais - Títulos de dívida - curto prazo"
	},
	{
		key: "67115",
		value: "Capitais Brasileiros - Mercado financeiro e de capitais - Títulos de dívida - longo prazo"
	},
	{
		key: "67201",
		value: "Capitais Brasileiros - Mercado financeiro e de capitais - Derivativos - prêmios de opções e ajustes periódicos"
	},
	{
		key: "67218",
		value: "Capitais Brasileiros - Mercado financeiro e de capitais - Derivativos - depósito e resgate de margens, garantias e colaterais"
	},
	{
		key: "67304",
		value: "Capitais Brasileiros - Empréstimos e financiamentos - Empréstimos diretos - curto prazo"
	},
	{
		key: "67311",
		value: "Capitais Brasileiros - Empréstimos e financiamentos - Empréstimos diretos - longo prazo"
	},
	{
		key: "67335",
		value: "Capitais Brasileiros - Empréstimos e financiamentos - Financiamentos de exportação de mercadorias - curto prazo"
	},
	{
		key: "67342",
		value: "Capitais Brasileiros - Empréstimos e financiamentos - Financiamentos de exportação de mercadorias - longo prazo"
	},
	{
		key: "67366",
		value: "Capitais Brasileiros - Empréstimos e financiamentos - Financiamentos de exportação de serviços - curto prazo"
	},
	{
		key: "67373",
		value: "Capitais Brasileiros - Empréstimos e financiamentos - Financiamentos de exportação de serviços - longo prazo"
	},
	{
		key: "67397",
		value: "Capitais Brasileiros - Empréstimos e financiamentos - Arrendamento mercantil financeiro"
	},
	{
		key: "67407",
		value: "Capitais Brasileiros - Investimento direto - Aumento/redução de capital"
	},
	{
		key: "67414",
		value: "Capitais Brasileiros - Investimento direto - Aquisição/transferência de titularidade"
	},
	{
		key: "67500",
		value: "Capitais Brasileiros - Depósitos e disponibilidades - Disponibilidades no exterior"
	},
	{
		key: "67517",
		value: "Capitais Brasileiros - Depósitos e disponibilidades - Depósitos em conta no país em moeda estrangeira"
	},
	{
		key: "67524",
		value: "Capitais Brasileiros - Depósitos e disponibilidades - Depósitos judiciais, cauções, garantias e outros recursos de terceiros"
	},
	{
		key: "67902",
		value: "Capitais Brasileiros - Outros - Aquisição de mercadorias entregues no exterior"
	},
	{
		key: "67919",
		value: "Capitais Brasileiros - Outros - Participação do Brasil no capital de organismos internacionais"
	},
	{
		key: "67926",
		value: "Capitais Brasileiros - Outros - Obrigações vinculadas a operações interbancárias"
	},
	{
		key: "67933",
		value: "Capitais Brasileiros - Outros - Operações com ouro"
	},
	{
		key: "67940",
		value: "Capitais Brasileiros - Outros - Compra e venda de imóveis no exterior"
	},
	{
		key: "67957",
		value: "Regularização Lei nº 13.254, de 2016"
	},
	{
		key: "68303",
		value: "Capitais Brasileiros a Longo Prazo - Investimento Direto no Exterior - participação em empresas"
	},
	{
		key: "68509",
		value: "Capitais Brasileiros a Longo Prazo - Investimentos em Portfolio no Exterior - por parte de pessoas físicas"
	},
	{
		key: "68657",
		value: "Capitais Brasileiros a Longo Prazo - Investimento Direto no Exterior - outros investimentos (inclui imóveis e outros bens)"
	},
	{
		key: "70016",
		value: "Capitais Estrangeiros a Longo Prazo - Empréstimos a Residentes no Brasil - empréstimos diretos"
	},
	{
		key: "70030",
		value: "Capitais Estrangeiros a Longo Prazo - Empréstimos a Residentes no Brasil - Projeto 1/A- New Money Facilities"
	},
	{
		key: "70054",
		value: "Capitais Estrangeiros a Longo Prazo - Empréstimos a Residentes no Brasil - Clube de Paris"
	},
	{
		key: "70061",
		value: "Capitais Estrangeiros a Longo Prazo - Empréstimos a Residentes no Brasil - vinculados à exportação"
	},
	{
		key: "70078",
		value: "Capitais Estrangeiros a Longo Prazo - Cauções"
	},
	{
		key: "70126",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos Diretos no Brasil - participação em empresas no País - capital complementar - instrum. híbridos"
	},
	{
		key: "70133",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos Diretos no Brasil - participação em empresas no País - para absorção de prejuízos"
	},
	{
		key: "70188",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos Diretos no Brasil - participação em empresas no País - para aumento de capital"
	},
	{
		key: "70205",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos Diretos no Brasil - participação em empresas no País - para transferência de titularidade"
	},
	{
		key: "70339",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos em Portfolio no Brasil - fundos de investimento - Depositary Receipts"
	},
	{
		key: "70353",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos em Portfolio no Brasil - fundos de investimento - fundos mútuos de invest. em empresas emergentes"
	},
	{
		key: "70377",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos em Portfolio no Brasil - fundos de investimento- fundos de Investimento Imobiliário"
	},
	{
		key: "70418",
		value: "Capitais Estrangeiros a Longo Prazo - Empréstimos a Residentes no Brasil - Bônus"
	},
	{
		key: "70425",
		value: "Capitais Estrangeiros a Longo Prazo - Empréstimos a Residentes no Brasil - Notes"
	},
	{
		key: "70432",
		value: "Capitais Estrangeiros a Longo Prazo - Financiamentos de Importação Registrados no Banco Central - amortização - mercadorias - outras"
	},
	{
		key: "70487",
		value: "Capitais Estrangeiros a Longo Prazo - Amortização - Merc - Outras"
	},
	{
		key: "70494",
		value: "Capitais Estrangeiros a Longo Prazo - Financiamentos de Importação Registrados no Banco Central - serviços"
	},
	{
		key: "70528",
		value: "Capitais Estrangeiros a Longo Prazo - Financiamentos de Importação Registrados no Banco Central - vinculado à exportação"
	},
	{
		key: "70535",
		value: "Capitais Estrangeiros a Longo Prazo - Financiamentos de Importação Registrados no Banco Central - ingresso - gastos locais"
	},
	{
		key: "70542",
		value: "Capitais Estrangeiros a Longo Prazo - Compromissos no Mercado Interno"
	},
	{
		key: "70566",
		value: "Capitais Estrangeiros a Longo Prazo - Financiamentos de Importação Registrados no Banco Central - amortização - mercadorias - petróleo"
	},
	{
		key: "70607",
		value: "Capitais Estrangeiros a Longo Prazo - Empréstimos a Residentes no Brasil - Commercial papers"
	},
	{
		key: "70700",
		value: "Capitais Estrangeiros a Longo Prazo - ArrendamentoMercantil Financeiro (Leasing)"
	},
	{
		key: "70720",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos em Portfolio no Brasil - aplicação ao amparo da Res. 2.689 - ações"
	},
	{
		key: "70833",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos em Portfolio no Brasil - aplicação ao amparo da Res. 2.689 - títulos"
	},
	{
		key: "70835",
		value: "Capitais Estrangeiros a LP - Investimentos em Portfolio no Brasil - aplicação Res.2.689 - títulos/valores mobiliários (arts. 1º e 3º da Lei nº 12.431)"
	},
	{
		key: "70875",
		value: "Capitais Estrangeiros a Longo Prazo - Investimentos em Portfolio no Brasil - aplicação ao amparo da Res. 2.689 - outros"
	},
	{
		key: "72007",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Ações"
	},
	{
		key: "72045",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Fundos de investimento"
	},
	{
		key: "72052",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Fundos mútuos de investimento em empresas emergentes"
	},
	{
		key: "72069",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Fundos de investimento imobiliário"
	},
	{
		key: "72076",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Depositary Receipts (DR) - ações"
	},
	{
		key: "72083",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Depositary Receipts (DR) - outros valores mobiliários"
	},
	{
		key: "72100",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos privados de dívida - no país - curto prazo"
	},
	{
		key: "72117",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos privados de dívida - no país - longo prazo"
	},
	{
		key: "72124",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos públicos de dívida - no país - curto prazo"
	},
	{
		key: "72131",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos públicos de dívida - no país - longo prazo"
	},
	{
		key: "72148",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos privados de dívida - mercado externo - curto prazo"
	},
	{
		key: "72155",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos privados de dívida - mercado externo - longo prazo"
	},
	{
		key: "72162",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos públicos de dívida - mercado externo - curto prazo"
	},
	{
		key: "72179",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos públicos de dívida - mercado externo - longo prazo"
	},
	{
		key: "72193",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Títulos e valores mobiliários (arts. 1º e 3º da Lei nº 12.431)"
	},
	{
		key: "72203",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Derivativos - prêmios de opções e ajustes periódicos"
	},
	{
		key: "72210",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Derivativos - depósito e resgate de margens, garantias e colaterais"
	},
	{
		key: "72234",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Derivativos - prêmios de opções e ajustes ao amparo da Res. nº 2.687"
	},
	{
		key: "72296",
		value: "Capitais Estrangeiros - Mercado financeiro e de capitais - Outros"
	},
	{
		key: "72344",
		value: "Capitais Estrangeiros - Empréstimos e financiamentos - Empréstimos diretos - curto prazo"
	},
	{
		key: "72351",
		value: "Capitais Estrangeiros - Empréstimos e financiamentos - Empréstimos diretos - longo prazo"
	},
	{
		key: "72368",
		value: "Capitais Estrangeiros - Empréstimos e financiamentos - Financiamentos - importação e gastos locais vinculados à importação - longo prazo"
	},
	{
		key: "72375",
		value: "Capitais Estrangeiros - Empréstimos e financiamentos - Financiamentos - gastos locais vinculados à importação - curto prazo"
	},
	{
		key: "72382",
		value: "Capitais Estrangeiros - Empréstimos e financiamentos - Financiamentos - demais financiamentos"
	},
	{
		key: "72399",
		value: "Capitais Estrangeiros - Empréstimos e financiamentos - Arrendamento mercantil financeiro"
	},
	{
		key: "72409",
		value: "Capitais Estrangeiros - Investimento direto - Aumento/redução de capital"
	},
	{
		key: "72416",
		value: "Capitais Estrangeiros - Investimento direto - Aquisição/transferência de titularidade"
	},
	{
		key: "72502",
		value: "Capitais Estrangeiros - Depósitos e disponibilidades - Disponibilidades no país"
	},
	{
		key: "72519",
		value: "Capitais Estrangeiros - Depósitos e disponibilidades - Disponibilidades no país em moeda estrangeira"
	},
	{
		key: "72526",
		value: "Capitais Estrangeiros - Depósitos e disponibilidades - Depósitos judiciais, cauções, garantias e outros recursos de terceiros"
	},
	{
		key: "72605",
		value: "Capitais Estrangeiros - Movimentações no país em contas de domiciliados no exterior - Aplicações financeiras e resgates na própria instituição"
	},
	{
		key: "72612",
		value: "Capitais Estrangeiros - Movimentações no país em contas de domiciliados no exterior - Em contrapartida a operações de câmbio"
	},
	{
		key: "72904",
		value: "Capitais Estrangeiros - Outros - Aquisição de mercadorias entregues no país"
	},
	{
		key: "72911",
		value: "Capitais Estrangeiros - Outros - Compra e venda de imóveis no país"
	},
	{
		key: "73659",
		value: "Capitais Estrangeiros a Longo Prazo - Aquisição de Imóveis"
	},
	{
		key: "75004",
		value: "Ouro Monetário - Compras no Exterior"
	},
	{
		key: "75107",
		value: "Ouro Monetário - Compras no País"
	},
	{
		key: "75509",
		value: "Ouro Monetário - Vendas no Exterior"
	},
	{
		key: "75602",
		value: "Ouro Monetário - Vendas no País"
	},
	{
		key: "80013",
		value: "Arbitragens - Operações no País - liquidação pronta"
	},
	{
		key: "80518",
		value: "Arbitragens - Operações no País - liquidação futura"
	},
	{
		key: "83034",
		value: "Arbitragens - Operações no Exterior - liquidação pronta"
	},
	{
		key: "83058",
		value: "Arbitragens - Operações no Exterior - liquidação futura"
	},
	{
		key: "90003",
		value: "Operações entre Instituições - Operações no País - interbancário não automático - liquidação pronta"
	},
	{
		key: "90302",
		value: "Operações entre Instituições - Operações no País - interbancário - liquidação pronta e futura"
	},
	{
		key: "90357",
		value: "Operações entre Instituições - Operações no País - interbancário - liquidação a termo"
	},
	{
		key: "90500",
		value: "Operações entre Instituições - Operações com instituição bancária do exterior, em contrapartida a reais em espécie rec. do ou env. para o exterior"
	},
	{
		key: "90508",
		value: "Operações entre Instituições - Operações no País - interbancário não automático - liquidação futura"
	},
	{
		key: "93017",
		value: "Operações entre Instituições - Operações no País - com ouro - liquidação pronta"
	},
	{
		key: "93024",
		value: "Operações entre Instituições - Operações no País - com ouro - liquidação futura"
	},
	{
		key: "95008",
		value: "Operações com o Banco Central do Brasil - Repasses Específicos"
	},
	{
		key: "95101",
		value: "Operações com o Banco Central do Brasil - Vendas de Mercado ao Banco Central"
	},
	{
		key: "95204",
		value: "Operações com o Banco Central do Brasil - Repasses Obrigatórios"
	},
	{
		key: "95503",
		value: "Operações com o Banco Central do Brasil - Coberturas Específicas"
	},
	{
		key: "95620",
		value: "Operações com o Banco Central do Brasil - Compras de Mercado ao Banco Central"
	},
	{
		key: "99000",
		value: "Operações Especiais - Ajuste da posição cambial relativamente a operações com informações enviadas via aplicativo PSTAW10"
	},
	{
		key: "99176",
		value: "Operações Especiais - Assunção de Dívidas"
	},
	{
		key: "99183",
		value: "Operações Especiais - Pagamento da Dívida Externa para Aplicação em Projetos Ambientais"
	},
	{
		key: "99200",
		value: "Operações Especiais - Outras"
	},
	{
		key: "99217",
		value: "Operações Especiais - Encadeamento PROEX"
	},
	{
		key: "99224",
		value: "Operações Especiais - Encadeamento BNDES-exim"
	},
	{
		key: "99671",
		value: "Operações Especiais - Depósitos no Banco Central do Brasil - Circular 1.303"
	},
	{
		key: "67964",
		value: "Regularização Lei nº 13.428, de 2017"
	},
	{
		key: "32205",
		value: "Aquisição de Bens e Serviços"
	},
	{
		key: "67911",
		value: "Licença para cópia e distribuição"
	}
]

export const BrazilStates = [
	{ key: 'AC', value: 'Acre' },
	{ key: 'AL', value: 'Alagoas' },
	{ key: 'AP', value: 'Amapá' },
	{ key: 'AM', value: 'Amazonas' },
	{ key: 'BA', value: 'Bahia' },
	{ key: 'CE', value: 'Ceará' },
	{ key: 'DF', value: 'Distrito Federal' },
	{ key: 'ES', value: 'Espírito Santo' },
	{ key: 'GO', value: 'Goiás' },
	{ key: 'MA', value: 'Maranhão' },
	{ key: 'MT', value: 'Mato Grosso' },
	{ key: 'MS', value: 'Mato Grosso do Sul' },
	{ key: 'MG', value: 'Minas Gerais' },
	{ key: 'PA', value: 'Pará' },
	{ key: 'PB', value: 'Paraíba' },
	{ key: 'PR', value: 'Paraná' },
	{ key: 'PE', value: 'Pernambuco' },
	{ key: 'PI', value: 'Piauí' },
	{ key: 'RJ', value: 'Rio de Janeiro' },
	{ key: 'RN', value: 'Rio Grande do Norte' },
	{ key: 'RS', value: 'Rio Grande do Sul' },
	{ key: 'RO', value: 'Rondônia' },
	{ key: 'RR', value: 'Roraima' },
	{ key: 'SC', value: 'Santa Catarina' },
	{ key: 'SP', value: 'São Paulo' },
	{ key: 'SE', value: 'Sergipe' },
	{ key: 'TO', value: 'Tocantins' }
];

export const replaceByIndex = (list, index, value) => {
	return list.map((item, i) => {
		if (i === index) {
			return value
		} else {
			return item
		}
	})
}
